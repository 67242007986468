import { instrumentsService } from '@/main';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { Prop, Component } from 'vue-property-decorator';
import { PricingPlan } from '@/models/PricingPlan';

import BasePage from '@/models/BasePage';
import Vue from 'vue';

@Component
export default class PricingOverviewComponent extends BasePage {
    @Prop({ default: () => 0 }) public instrumentId: number;

    public pricingPlans: PricingPlan[] = [];

    public columns: GridColumnProps[] = [
        { field: 'pricingPlanName', title: 'Name' },
        { field: 'pricingPlanAmount', title: 'Amount', cell: this.renderAmount },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public async created() {
        await this.getPricingPlans(this.instrumentId);

        this.clearNotifications();
        this.isLoaded = true;
    }

    public renderAmount(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.pricingPlanAmount,
                currencyCode: 'USD',
            },
        });
    }

    public renderActions(item: any, _: any, row: any): any {
        const actions = [
            { title: 'Edit', function: this.openUpdatePricingPlanAction },
            { title: 'Delete', function: this.deletePricingPlan },
        ];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    public openAddPricingPlanAction(): any {
        this.$sideActions.push('pricing-plan-add-action', { instrumentId: this.instrumentId }, async () => {
            await this.getPricingPlans(this.instrumentId);
        });
    }

    public openUpdatePricingPlanAction(pricingPlan: PricingPlan): any {
        this.$sideActions.push('pricing-plan-update-action', { pricingPlan, instrumentId: this.instrumentId }, async () => {
            await this.getPricingPlans(this.instrumentId);
        });
    }

    private async getPricingPlans(id: number) {
        await instrumentsService
            .getInstrumentPricingPlans(id)
            .then((pricingPlans) => {
                this.pricingPlans = pricingPlans;
            })
            .catch(() => {
                this.showError(
                    `The pricing plans couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }

    private async deletePricingPlan(pricingPlan: any) {
        if (confirm(`Are you sure that you want to delete the pricing plan with the name: ${pricingPlan.pricingPlanName}`)) {
            this.showPending('Deleting pricing plan');
            await instrumentsService
                .deletePricingPlan(this.instrumentId, pricingPlan.pricingPlanId)
                .then(async () => {
                    this.clearAndShowSuccess('Pricing plan successfully deleted!');
                    await this.getPricingPlans(this.instrumentId);
                })
                .catch((e) => {
                    this.clearAndShowError('Pricing plan could not be deleted.', e);
                });
        }
    }
}
