import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { organizationGroupMembershipService } from '../../../main';
import { CreateResult, CreationStatus } from '../../../models/CreateResult';
import { EntityCreateState, ParticipantState } from '../../../pages/surveys/models/participant';
import { EntityCreationWrapper, ParticipantThing } from '../../participant-management/baseParticipantManagement';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import ParticipantCreate from '@/modules/participants/models/ParticipantCreate';
import Participant from '@/modules/participants/models/Participant';
import { GridAddMode } from '@/modules/participants/grid/grid-add-mode';

@Component({
    components: {
        'add-existing-participant': require('../../participant-management/addExistingParticipant.vue').default,
        'add-new-participant': require('../../participant-management/addNewParticipant.vue').default,
        'import-participant': require('../../participant-management/importParticipant.vue').default,
    },
})
export default class AddGroupMembersActionComponent extends SideActionBase<AddGroupMembersOptions> {
    public addModes: GridAddMode[] = [];
    public selectedMode: string = '';
    public selectedPersons: Array<EntityCreationWrapper<ParticipantThing>> = [];
    private participantService: ParticipantsService = new ParticipantsService();

    public async created() {
        this.addModes.push(new GridAddMode({ disabled: false, label: 'Add', value: 'add', participantState: ParticipantState.Created }));
    }

    public addParticipant(participant: ParticipantThing, finished: boolean): void {
        this.selectedPersons.push(new EntityCreationWrapper<ParticipantThing>({ entity: participant }));

        if (finished) {
            this.closeSelectedMode();
        }
    }

    public closeSelectedMode(): void {
        this.selectedMode = '';
    }

    public removeParticipantFromSelection(_: ParticipantThing, index: number): void {
        this.selectedPersons.splice(index, 1);
    }

    public async addParticipantsToGroup(): Promise<void> {
        this.showPending('Adding people to group');

        for (let i = 0; i < this.selectedPersons.length; i++) {
            const participantThing = this.selectedPersons[i];

            participantThing.creationStatus = EntityCreateState.Processing;

            if (participantThing.entity.participantAddState !== ParticipantState.Created) {
                await this.participantService
                    .createParticipant(ParticipantCreate.fromParticipant(participantThing.entity.participantData))
                    .then(async (participantData: CreateResult<Participant>) => {
                        if (
                            participantData.creationStatus === CreationStatus.created ||
                            participantData.creationStatus === CreationStatus.duplicate
                        ) {
                            participantThing.entity.participantData.participantId = participantData.entity.participantId;
                        } else {
                            participantThing.creationStatus = EntityCreateState.Failed;
                            participantThing.errorReason = 'Creating this person failed. Possibly this person already exists in this organization.';
                        }
                    })
                    .catch((reason: any) => {
                        participantThing.creationStatus = EntityCreateState.Failed;
                        participantThing.errorReason = `Creating this person for organization failed. ${reason}`;
                    });
            }

            if (participantThing.entity.participantData.participantId) {
                await organizationGroupMembershipService
                    .createGroupMembership(this.options.organizationId, this.options.groupId, participantThing.entity.participantData.participantId)
                    .then(() => {
                        participantThing.creationStatus = EntityCreateState.Created;
                    })
                    .catch(() => {
                        participantThing.creationStatus = EntityCreateState.Failed;
                        participantThing.errorReason = 'Adding this person to the group failed.';
                    });
            }
        }

        this.clearAndShowSuccess('All people are added!');
        this.finished();
    }
}

// tslint:disable-next-line: max-classes-per-file
export class AddGroupMembersOptions {
    public organizationId: number = 0;
    public groupId: number = 0;
    public members: Participant[] = [];

    constructor(data: AddGroupMembersOptions) {
        Object.assign(this, data);
    }
}
