import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DropDownList } from '@progress/kendo-vue-dropdowns';

@Component({
    components: {
        'kendo-dropdownlist': DropDownList,
    },
})
export default class MembersComponent extends Vue {
    @Prop({ default: () => [] }) public elements: string[];
    @Prop({ default: '' }) public icon: string;
    @Prop({ default: '' }) public detailIcon: string;
    @Prop({ default: '' }) public textTemplate: string;

    public propOrientation = 'horizontal';
    public valueRender = 'valueTemplate';
    public itemRender = 'itemTemplate';

    public noDataTemplate(): string {
        return '<span>No demographic data</span>';
    }

    public onSelect(e) {
        if (e && e.event) {
            e.event.preventDefault();
            e.event.stopPropagation();
        }
    }

    public dropdownButtonTemplate() {
        if (!this.elements) {
            return this.noDataTemplate();
        }

        return this.textTemplate.replace(/{{count}}/g, this.elements.length.toString());
    }
}
