import Instrument from '@/modules/instruments/Models/Instrument';
import { loginHelper } from '../main';
import PageRender from './PageRender';

export default class BasePage extends PageRender {
    public siteKey: string = '';
    public mode: string = '';
    public isLoaded: boolean = false;
    public editMode: boolean = false;

    /**
     * Partner
     */
    get isPartner(): boolean {
        return loginHelper.isInRole('Partner');
    }

    get isSuperAdmin(): boolean {
        return loginHelper.isInRole('SuperAdmin');
    }

    get isPowerUser() {
        return loginHelper.isInRole('PowerUser');
    }

    get isPractitioner(): boolean {
        return loginHelper.isInRole('Practitioner');
    }

    get instrumentsList(): Instrument[] {
        return this.$store.getters['instrumentsStore/instruments'];
    }

    public checkMode() {
        switch (this.mode) {
            case 'edit':
                this.editMode = true;
                break;
            default:
                this.editMode = false;
                break;
        }
    }

    public edit() {
        this.editMode = true;
    }

    public cancelEdit() {
        this.editMode = false;
    }
}
