import moment from 'moment';

export default class Prepaid {
    public prepaidId: number;

    public totalPrepaid: number;
    public usedPrepaid: number;
    public remainingPrepaid: number;
    public startDate: Date;
    public endDate: Date = moment().add(1, 'years').subtract(1, 'days').toDate();
    public cost: number;

    public applyRefund: boolean = false;

    public partnerId: number;
    public practitionerId: number;

    public knowledgeModelId: number;
    public knowledgeModelName?: string;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
