export enum EmailSendStatus {
    None = -1,
    Queued = 0,
    Sending = 1,
    Sent = 2,
    Delivered = 3,
    NotDelivered = 4,
    ErrorInSending = 10
}


