import { OrganizationGroup, OrganizationGroupCreate, OrganizationGroupUpdate } from '../models/Organization';
import { UpdateResult } from '../models/UpdateResult';
import { BaseService } from './baseService';
import Vue from 'vue';

export class OrganizationGroupService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getOrganizationGroup(organizationId: number, groupId: number): Promise<OrganizationGroup> {
        return new Promise<OrganizationGroup>((resolve, reject) => {
            this.get<OrganizationGroup>(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}?embedOptions=Sequences,MemberCount`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    }

    public getOrganizationGroups(organizationId: number): Promise<OrganizationGroup[]> {
        return new Promise<OrganizationGroup[]>((resolve, reject) => {
            this.get<OrganizationGroup[]>(`${this.endpoint}/organizations/${organizationId}/groups?embedOptions=MemberCount`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((r) => {
                    reject(r);
                });
        });
    }

    public createGroup(organizationId: number, createData: OrganizationGroupCreate): Promise<OrganizationGroup> {
        return new Promise<OrganizationGroup>((resolve, reject) => {
            this.post<OrganizationGroup>(`${this.endpoint}/organizations/${organizationId}/groups`, createData)
                .then((result) => {
                    resolve(result.data);
                })
                .catch(() => {
                    reject(createData);
                });
        });
    }

    public updateGroup(organizationId: number, groupId: number, updateData: OrganizationGroupUpdate): Promise<UpdateResult<OrganizationGroup>> {
        return new Promise<UpdateResult<OrganizationGroup>>((resolve, reject) => {
            this.put<UpdateResult<OrganizationGroup>>(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}`, updateData)
                .then((result) => {
                    resolve(result.data);
                })
                .catch(() => {
                    reject(updateData);
                });
        });
    }

    public deleteGroup(organizationId: number, groupId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.delete(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
