import { BaseService } from '../../../services/baseService';
import { InstrumentReport, InstrumentUpdate } from '../../../models/Instrument';
import Vue from 'vue';
import { PricingPlan, PricingPlanCreate } from '../../../models/PricingPlan';
import { AxiosResponse } from 'axios';
import { ContextOptions } from '../../../models/ContextOptions';
import to from 'await-to-js';
import Question from '../Models/Question';
import QuestionAnswer from '../Models/QuestionAnswer';
import Instrument from '../Models/Instrument';
import { InstrumentCreate } from '@/components/side-actions/instrument-action/create-instrument-action';
import PillarThreshold from '@/models/PillarThreshold';
import InstrumentPillar from '@/models/InstrumentPillar';
import { PillarThresholdCreate } from '@/components/side-actions/pillar-threshold-action/pillarThresholdAddAction';

export class InstrumentsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getInstruments(): Promise<Instrument[]> {
        const [err, response] = await to(this.get<Instrument[]>(`${this.endpoint}/instruments`));
        if (err) {
            return [];
        }

        return response.data.map((x) => new Instrument(x));
    }

    public async getInstrumentDetails(instrumentId: number): Promise<Instrument> {
        const [err, response] = await to(this.get<Instrument>(`${this.endpoint}/instruments/${instrumentId}`));
        if (err) {
            return null;
        }

        return new Instrument(response.data);
    }

    public async createInstrument(instrument: InstrumentCreate, knowledgeModelId: number): Promise<Instrument> {
        const [err, response] = await to(this.post<Instrument>(`${this.endpoint}/instruments`, {
            name: instrument.name,
            knowledgeModelId,
        }));
        if (err) {
            return null;
        }

        return new Instrument(response.data);
    }

    public async getInstrumentPricingPlans(instrumentId: number): Promise<PricingPlan[]> {
        const [err, response] = await to(this.get<PricingPlan[]>(`${this.endpoint}/instruments/${instrumentId}/price-plans`));
        if (err) {
            return [];
        }

        return response.data.map((x) => new PricingPlan(x));
    }

    public async getInstrumentPillarThresholds(instrumentId: number): Promise<PillarThreshold[]> {
        const [err, response] = await to(this.get<PillarThreshold[]>(`${this.endpoint}/instruments/${instrumentId}/pillar-thresholds`));
        if (err) {
            return [];
        }

        return response.data.map((x) => new PillarThreshold(x));
    }

    public async addInstrumentPillarThreshold(instrumentId: number, pillarThreshold: PillarThresholdCreate): Promise<PillarThreshold> {
        const [err, response] = await to(this.post<PillarThreshold>(`${this.endpoint}/instruments/${instrumentId}/pillar-thresholds`, {
            Alias: pillarThreshold.pillar.alias, Type: pillarThreshold.type, Value: pillarThreshold.value, Order: pillarThreshold.order
        }));
        if (err) {
            return null;
        }

        return new PillarThreshold(response.data);
    }

    public async updateInstrumentPillarThreshold(instrumentId: number, pillarThreshold: PillarThreshold): Promise<PillarThreshold> {
        const [err, response] = await to(this.put<PillarThreshold>(`${this.endpoint}/instruments/${instrumentId}/pillar-thresholds/${pillarThreshold.id}`, {
            Alias: pillarThreshold.alias, Type: pillarThreshold.type, Value: pillarThreshold.value, Order: pillarThreshold.order
        }));
        if (err) {
            return null;
        }

        return new PillarThreshold(response.data);
    }

    public async getInstrumentPillars(instrumentId: number): Promise<InstrumentPillar[]> {
        const [err, response] = await to(this.get<InstrumentPillar[]>(`${this.endpoint}/instruments/${instrumentId}/pillars`));
        if (err) {
            return [];
        }

        return response.data.map((x) => new InstrumentPillar(x));
    }

    public async getInstrumentQuestions(instrumentId: number): Promise<Question[]> {
        const [err, response] = await to(this.get<Question[]>(`${this.endpoint}/instruments/${instrumentId}/questions`));
        if (err) {
            return [];
        }

        return response.data.map((x) => new Question(x));
    }

    public addPricingPlan(instrumentId: number, pricingPlan: PricingPlanCreate): Promise<boolean> {
        const self = this;
        return new Promise<boolean>(async (resolve, reject) => {
            await self
                .post(`${this.endpoint}/instruments/${instrumentId}/price-plans`, pricingPlan)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updatePricingPlan(instrumentId: number, pricingPlanId: number, pricingPlan: PricingPlanCreate): Promise<boolean> {
        const self = this;
        return new Promise<boolean>(async (resolve, reject) => {
            await self
                .put(`${this.endpoint}/instruments/${instrumentId}/price-plans/${pricingPlanId}`, pricingPlan)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deletePricingPlan(instrumentId: number, pricingPlanId: number): Promise<boolean> {
        const self = this;
        return new Promise<boolean>(async (resolve, reject) => {
            await self
                .delete(`${this.endpoint}/instruments/${instrumentId}/pricing-plans/${pricingPlanId}`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updateInstrument(id: number, updateModel: InstrumentUpdate): Promise<Instrument> {
        const self = this;
        return new Promise(async (resolve) => {
            await self.put<Instrument>(`${self.endpoint}/instruments/${id}`, updateModel).then((instrumentResponse: AxiosResponse<Instrument>) => {
                resolve(instrumentResponse.data);
            });
        });
    }

    public copyInstrument(instrumentId: number, newInstrument: Instrument): Promise<Instrument> {
        const self = this;
        return new Promise(async (resolve) => {
            await self
                .post<Instrument>(`${self.endpoint}/instruments/${instrumentId}/copy`, { name: newInstrument.name })
                .then((instrumentResponse: AxiosResponse<Instrument>) => {
                    resolve(instrumentResponse.data);
                });
        });
    }

    public async deleteInstrument(instrumentId: number): Promise<Boolean> {
        const [err] = await to(this.delete(`${this.endpoint}/instruments/${instrumentId}`));
        if (err) {
            return false;
        }

        return true;
    }

    public async unpublishLanguageForInstrument(instrumentId: number, languageCode: string) {
        const [err] = await to(this.post(`${this.endpoint}/languages/${languageCode}/instrument/${instrumentId}/unpublish`));
        if (err) {
            return false;
        }

        return true;
    }

    public async publishLanguageForInstrument(instrumentId: number, languageCode: string) {
        const [err] = await to(this.post(`${this.endpoint}/languages/${languageCode}/instrument/${instrumentId}/publish`));
        if (err) {
            return false;
        }

        return true;
    }

    public async updateQuestion(instrumentId: number, question: Question) {
        const [err, response] = await to(this.put(`${this.endpoint}/instruments/${instrumentId}/questions/${question.questionId}`, question));
        if (err) {
            return new Question();
        }

        return new Question(response.data);
    }

    public async deleteQuestion(instrumentId: number, question: Question) {
        const [err, response] = await to(this.delete(`${this.endpoint}/instruments/${instrumentId}/questions/${question.questionId}`));
        if (err) {
            return false;
        }

        return response.data.instrumentQuestionDeleted;
    }

    // public async getQuestion(instrumentId: number, questionId: number) {
    //     const [err, response] = await to(this.get(`${this.endpoint}/instruments/${instrumentId}/questions/${questionId}`));
    //     if (err) {
    //         return [];
    //     }

    //     return response.data.map((x) => new QuestionAnswer(x));
    // }

    public addQuestion(instrumentId: number, question: Question): Promise<Question> {
        const self = this;
        return new Promise<Question>(async (resolve, reject) => {
            await self
                .post(`${this.endpoint}/instruments/${instrumentId}/questions`, question)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updateReport(instrumentId: number, report: InstrumentReport): Promise<InstrumentReport> {
        const self = this;
        return new Promise<InstrumentReport>(async (resolve, reject) => {
            await self
                .put(`${this.endpoint}/instruments/${instrumentId}/reports/${report.alias}`, {
                    name: report.name,
                    alias: report.alias,
                    reportType: report.reportType,
                    fileTypes: report.fileTypeValue,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addReport(instrumentId: number, report: InstrumentReport): Promise<InstrumentReport> {
        const self = this;
        return new Promise<InstrumentReport>(async (resolve, reject) => {
            await self
                .post(`${this.endpoint}/instruments/${instrumentId}/reports`, {
                    name: report.name,
                    alias: report.alias,
                    reportType: report.reportType,
                    fileTypes: report.fileTypeValue,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public exportInstrumentData(instrumentId: number, fromDate?: Date, toDate?: Date): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            await this.post(`${this.endpoint}/instruments/${instrumentId}/export?language=en`, 
                { FromDate: fromDate, ToDate: toDate })
            .then((result) => {
                resolve(result.data);
            }).catch((reason) => {
                reject(reason);
            })
        })
    }
}

// tslint:disable-next-line: max-classes-per-file
export class InstrumentFilter {
    public teamInstrument: boolean = null;

    constructor(filter: Partial<InstrumentFilter>) {
        Object.assign(this, filter);
    }
}
