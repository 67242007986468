import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { BModal } from 'bootstrap-vue';
import BasePage from '@/models/BasePage';
import Practitioner from '../models/Practitioner';
import { loginHelper } from '@/main';
import { PractitionerService } from '../services/practitionerService';
import { PagedResponse } from '@/models/PagedResponse';
import { PartnerService } from '@/modules/partners/services/partnerService';
import Instrument from '../models/Instrument';
import PractitionerOrderBy from '../models/PractitionerOrderBy';
import { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import PractitionerFilter from '../models/PractitionerFilter';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { Project } from '@/models/Project';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
@Component({
    props: {
        defaultFilter: { directInvoicedOnly: null, missingPaperlessOnly: null, performanceView: null },
    },
} as any)
export default class PractitionersOverviewComponent extends BasePage {
    public practitioner: Practitioner;
    @Prop({
        default: () => {
            return { directInvoicedOnly: null, missingPaperlessOnly: null, performanceView: null };
        },
    })
    public defaultFilter: any;
    @Prop({ default: null }) public partnerId?: number;
    @Prop({ default: null }) public actions: any[];
    @Prop({ default: '' }) public partnerName: string;

    public practitionerService: PractitionerService = new PractitionerService();
    public KnowledgeModelService: KnowledgeModelService = new KnowledgeModelService();
    public partnerService: PartnerService = new PartnerService();
    public selectedPractitioner: Practitioner = null;
    public inviteMail: string = '';
    public includeDeleted: boolean = false;
    public filter: PractitionerFilter = new PractitionerFilter();

    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'name', title: 'Practitioner', cell: this.renderRouterLink, sortable: true },
        { field: 'emailAddress', title: 'E-mail', sortable: true },
        { field: 'companyName', title: 'Company name', cell: this.renderCompanyName, sortable: true },
        { field: 'partnerName', title: 'Partner name', cell: this.renderRouterLinkPartner, sortable: false },
        { field: '', title: 'Knowledge models', cell: this.renderKnowledgeModels, width: '200px', sortable: false },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px', sortable: false },
    ];

    public $refs!: {
        createPractitioner: BModal;
        invitePractitioner: BModal;
    };

    public knowledgeModels: KnowledgeModel[];

    public practitionersData: any = {
        practitioners: [],
        count: 0,
        skip: 0,
        take: 25,
        recentProjectMonths: 12,
    };

    public get practitioners() {
        if (this.partnerId) {
            return this.practitionersData.practitioners.filter(
                (_x, index) =>
                    index >= this.practitionersData.skip && index < (this.practitionersData.take as number) + (this.practitionersData.skip as number),
            );
        }

        return this.practitionersData.practitioners;
    }

    public orderBy = new PractitionerOrderBy();
    public sort: any[] = [];
    public sortable = {
        allowUnsort: true,
        mode: 'multiple',
    };
    private filterTimer: number = null;

    public async practitionerCreatedCallback(practitionerData: Practitioner) {
        await this.$router.push({ name: 'practitioner-details', params: { practitionerId: practitionerData.practitionerId.toString() } });
    }

    public async created() {
        await this.getKnowledgeModels();

        if (this.defaultFilter.performanceView) {
            this.practitionersData.take = 2000;
        }
        
        await this.getPractitioners();
        this.$store.dispatch('instrumentsStore/fetch');

        this.filter = new PractitionerFilter(this.defaultFilter);
        this.filter.InstrumentIds = [];

        if (this.defaultFilter.performanceView) {
            this.columns = [
                { field: 'name', title: 'Practitioner', cell: this.renderRouterLink, sortable: true },
                { field: 'emailAddress', title: 'E-mail', sortable: true },
                { field: '', title: 'Nr. of projects', cell: this.renderProjects, sortable: false },
                { field: '', title: 'Recent projects', cell: this.renderRecentProjects, sortable: false },
                { field: 'projects', title: 'Surveys completed', cell: this.renderSurveysCompleted, sortable: false },
                { field: '', title: 'First project', cell: this.renderFirstProjectDate, width: '125px', sortable: true },
                { field: '', title: 'Last project', cell: this.renderLastProjectDate, width: '125px', sortable: true },
                { field: 'partnerName', title: 'Partner name', cell: this.renderRouterLinkPartner, sortable: true },
                {
                    field: '',
                    title: 'Knowledge models',
                    cell: this.renderKnowledgeModels,
                    width: '200px',
                    headerCell: this.renderKnowledgeModelsHeader,
                    sortable: false,
                },
                {
                    field: '',
                    title: 'Instruments',
                    cell: this.renderInstruments,
                    width: '200px',
                    headerCell: this.renderInstrumentsHeader,
                    sortable: false,
                },
            ];
        }

        if (this.isSuperAdmin && !this.defaultFilter.performanceView) {
            this.columns.splice(5, 0, {
                field: 'hasAccount',
                title: 'Has account',
                width: 120,
                cell: (item, _, row) => {
                    return item('td', row.dataItem.hasAccount ? 'Yes' : 'No');
                },
            });
        }
    }

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` ${item.dataItem.deleted ? 'bg-secondary text-muted' : ''}`;
        return tr;
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.practitionersData.skip = event.page.skip;
        this.practitionersData.take = event.page.take;

        if (!this.partnerId) {
            // tslint:disable-next-line: no-floating-promises
            this.getPractitioners();
        }
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.practitionersData.skip = 0;
            await this.getPractitioners();

            if (this.includeDeleted) {
                if (!this.partnerId && !this.columns.some((x) => x.field === 'deleted')) {
                    this.columns.splice(5, 0, {
                        field: 'deleted',
                        title: 'Deleted',
                        width: 120,
                        cell: (item, _, row) => {
                            return item('td', row.dataItem.deleted ? 'Yes' : 'No');
                        },
                    });
                }
            } else {
                this.columns = this.columns.filter((x) => x.field !== 'deleted');
            }
        }, 400);
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.name,
                url: this.$router.resolve({ name: 'practitioner-details', params: { practitionerId: row.dataItem.practitionerId.toString() } }).href,
            },
        });
    }

    public renderRouterLinkPartner(item: any, _, row): any {
        if (!row.dataItem.partnerId) {
            return item('td', {}, row.dataItem.partnerName ?? row.dataItem.companyName);
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.partnerName ?? `(Partner ${row.dataItem.partnerId} - partner company name empty)`,
                url: this.$router.resolve({ name: 'partner-details', params: { partnerId: row.dataItem.partnerId.toString() } }).href,
            },
        });
    }

    public renderCompanyName(item: any, _, row): any {
        return item('td', {}, row.dataItem.companyName ?? '(Company name empty)');
    }

    public renderRemainingPartnerPrepaid(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.remainingPrepaid,
                currencyCode: 'USD',
            },
        });
    }

    public async refreshData() {
        this.practitionersData.skip = 0;
        this.practitionersData.take = 25;
        if (this.defaultFilter.performanceView) {
            this.practitionersData.take = 2000;
        }
        await this.getPractitioners();
    }

    public openCreatePractitionerAction(): any {
        let partnerId = null;
        if (this.isPartner) {
            partnerId = loginHelper.getUser().partnerId;
        }

        this.$sideActions.push('practitioner-create-action', { partnerId: this.partnerId ?? partnerId }, async () => {
            await this.refreshData();
        });
    }

    public async goToCreatePractitioner() {
        await this.$router.push({ name: 'createPractitioner' });
    }

    public get showAddToPartnerButton() {
        return this.$route.name === 'partnerDetails';
    }

    public openAddPractitionerAction(): void {
        this.$sideActions.push('add-existing-practitioner-to-partner-action', { partner: { id: this.partnerId } }, () => {
            this.refreshData();
        });
    }

    public openMergePractitionerAction(): void {
        this.$sideActions.push('merge-practitioner-side-action', {}, async () => {
            this.refreshData();
            this.showSuccess('Practitioners merged!');
        });
    }

    public showModalCreateAccount(practitioner: Practitioner) {
        this.$refs.invitePractitioner.show();
        this.selectedPractitioner = practitioner;
        this.inviteMail = practitioner.emailAddress;
    }

    public async createPractitionerAccount() {
        await this.practitionerService.createAccountForPractitioner(this.selectedPractitioner.practitionerId, this.inviteMail);

        this.practitionersData.practitioners.find((x) => x.practitionerId === this.selectedPractitioner.practitionerId).hasAccount = true;
        this.$refs.invitePractitioner.hide();
        this.clearAndShowSuccess('Account created, invite mail scheduled.');
    }

    public sortChangeHandler(e) {
        this.sort = e.sort;
        this.orderBy.reset();
        this.sort.forEach((sort: { dir: OrderByDirection; field: string }, index: number) => {
            this.orderBy.setSort(sort.field, sort.dir, index);
        });
        this.refreshData();
    }

    public async exportToExcel() {
        saveExcel({
            data: this.convertToExportData(this.practitionersData),
            fileName: 'PractitionerPerformanceOverview.xlsx',
            columns: [
                { field: 'practitioner', title: 'Practitioner' },
                { field: 'email', title: 'Email address' },
                { field: 'numberOfProjects', title: 'Total number of projects' },
                { field: 'recentProjects', title: 'Projects in the last 12 months' },
                { field: 'surveysCompleted', title: 'Surveys completed / total surveys' },
                { field: 'firstProject', title: 'First project' },
                { field: 'lastProject', title: 'Last project' },
                { field: 'partnerName', title: 'Partner' },
                { field: 'knowledgeModels', title: 'Knowledge models' },
                { field: 'instruments', title: 'Instruments' },
            ],
        });
    }

    private convertToExportData(practitionersData): any {
        return practitionersData.practitioners.map((practitioner) => {
            return {
                practitioner: practitioner.name,
                email: practitioner.emailAddress,
                numberOfProjects: practitioner.projects ? practitioner.projects.length : 0,
                recentProjects: practitioner.projects ? this.countRecentProjects(practitioner.projects) : 0,
                surveysCompleted: practitioner.projects ? this.countSurveysCompleted(practitioner.projects) : 0,
                firstProject: practitioner.projects ? this.getProjectDate(practitioner.projects, true) : '',
                lastProject: practitioner.projects ? this.getProjectDate(practitioner.projects, false) : '',
                partnerName: practitioner.partnerName,
                knowledgeModels: this.getKnowledgeModelsExport(practitioner.instruments),
                instruments: this.getInstrumentsExport(practitioner.instruments),
            };
        });
    }

    private countRecentProjects(projects: Partial<Project[]>, months = 12) {
        const deadline = new Date().setMonth(-months);
        const validProjects = projects.filter((project) => {
            const date = new Date(project.closeDate);
            if (date.getTime() >= deadline) {
                return true;
            }
            return false;
        });
        return validProjects.length;
    }

    private renderProjects(item: any, _, row): any {
        return item('td', {}, row.dataItem.projects.length);
    }

    private renderRecentProjects(item: any, _, row): any {
        return item('td', {}, this.countRecentProjects(row.dataItem.projects, this.practitionersData.recentProjectMonths));
    }

    private countSurveysCompleted(projects) {
        const completed = projects
            .map((project) => {
                return project.surveyCompletedCount;
            })
            .reduce((a, b) => (a += b), 0);
        const totalSurveys = projects
            .map((project) => {
                return project.surveyCount;
            })
            .reduce((a, b) => (a += b), 0);
        return `${completed}/${totalSurveys}`;
    }

    private renderSurveysCompleted(item: any, _, row): any {
        return item('td', this.countSurveysCompleted(row.dataItem.projects));
    }

    private getProjectDate(projects: Partial<Project[]>, firstProject: boolean) {
        if (projects.length > 0) {
            if (firstProject) {
                return projects.reduce((pre, cur) => {
                    return new Date(pre.startDate).getTime() > new Date(cur.startDate).getTime() ? cur : pre;
                }).startDate;
            }

            return projects.reduce((pre, cur) => {
                return new Date(pre.startDate).getTime() < new Date(cur.startDate).getTime() ? cur : pre;
            }).startDate;
        }
        return '';
    }

    private renderFirstProjectDate(item: any, _, row): any {
        if (row.dataItem.projects.length > 0) {
            const firstProjectDate = this.getProjectDate(row.dataItem.projects, true);

            return item(Vue.component('grid-date-display'), { props: { date: firstProjectDate } });
        }
        return item('td', {}, '-');
    }

    private renderLastProjectDate(item: any, _, row): any {
        if (row.dataItem.projects.length > 0) {
            const lastProjectDate = this.getProjectDate(row.dataItem.projects, false);

            return item(Vue.component('grid-date-display'), { props: { date: lastProjectDate } });
        }
        return item('td', {}, '-');
    }

    private renderInstruments(item: any, _, row: { dataItem: Practitioner }): any {
        const actions = [];

        if (!row.dataItem.instruments || row.dataItem.instruments.length === 0) {
            return item('td', '-');
        }

        row.dataItem.instruments.forEach((instrument: Instrument) => {
            actions.push({
                title: instrument.instrumentName,
                function: () => {
                    if (this.isSuperAdmin) {
                        this.$router.push({
                            name: 'instrument-details',
                            params: { instrumentId: instrument.instrumentId.toString() },
                        });
                    }
                },
            });
        });

        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    private renderActions(render: any, _, row): any {
        const actions = [...this.actions];

        if (!row.dataItem.hasAccount && this.isSuperAdmin) {
            actions.push({
                title: 'Create account',
                function: (item: Practitioner) => {
                    this.showModalCreateAccount(item);
                },
            });
        }

        if (!actions || actions.length === 0) {
            return render('td', {}, '-');
        }

        const props = { actions, item: row.dataItem };
        return render(Vue.component('grid-actions'), { props });
    }

    private renderInstrumentsHeader(h, _) {
        const self = this;
        const instance = h(Vue.component('grid-dropdown-filter'), {
            props: {
                value: this.filter.InstrumentIds,
                title: 'Instruments',
                options: this.$store.getters['instrumentsStore/instruments'].map((instrument: Instrument) => {
                    return {
                        id: instrument.instrumentId,
                        name: instrument.name,
                        checked: this.filter.InstrumentIds.indexOf(instrument.instrumentId) > -1,
                    };
                }),
            },
            on: {
                input(newValue) {
                    self.filter.InstrumentIds = newValue;
                    self.practitionersData.skip = 0;
                    self.getPractitioners();
                },
            },
        });
        return instance;
    }

    private getKnowledgeModelsExport(instruments) {
        const models = [];
        const ids = [];

        if (!instruments || instruments.length === 0) {
            return '';
        }

        instruments.forEach((instrument: Instrument) => {
            if (ids.indexOf(instrument.knowledgeModelId) < 0) {
                ids.push(instrument.knowledgeModelId);
                models.push(instrument.knowledgeModelName);
            }
        });

        return models.join(', ');
    }

    private getInstrumentsExport(instruments) {
        const names = [];

        if (!instruments || instruments.length === 0) {
            return '';
        }

        instruments.forEach((instrument) => {
            names.push(instrument.instrumentName);
        });

        return names.join(', ');
    }

    private renderKnowledgeModelsHeader(h, _) {
        const self = this;
        const instance = h(Vue.component('grid-dropdown-filter'), {
            props: {
                value: this.filter.KnowledgeModelIds,
                title: 'Knowledge models',
                options: this.knowledgeModels.map((model: KnowledgeModel) => {
                    return {
                        id: model.knowledgeModelId,
                        name: model.name,
                        checked: this.filter.KnowledgeModelIds.indexOf(model.knowledgeModelId) > -1,
                    };
                }),
            },
            on: {
                input(newValue) {
                    self.filter.KnowledgeModelIds = newValue;
                    self.filter.InstrumentIds = self.$store.getters['instrumentsStore/instruments']
                        .filter((x: Instrument) => newValue.includes(x.knowledgeModelId))
                        .map((y: Instrument) => y.instrumentId);
                    self.practitionersData.skip = 0;
                    self.getPractitioners();
                },
            },
        });
        return instance;
    }

    private renderKnowledgeModels(h: any, _, row: { dataItem: Practitioner }) {
        const actions = [];
        const ids = [];

        if (!row.dataItem.instruments || row.dataItem.instruments.length === 0) {
            return h('td', '-');
        }

        row.dataItem.instruments.forEach((instrument: Instrument) => {
            if (ids.indexOf(instrument.knowledgeModelId) < 0) {
                ids.push(instrument.knowledgeModelId);
                actions.push({
                    title: instrument.knowledgeModelName,
                    function: () => {
                        if (this.isSuperAdmin) {
                            this.$router.push({
                                name: 'knowledge-model-details',
                                params: { knowledgeModelId: instrument.knowledgeModelId.toString() },
                            });
                        }
                    },
                });
            }
        });

        if (ids.length === 1) {
            if (!this.isSuperAdmin) {
                return h('td', row.dataItem.instruments[0].knowledgeModelName);
            }

            return h(Vue.component('grid-router-link'), {
                props: {
                    title: row.dataItem.instruments[0].knowledgeModelName,
                    url: this.$router.resolve({
                        name: 'knowledge-model-details',
                        params: { knowledgeModelId: row.dataItem.instruments[0].knowledgeModelId.toString() },
                    }).href,
                },
            });
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    private async getKnowledgeModels() {
        await this.KnowledgeModelService.getKnowledgeModels({}).then((response: PagedResponse<KnowledgeModel>) => {
            this.knowledgeModels = response.items.map((k) => {
                return {
                    ...k,
                    knowledgeModelId: k.knowledgeModelId,
                    name: k.name,
                    alias: k.alias,
                };
            });
        });
    }

    private async getPractitioners(includeCount: boolean = true) {
        this.isLoaded = false;

        if (this.partnerId) {
            await this.partnerService.getPractitioners(this.partnerId).then((response: PagedResponse<Practitioner>) => {
                this.practitionersData.practitioners = response.items.map((p) => {
                    return {
                        practitionerId: p.practitionerId,
                        name: p.firstName + ' ' + p.lastName,
                        partnerId: p.partnerId,
                        companyName: p.companyName,
                        partnerName: this.partnerName,
                        emailAddress: p.emailAddress,
                        instruments: p.instruments,
                        hasAccount: p.hasAccount,
                    };
                });

                if (includeCount) {
                    this.practitionersData.count = response.count;
                }

                this.isLoaded = true;
            });
        } else {
            await this.practitionerService
                .getPractitioners(
                    {
                        skip: this.practitionersData.skip,
                        take: this.practitionersData.take,
                        search: this.filter.search,
                        searchOptions: this.filter.searchOptions,
                        $count: includeCount,
                        directInvoicingOnly: this.defaultFilter.directInvoicedOnly,
                        missingPaperlessOnly: this.defaultFilter.missingPaperlessOnly,
                        includeDeleted: this.includeDeleted,
                        embedOptions: this.defaultFilter.performanceView,
                        InstrumentIds: this.filter.InstrumentIds,
                        KnowledgeModelIds: this.filter.KnowledgeModelIds,
                    },
                    this.orderBy,
                )
                .then((value: PagedResponse<Practitioner>) => {
                    this.practitionersData.practitioners = value.items.map((p) => {
                        return {
                            practitionerId: p.practitionerId,
                            name: p.firstName + ' ' + p.lastName,
                            partnerId: p.partnerId,
                            companyName: p.companyName,
                            partnerName: p.partnerName,
                            emailAddress: p.emailAddress,
                            instruments: p.instruments,
                            hasAccount: p.hasAccount,
                            deleted: p.deleted,
                            projects: p.projects,
                        };
                    });

                    if (includeCount) {
                        this.practitionersData.count = value.count;
                    }

                    this.isLoaded = true;
                });
        }
    }
}
