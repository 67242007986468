import { Country } from '@/models/Country';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import { State } from '@/models/State';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import Partner from '@/modules/partners/models/Partner';

export default class Organization {
    public selected: boolean = false;
    public organizationId: number = 0;
    public id: number = 0;
    public name: string = '';
    public organizationType: string = '';
    public practitioner: Practitioner = null;
    public partner: Partner = null;

    public vat: string = '';
    public countryId: number = 0;
    public stateId: number = 0;
    public city: string = '';
    public zipCode: string = '';
    public address: string = '';
    public contactName: string = '';

    public subscription?: OrganizationSubscription = null;

    public sharedPractitioners: Practitioner[] = [];

    public selectedCountry: Country = new Country();
    public selectedState: State = new State();
    public isOwner?: boolean;
    public isSharedWith?: boolean;
    public retentionTime?: number;

    constructor(organization?: any) {
        (Object as any).assign(this, organization);
    }
}

// tslint:disable: max-classes-per-file
export class CreatedOrganization {
    public id: number = 0;
    public name: string = '';
    public organizationType: string = '';
}
