import { Language } from "./Language";

export class Translation {
    public id: number = 0;
    public key: string = '';
    public knowledgeModelId: number = 0;
    public instrumentId: number = 0;
    public value: string = '';
    public section: string;
    public subSection: string;
    public reportAlias: string;
    public languageCode: string;

    public language?: Language;
    public selectedSection?: any;
    public selectedSubSection?: any;

    constructor(translation?: Partial<Translation>) {
        (Object as any).assign(this, translation);
    }
}
