import BasePage from '@/models/BasePage';
import { InstrumentType } from '@/models/Instrument';
import { Component } from 'vue-property-decorator';
import { $router, eventBus, projectsService } from '../../main';
import { Language } from '../../models/Language';
import { Project } from '../../models/Project';
import EmailTemplate from '../../modules/emailTemplates/models/emailTemplate';
import { ParticipantState, SurveyCreate } from './models/participant';

@Component
export default class SetupEmailComponent extends BasePage {
    public loading: boolean = true;
    public projectData: Project = null;

    public participants: SurveyCreate[] = this.$store.getters['participantsStore/participants'];
    public tabs: Tab[] = [];
    public emailParticipants: any[] = [];
    public tabIndex: number = 0;

    public bSendEmails: boolean = false;
    public bUseLinks: boolean = false;

    private projectId: number = 0;

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);
        await projectsService.getProject(this.projectId).then((value: Project) => {
            this.projectData = value;
        });

        this.participants.forEach((participant) => {
            participant.organizationId = this.projectData.organization.organizationId;
        });

        this.getReusedParticipantsOnly().forEach((p) => {
            p.sendEmail = false;
        });

        this.participantUpdated();

        this.loading = false;
    }

    public updateParticipant(newValue: any, field: string): void {
        this.participants.forEach((p) => {
            p[field] = newValue;
        });

        this.participantUpdated();
    }

    public sendEmails(): void {
        if (this.participants.length - this.getReusedParticipantsOnly().length === 0) {
            return;
        }

        this.bSendEmails = true;
        this.updateParticipant(true, 'sendEmail');
        this.$store.commit('participantsStore/SET_INVITE_ACTION', true);
        eventBus.$emit('handle-finish');
    }

    public useLinks(): void {
        this.bUseLinks = true;
        this.updateParticipant(false, 'sendEmail');
        this.$store.commit('participantsStore/SET_INVITE_ACTION', false);

        eventBus.$emit('handle-finish');
    }

    private participantUpdated() {
        this.$store.commit('participantsStore/SAVE_PARTICIPANTS', this.participants);
    }

    private getReusedParticipantsOnly(): SurveyCreate[] {
        return this.participants.filter((item) => {
            return item.state === ParticipantState.Reused && !this.projectData.instruments.some((x) => x.instrumentType === InstrumentType.Group);
        });
    }
}

// tslint:disable-next-line: max-classes-per-file
export class Tab {
    public name: string = '';
    public participants: any[];
    public readonly key: string;
    public selectedLanguage: number = 0;
    public selectedLanguageId: string = '';

    public mailSubjects: any = {};
    public mailBodies: any = {};
    public emailTemplates: any[] = [];
    private updateCallback: any = null;

    constructor(name: string, participants: any[], updateCallback: any, emailTemplates: EmailTemplate[]) {
        const self = this;

        this.name = name;
        this.key = this.name.toLowerCase().replace(' ', '-');
        this.participants = participants;
        this.emailTemplates = emailTemplates;
        this.updateCallback = updateCallback;

        const languages = this.getLanguages();
        if (languages !== null && languages.length > 0) {
            this.selectedLanguageId = languages[0].id;

            this.emailTemplates.forEach((template: EmailTemplate) => {
                self.mailSubjects[template.language] = template.subject;
                self.mailBodies[template.language] = template.body.replace(/\r?\n|\r/g, '');

                self.updateMail(template.subject, 'subject', template.language);
                self.updateMail(template.body, 'body', template.language);
            });
        }
    }

    public getLanguages(): any[] {
        const languages = this.participants.map((p) => {
            return p.preferredLanguage;
        });

        return Array.from(new Set(languages));
    }

    public tabSelected(language: Language): void {
        this.selectedLanguageId = language.languageCode;
    }

    public mailTextChanged(languageId): void {
        this.updateMail(this.mailBodies[this.selectedLanguageId], 'body', languageId);
    }

    public updateMail(update: string, field: string, language?: string): void {
        this.participants.forEach((p) => {
            if (!language || p.preferredLanguage === language) {
                p.email[field] = update;
            }
        });

        if (typeof this.updateCallback === 'function') {
            this.updateCallback();
        }
    }
}