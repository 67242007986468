import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import ApiKey from '../models/APIKeys/ApiKey';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { ApiResource } from '../models/APIKeys/ApiResource';
import { ResourcePermissions } from '../models/APIKeys/ResourcePermissions';
import Instrument from '@/modules/instruments/Models/Instrument';
import { PractitionerService } from '../services/practitionerService';
import to from 'await-to-js';
import { ApiKeyActionParameters, ApiKeyService } from '../components/api-keys-overview';

@Component
export default class ApiKeySideAction extends SideActionBase<ApiKeyActionParameters> {
    public apiKey: ApiKey = null;
    public resources: any[] = [];
    public instruments: Instrument[] = [];
    public selectedInstruments: number[] = [];
    public selectedKnowledgeModel: KnowledgeModel = null;

    private practitionersService: PractitionerService = new PractitionerService();
    private apiKeyService: ApiKeyService = new ApiKeyService();

    public get knowledgeModels() {
        const knowledgeModels = [];

        for (const instrument of this.instruments) {
            if (!knowledgeModels.find((x) => x.knowledgeModelId === instrument.knowledgeModelId)) {
                knowledgeModels.push(
                    new KnowledgeModel({
                        knowledgeModelId: instrument.knowledgeModelId,
                        name: instrument.knowledgeModelName,
                        alias: instrument.knowledgeModelAlias,
                        instruments: [new Instrument(instrument)],
                    }),
                );
            } else {
                const knowledgeModel = knowledgeModels.find((x) => x.knowledgeModelId === instrument.knowledgeModelId);
                knowledgeModel.instruments.push(new Instrument(instrument));
            }
        }

        return knowledgeModels;
    }

    public async initializeAction(): Promise<void> {
        this.apiKey = this.options.apiKey || new ApiKey();
        this.resources = this.initializeResources();
        this.instruments = this.$store.getters['instrumentsStore/instruments'];
        this.selectedInstruments = [];
        this.selectedKnowledgeModel = null;

        this.selectedInstruments = this.apiKey.instruments.map((x) => x.instrumentId);
        this.selectedKnowledgeModel = this.knowledgeModels.find((x) => x.knowledgeModelId === this.apiKey.knowledgeModel.knowledgeModelId);
        if (this.selectedInstruments) {
            await this.instrumentSelected(this.selectedInstruments);
        }
        if (this.selectedKnowledgeModel.instruments) {
            this.apiKey.instruments.forEach((x) => {
                this.selectedKnowledgeModel.instruments.find((y) => y.instrumentId == x.instrumentId).pricingPlanId = x.pricePlanId
            })
        }
    }

    public initializeResources() {
        const resources = Object.keys(ApiResource).filter((key) => !isNaN(Number(ApiResource[key])));
        return resources
            .filter((r) => r !== ApiResource[ApiResource.Unknown])
            .map((key) => {
                var selectedPermissions = this.apiKey.accessScope.find((scope) => scope.resource.toString() == key.toLowerCase())?.permissions.toString().split(', ');
                return {
                    name: key,
                    value: ApiResource[key],
                    selected: selectedPermissions != null ? this.permissions.filter((permission) => selectedPermissions.find((select) => select == permission.name.toLowerCase())).map((x) => x.value) : [],
                };
            });
    }

    public get permissions() {
        return Object.keys(ResourcePermissions)
            .filter((r) => r !== ResourcePermissions[ResourcePermissions.None])
            .filter((key) => !isNaN(Number(ResourcePermissions[key])))
            .map((key) => {
                return {
                    name: key,
                    value: ResourcePermissions[key],
                };
            });
    }

    public async beforeMount() {
        await this.initializeAction();
    }

    public async instrumentSelected(instrumentIds: number[]) {
        for (const instrumentId of instrumentIds) {
            const instrument = this.selectedKnowledgeModel.instruments.find((x) => x.instrumentId === instrumentId);

            if (!instrument.pricingPlans || instrument.pricingPlans.length === 0) {
                instrument.pricingPlans = await this.loadPricingPlans(instrumentId);
            }
        }
    }

    public async loadPricingPlans(instrumentId: number) {
        const [err, pricingPlans] = await to(this.practitionersService.getPricePlans(instrumentId, this.options.practitioner.practitionerId));
        if (err) {
            this.showError(
                `The pricing plans couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
            );

            return [];
        }
        return pricingPlans;
    }

    public async updateAPiKey(): Promise<any> {
        this.finished();
    }

    public async createAPiKey(): Promise<any> {
        this.apiKey.knowledgeModelId = this.selectedKnowledgeModel.knowledgeModelId;

        const accessScopes = [];

        for (const resource of this.resources) {
            const permissionValue = resource.selected.reduce((a, b) => a + b, 0);

            accessScopes.push({
                resource: resource.name,
                permissions: permissionValue,
            });
        }

        const apiKeyRequest: CreateApiKeyRequest = {
            name: this.apiKey.name,
            description: this.apiKey.description,
            knowledgeModelId: this.apiKey.knowledgeModelId,
            accessScope: accessScopes,
            instruments: this.selectedInstruments.map((x) => {
                return {
                    instrumentId: x,
                    pricePlanId: this.selectedKnowledgeModel.instruments.find((y) => y.instrumentId === x).pricingPlanId,
                };
            }),
        };

        const [err, apiKey] = await to(this.apiKeyService.createApiKey(apiKeyRequest));
        if (err) {
            this.showError(err);
            return;
        }

        apiKey.isNew = true;

        this.finished(apiKey);
    }

    public async updateApiKey(): Promise<any> {
        this.apiKey.knowledgeModelId = this.selectedKnowledgeModel.knowledgeModelId;

        const accessScopes = [];

        for (const resource of this.resources) {
            const permissionValue = resource.selected.reduce((a, b) => a + b, 0);

            accessScopes.push({
                resource: resource.name,
                permissions: permissionValue,
            });
        }

        const apiKey: UpdateApiKeyRequest = {
            apiKeyId: this.apiKey.apiKeyId,
            name: this.apiKey.name,
            description: this.apiKey.description,
            knowledgeModelId: this.apiKey.knowledgeModelId,
            accessScope: accessScopes,
            instruments: this.selectedInstruments.map((x) => {
                return {
                    instrumentId: x,
                    pricePlanId: this.selectedKnowledgeModel.instruments.find((y) => y.instrumentId === x).pricingPlanId,
                };
            }),
        };

        const [err, response] = await to(this.apiKeyService.updateApiKey(apiKey));
        if (err) {
            this.showError(err);
            return;
        }

        this.finished(response);
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }
}

export class CreateApiKeyRequest {}
export class UpdateApiKeyRequest {
    public apiKeyId: string;
    public name: string;
    public description: string;
    public knowledgeModelId: number;
    public accessScope: any[];
    public instruments: any[];
}
