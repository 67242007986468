export class ParticipantFilter {
    public skip: number;
    public take: number;
    public $count: boolean;
    public search: string;
    public organizationId: number;
    public instrumentId?: number;
    public includeDemographicData: boolean;
    public includeProjectCount: boolean;

    constructor(filter: Partial<ParticipantFilter>) {
        Object.assign(this, filter);
    }
}
