import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import Organization from '@/modules/organizations/models/Organization';
import { OrganizationsService } from '@/services/organizationsService';
import to from 'await-to-js';
import { required } from 'vuelidate/lib/validators';

@Component({
validations: {
    fromOrganization: { required },
    toOrganization: { required },
},
} as any)
export default class MergeOrganizationAction extends SideActionBase<any> {
    public organizationList: Organization[] = [];
    public organizationOptions: any[] = [];
    public submitted: boolean = false;

    public fromOrganization: Organization = null;
    public toOrganization: Organization = null;

    public working: boolean = true;
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        await this.getOrganizations().then(() => {
            this.organizationList.forEach((organization) => {
                this.organizationOptions.push({
                    organizationId: organization.organizationId,
                    name: `${organization.name} - (Practitioner: ${organization.practitioner.firstName} ${organization.practitioner.lastName}, Partner: ${organization.partner.companyName}) `,
                });
            });
        });
    }

    public async showMergeWarning(): Promise<void> {
        this.submitted = true;

        if (this.fromOrganization && this.toOrganization) {
            this.$bvModal
            .msgBoxConfirm(`This action can't be undone! Please verify that you are merging the right organizations.`, {
                title: `Merging organizations`,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: `Merge`,
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            })
            .then(async (value) => {
                if (value) {
                    await this.mergeOrganizations();
                }
            })
            .catch((err) => {
                // An error occurred
                console.log(err);
            });
        }
    }

    public async mergeOrganizations(): Promise<any> {
        const [err] = await to(
            this.organizationsService.mergeOrganizations(this.fromOrganization.organizationId, this.toOrganization.organizationId),
        );
        if (err) {
            return this.showError('Failed to merge organizations');
        }
        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }

    public FromOrganizationOptions(): any[] {
        return this.organizationOptions.filter((organization) => {
            return !this.toOrganization || (this.toOrganization && organization.organizationId !== this.toOrganization.organizationId);
        });
    }

    public ToOrganizationOptions(): any[] {
        return this.organizationOptions.filter((organization) => {
            return !this.fromOrganization || (this.fromOrganization && organization.organizationId !== this.fromOrganization.organizationId);
        });
    }

    private async getOrganizations() {
        await this.organizationsService
            .getOrganizations({ skip: 0, take: 99999, embedOptions: true })
            .then((organizations) => {
                this.organizationList = organizations.items as any;
            })
            .catch(() => {
                this.showError(
                    `The countries couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}
