import { Country } from '@/models/Country';
import { Language } from '@/models/Language';
import { State } from '@/models/State';
import { BillingInformation } from './BillingInformation';

export default class PractitionerUpdate {
    public practitionerId: number = null;
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public companyName: string = '';
    public preferredLanguage: string | Language = '';

    public billingInformation: BillingInformation = new BillingInformation();

    public country?: Country = null;
    public state?: State = null;
}
