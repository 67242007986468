import Vue from 'vue';

Vue.component('side-action-add-prepaid', require('@/modules/prepaid/side-actions/add-prepaid-action.vue').default);
Vue.component('edit-language-permissions-action', require('@/modules/settings/side-actions/edit-language-permissions-action.vue').default);
Vue.component('create-language-permissions-action', require('@/modules/translations/side-actions/create-language-permissions-action.vue').default);
Vue.component('move-practitioner-side-action', require('@/modules/partners/side-actions/move-practitioner-side-action.vue').default);
Vue.component('merge-practitioner-side-action', require('@/modules/practitioners/side-actions/merge-practitioner-action.vue').default);
Vue.component('instrument-price-plan-action', require('@/modules/partners/side-actions/instrument-price-plan-action.vue').default);

Vue.component('are-you-sure-modal', require('@/components/modals/are-you-sure-modal.vue').default);

Vue.component('transfer-organization-rights-action', require('@/modules/organizations/side-actions/transfer-organization-rights-action.vue').default);
Vue.component('project-share-action', require('@/modules/projects/side-actions/project-share-action.vue').default);
Vue.component('import-demographic-data-on-project-action', require('@/modules/projects/side-actions/import-demographic-data-on-project.vue').default);
Vue.component(
    'participant-manage-demographic-data-action',
    require('@/modules/participants/side-actions/participant-manage-demographic-data-action.vue').default,
);


Vue.component('question-model-update-action', require('@/modules/instruments/side-actions/change-question.vue').default);
Vue.component('question-model-add-action', require('@/modules/instruments/side-actions/add-question.vue').default);
Vue.component('instrument-report-side-action', require('@/modules/instruments/side-actions/instrument-report.vue').default);
Vue.component('instrument-export-side-action', require('@/modules/instruments/side-actions/instrument-export.vue').default);
