import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { languageService } from '../../../main';
import languages from 'language-list';
import { Language } from '@/models/Language';

@Component
export default class AddLanguageActionComponent extends SideActionBase<AddLanguageActionOptions> {
    public instrumentId: number = this.options.instrumentId;
    public knowledgeModelId: number = this.options.knowledgeModelId;
    public languages: Language[] = [];
    public selectedLanguage: any = null;

    public get languageOptions() {
        return languages().getData();
    }

    public async addInstrument() {
        if (this.selectedLanguage !== null) {
            this.showPending('Adding the selected language');
            await languageService.addLanguageToInstrument(this.selectedLanguage.code, this.instrumentId, this.knowledgeModelId);
            this.clearAndShowSuccess('Selected language was successfully added to the portal!');
            this.finished(this.selectedLanguage.code);
        } else {
            this.clearAndShowError('You must select an language first', null);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class AddLanguageActionOptions {
    public instrumentId: number;
    public knowledgeModelId: number;

    constructor(data: AddLanguageActionOptions) {
        Object.assign(this, data);
    }
}
