import Vue from 'vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import Component from 'vue-class-component';
import { PartnerService } from '@/modules/partners/services/partnerService';
import BasePage from '@/models/BasePage';
import Instrument from '../models/Instrument';

@Component
export default class InstrumentPermissionComponent extends BasePage {
    get instrumentList(): any[] {
        return this.instruments.sort((a, b) => {
            return a.instrumentId < b.instrumentId ? -1 : 1;
        });
    }

    get selectedInstruments(): any[] {
        return this.instruments.filter((instrument: any) => {
            return instrument.selected;
        });
    }

    get instrumentsSelected(): boolean {
        return this.selectedInstruments.length !== 0;
    }
    public instruments: any[] = [];
    public loading: boolean = true;
    public thekey: string = new Date().getTime().toString();

    public partnerService: PartnerService = new PartnerService();

    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: this.renderCheckbox },
        { field: 'enabled', title: 'Enabled', width: '200px', cell: this.renderPill },
        { field: 'name', title: 'Instrument' },
        { field: 'actions', title: 'Price plans', width: 200, cell: this.renderPricePlanLink },
    ];

    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    public async created() {
        if (this.isSuperAdmin || this.isPartner) {
            await this.getInstruments();
        }

        this.loading = false;
    }

    public setInstruments(): void {
        const selectedInstruments = this.instruments.filter((inst) => inst.selected);

        this.showPending('Saving instrument permission(s).');

        this.partnerService
            .setInstruments(
                parseInt(this.$router.currentRoute.params.partnerId),
                selectedInstruments.map((x) => x.instrumentId),
            )
            .then(async () => {
                this.clearAndShowSuccess('The instrument permission(s) are successfully changed.');
                await this.getInstruments();
            })
            .catch((e) => {
                this.clearAndShowError('Failed to save the instrument permission(s).', e);
            });
    }

    public deselect(): void {
        this.instruments.forEach((instrument: any) => {
            instrument.selected = false;
        });
    }

    public selectAllInstruments(): void {
        this.instruments.forEach((instrument: any) => {
            instrument.selected = true;
        });
    }

    public getHeight() {
        return {
            height: this.instruments.length === 0 ? 40 : this.instruments.length * 40,
        };
    }

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` ${item.dataItem.deleted ? 'bg-secondary text-muted' : ''}`;
        tr.data.class += ` ${item.dataItem.enabled ? 'text-success' : ''}`;
        tr.data.class += ` ${!item.dataItem.enabled ? 'text-danger' : ''}`;
        return tr;
    }

    public renderActions(item: any, _: any, row: any): any {
        if (!row.dataItem.selected) {
            return item('td');
        }

        const actions = [{ title: 'Edit price plans', function: this.editInstrumentPricePlanAction }];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    public editInstrumentPricePlanAction(instrument) {
        this.$sideActions.push('instrument-price-plan-action', { partnerId: this.$route.params.partnerId, instrument }, async () => {
            // await this.refreshData();
        });
    }

    private async getInstruments(): Promise<void> {
        this.instruments = [];

        await this.partnerService.getInstruments(parseInt(this.$route.params.partnerId)).then((instrumentsResponse) => {
            const instrumentsMapped = instrumentsResponse.items.map((val: Instrument) => {
                return {
                    selected: true,
                    enabled: true,
                    name: val.name,
                    instrumentId: val.instrumentId,
                    key: `instrument${val.instrumentId}`,
                    pricePlans: `${Math.ceil(Math.random() * 10)} price plan(s) active`,
                };
            });

            this.instruments.push(...instrumentsMapped);
        });

        await this.$store.dispatch('instrumentsStore/fetch');
        const listInstruments = this.$store.getters['instrumentsStore/instruments'];
        listInstruments.forEach((element) => {
            if (!this.instruments.find((x) => x.instrumentId === element.id)) {
                this.instruments.push({
                    selected: false,
                    enabled: false,
                    name: element.name,
                    instrumentId: element.id,
                    key: `instrument${element.id}`,
                });
            }
        });

        this.updateCacheKey();

        this.isLoaded = true;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }

    private renderCheckbox(item: any, _: any, row: any): any {
        return item(Vue.component('grid-checkbox'), {
            props: {
                dataItem: row.dataItem,
                field: 'selected',
            },
            on: {
                change: () => {
                    this.setInstruments();
                },
            },
        });
    }

    private renderPricePlanLink(item: any, _: any, row: any): any {
        if (!row.dataItem.selected) {
            return item('td');
        }

        return item(
            'td',
            {
                props: {
                    dataItem: row.dataItem,
                    field: 'selected',
                },
                on: {
                    click: () => {
                        this.editInstrumentPricePlanAction(row.dataItem);
                    },
                },
                class: {
                    'btn-link cursor-pointer': true,
                },
            },
            'Price plans',
        );
    }

    private renderPill(item: any, _: any, row: any): any {
        return item('td', {}, [row.dataItem.enabled ? 'Yes' : 'No']);
    }
}
