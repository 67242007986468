import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { instrumentsService, projectsService } from '../../../main';
import { Project, ProjectUpdate } from '../../../models/Project';
import { SurveyDetails } from '@/models/Survey';
import { OrganizationsService } from '@/services/organizationsService';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import to from 'await-to-js';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component
export default class AddInstrumentActionComponent extends SideActionBase<AddInstrumentActionOptions> {
    public project: Project = this.options.project;
    public instruments: Instrument[] = [];
    public thePricingPlanKey: number = new Date().getTime();
    public selectedInstrument: Instrument = null;
    public selectedInstrumentObject: Instrument = null;
    public context: string = '';
    public combineSurveys: boolean = false;
    public submitted: boolean = false;

    public showSelectInstruments: boolean = true;
    public showSendEmail: boolean = true;
    private organizationSubscriptions: OrganizationSubscription[] = [];

    private organizationsService: OrganizationsService = new OrganizationsService();

    public get showContext() {
        return (
            this.selectedInstrumentObject &&
            (this.selectedInstrumentObject.contextOption === 'optional' || this.selectedInstrumentObject.contextOption === 'mandatory')
        );
    }

    public get contextMandatory() {
        return this.selectedInstrumentObject && this.selectedInstrumentObject.contextOption === 'mandatory';
    }

    public get contextValue() {
        return this.contextMandatory ? 'mandatory' : 'optional';
    }

    public async mounted() {
        await this.$store.dispatch('instrumentsStore/fetch');

        const projectInstrumentIds = this.options.project.instruments.map((x) => x.instrumentId);

        for (let i = 0; i < projectInstrumentIds.length; i++) {
            const projectInstrument = await instrumentsService.getInstrumentDetails(projectInstrumentIds[i]);
            const additionalConnectedInstruments = projectInstrument.connectedInstruments.filter(
                (x) => projectInstrumentIds.indexOf(x.instrumentId) === -1,
            );

            this.instruments.push(...additionalConnectedInstruments.map((x) => new Instrument(x)));

            if (this.instruments.length === 1) {
                this.selectedInstrument = this.instruments[0];
                this.OnInstrumentChange(this.instruments[0]);
            }
        }

        await this.getOrganizationSubscriptions();

        this.isLoaded = true;
    }

    public async OnInstrumentChange(ev) {
        if (this.isPricingPlanRequired(ev.instrumentId)) {
            await this.getPricingPlans(ev.instrumentId).then(() => {
                this.selectedInstrument.pricingPlanOptions = [];
                this.selectedInstrument.pricingPlans.forEach((pricingPlan) => {
                    this.selectedInstrument.pricingPlanOptions.push(pricingPlan);
                });
            });
        }

        this.selectedInstrumentObject = await instrumentsService.getInstrumentDetails(ev.instrumentId);
    }

    public async addInstrument() {
        this.submitted = true;

        if (this.selectedInstrument !== null) {
            this.showPending('Adding the selected instrument');

            const postModel = {
                instrumentId: this.selectedInstrument.instrumentId,
                subscriptionId: null,
                pricingPlanId: null,
            };

            if (this.isPricingPlanRequired(this.selectedInstrument.instrumentId)) {
                postModel.pricingPlanId = this.selectedInstrument.pricingPlan.pricingPlanId;
            } else {
                const subscription = this.organizationSubscriptions.find(
                    (x) => x.knowledgeModelId === this.selectedInstrument.knowledgeModelId && x.isActive,
                );

                postModel.subscriptionId = subscription.id;
            }

            const [err] = await to(projectsService.addInstrument(this.options.project.projectId, postModel));
            if (err) {
                return this.showFailedResponse('Failed to add selected instrument to the project', err);
            }

            if ((this.showContext && this.context) || this.combineSurveys) {
                const projectUpdate = new ProjectUpdate(this.project);
                projectUpdate.combineInstrumentSurveys = this.combineSurveys;
                projectUpdate.context = this.context;

                const [err] = await to(projectsService.updateProject(this.project.projectId, projectUpdate));
                if (err) {
                    return this.showFailedResponse('Failed to update the project to set the context and/or combine survey option', err);
                }
            }

            this.clearAndShowSuccess('Selected instrument was successfully added to the project!');

            this.showSelectInstruments = false;
        } else {
            this.clearAndShowError('You must select an instrument first', null);
        }
    }

    public sendEmails() {
        this.$store.commit('participantsStore/SET_INVITE_ACTION', true);
        this.finished();
    }

    public pricingPlanChanged() {
        this.thePricingPlanKey = new Date().getTime();
    }

    public isPricingPlanRequired(instrumentId: number): boolean {
        if (
            !this.organizationSubscriptions ||
            !this.selectedInstrument ||
            !this.organizationSubscriptions.find((x) => x.knowledgeModelId === this.selectedInstrument.knowledgeModelId && x.isActive)
        ) {
            return true;
        }

        const subscription = this.organizationSubscriptions.find(
            (x) => x.knowledgeModelId === this.selectedInstrument.knowledgeModelId && x.isActive,
        );

        return subscription.instruments.find((x) => x.instrumentId === instrumentId) == null;
    }

    private async getOrganizationSubscriptions() {
        await this.organizationsService.getOrganizationSubscriptions(this.options.project.organization.organizationId).then((data) => {
            this.organizationSubscriptions = data.items;
        });
    }

    private async getPricingPlans(instrumentId: number) {
        const pricingPlans = await instrumentsService.getInstrumentPricingPlans(instrumentId).catch(() => {
            this.showError(
                `The pricing plans couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
            );
        });

        if (pricingPlans && pricingPlans.length > 0) {
            const instrument = this.instruments.find((x) => x.instrumentId === instrumentId);

            instrument.pricingPlans = pricingPlans;

            if (pricingPlans.length === 1) {
                instrument.pricingPlan = pricingPlans[0];
            }
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class AddInstrumentActionOptions {
    public project: Project;
    public surveys: SurveyDetails[];

    constructor(data: AddInstrumentActionOptions) {
        Object.assign(this, data);
    }
}
