import Vue from 'vue';
import he from 'he';

export default class PageRender extends Vue {
    public showValidationErrors(errors): void {
        const errorsHtml = errors
            .map((item) => {
                return `<li>${item}</li>`;
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'The form is invalid, please correct the following issues:',
            text: `<ul>${errorsHtml}</ul>`,
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error, consoleError): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(message): void {
        this.$notify({
            title: `${message}`,
            text:
                '<div class="d-flex justify-content-center">' +
                '<div class="spinner-border" style="width: 2rem; height: 2rem;">' +
                '<span class="sr-only">Loading...</span>' +
                '</div>' +
                '</div>',
            type: 'info',
            duration: -1,
        });
    }

    public showError(error, timeInMs: number = 3000): void {
        this.clearNotifications();

        this.$notify({
            title: 'Oh snap! Something has gone wrong...',
            text: error,
            type: 'error',
            duration: timeInMs,
        });
    }

    public showWarning(message, noClear: boolean = false): void {
        if (!noClear) {
            this.clearNotifications();
        }

        this.$notify({
            title: `${message}`,
            type: 'warning',
            duration: -1,
        });
    }

    public showSuccess(message: string, text?: string, noClear: boolean = false): void {
        if (!noClear) {
            this.clearNotifications();
        }
        
        this.$notify({
            title: `${message}`,
            text,
            type: 'success',
        });
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(message): void {
        this.clearNotifications();
        this.showSuccess(message);
    }

    public clearAndShowError(error, consoleError = null): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.clearNotifications();
        this.showError(error);
    }

    public determineProjectRouterComponent(item: any, row, projectUrl: string) {
        if (
            row.dataItem._links &&
            row.dataItem._links.length &&
            row.dataItem._links.find(
                (x) =>
                    x.type === 'GET' &&
                    x.rel === 'details' &&
                    !x.attributes.includes('ownership:Owner') &&
                    !x.attributes.includes('ownership:Viewer'),
            )
        ) {
            return item(Vue.component('grid-router-link'), {
                props: {
                    title: he.decode(row.dataItem.name),
                    url: this.$router.resolve({
                        name: 'breach-of-confidence',
                        query: {
                            entity: 'project',
                            owner:
                                typeof row.dataItem.practitioner === 'object'
                                    ? `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}`
                                    : row.dataItem.practitioner,
                            url: projectUrl,
                        },
                    }).href,
                },
            });
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.name ?? row.dataItem.projectName),
                url: projectUrl,
            },
        });
    }
}
