import { Grid } from '@progress/kendo-vue-grid';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';

// Vue.component('kendo-popup', Popup);
Vue.component('grid', Grid);
Vue.component('bi-grid', require('./grid/inst-grid-wrapper.vue').default);
// // Components
Vue.component('grid-actions', require('./grid/grid-actions.vue').default);
Vue.component('grid-checkbox', require('./grid/grid-checkbox.vue').default);
Vue.component('grid-router-link', require('./grid/grid-router-link.vue').default);
Vue.component('grid-date-display', require('./grid/grid-date-display.vue').default);
Vue.component('grid-interval-display', require('./grid/grid-interval.vue').default);
Vue.component('grid-add-mode', require('@/modules/participants/grid/grid-add-mode.vue').default);
Vue.component('grid-status-display', require('@/modules/projects/grid/grid-status-display.vue').default);
Vue.component('grid-loader', require('./grid/loader.vue').default);
Vue.component('grid-personal-link-button', require('@/modules/participants/grid/grid-personal-link-button.vue').default);
Vue.component('grid-email-status-label', require('./grid/grid-email-status-label.vue').default);
Vue.component('grid-reject-sentence', require('@/modules/participants/grid/grid-reject-sentence.vue').default);
Vue.component('grid-members-dropdownlist', require('./grid/grid-members-dropdown.vue').default);
Vue.component('grid-string-dropdownlist', require('./grid/grid-string-dropdown.vue').default);
Vue.component('grid-delete-participant', require('./grid/grid-delete-participant.vue').default);
Vue.component('grid-popover-question', require('./grid/grid-popover-question.vue').default);
Vue.component('grid-is-locked', require('./grid/grid-is-locked.vue').default);
Vue.component('grid-money-display', require('./grid/grid-money-display.vue').default);
Vue.component('grid-participant-status', require('./grid/grid-participant-status.vue').default);
Vue.component('grid-popover-instruments', require('./grid/grid-popover-instruments.vue').default);
Vue.component('grid-popover', require('./grid/grid-popover.vue').default);
Vue.component('grid-invoice-status', require('./grid/grid-invoice-status.vue').default);
Vue.component('grid-header-tooltip', require('./grid/grid-header-tooltip.vue').default);
Vue.component('grid-dropdown-filter', require('./grid/grid-dropdown-filter.vue').default);
Vue.component('grid-translation-display', require('./grid/grid-translation-display.vue').default);
Vue.component('grid-enabled', require('./grid/grid-enabled.vue').default);

Vue.component('title-bar', require('./title-bar/titleBar.vue').default);
Vue.component('member-name', require('./dropdownlist/member-name.vue').default);
Vue.component('date-display', require('./displays/dateDisplay.vue').default);
Vue.component('status-display', require('./displays/statusDisplay.vue').default);
Vue.component('list-display', require('./displays/listDisplay.vue').default);

Vue.component('language-input', require('./inputs/languageInput.vue').default);
Vue.component('organization-type-input', require('./inputs/organizationTypeInput.vue').default);

Vue.component('vue-editor', VueEditor);
Vue.component('multiselect', Multiselect);
Vue.component('offline-bar', require('@/components/navigation/offline-bar.vue').default);

Vue.component('email-action', require('./side-actions/email-actions/emailAction.vue').default);
Vue.component('project-create-action', require('./side-actions/project-action/projectAction.vue').default);
Vue.component('add-language-instrument-action', require('./side-actions/language-action/languageAction.vue').default);
Vue.component('add-instrument-action', require('./side-actions/project-action/addInstrumentAction.vue').default);
Vue.component('practitioner-create-action', require('./side-actions/practitioner-action/practitionerAction.vue').default);
Vue.component('partner-create-action', require('@/modules/partners/side-actions/create-partner-action.vue').default);
Vue.component('add-organization-subscription-action', require('./side-actions/organization-action/addOrganizationSubscriptionAction.vue').default);
Vue.component(
    'change-organization-subscription-action',
    require('./side-actions/organization-action/changeOrganizationSubscriptionAction.vue').default,
);
Vue.component('pricing-plan-add-action', require('./side-actions/pricing-plan-action/pricingplanAddAction.vue').default);
Vue.component('pricing-plan-update-action', require('./side-actions/pricing-plan-action/pricingplanUpdateAction.vue').default);
Vue.component('pillar-threshold-add-action', require('./side-actions/pillar-threshold-action/pillarThresholdAddAction.vue').default);
Vue.component('pillar-threshold-update-action', require('./side-actions/pillar-threshold-action/pillarThresholdUpdateAction.vue').default);
Vue.component('survey-overview-action', require('./side-actions/survey-overview-action/surveyOverviewAction.vue').default);
Vue.component('create-organization-group-action', require('./side-actions/group-action/createGroup.vue').default);
Vue.component('add-group-member-action', require('./side-actions/group-action/addGroupMembers.vue').default);
Vue.component('import-organization-group-action', require('./side-actions/group-action/importAction.vue').default);
Vue.component('organization-share-action', require('./side-actions/organization-action/organizationShareAction.vue').default);
Vue.component('organization-merge-action', require('./side-actions/organization-action/mergeOrganizationAction.vue').default);
Vue.component('organization-create-action', require('@/modules/organizations/side-actions/create-organization-side-action.vue').default);
Vue.component('organization-add-meta-data-action', require('./side-actions/participant-actions/participantAddDemographicDataAction.vue').default);
Vue.component('create-sequence-action', require('./side-actions/sequence-actions/sequenceAction.vue').default);
Vue.component('update-sequence-action', require('./side-actions/sequence-actions/sequenceEditAction.vue').default);
Vue.component('add-existing-practitioner-to-partner-action', require('@/modules/partners/side-actions/add-existing-practitioners.vue').default);
Vue.component('add-partner-account-action', require('@/modules/partners/side-actions/add-partner-account-action.vue').default);
Vue.component('retest-project-action', require('./side-actions/retest-project-action/retestProjectAction.vue').default);
Vue.component('instrument-create-action', require('./side-actions/instrument-action/create-instrument-action.vue').default);
Vue.component('create-project-for-group-action', require('@/modules/organizations/side-actions/create-project-for-group-action.vue').default);
Vue.component('knowledge-model-create-action', require('@/modules/knowledge-models/side-actions/create-knowledge-model-action.vue').default);

Vue.component('loader', require('./loader/loader.vue').default);
Vue.component('navigation-breadcrumb', require('./navigation/breadcrumb.vue').default);
Vue.component('logo-uploader', require('@/components/uploads/logo-uploader.vue').default);

Vue.component('grid-arrange-order', require('./grid/grid-arrange-order.vue').default);

Vue.component('change-demographic-question-action', require('./side-actions/demographic-action/change-demographic-question.vue').default);
Vue.component('add-demographic-question-action', require('./side-actions/demographic-action/add-demographic-question.vue').default);
Vue.component('copy-demographic-question-action', require('./side-actions/demographic-action/copy-demographic-question.vue').default);

Vue.component('confidence-breach-modal', require('@/components/modals/breach-of-confidence-modal.vue').default);

Vue.component('instrument-copy-action', require('@/modules/instruments/side-actions/instrument-copy-action.vue').default);
Vue.component('api-key-action', require('@/modules/practitioners/side-actions/api-key-action.vue').default);


Vue.component('add-translation-action', require('@/modules/knowledge-models/side-actions/add-translation-action.vue').default);
Vue.component('edit-project-mail-action', require('@/modules/projects/side-actions/edit-project-mail-action.vue').default);
Vue.component('copy-participants-to-org', require('@/modules/projects/side-actions/copy-participants-to-org.vue').default);
