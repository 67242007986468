import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';

@Component({
    components: {
        'add-demographic-data': require('@/modules/participants/components/add-demographic-data.vue').default,
    },
} as any)
export default class ImportProjectDemographicDataActionComponent extends SideActionBase<ImportProjectDemographicDataOptions> {
    public addedDemographicData(org: any): void {
        this.finished(org);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ImportProjectDemographicDataOptions {
    public projectId: number;
    public organizationId: number;
    public participants: ProjectParticipant[] = [];

    constructor(actionParams: ImportProjectDemographicDataOptions) {
        Object.assign(this, actionParams);
    }
}
