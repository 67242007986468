import { SurveyCreate, ParticipantState } from '../pages/surveys/models/participant';
import { Store } from 'vuex';
import Participant from '@/modules/participants/models/Participant';
import { SurveyDetails } from '@/models/Survey';

export default class AddParticipantForProjectCommand {
    private members: Participant[];
    private $store: Store<any>;

    constructor(store: Store<any>, members: Participant[]) {
        this.members = members;
        this.$store = store;
    }

    public async execute(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const allAddSteps = new Array<Promise<any>>();
            for (let i = 0; i < this.members.length; i++) {
                const member = this.members[i];

                allAddSteps.push(
                    this.$store.dispatch(
                        'participantsStore/addParticipant',
                        new SurveyCreate({
                            participant: member,
                            state: ParticipantState.Added,
                            basedOn: new SurveyDetails({ surveyId: null }),
                        }),
                    ),
                );
            }

            Promise.all(allAddSteps)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }
}
