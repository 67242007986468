import { Country } from './Country';
import { State } from './State';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import Participant from '@/modules/participants/models/Participant';
// tslint:disable: max-classes-per-file
export class OrganizationCreate {
    public name: string = '';
    public vat: string = '';
    public countryId: number = 0;
    public stateId: number = 0;
    public city: string = '';
    public zipCode: string = '';
    public address: string = '';
    public contactName: string = '';
    public practitionerId: number = 0;
    public retentionTime?: number;

    public organizationType: any = null;

    public country?: Country = null;
    public state?: State = null;
    public practitioner?: Practitioner = null;
}

export class OrganizationUpdate {
    public name: string = '';
    public vat: string = '';
    public countryId: number = 0;
    public stateId: number = 0;
    public city: string = '';
    public zipCode: string = '';
    public address: string = '';
    public contactName: string = '';
    public practitionerId: number = 0;
    public retentionTime?: number;
    public applyToPrevious: boolean = false;

    public organizationType: any = null;

    public country?: Country = null;
    public state?: State = null;
    public practitioner?: Practitioner = null;

    constructor(organizationUpdate?: any) {
        (Object as any).assign(this, organizationUpdate);
    }
}

export class OrganizationGroup {
    public id: number = 0;
    public groupId: number = 0;
    public name: string = '';
    public createdAt: Date = null;
    public externalIdentifiers: string[] = [];

    public members: Participant[] = [];

    constructor(organizationGroup?: any) {
        (Object as any).assign(this, organizationGroup);
    }
}

export class OrganizationGroupCreate {
    public name: string = '';
}
export class OrganizationGroupUpdate {
    public name: string = '';
    public externalIdentifiers: string[] = [];
}

export class RetentionTimeUpdate {
    public retentionTime?: number;
    public applyToPreviousProjects: boolean = false;
}
