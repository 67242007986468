import { Component } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import BasePage from '@/models/BasePage';
import Vue from 'vue';
import Translator from '../models/Translator';
import { LanguagePermissionService } from '@/modules/settings/services/languagePermissionsService';
import LanguagePermission from '@/modules/settings/models/LanguagePermission';

@Component
export default class AccountsOverviewComponent extends BasePage {
    public translator: Translator[] = [];
    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();

    public isLoaded: boolean = false;

    public columns: GridColumnProps[] = [
        { field: 'emailAddress', title: 'Email address' },
        { field: 'permission(s)', title: 'Permissions', cell: this.renderPermissions, width: 300 },
        { field: 'actions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public translators: any = {
        accounts: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };
    private filterTimer: number = null;

    public async created() {
        await this.getTranslatorPermissions();

        this.isLoaded = true;
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.translators.skip = 0;
            await this.getTranslatorPermissions();
        }, 400);
    }

    public openEditPermissionsSideAction(item) {
        this.openEditLanguagesSideAction(item.dataItem);
    }

    public openCreatePermissionsSideActions(){
        this.$sideActions.push('create-language-permissions-action', null, async () => {
            await this.getTranslatorPermissions();
        });
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.translators.skip = event.page.skip;
        this.translators.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getTranslatorPermissions();
    }

    public renderPermissions(item: any, _: any, row: any): any {
        if (row.dataItem.instrumentPermissions.length === 1) {
            return item(
                'td',
                `${row.dataItem.instrumentPermissions[0].languageCode.toUpperCase()} - ${row.dataItem.instrumentPermissions[0].instrumentName}`,
            );
        }

        return item(Vue.component('grid-popover-instruments'), {
            props: {
                id: row.dataItem.userId,
                title: `Permissions`,
                instruments: row.dataItem.instrumentPermissions.map((x) => {
                    return {
                        instrumentId: x.instrumentId,
                        name: `${x.languageCode.toUpperCase()} - ${x.instrumentName}`,
                    };
                }),
                text: `${row.dataItem.instrumentPermissions.length} permissions`,
            },
        });
    }

    private async getTranslatorPermissions(includeCount: boolean = true) {
        await this.languagePermissionService.getLanguagePermissions().then((value: LanguagePermission[]) => {
            this.translators.accounts = value
                .map((p) => {
                    return p;
                })
                .filter((p) => {
                    return p !== undefined;
                });

            if (includeCount) {
                this.translators.count = value.length;
            }

            this.isLoaded = true;
        });
    }

    private openEditLanguagesSideAction(translator: Translator) {
        this.$sideActions.push('edit-language-permissions-action', { translator: translator }, async () => {
            await this.getTranslatorPermissions();
        });
    }

    private renderActions(h, _, row) {
        const actions = [];

        actions.push({
            title: 'Edit languages',
            function: () => {
                this.openEditLanguagesSideAction(row.dataItem);
            },
        });

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
