import BasePage from '@/models/BasePage';
import { Prop } from 'vue-property-decorator';
import ProjectFilter from '../models/ProjectFilter';
import ProjectOrderBy from '../models/ProjectOrderBy';
import { GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { loginHelper, projectsService } from '@/main';
import { AxiosResponse } from 'axios';
import { PagedResponse } from '@/models/PagedResponse';
import { Project } from '@/models/Project';
import { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import he from 'he';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component
export default class BaseProjectOverviewPage extends BasePage {
    @Prop({ default: { activeOnly: null, uncheckedOnly: null, expiredOnly: null } }) public defaultFilter: any;

    get projectCount(): number {
        return this.projectData.projectCount;
    }

    get selectedProjects(): Project[] {
        return this.projectData.projects.filter((p) => p.selected);
    }

    public projectData: any = {
        projects: [],
        projectCount: 0,
        skip: 0,
        take: 25,
    };

    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public toggleOrganizationLink: boolean = false;

    public $refs!: {
        projectOverviewGrid: any;
    };

    public filter = new ProjectFilter();
    public orderBy = new ProjectOrderBy();
    public sort: any[] = [];

    private filterTimer: number = null;

    public sortable = {
        allowUnsort: true,
        mode: 'multiple',
    };
    public gridKey: string = '';

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.projectData.skip = event.page.skip;
        this.projectData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getProjects(false);
    }

    public sortChangeHandler(e) {
        this.sort = e.sort;
        this.projectData.skip = 0;
        this.orderBy.reset();
        this.sort.forEach((sort: { dir: OrderByDirection; field: string }, index: number) => {
            this.orderBy.setSort(sort.field, sort.dir, index);
        });
        this.getProjects(true);
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.projectData.skip = 0;
            await this.getProjects(true);
        }, 400);
    }

    public async getProjects(includeCount: boolean) {
        this.isLoaded = false;
        await projectsService
            .getProjects(this.projectData.skip, this.projectData.take, includeCount, this.filter, this.orderBy)
            .then((value: AxiosResponse<PagedResponse<Project>>) => {
                this.projectData.projects = value.data.items.map((p) => new Project(p));

                if (includeCount) {
                    this.projectData.projectCount = value.data.count;
                }

                this.updateCacheKey();
                this.isLoaded = true;
            });
    }

    public updateCacheKey(): void {
        this.gridKey = new Date().getTime().toString();
    }

    public renderPricePlans(item: any, _, row): any {
        if (row.dataItem.instruments.length > 1) {
            return item(Vue.component('grid-popover'), {
                props: {
                    id: row.dataItem.projectId,
                    title: `Price plans`,
                    labels: row.dataItem.instruments.map((i) => i.pricingPlanName),
                    text: `${row.dataItem.instruments.length} price plans`,
                },
            });
        }

        return item('td', row.dataItem.instruments[0].pricingPlanName);
    }

    public renderCheckbox(item: any, _: any, row: any): any {
        return item(Vue.component('grid-checkbox'), {
            props: {
                dataItem: row.dataItem,
                field: 'selected',
            },
            on: {
                change: () => {
                    //this.setInstruments();
                },
            },
        });
    }

    public renderProjectRouterLinkWithBreachCheck(item: any, _, row): any {
        const projectUrl = this.$router.resolve({ name: 'project-details', params: { projectId: row.dataItem.projectId.toString() } }).href;

        return this.determineProjectRouterComponent(item, row, projectUrl);
    }

    public renderCompleted(item: any, _, row): any {
        return item('td', `${row.dataItem.participantCompletedCount}/${row.dataItem.participantCount}`);
    }

    public renderPractitionerLink(item: any, _, row): any {
        if (this.isPractitioner && row.dataItem.practitioner.practitionerId !== loginHelper.getUser().practitionerId) {
            return item('td', `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}`);
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}`,
                url: this.$router.resolve({
                    name: 'practitioner-details',
                    params: { practitionerId: row.dataItem.practitioner.practitionerId.toString() },
                }).href,
            },
        });
    }

    public renderOrganizationLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.organization.name),
                url: this.$router.resolve({
                    name: 'organization-details',
                    params: { organizationId: row.dataItem.organization.organizationId.toString() },
                }).href,
            },
        });
    }

    public renderKnowledgeModelLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.knowledgeModel.name,
                url: this.$router.resolve({
                    name: 'knowledge-model-details',
                    params: { knowledgeModelId: row.dataItem.knowledgeModel.knowledgeModelId.toString() },
                }).href,
            },
        });
    }

    public renderInstruments(item: any, _: any, row: any): any {
        if (row.dataItem.instruments.length > 1) {
            return item(Vue.component('grid-popover-instruments'), {
                props: {
                    id: row.dataItem.projectId,
                    title: `Instruments`,
                    instruments: row.dataItem.instruments,
                    text: `${row.dataItem.instruments.length} instruments`,
                },
            });
        }

        if (this.isSuperAdmin) {
            return item(Vue.component('grid-router-link'), {
                props: {
                    title: row.dataItem.instruments[0].name,
                    url: this.$router.resolve({
                        name: 'instrument-details',
                        params: { instrumentId: row.dataItem.instruments[0].instrumentId.toString() },
                    }).href,
                },
            });
        } else {
            return item('td', row.dataItem.instruments[0].name);
        }
    }

    public renderInstrumentsHeader(h, _) {
        const self = this;
        const instance = h(Vue.component('grid-dropdown-filter'), {
            props: {
                value: this.filter.InstrumentIds,
                title: 'Instruments',
                options: this.$store.getters['instrumentsStore/instruments'].map((instrument: Instrument) => {
                    return {
                        id: instrument.instrumentId,
                        name: instrument.name,
                        checked: this.filter.InstrumentIds.indexOf(instrument.instrumentId) > -1,
                    };
                }),
            },
            on: {
                input(newValue) {
                    self.filter.InstrumentIds = newValue;
                    self.projectData.skip = 0;
                    self.getProjects(true);
                },
            },
        });
        return instance;
    }
}
