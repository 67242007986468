import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';

@Component({
    components: {
        'create-project': require('@/modules/projects/components/create-project.vue').default,
    },
})
export default class ProjectActionComponent extends SideActionBase<any> {
    public createProject(): void {
        this.finished();
    }
}
