import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import moment from 'moment';

@Component
export default class DateDisplayComponent extends Vue {
    public dateFormat: string = 'll';
    public dateFormatWithTime: string = 'lll';

    @Prop() public date: Date;
    @Prop({ default: false }) public showTime: boolean;

    get dateIsNullOrUndefined(): boolean {
        return this.date === null || this.date === undefined;
    }

    get formattedDate(): string {
        return moment
            .utc(this.date)
            .local()
            .format(this.showTime ? this.dateFormatWithTime : this.dateFormat);
    }
}
