import { instrumentsService, loginHelper } from '@/main';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import to from 'await-to-js';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Instrument from '../Models/Instrument';

@Module({ namespaced: true })
export default class InstrumentsStore extends VuexModule {
    private _instruments: Instrument[] = [];
    private _failed: boolean = false;
    private _partnerService: PartnerService = null;
    private _practitionerService: PractitionerService = null;

    get instruments(): Instrument[] {
        return this._instruments;
    }

    public get failed() {
        return this._failed;
    }

    @Action({ rawError: true })
    public async cleanup() {
        this.context.commit('SET_INSTRUMENTS', []);
    }

    @Action
    public async fetch() {
        if (this._instruments.length > 0) {
            return;
        }

        if (loginHelper.isInRole('SuperAdmin')) {
            const [err, response] = await to(instrumentsService.getInstruments());
            if (err) {
                return this.FAIL();
            }

            this.context.commit('SET_INSTRUMENTS', response);
            return;
        } else if (loginHelper.isInRole('Partner')) {
            this.context.commit('SET_PARTNERSERVICE');
            const [err, response] = await to(this._partnerService.getInstruments(loginHelper.getUser().partnerId));
            if (err) {
                return this.FAIL();
            }

            this.context.commit('SET_INSTRUMENTS', response.items);
            return;
        } else if (loginHelper.isInRole('Practitioner')) {
            this.context.commit('SET_PRACTITIONERSERVICE');
            const [err, response] = await to(this._practitionerService.getInstruments(loginHelper.getUser().practitionerId));
            if (err) {
                return this.FAIL();
            }

            this.context.commit('SET_INSTRUMENTS', response.items);
            return;
        }

        return this.FAIL();
    }

    @Mutation
    // tslint:disable:no-unused-variable
    private SET_INSTRUMENTS(payload: Instrument[]) {
        this._instruments = payload;
    }

    @Mutation
    private SET_PARTNERSERVICE() {
        this._partnerService = new PartnerService();
    }
    @Mutation
    private SET_PRACTITIONERSERVICE() {
        this._practitionerService = new PractitionerService();
    }

    @Mutation
    private FAIL() {
        this._failed = true;
    }
}
