import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        inputModel: {
            preferredLanguage: { required },
        },
    },
} as any)
export default class LanguageInputComponent extends Vue {
    @Prop({ default: '' }) public placeholder: string;
    @Prop({ default: () => [] }) public value: string[];

    get realValue(): string[] {
        return this.value;
    }

    set realValue(value: string[]) {
        this.$emit('input', value);
    }

    public addItem() {
        this.realValue.push('');

        this.$nextTick(() => {
            const index = this.realValue.length - 1;
            const input = this.$refs.listInput[index];
            input.focus();
        });
    }

    public removeItem(_, index) {
        this.realValue.splice(index, 1);
    }
}
