import { GridColumnProps } from '@progress/kendo-vue-grid';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import to from 'await-to-js';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';
import Prepaid from '../models/Prepaid';
import { PrepaidFilter } from '../models/PrepaidFilter';
import { PrepaidService } from '../services/prepaidService';

@Component({})
export default class PrepaidOverview extends BasePage {
    public prepaidService: PrepaidService = new PrepaidService();

    public prepaidFilter = new PrepaidFilter();

    public columns: GridColumnProps[] = [
        { field: 'prepaidId', title: 'Id' },
        { field: '', title: 'Owner', cell: this.renderOwner },
        { field: 'totalPrepaid', title: 'Total Prepaid', format: '{0:c}' },
        { field: 'usedPrepaid', title: 'Used Prepaid', format: '{0:c}' },
        { field: 'remainingPrepaid', title: 'Remaining Prepaid', format: '{0:c}' },
        { field: 'startDate', title: 'Start Date', cell: Vue.component('grid-date-display') },
        { field: 'endDate', title: 'End Date', cell: Vue.component('grid-date-display') },
        { field: 'cost', title: 'Cost', format: '{0:c}' },
    ];

    public gridKey: string = '';
    public prepaidData = {
        items: [],
        count: 0,
        skip: 0,
        take: 25,
    };

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['id', 'userId', 'totalPrepaid', 'usedPrepaid', 'remainingPrepaid', 'startDate', 'endDate', 'cost'],
    });

    public async mounted() {
        if (!this.isSuperAdmin) {
            return this.$router.push({ name: 'project-overview' });
        }

        await this.getPrepaids();

        if (this.isSuperAdmin) {
            this.columns.push({ field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' });
        }

        this.isLoaded = true;
    }

    public async deletePrepaidAction(prepaid: Prepaid) {
        this.isLoaded = false;
        await this.deletePrepaid(prepaid.prepaidId);
        await this.getPrepaids();
        this.isLoaded = true;
    }

    private renderActions(h, _, row) {
        const prepaid = new Prepaid(row.dataItem);

        const actions = [{ title: 'Delete', function: this.deletePrepaidAction }];
        const props = { actions, item: prepaid };
        return h(Vue.component('grid-actions'), { props });
    }

    private async getPrepaids() {
        const [err, response] = await to(this.prepaidService.getPrepaids(null));
        if (err || !response) {
            return this.clearAndShowError(
                `The prepaids couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem still exists, contact us with the support button.`,
                err,
            );
        }

        this.prepaidData.items = response.data.items;
        this.prepaidData.count = response.data.count;

        this.updateCacheKey();
    }

    private updateCacheKey(): void {
        this.gridKey = new Date().getTime().toString();
    }

    private async deletePrepaid(prepaidId: number) {
        this.showPending('Deleting prepaid');
        const [err, response] = await to(this.prepaidService.deletePrepaid(prepaidId));
        if (err || !response) {
            return this.clearAndShowError(`The prepaid couldn't be deleted due to an error, please try again or contact an admin.`, err);
        }
        this.clearAndShowSuccess(`Successfully deleted prepaid with id ${prepaidId}`);
    }

    private renderOwner(h, _, row) {
        if (row.dataItem.practitionerId) {
            return h(Vue.component('grid-router-link'), {
                props: {
                    title: `Practitioner ${row.dataItem.practitionerId}`,
                    url: this.$router.resolve({
                        name: 'practitioner-details',
                        params: { practitionerId: row.dataItem.practitionerId.toString() },
                    }).href,
                },
            });
        }

        return h(Vue.component('grid-router-link'), {
            props: {
                title: `Partner ${row.dataItem.partnerId}`,
                url: this.$router.resolve({
                    name: 'partner-details',
                    params: { partnerId: row.dataItem.partnerId.toString() },
                }).href,
            },
        });
    }
}
