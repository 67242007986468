import { Component, Prop } from 'vue-property-decorator';
import { loginHelper, projectsService } from '../../main';
import { Project, ProjectUpdate } from '../../models/Project';
import moment from 'moment';
import BasePage from '@/models/BasePage';
import Vue from 'vue';
import { findWindows } from 'windows-iana';

// kendo.culture('en-US');
@Component
export default class TimeFrameBLAATComponent extends BasePage {
    @Prop({ default: null }) public projectData: Project;

    public startDate: Date = null;
    public closeDate: Date = null;
    public minDate: Date;
    public maxDate: Date;

    public selectedTimeZone: string = null;
    public get timezones() {
        return (this as any).$moment.tz.names();
    }

    public get timeZoneUTCOffset() {
        if (this.selectedTimeZone) {
            return (this as any).$moment().tz(this.selectedTimeZone).format('Z');
        }

        return '';
    }

    get isViewer() {
        return !!this.projectData.viewers.find((x) => x.practitionerId === loginHelper.getUser().practitionerId);
    }

    get canEditStartDate(): boolean {
        return this.editMode ? (this.isSuperAdmin || this.isPartner || !this.isViewer ? true : false) : false;
    }

    public created() {
        this.setup();

        this.selectedTimeZone = Vue.filter('timezoneBrowserFormat')(this.projectData.timeZone);
    }

    public setup(): void {
        this.startDate = moment(this.projectData.startDate).toDate();
        this.closeDate = moment(this.projectData.closeDate).toDate();
        this.minDate = this.startDate;
        this.maxDate = this.closeDate;
    }

    public startEdit(): void {
        this.editMode = true;
    }

    public async submit() {
        this.showPending('Saving changes..');
        const startDate: Date = moment(this.startDate).toDate();
        const closeDate: Date = moment(this.closeDate).toDate();

        const projectUpdate = new ProjectUpdate(this.projectData);
        projectUpdate.startDate = startDate;
        projectUpdate.closeDate = closeDate;

        const timeZones = findWindows(this.selectedTimeZone);
        projectUpdate.timeZone = timeZones[0];
        this.projectData.timeZone = timeZones[0];

        await projectsService
            .updateProject(this.projectData.projectId, projectUpdate)
            .then(() => {
                this.$emit('timeframeUpdated', this.projectData, startDate, closeDate);
            })
            .then(() => {
                this.clearAndShowSuccess('The project has been successfully updated');
            })
            .catch((e) => {
                this.clearAndShowError('The project has not been updated due to a error', e);
            })
            .finally(() => {
                this.editMode = false;
            });
    }

    public cancel(): void {
        this.editMode = false;
        this.setup();
    }
}
