import { Component } from 'vue-property-decorator';
import { organizationGroupMembershipService, sequenceService } from '../../../main';
import BasePage from '../../../models/BasePage';
import { OrganizationGroup, OrganizationGroupUpdate } from '../../../models/Organization';
import { ProjectCreate } from '../../../models/Project';
import { UpdateResult } from '../../../models/UpdateResult';
import AddParticipantForProjectCommand from '../../../commands/addParticipantForProjectCommand';
import { OrganizationsService } from '@/services/organizationsService';
import { OrganizationGroupService } from '@/services/organizationGroupService';

@Component({
    components: {
        'members-overview': require('./participantOverview.vue').default,
        'list-input': require('../../../components/inputs/listInput.vue').default,
        'create-project-for-group': require('./createProjectForGroup.vue').default,
    },
})
export default class GroupDetailsComponent extends BasePage {
    public tabIndex: number = 0;
    public isLoading: boolean = false;
    public editMode: boolean = false;
    public groupData: OrganizationGroup = new OrganizationGroup(null);
    public groupDataEdit: OrganizationGroupUpdate = new OrganizationGroupUpdate();
    public projectCreate: ProjectCreate = new ProjectCreate();

    private organizationId: number = 0;
    private groupId: number = 0;
    private organizationsService: OrganizationsService = new OrganizationsService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        this.organizationId = parseInt(this.$router.currentRoute.params.organizationId);
        this.groupId = parseInt(this.$router.currentRoute.params.groupId);

        await this.refreshGroupData();
        await this.setupProject();
    }

    public async refreshGroupData(): Promise<void> {
        this.isLoading = true;

        await this.organizationGroupService
            .getOrganizationGroup(this.organizationId, this.groupId)
            .then(async (groupData: OrganizationGroup) => {
                this.groupData = groupData;

                const members = await organizationGroupMembershipService.getGroupMembers(this.organizationId, this.groupId);
                return (this.groupData.members = members);
            })
            .catch(async () => {
                await this.$router.push({ name: 'organization-details', params: { organizationId: this.organizationId.toString() } });
                this.showError(`Unable to retrieve group information, you're now redirected back to the organization.`);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public startEdit(): void {
        this.editMode = true;
        Object.assign(this.groupDataEdit, {
            name: this.groupData.name,
            externalIdentifiers: this.groupData.externalIdentifiers.slice(0, this.groupData.externalIdentifiers.length),
        });
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.groupDataEdit, this.groupData);
    }

    public async saveEdit() {
        this.showPending('Saving group details.');
        await this.organizationGroupService
            .updateGroup(this.organizationId, this.groupId, this.groupDataEdit)
            .then((result: UpdateResult<OrganizationGroup>) => {
                if (result.updated) {
                    Object.assign(this.groupData, result.entity);
                    this.clearAndShowSuccess('Group details successfully updated.');
                } else {
                    this.clearAndShowError(
                        'An error occurred while saving the group details. Please try again or, when the problem exists, contact us via the support button.',
                        null,
                    );
                }
            })
            .catch((e) => {
                this.clearAndShowError(
                    'An error occurred while saving the group details. Please try again or, when the problem exists, contact us via the support button.',
                    e,
                );
            })
            .finally(() => {
                this.editMode = false;
            });
    }

    public async deleteGroup(): Promise<void> {
        if (confirm('Are you sure that you want to delete the group with the name: ' + this.groupData.name)) {
            this.showPending('Deleting group ' + this.groupData.name);
            this.organizationGroupService
                .deleteGroup(this.organizationId, this.groupId)
                .then(async () => {
                    this.clearAndShowSuccess('The group ' + this.groupData.name + ' was successfully deleted.');
                    await this.$router.push({ name: 'organization-details', params: { organizationId: this.organizationId.toString() } });
                })
                .catch((e) => {
                    this.clearAndShowError(
                        'An error occurred while deleting the group. Please try again or, when the problem exists, contact us via the support button.',
                        e,
                    );
                });
        }
    }

    public async setupProject(): Promise<void> {
        const organization = await this.organizationsService.getOrganization(this.organizationId);

        this.projectCreate.name = 'Project ' + this.groupData.name;
        this.projectCreate.context = this.groupData.name;
        this.projectCreate.organization = organization;
        this.projectCreate.practitioner = organization.practitioner;
        this.projectCreate.startDate = new Date();
    }

    public async setupSequenceProject(sequenceId: number): Promise<void> {
        const newSequencedProject = await sequenceService.createProject(sequenceId);
        await this.addParticipantsToNewProject(newSequencedProject.projectId);
    }

    public async addParticipantsToNewProject(projectId): Promise<void> {
        const addParticipants = new AddParticipantForProjectCommand(this.$store, this.groupData.members);
        try {
            await addParticipants.execute();
            await this.$router.push({ name: 'addSurvey', params: { projectId: projectId.toString() } });
        } catch (e) {
            console.error(e);
        }
    }
}
