import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Member } from '../../models/Member';

@Component
export default class MemberNameComponent extends Vue {
    public member: Member = new Member();

    @Prop({ default: null }) public args!: any;

    public icon: string = '';

    public mounted() {
        const args = this.$data.templateArgs || this.args;

        if (args !== 'undefined') {
            this.member = args.member;
            this.icon = args.icon;
        }
    }
}
