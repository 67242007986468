import Practitioner from '@/modules/practitioners/models/Practitioner';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PractitionerMultiselect extends Vue {
    @Prop({ type: String, default: 'Select practitioner' }) public placeholder: string;
    @Prop({ type: Array }) public options: Practitioner[];
    @Prop({ type: Object }) public value: Practitioner;

    public customLabel(p: Practitioner) {
        return p.firstName + ' ' + p.lastName;
    }

    public onSelect(p: Practitioner) {
        this.$emit('input', p);
    }
}
