import { AxiosResponse } from 'axios';
import { SequenceCreate } from '../components/side-actions/sequence-actions/sequenceAction';
import { CreateResult } from '../models/CreateResult';
import { OrganizationCreate, OrganizationUpdate, RetentionTimeUpdate } from '../models/Organization';
import Sequence from '../models/Sequence';
import { UpdateResult } from '../models/UpdateResult';
import { BaseService } from './baseService';
import Vue from 'vue';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import { PagedResponse } from '@/models/PagedResponse';
import DemoGraphicModel from '@/models/DemoGraphicModel';
import { DemoGraphicItem } from '@/models/DemographicItem';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import Organization from '@/modules/organizations/models/Organization';

// tslint:disable: no-floating-promises
export class OrganizationsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getOrganization(id: number): Promise<Organization> {
        const self = this;
        const url = `${this.endpoint}/organizations/${id}`;

        return new Promise<Organization>((resolve, reject) => {
            self.get<Organization>(url)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getOrganizations(filter?: any): Promise<PagedResponse<Organization>> {
        const queryString = [];
        if (filter) {
            for (const prop in filter) {
                if (filter[prop] !== null && filter[prop] !== undefined && !(filter[prop] === '' && typeof filter[prop] === 'string')) {
                    if (prop === 'searchOptions') {
                        Object.keys(filter[prop])
                            .filter((key) => filter[prop][key])
                            .forEach((option) => {
                                queryString.push(`${prop}=${option}`);
                            });
                    } else {
                        queryString.push(`${prop}=${filter[prop]}`);
                    }
                }
            }
        }

        if (filter && filter.embedOptions) {
            queryString.push('embed_options=Practitioner,Partner,ProjectCount');
        }

        return new Promise<PagedResponse<Organization>>((resolve) => {
            this.get<PagedResponse<Organization>>(`${this.endpoint}/organizations${queryString.length > 0 ? '?' + queryString.join('&') : ''}`).then(
                (result) => {
                    resolve(result.data);
                },
            );
        });
    }

    public createOrganization(organizationData: OrganizationCreate): Promise<CreateResult<Organization>> {
        const self = this;
        return new Promise<CreateResult<Organization>>((resolve) => {
            self.post<CreateResult<Organization>>(`${this.endpoint}/organizations`, organizationData).then((result) => {
                resolve(result.data);
            });
        });
    }

    public updateOrganization(organizationId: number, organizationData: OrganizationUpdate): Promise<UpdateResult<Organization>> {
        return new Promise<UpdateResult<Organization>>((resolve, reject) => {
            this.put<UpdateResult<Organization>>(`${this.endpoint}/organizations/${organizationId}`, organizationData)
                .then((response: AxiosResponse<UpdateResult<Organization>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updateRetentionTime(organizationId: number, retentionTimeData: RetentionTimeUpdate): Promise<UpdateResult<Organization>> {
        return new Promise<UpdateResult<Organization>>((resolve, reject) => {
            this.put<UpdateResult<Organization>>(`${this.endpoint}/organizations/${organizationId}/retention`, retentionTimeData)
                .then((response: AxiosResponse<UpdateResult<Organization>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getSequences(organizationId: number): Promise<Sequence[]> {
        return new Promise<Sequence[]>((resolve, reject) => {
            this.get<Sequence[]>(`${this.endpoint}/organizations/${organizationId}/sequences`)
                .then((response: AxiosResponse<Sequence[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public createSequence(organizationId: number, sequenceData: SequenceCreate): Promise<Sequence> {
        return new Promise<Sequence>((resolve, reject) => {
            this.post<Sequence>(`${this.endpoint}/organizations/${organizationId}/sequence`, sequenceData)
                .then((response: AxiosResponse<Sequence>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getShared(organizationId: number): Promise<Practitioner[]> {
        return new Promise<Practitioner[]>((resolve, reject) => {
            this.get<Practitioner[]>(`${this.endpoint}/organizations/${organizationId}/share`)
                .then((response: AxiosResponse<Practitioner[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public createShare(organizationId: number, email: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.post<boolean>(`${this.endpoint}/organizations/${organizationId}/share?email=:email`, null, [['email', encodeURIComponent(email)]])
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deletedShare(organizationId: number, practitionerId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.delete(`${this.endpoint}/organizations/${organizationId}/share?practitionerId=${practitionerId}`)
                .then((response: AxiosResponse<void>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public transferAdminRights(organizationId: number, practitionerId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.post(`${this.endpoint}/organizations/${organizationId}/transfer-owner`, { practitionerId })
                .then((response: AxiosResponse<void>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deleteOrganization(organizationId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.delete(`${this.endpoint}/organizations/${organizationId}`)
                .then((response: AxiosResponse<void>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getOrganizationSubscriptions(organizationId): Promise<PagedResponse<OrganizationSubscription>> {
        return new Promise<PagedResponse<OrganizationSubscription>>((resolve, reject) => {
            this.get<PagedResponse<OrganizationSubscription>>(`${this.endpoint}/organizations/${organizationId}/subscriptions`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addOrganizationSubscription(id: number, organizationSubscription: OrganizationSubscription): Promise<OrganizationSubscription> {
        const self = this;
        return new Promise<OrganizationSubscription>((resolve, reject) => {
            self.put<OrganizationSubscription>(`${this.endpoint}/organizations/${id}/subscriptions`, {
                seats: organizationSubscription.seats,
                price: organizationSubscription.price,
                renewalDate: organizationSubscription.renewalDate,
                startDate: organizationSubscription.startDate,
                instruments: organizationSubscription.instruments,
                knowledgeModelId: organizationSubscription.knowledgeModelId,
            })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updateOrganizationSubscription(Subscription: OrganizationSubscription, refund: boolean) {
        return new Promise<OrganizationSubscription>((resolve, reject) => {
            this.post<OrganizationSubscription>(`${this.endpoint}/organizations/subscription/${refund}`, Subscription)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public mergeOrganizations(fromId: number, toId: number): Promise<Organization> {
        const self = this;
        return new Promise<Organization>((resolve, reject) => {
            self.post<Organization>(`${this.endpoint}/organizations/${fromId}/merge?to=${toId}`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addQuestionToDemographicModel(demographicId: number, organizationId: number, question: DemoGraphicItem) {
        return this.post<DemoGraphicItem>(
            `${this.endpoint}/organizations/${organizationId}/demographic-models/${demographicId}/questions`,
            question,
        );
    }

    public updateQuestionToDemographicModel(demographicId: number, organizationId: number, question: DemoGraphicItem) {
        return this.put<DemoGraphicItem>(
            `${this.endpoint}/organizations/${organizationId}/demographic-models/${demographicId}/questions/${question.id}`,
            question,
        );
    }

    public deleteQuestionFromDemographicModel(demographicId: number, organizationId: number, question: DemoGraphicItem) {
        return this.delete(
            `${this.endpoint}/organizations/${organizationId}/demographic-models/${demographicId}/questions/${question.id}`
        );
    }

    public getDemographicModel(organizationId) {
        return this.get<DemoGraphicModel>(`${this.endpoint}/organizations/${organizationId}/demographic-models`);
    }

    public async exportDemographicData(organizationId?: number): Promise<any> {
        if (!organizationId) {
            console.error('projectId or organizationId cannot both be empty');
            return;
        }

        const response = await this.post(
            `${this.endpoint}/organizations/:organizationId/demographic-data/export`,
            null,
            [['organizationId', organizationId]],
            {
                responseType: 'arraybuffer',
            },
        );

        const url = URL.createObjectURL(
            new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Demographic-data-${organizationId}`);
        document.body.appendChild(link);
        return link.click();
    }

    public getDashboardToken(organizationId: number): Promise<any> {
        const self = this;
        return new Promise<string>((resolve, reject) => {
            self.get<string>(`${this.endpoint}/organizations/${organizationId}/dashboard-token`)
                .then((response: AxiosResponse<string>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}

// tslint:disable-next-line: max-classes-per-file
export class OrganizationFilter {
    public organizationId: number = null;
    public skip: number;
    public take: number;
    public includeCount: boolean;

    constructor(filter: Partial<OrganizationFilter>) {
        Object.assign(this, filter);
    }
}
