import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { projectsService } from '@/main';
import { Project } from '@/models/Project';
import ProjectFilter from '../models/ProjectFilter';
import { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import to from 'await-to-js';
import BaseProjectOverviewPage from './base-project-overview';

@Component({
    components: {
        'create-project': require('@/modules/projects/components/create-project.vue').default,
    },
} as any)
export default class ProjectsOverviewComponent extends BaseProjectOverviewPage {
    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '46px', cell: this.renderCheckbox, sortable: false, filterable: false },
        { field: 'ProjectName', title: 'Name', cell: this.renderProjectRouterLinkWithBreachCheck, sortable: true },
        {
            field: 'instrument',
            title: 'Price plan(s)',
            cell: this.renderPricePlans,
            sortable: false,
        },
        {
            field: 'instrument',
            title: 'Instrument(s)',
            cell: this.renderInstruments,
            sortable: false,
        },
        { field: 'practitioner', title: 'Practitioner', cell: this.renderPractitionerLink, sortable: false },
        { field: 'organization', title: 'Organization', cell: this.renderOrganizationLink, sortable: false },
        { field: 'dateCreated', title: 'Create date', cell: Vue.component('grid-date-display'), width: '125px', sortable: true },
        { field: 'closeDate', title: 'Close date', cell: Vue.component('grid-date-display'), width: '125px', sortable: true },
        { field: 'participantCount', title: 'Participants', width: 140, sortable: false },
        { field: 'actions', title: 'Actions', cell: this.renderActions, width: 120, sortable: false },
    ];

    public async created() {
        const self = this;
        this.filter = new ProjectFilter(this.defaultFilter);

        this.orderBy.setSort('dateCreated', OrderByDirection.Desc);

        this.$store.dispatch('instrumentsStore/fetch');

        self.projectData.projects = new Array();
        await self.getProjects(true);

        this.$store.commit('projectStore/TAB_CHANGED', 0);
    }

    public async setAllSelectedProjectsChecked() {
        this.showPending('Approving the selected projects..');

        for (let i = 0; i < this.selectedProjects.length; i++) {
            const project = this.selectedProjects[i];
            const [err] = await to(projectsService.setPartnerChecked(project.projectId));
            if (err) {
                continue;
            }
        }

        await this.getProjects(true);

        this.clearAndShowSuccess('The selected projects are approved');
    }

    private renderActions(item: any, _, row): any {
        const actions = [
            {
                title: 'Approve',
                function: this.approveProject,
            },
        ];

        const props = { actions, item: row.dataItem, showSingleButton: true };
        return item(Vue.component('grid-actions'), { props });
    }

    public async approveProject(project: Project) {
        const [err] = await to(projectsService.setPartnerChecked(project.projectId));
        if (err) {
            return this.showError(`Project couldn't be approved`);
        }

        this.projectData.projects = this.projectData.projects.filter((p) => p.projectId !== project.projectId);
        this.showSuccess('The project is approved');

        this.updateCacheKey();

        setTimeout(() => {
            Vue.nextTick(async () => {
                this.$store.dispatch('projectStore/fetchMenuCounters');
            });
        }, 500);
    }
}
