import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DropdownFilter extends Vue {
    @Prop({ default: [] }) public value: number[];
    @Prop({ default: [], required: true }) public options: any[];
    @Prop({ default: 'title', required: true}) public title: string;

    public get id() {
        return `${this.title.toLowerCase()}_filter_${(this as any)._uid}`;
    }

    public onChange(id: number) {
        const newValue = [...this.value];

        if (newValue.indexOf(id) > -1) {
            newValue.splice(newValue.indexOf(id), 1);
        } else {
            newValue.push(id);
        }
        this.$emit('input', newValue);
    }
}
