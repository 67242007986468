import BasePage from "@/models/BasePage";
import Vue, { PropType } from 'vue';
import Component from "vue-class-component";
import { instrumentsService } from '@/main';
import { Prop } from "vue-property-decorator";
import PillarThreshold, { PillarThresholdType, PillarThresholdView, ThresholdValue } from "@/models/PillarThreshold";
import { GridColumnProps } from "@progress/kendo-vue-grid";

@Component
export default class PillarThresholdsComponent extends BasePage {
    @Prop({ default: () => 0 }) public instrumentId: number;

    public pillarThresholds: PillarThresholdView[] = [];
    public gridKey: number = 0;

    public columns: GridColumnProps[] = [
        { field: 'alias', title: 'Pillar Alias', width: '200px' },
        { field: 'type', title: 'Threshold Type', cell: this.renderType, width: '200px' },
        { field: 'values', title: 'Values', cell: this.renderValues },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public async created() {
        await this.getPillarThresholds(this.instrumentId);
    }
 
    public renderActions(item: any, _: any, row: any): any {
        const actions = [
            { title: 'Edit', function: this.openUpdatePillarThresholdActionRow },
        ];
        const props = { actions, item: row };
        return item(Vue.component('grid-actions'), { props });
    }

    public renderValues(item: any, _: any, row: { dataItem: PillarThresholdView }): any {
        return item('td', row.dataItem.values.map((x) => ` ${x.value} ${x.order >= row.dataItem.values.length - 1 ? '' : '|'}`));
    }

    public renderType(item: any, _: any, row: { dataItem: PillarThresholdView }): any {
        return item('td', row.dataItem.type);
    }

    public openAddPillarThreshold(): any {
        this.$sideActions.push('pillar-threshold-add-action', {
            instrumentId: this.instrumentId,
            currentSettings: this.pillarThresholds.reduce((x, y) => {
                x.push(...y.convertToPillarThresholds()); 
                return x; 
            }, []) }, async () => {
            await this.getPillarThresholds(this.instrumentId);
            this.gridKey ++;
        });
    }

    public openUpdatePillarThresholdActionRow(row: { dataItem: PillarThreshold }): any {
        const thresholds = this.pillarThresholds.reduce((x, y) => {
            x.push(...y.convertToPillarThresholds()); 
            return x; 
        }, [])
        .filter((x: PillarThreshold) =>  x.alias == row.dataItem.alias && x.type == row.dataItem.type)
        .sort((x, y) => x.order - y.order);
        this.openUpdatePillarThresholdAction(thresholds);
    }

    private openUpdatePillarThresholdAction(thresholds: PillarThreshold[]): any {
        this.$sideActions.push(
            'pillar-threshold-update-action',
            {
                thresholds,
                instrumentId: this.instrumentId,
                type: thresholds.at(0).type,
                alias: thresholds.at(0).alias,
            },
            async () => {
                await this.getPillarThresholds(this.instrumentId);
                this.gridKey ++;
            },
        );
    }

    private groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
        arr.reduce((groups, item) => {
          (groups[key(item)] ||= []).push(item);
          return groups;
        }, {} as Record<K, T[]>);

    private async getPillarThresholds(instrumentId: number) {
        await instrumentsService
            .getInstrumentPillarThresholds(instrumentId)
            .then((thresholds) => {
                const result = this.groupBy(thresholds, i => i.alias);
                const keys = Object.keys(result);
                const final: PillarThresholdView[] = [];
                keys.forEach(element => {
                    const groups = Object.keys(this.groupBy(result[element], i => i.type));
                    groups.forEach(group => {
                        const values = result[element].reduce((x, y) => { 
                            if (y.type.toString() === group) { 
                                x[y.order] = new ThresholdValue({ value: y.value, order: y.order, id: y.id }); 
                            } 
                            return x; 
                        }, []);
                        final.push(new PillarThresholdView({ values: values, alias: element, 
                            type:  group as PillarThresholdType }))
                    });
                });
                this.pillarThresholds = final;
                this.isLoaded = true;
            })
            .catch(() => {
                this.showError(
                    `The insturment pillar thresholds couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}