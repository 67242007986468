import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class OrganizationDetailsStore extends VuexModule {
    private _currentTab: number = 0;

    @Mutation
    public TAB_CHANGED(newTab: number) {
        this._currentTab = newTab;
    }

    get currentTab(): number {
        return this._currentTab;
    }
}
