import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridCheckboxComponent extends Vue {
    @Prop() public field: string;
    @Prop() public dataItem: object;

    set model(input) {
        if (this.field) {
            const splittedItems = this.field.split('.');
            let realDataItem = this.dataItem;

            for (let i = 0; i < splittedItems.length - 1; i++) {
                realDataItem = realDataItem[splittedItems[i]];
            }

            realDataItem[splittedItems[splittedItems.length - 1]] = input;
        }
    }

    get model(): any {
        if (this.field) {
            const splittedItems = this.field.split('.');
            let realDataItem = this.dataItem;

            for (let i = 0; i < splittedItems.length; i++) {
                realDataItem = realDataItem[splittedItems[i]];
            }

            return realDataItem;
        }

        return false;
    }

    public change(e) {
        this.$emit('change', e, e.target.value);
    }
}
