import { Component } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { loginHelper } from '@/main';
import { OrganizationGroup } from '../../../models/Organization';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import PractitionerFilter from '@/modules/practitioners/models/PractitionerFilter';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import { OrganizationsService } from '@/services/organizationsService';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component({
    validations: {
        createSequenceInput: {
            practitioner: { required },
            selectedInstruments: { required },
            group: {
                requiredIf: requiredIf((createModel: SequenceCreate) => {
                    return createModel.isGroupRequired;
                }),
            },
            startDate: {
                requiredIf: requiredIf((createModel: SequenceCreate) => {
                    return createModel.useInterval;
                }),
            },
            interval: {
                requiredIf: requiredIf((createModel: SequenceCreate) => {
                    return createModel.useInterval;
                }),
            },
        },
    },
    components: {
        'date-time-input': require('../../inputs/dateTimeInput.vue').default,
    },
} as any)
export default class SequenceActionComponent extends SideActionBase<SequenceActionOptions> {
    public organizationId: number = this.options.organizationId;
    public practitioners: Practitioner[] = this.options.practitioners;
    public groupIsRequired: boolean = this.options.groupIsRequired;

    // tslint:disable: no-use-before-declare
    public timeOptions: any[] = SequenceManagementBase.timeOptions;

    public createSequenceInput: SequenceCreate = new SequenceCreate(false);
    public instruments: Instrument[] = [];
    public groups: OrganizationGroup[] = [];
    public submitted: boolean = false;
    public loading: boolean = true;
    public working: boolean = false;
    private practitionerService: PractitionerService = new PractitionerService();
    private organizationsService: OrganizationsService = new OrganizationsService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        this.groups = await this.organizationGroupService.getOrganizationGroups(this.organizationId);
        await this.$store.dispatch('instrumentsStore/fetch');
        const instruments = this.$store.getters['instrumentsStore/instruments'];
        this.instruments = instruments.filter((i) => {
            return i.isInstrumentSelectable;
        });

        if (this.instruments.length === 1) {
            // pre-select instrument
            this.createSequenceInput.selectedInstruments = [this.instruments[0]];
        }

        this.loading = false;
    }

    public async mounted() {
        this.createSequenceInput.isGroupRequired = this.groupIsRequired;

        if (this.practitioners.length === 0) {
            await this.practitionerService
                .getPractitioners(new PractitionerFilter({ skip: 0, take: 9999 }))
                .then((practitioners) => {
                    this.practitioners = practitioners.items;

                    const me = this.practitioners.find((u) => {
                        return loginHelper.getUser().id === u.practitionerId;
                    });
                    if (me) {
                        this.createSequenceInput.practitioner = me;
                    }
                })
                .catch(() => {
                    this.showError(
                        `The practitioners couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                    );
                });
        }

        if (this.practitioners.length === 1) {
            this.createSequenceInput.practitioner = this.practitioners[0];
        }
    }

    public get groupLabel(): string {
        if (this.groupIsRequired) {
            return 'Group';
        }

        return 'Group (optional)';
    }

    public async createSequence(): Promise<void> {
        if (!(this as any).$v.$invalid) {
            if (!this.createSequenceInput.useInterval) {
                this.createSequenceInput.startDate = null;
                this.createSequenceInput.interval = null;
            }

            this.working = true;
            this.showPending('Creating the sequence data');
            const sequence = await this.organizationsService.createSequence(this.organizationId, this.createSequenceInput);
            this.clearAndShowSuccess('Creation of sequence data completed');
            this.working = false;

            this.finished(sequence);
        }
    }
}

// tslint:disable: max-classes-per-file
export class SequenceManagementBase {
    public static timeOptions: any[] = [
        { text: 'Hour(s)', value: 1 },
        { text: 'Day(s)', value: 24 },
        { text: 'Week(s)', value: 24 * 7 },
    ];

    public static getTimeOption(interval: number): any {
        let timeUnit = null;

        for (const item of SequenceManagementBase.timeOptions.slice().reverse()) {
            if (interval >= item.value && interval % item.value === 0) {
                timeUnit = item;
                break;
            }
        }

        return timeUnit;
    }

    public groupId?: number = null;
    public practitionerId: number = null;
    public instruments: number[];
    public startDate?: Date = null;
    public interval?: number = null;
    public isGroupRequired: boolean = false;
    public useInterval: boolean = false;

    // tslint:disable: variable-name

    protected _timeUnit: number = 24 * 7;

    private _selectedInstruments: Instrument[] = [null];
    private _selectedGroup?: OrganizationGroup = null;
    private _selectedPractitioner: Practitioner = null;

    public get group(): OrganizationGroup {
        return this._selectedGroup;
    }

    public set group(input: OrganizationGroup) {
        this._selectedGroup = input;
        if (input) {
            this.groupId = input.id;
        } else {
            this.groupId = null;
        }
    }

    public get practitioner(): Practitioner {
        return this._selectedPractitioner;
    }

    public set practitioner(input: Practitioner) {
        this._selectedPractitioner = input;
        if (input) {
            this.practitionerId = input.practitionerId;
        } else {
            this.practitionerId = null;
        }
    }

    public get selectedInstruments(): Instrument[] {
        // In the future this thing is a list of instruments, for now the front-end limits the function to one because the rest of the application is not built for multiple instruments
        return this._selectedInstruments;
    }

    public set selectedInstruments(instruments: Instrument[]) {
        this._selectedInstruments = instruments;
        this.instruments = this.selectedInstruments
            .map((i) => {
                if (i) {
                    return i.instrumentId;
                }

                return null;
            })
            .filter((i) => {
                return i !== null;
            });
    }

    public get selectedTimeUnit(): any {
        return this._timeUnit;
    }
    public set selectedTimeUnit(timeUnit: any) {
        this._timeUnit = timeUnit;
    }

    public set setInterval(interval: number) {
        this.interval = interval * this._timeUnit;
    }

    public get setInterval(): number {
        return this.interval / this._timeUnit;
    }

    constructor(isGroupRequired: boolean) {
        this.isGroupRequired = isGroupRequired;
    }
}

export class SequenceCreate extends SequenceManagementBase {
    constructor(isGroupRequired: boolean) {
        super(isGroupRequired);

        this.startDate = new Date();
    }
}

export class SequenceActionOptions {
    public organizationId: number;
    public practitioners: Practitioner[] = [];
    public groupIsRequired: boolean = false;

    constructor(data: SequenceActionOptions) {
        Object.assign(this, data);

        if (!this.organizationId) {
            throw new Error('Organization id is required');
        }
    }
}
