import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridTotalInvoiceComponent extends Vue {
    public currencySymbols = {
        USD: '$', // US Dollar
        EUR: '€', // Euro
        CRC: '₡', // Costa Rican Colón
        GBP: '£', // British Pound Sterling
        ILS: '₪', // Israeli New Sheqel
        INR: '₹', // Indian Rupee
        JPY: '¥', // Japanese Yen
        KRW: '₩', // South Korean Won
        NGN: '₦', // Nigerian Naira
        PHP: '₱', // Philippine Peso
        PLN: 'zł', // Polish Zloty
        PYG: '₲', // Paraguayan Guarani
        THB: '฿', // Thai Baht
        UAH: '₴', // Ukrainian Hryvnia
        VND: '₫', // Vietnamese Dong
    };

    @Prop({ default: 0 }) public value: number;
    @Prop({ default: 'EUR' }) public currencyCode: string;
    public currencySymbol: string = '';
    public result: string = '';

    public mounted() {
        if (this.currencySymbols[this.currencyCode] !== undefined) {
            this.currencySymbol = this.currencySymbols[this.currencyCode];
        }
        this.result = this.value.toFixed(2);
    }

    public discount(num: number, per: number): number {
        return (num / 100) * per;
    }
}
