import { Component } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import { languageService } from '../../main';
import { Language } from '../../models/Language';
import { ParticipantState } from '@/pages/surveys/models/participant';
import BaseParticipantManagement, { ParticipantThing } from './baseParticipantManagement';
import Participant from '@/modules/participants/models/Participant';

@Component({
    validations: {
        inputModel: {
            firstName: { required },
            lastName: { required },
            emailAddress: { required, email },
            preferredLanguage: { required },
        },
    },
} as any)
export default class AddNewParticipantComponent extends BaseParticipantManagement {
    public languages: Language[] = [];
    public inputModel: any = {
        preferredLanguage: {},
    };
    public submitted: boolean = false;
    public newEntityAfterAdding: boolean = true;

    public async created() {
        this.languages = await languageService.getLanguages();
        this.inputModel.preferredLanguage = this.languages.find((x) => x.languageCode.toLowerCase() === 'en');
    }

    public addParticipant(): void {
        if ((this as any).$v.$invalid) {
            return;
        }

        const participant = new Participant(this.inputModel);
        participant.organizationId = this.organizationId;
        participant.preferredLanguage = this.inputModel.preferredLanguage.languageCode;

        this.addBaseParticipant(new ParticipantThing({ participantData: participant, participantAddState: ParticipantState.Undefined }), !this.newEntityAfterAdding);
    }
}
