import { Project } from './Project';
import { QuestionTracker } from './QuestionTracker';
import Participant from '@/modules/participants/models/Participant';
import { SurveyType } from '@/pages/surveys/models/participant';
import { EmailOverviewItem } from '@/modules/projects/components/email-overview';

export class SurveyDetails {
    public surveyId: number;
    public parentSurveyId?: number;
    public instrumentId: number;
    public instrumentName: string;
    public participant: Participant;
    public participantId: number;
    public project: Project;
    public Status: SurveyStatus;
    public completionDate: Date;
    public surveyCompleted: boolean;
    public surveyLink: string;
    public reportLink: string;
    public deleted: boolean;
    public questionTracker: QuestionTracker;
    public basedOn: SurveyDetails;
    public isLocked: boolean;
    public surveyType: SurveyType;

    public email: EmailOverviewItem = new EmailOverviewItem();

    constructor(surveyData?: Partial<SurveyDetails>) {
        Object.assign(this, surveyData);

        if (surveyData) {
            if (surveyData.participant) {
                this.participant = new Participant(surveyData.participant);
            }
        }
    }
}

export enum SurveyStatus {
    Open = 'Open',
    Started = 'Started',
    Finished = 'Finished',
}

export enum EmailStatus {
    Processing = 'Processing',
    Sent = 'Sent',
    Error = 'Error',
}
