import { Component, Vue } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { ProjectCreate, Project, ProjectInstrument } from '../../../models/Project';
import { ConnectedInstrument } from '../../../models/Instrument';
import { Member } from '../../../models/Member';
import { loginHelper, projectsService, $router } from '../../../main';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import BasePage from '@/models/BasePage';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import PractitionerFilter from '@/modules/practitioners/models/PractitionerFilter';
import Organization, { CreatedOrganization } from '@/modules/organizations/models/Organization';
import CreateOrganizationComponent from '@/modules/organizations/side-actions/create-organization-side-action';
import { CreateResult } from '@/models/CreateResult';
import { OrganizationsService } from '@/services/organizationsService';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { ProgressType } from '@/plugins/Progress/ProgressArgs';
import he from 'he';
import Instrument from '@/modules/instruments/Models/Instrument';
import { findWindows } from 'windows-iana';

@Component({
    validations: {
        createProjectInput: {
            name: { required },
            practitioner: { required },
            organizationId: { required },
            instruments: { required },
            startDate: { required },
            closeDate: { required },
            context: {
                requiredIf: requiredIf((createModel: ProjectCreate) => {
                    return createModel.contextMandatory;
                }),
            },
        },
    },
} as any)
export default class CreateProjectComponent extends BasePage {
    public user: Member;
    public practitioners: Practitioner[] = [];
    public organizations: Organization[] = [];
    public selectableInstruments: Instrument[] = [];
    public instruments: Instrument[] = [];
    public applicableInstruments: ConnectedInstrument[] = [];
    public mandatoryInstruments: ConnectedInstrument[] = [];
    public primaryInstrument: Instrument = null;
    public knowledgeModel: KnowledgeModel = null;
    public thePricingPlanKey: number = new Date().getTime();
    public submitted: boolean = false;
    public createProjectInput: ProjectCreate = new ProjectCreate();
    public createError: boolean = false;
    public loadingOrgs = false;
    public loadingOrgInfo = false;
    public loadingOrgInstruments = false;
    public loadingInstrumentData = false;

    private selectedOrganization: Organization = new Organization();
    private organizationSubscriptions: OrganizationSubscription[] = [];
    private practitionersService: PractitionerService = new PractitionerService();
    private organizationsService: OrganizationsService = new OrganizationsService();
    private knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public get showContext() {
        return this.instruments.some((x) => x.contextOption === 'optional' || x.contextOption === 'mandatory');
    }

    public get contextMandatory() {
        return this.instruments.some((x) => x.contextOption === 'mandatory');
    }

    public get contextValue() {
        return this.contextMandatory ? 'mandatory' : 'optional';
    }

    public async created() {
        this.user = loginHelper.getUser();
        await this.getOrganizations();

        this.createProjectInput.startDate = new Date();

        if (!this.isPartner || !this.isSuperAdmin) {
            await this.getInstrumentsForPractitioner();
        }
    }

    public cancel(): void {
        this.$emit('cancel');
    }

    public isPricingPlanRequired(instrumentId: number): boolean {
        if (
            !this.organizationSubscriptions ||
            !this.primaryInstrument ||
            !this.organizationSubscriptions.find((x) => x.knowledgeModelId === this.primaryInstrument.knowledgeModelId && x.isActive)
        ) {
            return true;
        }

        const subscription = this.organizationSubscriptions.find((x) => x.knowledgeModelId === this.primaryInstrument.knowledgeModelId && x.isActive);

        return subscription.instruments.find((x) => x.instrumentId === instrumentId) == null;
    }

    public createOrganizationSideAction(): void {
        this.$sideActions.push('organization-create-action', new CreateOrganizationComponent(), async (createdOrganization: CreatedOrganization) => {
            if (createdOrganization) {
                await this.getOrganizations();
                this.createProjectInput.organization = createdOrganization;
                this.createProjectInput.organizationId = createdOrganization.id;
                this.selectedOrganization = this.organizations.find((x) => {
                    return x.organizationId === createdOrganization.id;
                });

                await this.getOrganizationInformation();
            }
        });
    }

    public async OnInstrumentChange(ev: Instrument, force: boolean = false) {
        if (this.primaryInstrument && this.primaryInstrument.instrumentId === ev.instrumentId && !force) {
            return;
        }

        this.loadingInstrumentData = true;
        this.primaryInstrument = ev;
        this.instruments = [];
        this.applicableInstruments = [];
        this.mandatoryInstruments = [];
        this.createProjectInput.mandatoryInstruments = [];

        await this.$store.dispatch('instrumentsStore/fetch');
        await this.loadKnowledgeModel(ev.knowledgeModelId);

        const instrumentObj = this.knowledgeModel.instruments.find((x) => x.instrumentId === ev.instrumentId);
        const instrument = new Instrument(instrumentObj);

        this.instruments.push(instrument);
        this.createProjectInput.contextMandatory = this.contextMandatory;
        this.createProjectInput.instruments.push(new ProjectInstrument({ instrumentId: instrument.instrumentId }));
        this.createProjectInput.knowledgeModelId = ev.knowledgeModelId;

        if (this.isPricingPlanRequired(ev.instrumentId)) {
            await this.getPricingPlans(ev.instrumentId).then(() => {
                instrument.pricingPlanOptions = [];
                instrument.pricingPlans.forEach((pricingPlan) => {
                    instrument.pricingPlanOptions.push(pricingPlan);
                });

                this.pricingPlanChanged();
            });
        }

        if (instrument.connectedInstruments) {
            this.applicableInstruments = instrument.connectedInstruments.filter(
                (x) => !x.mandatory && this.selectableInstruments.find((y) => y.instrumentId === x.instrumentId),
            );
            const mandatoryInstruments = instrument.connectedInstruments.filter(
                (x) => x.mandatory && this.selectableInstruments.find((y) => y.instrumentId === x.instrumentId),
            );

            for (let i = 0; i < mandatoryInstruments.length; i++) {
                const mandatoryConnectedInstrument = mandatoryInstruments[i];

                const mandatoryInstrumentObj = this.knowledgeModel.instruments.find(
                    (x) => x.instrumentId === mandatoryConnectedInstrument.instrumentId,
                );
                const mandatoryInstrument = new Instrument(mandatoryInstrumentObj);

                this.instruments.push(mandatoryInstrument);
                this.createProjectInput.mandatoryInstruments.push(mandatoryInstrument.instrumentId);

                if (this.isPricingPlanRequired(mandatoryInstrument.instrumentId)) {
                    await this.getPricingPlans(mandatoryInstrument.instrumentId).then(() => {
                        mandatoryInstrument.pricingPlanOptions = [];
                        mandatoryInstrument.pricingPlans.forEach((pricingPlan) => {
                            mandatoryInstrument.pricingPlanOptions.push(pricingPlan);
                        });

                        this.pricingPlanChanged();
                    });
                }

                this.mandatoryInstruments.push(mandatoryConnectedInstrument);
            }
        }
        this.loadingInstrumentData = false;
    }

    public async additionalInstrumentSelected(instrumentIds) {
        if (instrumentIds.length <= this.instruments.length - 1) {
            if (instrumentIds.length === 0) {
                this.OnInstrumentChange(this.primaryInstrument, true);
            } else {
                const instrumentIdsDeselected = this.instruments
                    .filter((x) => instrumentIds.indexOf(x.instrumentId) === -1 && x.instrumentId !== this.primaryInstrument.instrumentId)
                    .map((x) => x.instrumentId);

                this.instruments = this.instruments.filter(
                    (x) => this.primaryInstrument.instrumentId === x.instrumentId || instrumentIdsDeselected.indexOf(x.instrumentId) === -1,
                );

                for (let i = 0; i < instrumentIdsDeselected.length; i++) {
                    const instrumentObj = this.knowledgeModel.instruments.find((x) => x.instrumentId === instrumentIds[i]);
                    const instrument = new Instrument(instrumentObj);

                    const manInstIds = instrument.connectedInstruments.filter((x) => x.mandatory).map((x) => x.instrumentId);
                    const addInstIds = instrument.connectedInstruments.filter((x) => !x.mandatory).map((x) => x.instrumentId);
                    this.mandatoryInstruments = this.mandatoryInstruments.filter((z) => manInstIds.indexOf(z.instrumentId) === -1);
                    this.applicableInstruments = this.applicableInstruments.filter((z) => addInstIds.indexOf(z.instrumentId) === -1);
                }
            }
        }

        for (let i = 0; i < instrumentIds.length; i++) {
            const instrumentObj = this.knowledgeModel.instruments.find((x) => x.instrumentId === instrumentIds[i]);
            const instrument = new Instrument(instrumentObj);

            if (!this.instruments.find((x) => x.instrumentId === instrument.instrumentId)) {
                this.instruments.push(instrument);
            }

            if (this.isPricingPlanRequired(instrument.instrumentId) && !instrument.pricingPlanOptions.length) {
                await this.getPricingPlans(instrument.instrumentId).then(() => {
                    instrument.pricingPlanOptions = [];
                    instrument.pricingPlans.forEach((pricingPlan) => {
                        instrument.pricingPlanOptions.push(pricingPlan);
                    });

                    this.pricingPlanChanged();
                });
            }

            if (instrument.connectedInstruments) {
                for (let j = 0; j < instrument.connectedInstruments.length; j++) {
                    const currentConnectedInstrument = instrument.connectedInstruments[j];

                    if (currentConnectedInstrument.mandatory) {
                        if (!this.mandatoryInstruments.find((y) => y.instrumentId === currentConnectedInstrument.instrumentId)) {
                            const mandatoryInstrumentObj = this.knowledgeModel.instruments.find(
                                (x) => x.instrumentId === currentConnectedInstrument.instrumentId,
                            );
                            const mandatoryInstrument = new Instrument(mandatoryInstrumentObj);

                            if (this.primaryInstrument.instrumentId !== mandatoryInstrument.instrumentId) {
                                this.mandatoryInstruments.push(new ConnectedInstrument(mandatoryInstrumentObj));
                                this.createProjectInput.mandatoryInstruments.push(mandatoryInstrument.instrumentId);
                            }

                            if (!this.instruments.find((x) => x.instrumentId === mandatoryInstrument.instrumentId)) {
                                this.instruments.push(mandatoryInstrument);
                            }

                            if (this.isPricingPlanRequired(mandatoryInstrument.instrumentId)) {
                                await this.getPricingPlans(mandatoryInstrument.instrumentId).then(() => {
                                    mandatoryInstrument.pricingPlanOptions = [];
                                    mandatoryInstrument.pricingPlans.forEach((pricingPlan) => {
                                        mandatoryInstrument.pricingPlanOptions.push(pricingPlan);
                                    });

                                    this.pricingPlanChanged();
                                });
                            }
                        }
                    } else {
                        if (
                            !this.applicableInstruments.filter((y) => y.instrumentId === currentConnectedInstrument.instrumentId) &&
                            this.selectableInstruments.find((y) => y.instrumentId === currentConnectedInstrument.instrumentId)
                        ) {
                            this.applicableInstruments.push(currentConnectedInstrument);
                        }
                    }
                }
            }
        }
    }

    public async OnOrganizationChange(ev: Organization) {
        this.selectedOrganization = this.organizations.find((x) => {
            return x.organizationId === ev.organizationId;
        });

        this.createProjectInput.practitioner = null;
        this.createProjectInput.organizationId = ev.organizationId;

        await this.getOrganizationInformation();
    }

    public async OnPractitionerChange(practitioner: Practitioner) {
        this.createProjectInput.practitioner = practitioner;
        await this.getInstrumentsForPractitioner();
    }

    public pricingPlanChanged() {
        this.thePricingPlanKey = new Date().getTime();
    }

    public submitCreateProjectModal(): void {
        const instrumentsProjectCreate: ProjectInstrument[] = [];

        this.instruments.forEach((instrument) => {
            const projectInstrument = new ProjectInstrument();

            projectInstrument.instrumentId = instrument.instrumentId;
            if (this.isPricingPlanRequired(instrument.instrumentId)) {
                if (instrument.pricingPlan) {
                    projectInstrument.pricingPlanId = instrument.pricingPlan.pricingPlanId;
                }
            } else {
                projectInstrument.subscriptionId = this.organizationSubscriptions.find(
                    (x) => x.isActive && x.instruments.find((y) => y.instrumentId === instrument.instrumentId),
                ).id;
            }

            instrumentsProjectCreate.push(projectInstrument);
        });

        if ((this as any).$v.$invalid || instrumentsProjectCreate.find((x) => !x.pricingPlanId && !x.subscriptionId)) {
            this.showError(`The project didn't pass the validation.`);
            return;
        }

        const selectedTimezone = (this as any).$moment.tz.guess();
        const timeZoneFormat = findWindows(selectedTimezone);

        const loading = Vue.$progress(ProgressType.Loading, { text: 'The project is being saved...' });
        projectsService
            .createProject({
                name: this.createProjectInput.name,
                context: this.createProjectInput.context,
                startDate: this.createProjectInput.startDate,
                closeDate: this.createProjectInput.closeDate,
                organizationId: this.selectedOrganization.organizationId,
                practitionerId: this.createProjectInput.practitionerId,
                knowledgeModelId: this.createProjectInput.knowledgeModelId,
                instruments: instrumentsProjectCreate.sort((a, b) => a.instrumentId - b.instrumentId),
                combineInstrumentSurveys: this.createProjectInput.combineInstrumentSurveys,
                timeZone: timeZoneFormat[0] as string,
            } as ProjectCreate)
            .then(async (response: CreateResult<Project>) => {
                loading.instance.close(1000);
                await loading.animation;
                this.showSuccess('Congratulations!', `You project is successfully created, you're now redirected to the project details.`);
                this.$emit('finished', response.entity);
                await $router.push({ name: 'project-details', params: { projectId: response.entity.projectId.toString() } });
            })
            .catch(async () => {
                loading.instance.close(1000);
                await loading.animation;

                this.createError = true;
                this.showError('Practitioner is not permitted to use that instrument.');
            });
    }

    public async getInstrumentsForPractitioner() {
        if (!this.createProjectInput.practitioner || this.createProjectInput.practitioner.practitionerId === 0) {
            return;
        }
        this.loadingOrgInstruments = true;
        await this.practitionersService
            .getInstruments(this.createProjectInput.practitioner.practitionerId)
            .then((instruments) => {
                this.selectableInstruments = instruments.items.map((instrument) => {
                    return {
                        name: instrument.name,
                        instrumentId: instrument.instrumentId,
                        key: `instrument${instrument.instrumentId}`,
                        alias: instrument.alias,
                        knowledgeModelId: instrument.knowledgeModelId,
                    } as any as Instrument;
                });
                this.loadingOrgInstruments = false;
            })
            .catch(() => {
                this.showError(
                    `The instruments couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
                this.loadingOrgInstruments = false;
            });

        if (this.selectableInstruments.length === 1) {
            this.OnInstrumentChange(this.selectableInstruments[0]);
        }
    }

    public organizationLabel(organization: Organization) {
        return he.decode(organization.name);
    }

    private async getOrganizations() {
        this.loadingOrgs = true;
        await this.organizationsService
            .getOrganizations({ skip: 0, take: 9999 })
            .then((organizations) => {
                this.organizations = organizations.items;
                this.loadingOrgs = false;
            })
            .catch(() => {
                this.showError(
                    `The organizations couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
                this.loadingOrgs = false;
            });
    }

    private async getPricingPlans(instrumentId: number) {
        const pricingPlans = await this.practitionersService.getPricePlans(instrumentId, this.createProjectInput.practitionerId).catch(() => {
            this.showError(
                `The pricing plans couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
            );
        });

        if (pricingPlans && pricingPlans.length > 0) {
            const instrument = this.instruments.find((x) => x.instrumentId === instrumentId);

            instrument.pricingPlans = pricingPlans;

            if (pricingPlans.length === 1) {
                instrument.pricingPlan = pricingPlans[0];
            }
        }
    }

    private async getOrganizationSubscriptions() {
        await this.organizationsService.getOrganizationSubscriptions(this.selectedOrganization.organizationId).then((data) => {
            this.organizationSubscriptions = data.items;
        });
    }

    private async getOrganizationInformation() {
        this.loadingOrgInfo = true;

        await this.getOrganizationSubscriptions();

        this.practitionersService
            .getPractitioners(
                new PractitionerFilter({
                    skip: 0,
                    take: 99999,
                    organizationId: this.selectedOrganization.organizationId,
                }),
            )
            .then(async (practitioners) => {
                this.practitioners = practitioners.items;
                this.loadingOrgInfo = false;

                const me = this.practitioners.find((u) => {
                    return this.user.practitionerId === u.practitionerId;
                });

                if (me) {
                    this.createProjectInput.practitioner = me;
                    await this.OnPractitionerChange(me);
                }

                if (this.practitioners.length === 1) {
                    this.createProjectInput.practitioner = this.practitioners[0];
                    await this.OnPractitionerChange(this.practitioners[0]);
                }
            })
            .catch(() => {
                this.showError(
                    `The practitioners couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
                this.loadingOrgInfo = false;
            });
    }

    private async loadKnowledgeModel(knowledgeModelId) {
        const response = await this.knowledgeModelService.getKnowledgeModel(knowledgeModelId);

        this.knowledgeModel = response;
    }
}
