import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { InstrumentsService } from '../services/instrumentsService';
import { InstrumentReport } from '@/models/Instrument';

@Component({
    validations: {
        report: {},
    },
} as any)
export default class InstrumentReportComponent extends SideActionBase<any> {
    public report: InstrumentReport = new InstrumentReport();
    public outputs: string[] = [];
    public isUpdate: boolean = false;

    public outputValues: any = [
        { alias: 'web', value: 1 },
        { alias: 'excel', value: 2 },
        { alias: 'powerpoint', value: 4 },
    ];

    private instrumentService: InstrumentsService = new InstrumentsService();
    private instrumentId: number = 0;

    public async created() {
        this.instrumentId = parseInt(this.$route.params.instrumentId);
        this.report = this.options.report ?? new InstrumentReport();
        this.isUpdate = !!this.options.report;

        this.outputs = this.report && this.report.fileTypes && this.report.fileTypes.split(',').map((x) => x.trim());
    }

    public async save() {
        this.showPending('Saving report...');

        let fileTypesValue = 0;
        this.outputs.forEach((x) => (fileTypesValue += this.outputValues.find((y) => y.alias === x).value));
        this.report.fileTypeValue = fileTypesValue;

        if (this.isUpdate) {
            const [err, report] = await to(this.instrumentService.updateReport(this.instrumentId, this.report));
            if (err) {
                return this.clearAndShowError(err, err);
            }
            return this.finished(report);
        } else {
            const [err, report] = await to(this.instrumentService.addReport(this.instrumentId, this.report));
            if (err) {
                return this.clearAndShowError(err, err);
            }
            return this.finished(report);
        }
    }
}
