import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { $router, eventBus, projectsService } from '../../main';
import BasePage from '../../models/BasePage';
import { CreateResult, CreationStatus } from '../../models/CreateResult';
import { Project } from '../../models/Project';
import { ParticipantState, SurveyCreate, EntityCreateState } from './models/participant';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import ParticipantCreate from '@/modules/participants/models/ParticipantCreate';
import Participant from '@/modules/participants/models/Participant';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { SurveyService } from '@/services/surveyService';
import { SurveyDetails } from '@/models/Survey';
import { BModal } from 'bootstrap-vue';
import moment from 'moment';

// tslint:disable: max-classes-per-file
// tslint:disable: no-use-before-declare

@Component
export default class AddSurveyWrapperComponent extends BasePage {
    public showWizard: boolean = true;
    public participantService: ParticipantsService = new ParticipantsService();
    public progress: number = 0;
    public finishing: boolean = false;
    public toHandleParticipants: ParticipantCreate[] = [];

    public steps: any = {
        1: { display: 'Participants', name: 'addSurvey', stepNumber: 1, isShowing: this.currentStep === 1 },
        2: { display: 'Email', name: 'setupEmail', stepNumber: 2, isShowing: this.currentStep === 2 },
        lastStep: 2,
        finished: false,
    };

    public get hasReuseData() {
        return this.participants.some((x) => x.basedOn && x.basedOn.surveyId);
    }

    public participantColumns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '80px', cell: this.renderDeleteParticipant },
        { field: 'participant.firstName', title: 'FirstName' },
        { field: 'participant.lastName', title: 'LastName' },
        { field: 'participant.emailAddress', title: 'Email' },
        { field: 'participant.preferredLanguageObject.languageName', title: 'Language' },
        { field: 'state', title: 'Reuse data?', cell: this.renderReuseData },
    ];

    public $refs!: {
        progressModal: BModal;
    };

    public projectData: Project = null;
    private projectId: number = 0;

    public async beforeRouteLeave(_: Route, __: Route, next: any) {
        if (
            this.participants.filter((participant: SurveyCreate) => {
                return participant.creationStatus !== EntityCreateState.Created;
            }).length > 0
        ) {
            if (confirm(`Not all participants are correctly added to the project. Do you want to proceed?`)) {
                await this.$store.dispatch('participantsStore/cleanup');
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);
        await projectsService.getProject(this.projectId).then((value: Project) => {
            this.projectData = value;
        });

        this.showWizard = this.$route.meta.showWizard;

        if (this.currentStep === 1 && this.$route.name !== 'addSurvey' && this.$route.meta.redirectOnLoad) {
            await $router.push({ name: 'addSurvey' });
        }

        eventBus.$once('nextStep', () => {
            this.next();
        });
        eventBus.$once('nextStepAvailable', () => {
            this.steps['2'].enableNext = true;
        });
        eventBus.$once('add-wizard-bound', () => {
            this.checkStepSettings();
        });

        eventBus.$once('handle-finish', async () => {
            await this.handleFinish();
        });

        if (!this.hasReuseData) {
            this.participantColumns = this.participantColumns.filter((x) => x.field !== 'state');
        }
    }

    public checkStepSettings() {
        if (this.$route.name === 'addSurvey' && this.currentStep !== 1) {
            this.$store.commit('participantsStore/SAVE_STEP', 1);
            this.steps[1].isShowing = true;
        }
    }

    public destroyed() {
        eventBus.$off('nextStep');
        eventBus.$off('nextStepAvailable');
        eventBus.$off('handle-finish');
    }

    get currentStep(): number {
        return this.$store.getters['participantsStore/currentStep'];
    }

    get participants(): SurveyCreate[] {
        return this.$store.getters['participantsStore/participants'];
    }

    public next() {
        let next = this.currentStep + 1;
        if (next > this.steps.lastStep) {
            next = this.steps.lastStep;
        }

        this.goTo(next);
    }

    public goTo(nextPage: number) {
        $router.push({ name: this.steps[nextPage].name });
        this.$store.commit('participantsStore/SAVE_STEP', nextPage);
    }

    public back() {
        this.goTo(this.currentStep - 1);
    }

    public async setupEmail() {
        this.$store.commit('participantsStore/INCREMENT_STEP');
        await $router.push({ name: 'setupEmail' });
    }

    public async handleFinish() {
        this.finishing = true;
        const surveyStartProcessHandler = new SurveyStartProcessHandler();
        const participantCreateCommand = new CreateParticipantHandler();
        const surveyCreateCommand = new CreateSurveyHandler();
        const surveyProcessCompleteHandler = new SurveyProcessCompleteHandler();

        this.$refs.progressModal.show();
        surveyStartProcessHandler.setNext(participantCreateCommand).setNext(surveyCreateCommand).setNext(surveyProcessCompleteHandler);

        this.toHandleParticipants = this.$store.getters['participantsStore/participants'];

        for (let i = 0; i < this.participants.length; i++) {
            const currentSurvey = this.participants[i];
            await surveyStartProcessHandler.handle(currentSurvey, this.projectData);

            if (this.participants.length < 10) {
                await this.sleep(200);
            }
            
            this.progress++;
        }

        this.$refs.progressModal.hide();
        this.steps.finished = true;

        if (
            this.participants.filter((participant: SurveyCreate) => {
                return participant.creationStatus !== EntityCreateState.Created;
            }).length > 0
        ) {
            this.showWarning('Participants added with remarks.');
        } else {
            this.$store.dispatch('participantsStore/cleanup');
            this.showSuccess('Participants added!');
            this.$router.push({ name: 'project-details' });
        }
    }

    public proceedToProject() {
        this.clearNotifications();
        this.$router.push({ name: 'project-details' });
    }

    private renderDeleteParticipant(item: any, _: any, row: any): any {
        return item(Vue.component('grid-delete-participant'), { props: { dataItem: row.dataItem } });
    }

    private renderReuseData(item: any, _: any, row: any) {
        let date = '';
        if (row.dataItem.state === 'reused') {
            date = moment(row.dataItem.participant.lastCompletedSurvey.completionDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
        return item('td', row.dataItem.state === 'reused' ? `Yes, based on survey completed on ${date}` : 'No');
    }

    private sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

interface ISurveyHandler {
    setNext(handler: ISurveyHandler): ISurveyHandler;
    handle(surveyData: SurveyCreate, projectData: Project);
}

// tslint:disable-next-line: max-classes-per-file
abstract class AbstractSurveyHandler implements ISurveyHandler {
    private nextHandler: ISurveyHandler = null;

    public setNext(handler: ISurveyHandler): ISurveyHandler {
        this.nextHandler = handler;

        return this.nextHandler;
    }

    public async handle(surveyData: SurveyCreate, projectData: Project) {
        if (this.nextHandler !== null) {
            await this.nextHandler.handle(surveyData, projectData);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
class SurveyStartProcessHandler extends AbstractSurveyHandler {
    public async handle(surveyData: SurveyCreate, projectData: Project) {
        surveyData.creationStatus = EntityCreateState.Processing;

        await super.handle(surveyData, projectData);
    }
}

class CreateParticipantHandler extends AbstractSurveyHandler {
    public participantService: ParticipantsService = new ParticipantsService();

    public async handle(surveyData: SurveyCreate, projectData: Project) {
        if (surveyData.state === ParticipantState.Created) {
            await this.participantService
                .createParticipant(ParticipantCreate.fromParticipant(surveyData.participant))
                .then(async (participantData: CreateResult<Participant>) => {
                    if (participantData.creationStatus === CreationStatus.created || participantData.creationStatus === CreationStatus.duplicate) {
                        surveyData.participant.participantId = participantData.entity.participantId;

                        await super.handle(surveyData, projectData);
                    } else {
                        surveyData.creationStatus = EntityCreateState.Failed;
                        surveyData.errorReason = `Creating this participant for organization ${
                            projectData.organization ? `${projectData.organization.name} ` : ''
                        }failed. Possibly this person already exists in this organization.`;
                    }
                })
                .catch((reason: any) => {
                    surveyData.creationStatus = EntityCreateState.Failed;
                    surveyData.errorReason = `Creating this participant for organization ${
                        projectData.organization ? `${projectData.organization.name} ` : ''
                    }failed. ${reason}`;
                });
        } else if (surveyData.state !== ParticipantState.Undefined) {
            await super.handle(surveyData, projectData);
        }
    }
}

class CreateSurveyHandler extends AbstractSurveyHandler {
    public surveyService: SurveyService = new SurveyService();

    public async handle(surveyInputData: SurveyCreate, projectData: Project) {
        await this.surveyService
            .createSurvey(projectData.projectId, surveyInputData)
            .then(async (surveyData: CreateResult<SurveyDetails>) => {
                if (surveyData.creationStatus === CreationStatus.created) {
                    surveyInputData.surveyLink = surveyData.entity.surveyLink;

                    await super.handle(surveyInputData, projectData);
                } else if (surveyData.creationStatus === CreationStatus.duplicate) {
                    surveyInputData.creationStatus = EntityCreateState.Duplicate;
                    surveyInputData.errorReason =
                        'Adding ' + surveyInputData.participant.emailAddress + ' failed, reason: This person already took a survey in this project.';
                } else {
                    surveyInputData.creationStatus = EntityCreateState.Failed;
                    surveyInputData.errorReason = 'Creating a survey for this participant failed.';
                }
            })
            .catch((reason: any) => {
                surveyInputData.creationStatus = EntityCreateState.Failed;
                surveyInputData.errorReason = `Creating a survey for this participant failed. ${reason}`;
            });
    }
}

class SurveyProcessCompleteHandler extends AbstractSurveyHandler {
    public async handle(surveyData: SurveyCreate, projectData: Project) {
        surveyData.creationStatus = EntityCreateState.Created;

        // If success
        await super.handle(surveyData, projectData);
    }
}
