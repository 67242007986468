import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import BasePage from '@/models/BasePage';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import to from 'await-to-js';
import ProjectDemographic from '@/modules/participants/models/ProjectDemographic';
import { projectsService } from '@/main';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import { ImportProjectDemographicDataOptions } from '../side-actions/import-demographic-data-on-project';
import { Project } from '@/models/Project';
import { saveExcel } from '@progress/kendo-vue-excel-export';

@Component
export default class ParticipantOverviewComponent extends BasePage {
    @Prop({ default: () => new Project() }) public project: Project;

    public thekey: string = new Date().getTime().toString();

    public get participants() {
        return this.participantsData.participants.filter((x) => x.name.toLowerCase().indexOf(this.participantsData.search.toLowerCase()) > -1);
    }

    public get isInstrumentAnonymous(){
        return this.project.instruments.every(x => x.isAnonymous);
    }

    public $refs!: {};

    public participantsData: any = {
        participants: [],
        skip: 0,
        take: 25,
        count: 0,
        search: '',
    };

    public surveyColumns: GridColumnProps[] = [
        { field: 'name', title: 'Participant', cell: this.renderRouterLink },
        { field: 'demographicData', title: 'Demographic data', cell: this.renderDemographicData },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['participant.firstName', 'participant.lastName', 'emailAddress'],
    });

    private filterTimer: number = null;

    public async mounted() {
        await this.loadProjectDemographics();
    }

    public async loadProjectDemographics() {
        const [err, response] = await to(projectsService.getDemographicProjectData(this.project.projectId));
        if (err) {
            this.showError('Failed to load demographic data');
            return [];
        }

        this.participantsData.count = response.count;
        this.participantsData.participants = response.items.map((x) => new ProjectDemographic(x));
        this.thekey = new Date().getTime().toString();
        this.isLoaded = true;
    }

    public async uploadProjectDemographicDataSideAction() {
        const participants = await this.loadAllParticipants();

        const options: ImportProjectDemographicDataOptions = {
            projectId: this.project.projectId,
            organizationId: this.project.organizationId,
            participants: participants,
        };

        this.$sideActions.push('import-demographic-data-on-project-action', options, () => {});
    }

    public async loadAllParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.project.projectId,
                    skip: 0,
                    take: 9999,
                    $count: false,
                }),
            ),
        );

        if (err) {
            this.clearAndShowError('Failed to load participants');
            return [];
        }

        return response.items.map((x) => new ProjectParticipant(x));
    }

    public exportColumns = [{ field: 'emailAddress', title: 'emailAddress' }];

    public async exportCurrentDemographicData() {
        const participants = await this.loadAllParticipants();

        let dataObjects = this.participantsData.participants.map((participant) => {
            const participantObj = {};

            const foundParticipant = participants.find((x) => x.participantId === participant.participantId);
            if (foundParticipant) {
                participantObj['emailAddress'] = foundParticipant.emailAddress;
            } else {
                participantObj['emailAddress'] = 'Email address not found (maybe removed from project?)';
            }

            participant.demographicData.forEach((demoData) => {
                participantObj[demoData.label] = demoData.value;

                if (!this.exportColumns.find((x) => x.field === demoData.label)) {
                    this.exportColumns.push({
                        field: demoData.label,
                        title: demoData.label,
                    });
                }
            });

            return participantObj;
        });

        saveExcel({
            data: dataObjects,
            fileName: `Demographic-data-project-${this.$route.params.projectId}`,
            columns: this.exportColumns,
        });
    }

    public renderRouterLink(item: any, _: any, row: { dataItem: ProjectDemographic }): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.name}`,
                url: this.$router.resolve({
                    name: 'participant-details',
                    params: { participantId: row.dataItem.participantId.toString() },
                }).href,
            },
        });
    }

    public expandChange(event) {
        event.event.stopPropagation();
        Vue.set(event.dataItem, event.target.$props.expandField, event.value);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.participantsData.skip = event.page.skip;
        this.participantsData.take = event.page.take;

        this.loadProjectDemographics();
    }

    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            self.participantsData.skip = 0;
        }, 400);
    }

    public renderDemographicData(item: any, _: any, row: { dataItem: ProjectDemographic }): any {
        const demographicData = row.dataItem.demographicData.map((x) => {
            return `${x.label} | ${x.value}`;
        });

        const props = {
            icon: '',
            detailIcon: '',
            textTemplate: '{{count}} element(s)',
            elements: demographicData,
        };

        return item(Vue.component('grid-string-dropdownlist'), { props });
    }

    public async refresh() {
        this.isLoaded = false;
        this.participantsData.skip = 0;
        await this.loadProjectDemographics();
        this.isLoaded = true;
    }
}
