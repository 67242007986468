import { CreateResult } from '@/models/CreateResult';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Practitioner from '../models/Practitioner';
import PractitionerCreate from '../models/PractitionerCreate';
import PractitionerUpdate from '../models/PractitionerUpdate';
import PractitionerFilter from '../models/PractitionerFilter';
import Instrument from '../models/Instrument';
import to from 'await-to-js';
import PractitionerOrderBy from '../models/PractitionerOrderBy';
import Organization from '@/modules/organizations/models/Organization';
import PricePlan from '@/modules/partners/models/PricePlan';
import Project from '../models/Project';
import { ProjectFilter } from '../models/ProjectsFilter';

export class PractitionerService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async createPractitioner(practitionerData: PractitionerCreate): Promise<CreateResult<Practitioner>> {
        const [err, response] = await to(this.post<CreateResult<Practitioner>>(`${this.endpoint}/practitioners`, practitionerData));
        if (err) {
            return;
        }

        return response.data;
    }

    public createPractitioners(partners: PractitionerCreate[]): Promise<Practitioner[]> {
        const promises = [];
        const self = this;

        for (let i = 0; i < partners.length; i++) {
            promises.push(self.createPractitioner(partners[i]));
        }

        return Promise.all(promises);
    }

    public async getPractitioner(practitionerId: number): Promise<Practitioner> {
        const [err, response] = await to(this.get<Practitioner>(`${this.endpoint}/practitioners/${practitionerId}`));
        if (err) {
            return new Practitioner();
        }

        return new Practitioner(response.data);
    }

    public getPractitioners(filter: Partial<PractitionerFilter>, orderBy?: PractitionerOrderBy): Promise<PagedResponse<Practitioner>> {
        return new Promise<PagedResponse<Practitioner>>((resolve, reject) => {
            const params = [];
            if (orderBy && orderBy.getQueryString()) {
                params.push(orderBy.getQueryString());
            }
            if (filter.embedOptions) {
                params.push('embed_options=Projects');
            }
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        if (property === 'InstrumentIds' || property === 'KnowledgeModelIds') {
                            propertyArray.forEach((id) => {
                                params.push(this.toSnakeCase(property) + '=' + encodeURIComponent(id));
                            });
                        } else {
                            propertyArray.forEach((element) => {
                                params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                            });
                        }
                    } else {
                        if (
                            property === 'missingPaperlessOnly' ||
                            property === 'directInvoicingOnly' ||
                            property === 'organizationId' ||
                            property === 'includeDeleted'
                        ) {
                            params.push(this.toSnakeCase(property) + '=' + encodeURIComponent(filter[property]));
                        } else if (property === 'searchOptions') {
                            Object.keys(filter[property])
                                .filter((k) => filter[property][k])
                                .forEach((x) => params.push(this.toSnakeCase(property) + '=' + x));
                        } else {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                        }
                    }
                }
            }
            this.get<PagedResponse<Practitioner>>(`${this.endpoint}/practitioners?${params.join('&')}`)
                .then((response: AxiosResponse<PagedResponse<Practitioner>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public mergePractitioners(fromId: number, toId: number): Promise<Practitioner> {
        const self = this;
        return new Promise<Practitioner>((resolve, reject) => {
            self.post<Practitioner>(`${this.endpoint}/practitioners/${fromId}/merge`, { destinationId: toId })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updatePractitioner(practitionerId: number, practitionerData: PractitionerUpdate): Promise<Practitioner> {
        return new Promise<Practitioner>((resolve, reject) => {
            this.put<Practitioner>(`${this.endpoint}/practitioners/${practitionerId}`, practitionerData)
                .then((response: AxiosResponse<Practitioner>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deletePractitioner(practitionerId: number, hardDelete: boolean = false): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.delete(`${this.endpoint}/practitioners/${practitionerId}?remove_all_data=${hardDelete}`)
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addToGroup(practitionerId: number, partnerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/practitioners/${practitionerId}/add-to-group?partnerId=${partnerId}`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public disablePractitioner(practitionerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/practitioners/${practitionerId}/disable`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public enablePractitioner(practitionerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/practitioners/${practitionerId}/enable`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public createAccountForPractitioner(practitionerId: number, emailAddress: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/practitioners/${practitionerId}/add-account`, {
                emailAddress,
            })
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getInstruments(practitionerId: number): Promise<PagedResponse<Instrument>> {
        return new Promise<PagedResponse<Instrument>>((resolve, reject) => {
            this.get<PagedResponse<Instrument>>(`${this.endpoint}/practitioners/${practitionerId}/instruments`)
                .then((response: AxiosResponse<PagedResponse<Instrument>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getOrganizations(practitionerId: number): Promise<PagedResponse<Organization>> {
        return new Promise<PagedResponse<Organization>>((resolve, reject) => {
            this.get<PagedResponse<Organization>>(`${this.endpoint}/practitioners/${practitionerId}/organizations`)
                .then((response: AxiosResponse<PagedResponse<Organization>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getProjects(filter: Partial<ProjectFilter>): Promise<PagedResponse<Project>> {
        return new Promise<PagedResponse<Project>>((resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                    }
                }
            }

            this.get<PagedResponse<Project>>(`${this.endpoint}/practitioners/${filter.practitionerId}/projects?${params.join('&')}`)
                .then((response: AxiosResponse<PagedResponse<Project>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public setInstruments(practitionerId: number, instruments: number[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.put(`${this.endpoint}/practitioners/${practitionerId}/instruments`, { instruments })
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public move(practitionerId: number, partnerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/practitioners/${practitionerId}/move`, { newPartnerId: partnerId })
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getPricePlans(instrumentId: number, practitionerId: number): Promise<PricePlan[]> {
        return new Promise<PricePlan[]>((resolve, reject) => {
            this.get<PricePlan[]>(`${this.endpoint}/practitioners/${practitionerId}/instruments/${instrumentId}/price-plans`)
                .then((response: AxiosResponse<PricePlan[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public setPricePlans(practitionerId: number, instrumentId: number, pricePlans: number[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.post(`${this.endpoint}/practitioners/${practitionerId}/instruments/${instrumentId}/price-plans`, { pricePlanIds: pricePlans })
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    private toSnakeCase(string: string): string {
        return string
            .replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map((word) => word.toLowerCase())
            .join('_');
    }
}
