import { Component, Prop } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import { loginHelper } from '../../../main';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        practitioner: {
            instruments: { required }
        }
    }
} as any)
export default class InstrumentSelectionComponent extends BasePage {
    public partnerId?: number;
    @Prop() public practitioner: CreatePractitionerModel;
    public instrumentOptions: any[] = [];
    public submitted = false;
    
    private partnerService: PartnerService = new PartnerService();

    public async mounted() {
        await this.getInstruments();
    }

    private async getInstruments() {
        this.instrumentOptions = [];

        if (this.isSuperAdmin) {
            if (!this.practitioner.partner || !this.practitioner.partner.id) {
                return;
            }

            await this.partnerService
                .getInstruments(this.practitioner.partner.id)
                .then((instruments) => {
                    instruments.items.forEach((instrument) => {
                        this.instrumentOptions.push({ value: instrument.instrumentId, text: instrument.name });
                    });
                })
                .catch(() => {
                    this.showError(
                        `The instruments couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                    );
                });
            return;
        }

        this.partnerId = loginHelper.getUser().partnerId;

        await this.partnerService
            .getInstruments(this.partnerId)
            .then((instruments) => {
                instruments.items.forEach((instrument) => {
                    this.instrumentOptions.push({ value: instrument.instrumentId, text: instrument.name });
                });
            })
            .catch(() => {
                this.showError(
                    `The instruments couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }

}