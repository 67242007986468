import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import ChangeOrganizationSubscriptionActionParameters from './changeOrganizationSubscriptionActionParameters';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import moment from 'moment';
import { OrganizationsService } from '@/services/organizationsService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';

@Component({
    components: {
        'confirm-dialogue': require('../../confirm/confirmDialogue.vue').default,
    },
})
export default class ChangOrganizationSubscriptionActionComponent extends SideActionBase<ChangeOrganizationSubscriptionActionParameters> {
    public subscription: OrganizationSubscription = this.options.subscription;

    public instruments: any[] = [];
    public rawInstruments: any[] = [];
    public selectedInstruments: number[] = [];

    public isLoaded: boolean = false;
    public thekey: string = new Date().getTime().toString();

    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '80px', cell: Vue.component('grid-checkbox') },
        { field: 'name', title: 'Instrument' },
    ];
    public startDate: Date = null;
    public renewalDate: Date = null;

    public working: boolean = false;
    public showConfirm = false;
    public dialog = {
        title: 'Confirm',
        message: 'Do you want to refund the changed subscription?',
        button: {
            yes: 'Yes',
            no: 'No',
        },
    };

    private organizationsService: OrganizationsService = new OrganizationsService();
    private practitionerService: PractitionerService = new PractitionerService();

    public async created() {
        await this.getInstruments();

        this.selectedInstruments.push(...this.subscription.instruments.map((x) => x.instrumentId));

        this.startDate = moment(this.subscription.startDate).toDate();
        this.renewalDate = moment(this.subscription.renewalDate).toDate();

        this.subscription.pricePerSeat = this.subscription.price / this.subscription.seats;
    }

    public instrumentSelected(instruments) {
        let currentKnowledgeModelId = null;
        instruments.forEach((instrumentId) => {
            const instrument = this.instruments.find((x) => x.instrumentId === instrumentId);
            if (!currentKnowledgeModelId) {
                currentKnowledgeModelId = instrument.knowledgeModelId;
            } else if (currentKnowledgeModelId !== instrument.knowledgeModelId) {
                this.selectedInstruments.pop();
                this.thekey = new Date().getTime().toString();

                this.showWarning('A combination of the selected instruments in one subscription is not allowed.');
                return;
            }
        });
    }

    public onSeatsChange() {
        this.subscription.price = this.subscription.pricePerSeat * this.subscription.seats;
    }

    public onPricePerSeatChange() {
        this.subscription.price = this.subscription.pricePerSeat * this.subscription.seats;
    }

    public showChangeOrganizationSubscriptionConfirmDialogue(evt: any) {
        evt.preventDefault();
        if (this.isSuperAdmin) {
            this.showConfirm = true;
        } else {
            this.changeOrganizationSubscription(false);
        }
    }

    public confirmChangeOrganizationSubscription(value: any) {
        this.showConfirm = false;
        this.changeOrganizationSubscription(value);
    }

    public async changeOrganizationSubscription(refund: boolean): Promise<any> {
        this.working = true;
        this.subscription.instruments = [];
        this.instruments.forEach((instrument) => {
            if (this.selectedInstruments.indexOf(instrument.instrumentId) > -1) {
                this.subscription.instruments.push(instrument);
            }
        });

        this.subscription.startDate = this.startDate;
        this.subscription.renewalDate = this.renewalDate;

        await this.organizationsService.updateOrganizationSubscription(this.subscription, refund);
        this.working = false;

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.clearNotifications();
        this.cancel();
    }

    private async getInstruments() {
        const instruments = await this.practitionerService.getInstruments(this.options.practitionerId);
        this.instruments = instruments.items;
        this.updateCacheKey();

        this.isLoaded = true;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }
}
