import { Component } from 'vue-property-decorator';
import { $router, projectsService, contextOptionService, loginHelper } from '../../../main';
import BasePage from '../../../models/BasePage';
import { Project, ProjectUpdate } from '../../../models/Project';
import Sequence from '../../../models/Sequence';
import { AddInstrumentActionOptions } from '../../../components/side-actions/project-action/addInstrumentAction';
import { PricingPlan } from '@/models/PricingPlan';
import { ContextOptions } from '@/models/ContextOptions';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { OrganizationsService } from '@/services/organizationsService';
import to from 'await-to-js';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import ExportInfo from '@/models/ExportInfo';
import EmailOverviewComponent from '../components/email-overview';
import ParticipantOverviewComponent from '../components/participant-overview';
import { InstrumentReport, InstrumentType } from '@/models/Instrument';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import { ProjectShareOptions } from '../side-actions/project-share-action';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import Vue from 'vue';

@Component({
    components: {
        'project-participants-overview': require('@/modules/projects/components/participant-overview.vue').default,
        'email-overview': require('@/modules/projects/components/email-overview.vue').default,
        'retest-project': require('@/modules/projects/components/retest-project.vue').default,
        'project-settings': require('@/modules/projects/components/project-settings.vue').default,
        'email-edit': require('@/components/email-edit/emailEdit.vue').default,
        'confirm-dialogue': require('@/components/confirm/confirmDialogue.vue').default,
        'project-time-frame': require('@/components/projects/timeFrame.vue').default,
        'practitioner-multiselect': require('@/components/practitioner/practitioner-multiselect.vue').default,
        'project-demographics': require('@/modules/projects/components/project-demographics.vue').default,
    },
})
export default class ProjectsDetailsComponent extends BasePage {
    get tabIndex(): number {
        return this.$store.getters['projectStore/currentTab'];
    }

    set tabIndex(newTabIndex: number) {
        this.$store.commit('projectStore/TAB_CHANGED', newTabIndex);
        $router.replace({ params: { tab: this.$store.getters['projectStore/currentTabRoute'] } });
    }

    get participants(): any[] {
        return this.surveys.map((s: any) => {
            return s.originalItem.participant;
        });
    }

    get projectActive(): boolean {
        return new Date(this.projectData.closeDate) > new Date();
    }

    get isProjectEligibleToDelete(): boolean {
        return (
            (this.projectData && this.projectData.participantCount === 0) ||
            (this.projectData && this.projectData.instruments.every((x) => x.instrumentType === InstrumentType.Group))
        );
    }

    get isOwnerOrSuperAdmin(): boolean {
        return (
            this.isSuperAdmin || (this.projectData && this.isPractitioner && loginHelper.getUser().practitionerId === this.projectData.practitionerId)
        );
    }

    get isPartnerOrSuperAdmin(): boolean {
        return this.isSuperAdmin || this.isPartner;
    }

    get hasAdditionalInstruments() {
        const possibleInstruments = [];

        if (this.knowledgeModel && this.knowledgeModel.instruments) {
            this.knowledgeModel.instruments.forEach((x) => {
                if (this.projectData.instruments.filter((y) => y.instrumentId === x.instrumentId).length) {
                    if (x.connectedInstruments && x.connectedInstruments.length > 0) {
                        x.connectedInstruments.forEach((y) => {
                            if (
                                possibleInstruments.indexOf(y.instrumentId) === -1 &&
                                !this.projectData.instruments.filter((z) => z.instrumentId === y.instrumentId).length
                            ) {
                                const practitionerInstruments = this.$store.getters['instrumentsStore/instruments'];
                                if (practitionerInstruments.length > 0 && practitionerInstruments.find((z) => z.instrumentId === y.instrumentId)) {
                                    possibleInstruments.push(y.instrumentId);
                                } else if (!practitionerInstruments || practitionerInstruments.length === 0) {
                                    possibleInstruments.push(y.instrumentId);
                                }
                            }
                        });
                    }
                }
            });
        } else {
            return false;
        }

        return possibleInstruments.length > 0;
    }

    get exportsAvailable() {
        const exports = ['excel', 'powerpoint'];
        const exportsAvailable: ExportInfo[] = [];

        if (!this.projectData || !this.projectData.instruments) {
            return [];
        }

        this.projectData.instruments.forEach((instrument) => {
            instrument.reports.forEach((report) => {
                report.fileTypes.split(',').forEach((fileType) => {
                    const trimmedFileType = fileType.trim();
                    if (exports.includes(trimmedFileType)) {
                        let lang;
                        lang = report.languages && report.languages.find((x) => x === this.practitionerData.preferredLanguage);
                        if (!lang) {
                            lang = report.languages && report.languages[0];

                            if (!lang) {
                                lang = 'en';
                            }
                        }

                        if (trimmedFileType === 'powerpoint') {
                            this.powerpointPrefLang = lang;
                        }

                        exportsAvailable.push({
                            knowledgeModel: this.projectData.knowledgeModel.alias,
                            alias: report.alias,
                            name: report.name,
                            type: report.reportType,
                            fileType: trimmedFileType,
                            reportUrl: report.reportUrls
                                .find((x) => x.fileType === trimmedFileType)
                                .url.replace('{language}', trimmedFileType === 'powerpoint' ? (lang ? lang : 'en') : 'en'),
                            languages: report.languages,
                        });
                    }
                });
            });
        });

        return exportsAvailable;
    }

    public today: Date = new Date();

    public $refs!: {
        emailOverview: EmailOverviewComponent;
        participantsOverview: ParticipantOverviewComponent;
        areYouSureModal: areYouSureModalVue;
    };

    public editMode: boolean = false;
    public practitionerEditMode: boolean = false;
    public projectData: Project = null;
    public surveys: any[] = [];
    public rawSurveys: any[] = [];
    public pricingPlans: PricingPlan[] = [];
    public pricingPlanOptions: any[] = [];
    public contextOptions: ContextOptions[] = [];
    public emails: any[] = [];
    public knowledgeModel: KnowledgeModel = { instruments: [] } as KnowledgeModel;

    public instrumentsLoaded: boolean = false;
    public practitionerLoaded: boolean = false;
    public pricingPlansLoaded: boolean = false;
    public participantsLoading: boolean = true;
    public thePricingPlanKey: number = new Date().getTime();

    public readonly waitTimeout: number = 1000;

    public editContext: string = '';
    public editName: string = '';
    public editPricingPlan: any = {};
    public editPractitionerId: number = 0;

    public powerpointPrefLang: string = 'en';
    public exportPrefLang: string = 'en';

    public organizationEditContext: any = {};
    public organizationEditMode: boolean = false;
    public organizationSequences: Sequence[] = [];
    public timeoutIndicator: number = null;
    public practitionerData: Practitioner = new Practitioner();

    public practitioners: Practitioner[] = [];

    private organizationsService: OrganizationsService = new OrganizationsService();
    private knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();
    private practitionersService: PractitionerService = new PractitionerService();

    /* Necessary for multiselect */
    private localPractitioner: Practitioner = null;
    set editPractitioner(p: Practitioner) {
        this.editPractitionerId = p.practitionerId;
        this.localPractitioner = p;
    }

    get editPractitioner() {
        return this.localPractitioner;
    }

    get isViewer() {
        return (
            this.projectData &&
            this.projectData.viewers &&
            !!this.projectData.viewers.find((x) => x.practitionerId === loginHelper.getUser().practitionerId)
        );
    }

    get showContext() {
        return this.knowledgeModel.instruments.some((x) => x.contextOption !== 'noContext');
    }

    get contextOption() {
        return this.knowledgeModel.instruments.find((x) => this.projectData.instruments.find(y => y.instrumentId === x.instrumentId)).contextOption;
    }

    private projectId: number = 0;

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);

        if (this.$store.getters['participantsStore/showInviteAction'] && this.tabIndex !== 1) {
            return this.showParticipants();
        }
        const tab = $router.currentRoute.params.tab;
        if (tab) {
            this.$store.commit('projectStore/TAB_CHANGED_BY_NAME', tab);
        }
    }

    public async mounted() {
        await this.loadProjectData();

        if (this.tabIndex === 0) {
            Promise.all([this.loadPractitioner(), this.loadPricingPlans()]);

            this.contextOptions = await contextOptionService.getContextOptions();

            this.loadKnowledgeModel();
        }

        this.ensureLanguageInExportUrl();
    }

    public ensureLanguageInExportUrl() {
        if (this.projectData.exportDataUrl && this.projectData.exportDataUrl.url) {
            const lang = this.projectData.exportDataUrl.languages.length > 0 ? this.projectData.exportDataUrl.languages[0] : 'en';
            this.projectData.exportDataUrl.url = this.projectData.exportDataUrl.url.replace('{language}', lang);
            this.exportPrefLang = lang;
        }
    }

    public async loadProjectData() {
        const [err, response] = await to(projectsService.getProject(this.projectId));
        if (err) {
            if (err) {
                return this.$router.push({ name: 'project-overview' });
            }
        }

        this.projectData = response;

        this.isLoaded = true;
    }

    public getWebReportUrl(report: InstrumentReport) {
        const reportUrlObj = report.reportUrls.find((x) => x.fileType === 'web');
        if (reportUrlObj) {
            return `${reportUrlObj.url}&${this.$env().enableDebug ? this.projectData.knowledgeModel.alias : ''}`;
        }

        return $router.resolve({ name: 'not-found' }).href;
    }

    public async openTeamReport(instrument: any) {
        if (instrument.processedUrl) {
            return window.open(instrument.processedUrl, '_blank');
        }

        this.showPending('Generating unique link..');
        const tokenModel = await projectsService.getEncryptedString(this.projectId, 'ExposedTeamReport');

        instrument.processedUrl = instrument.reportUrl
            .replace(/{projectId}/, this.projectId.toString())
            .replace(/{token}/, encodeURIComponent(tokenModel.token))
            .replace(/{language}/, this.practitionerData.preferredLanguage);

        this.clearNotifications();
        return window.open(instrument.processedUrl, '_blank');
    }

    public timeframeUpdated(projectData: Project, startDate: Date, closeDate: Date): void {
        this.projectData = projectData;
        this.projectData.startDate = startDate;
        this.projectData.closeDate = closeDate;
    }

    public startEdit(): void {
        this.editMode = true;
        this.editContext = this.projectData.context;
        this.editName = this.projectData.name;
    }

    public async submit() {
        this.showPending('Project details are being saved');
        const projectUpdate = new ProjectUpdate(this.projectData);

        projectUpdate.projectName = this.editName;
        projectUpdate.context = this.editContext;

        await projectsService
            .updateProject(this.projectData.projectId, projectUpdate)
            .then(() => {
                this.projectData.context = this.editContext;
                this.projectData.name = this.editName;

                this.clearAndShowSuccess('The details of this project has been successfully updated');
            })
            .catch((e) => {
                this.clearAndShowError('The details could not be updated due to an error', e);
            })
            .finally(() => {
                this.editMode = false;
            });
    }

    public cancel(): void {
        this.editMode = false;
        this.editContext = '';
        this.editName = '';
        this.editPricingPlan = {};
    }
    public async startOrganizationEdit(): Promise<void> {
        this.organizationSequences = await this.organizationsService.getSequences(this.projectData.organization.organizationId);

        this.organizationEditMode = true;
        this.organizationEditContext = {
            selectedSequence: this.projectData.sequence,
        };
    }

    public async saveOrganizationEdit(): Promise<void> {
        this.showPending('Saving new sequence to the project');

        await projectsService.setSequence(this.projectData.projectId, this.organizationEditContext.selectedSequence.id);
        this.projectData.sequence = this.organizationEditContext.selectedSequence;
        this.organizationEditMode = false;

        this.clearAndShowSuccess('Saving new sequence to the project');
    }

    public cancelOrganizationEdit(): void {
        this.organizationEditMode = false;
    }

    public async startPractitionerEdit(): Promise<void> {
        this.showPending('Loading practitioners..');
        const practitioners = await this.organizationsService.getShared(this.projectData.organizationId);
        const organization = await this.organizationsService.getOrganization(this.projectData.organizationId);

        this.practitioners = practitioners;
        this.practitioners.push(organization.practitioner);

        this.editPractitionerId = this.practitionerData.practitionerId;
        this.editPractitioner = this.practitionerData;
        this.practitionerEditMode = true;
        this.clearNotifications();
    }

    public async savePractitionerEdit(): Promise<void> {
        this.showPending('Changing practitioner');
        const practitioner = this.practitioners.find((practitionerObj) => {
            return practitionerObj.practitionerId === this.editPractitionerId;
        });

        const projectUpdate = new ProjectUpdate(this.projectData);
        projectUpdate.practitionerId = this.editPractitionerId;

        await projectsService
            .updateProject(this.projectData.projectId, projectUpdate)
            .then(() => {
                this.projectData.practitioner = practitioner;

                this.clearAndShowSuccess('The practitioner of this project has been successfully updated');
            })
            .catch((e) => {
                this.clearAndShowError('The practitioner could not be updated due to an error', e);
            })
            .finally(() => {
                this.practitionerData = this.projectData.practitioner;
                this.practitionerEditMode = false;
            });
    }

    public cancelPractitionerEdit(): void {
        this.practitionerEditMode = false;
    }

    public async removeSequence(): Promise<void> {
        this.showPending('Removing sequence from project');

        await projectsService.removeSequence(this.projectData.projectId);
        this.projectData.sequence = null;

        this.clearAndShowSuccess('Sequence removed from project');
    }

    public showAddAdditionalInstrument() {
        this.$sideActions.push(
            'add-instrument-action',
            new AddInstrumentActionOptions({
                project: this.projectData,
                surveys: this.surveys.map((s: any) => {
                    return s.originalItem;
                }),
            }),
            async () => {
                this.showParticipants();
                await this.loadProjectData();
            },
        );
    }

    public async openShareProjectAction(): Promise<void> {
        const practitioners = await this.organizationsService.getShared(this.projectData.organizationId);

        this.$sideActions.push(
            'project-share-action',
            new ProjectShareOptions({
                projectId: this.projectData.projectId,
                practitioners,
            }),
            async (viewer) => {
                this.projectData.viewers.push(viewer);
            },
        );
    }

    public async deleteOwnProjectShare() {
        await this.deleteProjectShare(loginHelper.getUser().practitionerId);
    }

    public async deleteProjectShare(practitionerId: number) {
        this.showPending('Deleting project share..');

        const [err] = await to(projectsService.deleteShare(this.projectId, practitionerId));
        if (err) {
            return this.showError('Failed to delete project share');
        }

        if (loginHelper.getUser().practitionerId === practitionerId) {
            this.clearAndShowSuccess('Project share deleted');
            return this.$router.push({ name: 'project-overview' });
        }

        this.projectData.viewers = this.projectData.viewers.filter((x) => x.practitionerId !== practitionerId);
        this.clearAndShowSuccess('Project share deleted');
    }

    public showParticipants() {
        this.tabIndex = 1;
        this.$store.commit('projectStore/TAB_CHANGED', this.tabIndex);
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            [this.projectData],
            'name',
            'project',
            false,
            'Are you sure you want to delete this project? This action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    public async goToAddParticipants() {
        await this.$store.dispatch('participantsStore/cleanup');
        await this.$router.push({ name: 'addSurvey', params: { projectId: this.projectId.toString() } });
    }

    public deleteProject() {
        this.showPending('Deleting project..');
        this.$refs.areYouSureModal.hide();

        projectsService
            .deleteProject(this.projectData.projectId)
            .then(() => {
                this.showSuccess('The project is successfully deleted');
                this.$router.push({ name: 'project-overview' });

                setTimeout(() => {
                    Vue.nextTick(async () => {
                        this.$store.dispatch('projectStore/fetchMenuCounters');
                    });
                }, 500);
            })
            .catch((e) => {
                this.showError(`Project couldn't be deleted`, e);
            });
    }

    public get hasWebTeamReports() {
        return this.projectData.instruments.some(
            (y) => y.reports.filter((x) => x.reportType === 'group' && x.fileTypes.split(',').includes('web')).length > 0,
        );
    }
    public getTeamReports(instrument) {
        return instrument.reports.filter((x) => x.reportType === 'group' && x.fileTypes.split(',').includes('web'));
    }

    public approveProject() {
        projectsService
            .setPartnerChecked(this.projectData.projectId)
            .then(() => {
                this.projectData.checkedByPartner = true;
                this.showSuccess('The project is approved');
            })
            .catch(() => {
                this.showError(`Project couldn't be approved`);
            });
    }

    public pricingPlanChanged() {
        this.thePricingPlanKey = new Date().getTime();
    }

    public setPPTPrefLang(languageCode: string, exportInfo: ExportInfo) {
        exportInfo.reportUrl = exportInfo.reportUrl.replace(`language_code=${this.powerpointPrefLang}`, `language_code=${languageCode}`);
        this.powerpointPrefLang = languageCode;
    }

    public setExportPrefLang(languageCode: string) {
        this.projectData.exportDataUrl.url = this.projectData.exportDataUrl.url.replace(
            `language_code=${this.exportPrefLang}`,
            `language_code=${languageCode}`,
        );
        this.exportPrefLang = languageCode;
    }

    private async loadPricingPlans() {
        if (this.isSuperAdmin || this.isPartner || this.isPractitioner && loginHelper.getUser().practitionerId === this.projectData.practitionerId) {
            for (let i = 0; i < this.projectData.instruments.length; i++) {
                const instrument = this.projectData.instruments[i];
                if (!instrument.subscriptionId) {
                    instrument.pricingPlans = await this.practitionersService.getPricePlans(instrument.instrumentId, this.projectData.practitionerId);
                    instrument.pricingPlanOptions = [];
                    instrument.pricingPlans.forEach((pricingPlan) => {
                        instrument.pricingPlanOptions.push(pricingPlan);
                    });

                    instrument.pricingPlan = instrument.pricingPlans.find((x) => x.pricingPlanId === instrument.pricingPlanId);
                }
            }
        }

        this.pricingPlansLoaded = true;
    }

    private async loadPractitioner() {
        this.practitionerData = this.projectData.practitioner;
        this.practitionerLoaded = true;
    }

    private async loadKnowledgeModel(): Promise<void> {
        const [error, knowledgeModel] = await to(this.knowledgeModelService.getKnowledgeModel(this.projectData.knowledgeModel.knowledgeModelId));
        if (error) {
            this.clearAndShowError('Failed to load knowledge model', error);
            this.knowledgeModel = new KnowledgeModel();
            return;
        }

        this.knowledgeModel = knowledgeModel;
    }
}
