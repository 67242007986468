import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import Practitioner from '@/modules/practitioners/models/Practitioner';

@Component({
    components: {
        'create-practitioner': require('@/modules/practitioners/components/create-practitioner.vue').default,
    },
})
export default class PractitionerActionComponent extends SideActionBase<any> {
    public stop() {
        this.cancel();
    }

    public practitionerCreated(result: Practitioner) {
        this.finished(result);
    }
}
