export class ProjectFilter {
    public skip: number;
    public take: number;
    public $count: boolean;
    public search: string;
    public practitionerId: number;

    constructor(filter: Partial<ProjectFilter>) {
        Object.assign(this, filter);
    }
}
