import { AxiosPromise, AxiosResponse } from 'axios';
import { Project, ProjectCreate, ProjectUpdate } from '../models/Project';
import { BaseService } from './baseService';
import { PagedResponse } from '../models/PagedResponse';
import { OrganizationGroup } from '../models/Organization';
import Vue from 'vue';
import { CreateResult } from '@/models/CreateResult';
import to from 'await-to-js';
import ProjectOrderBy from '@/modules/projects/models/ProjectOrderBy';
import ProjectFilter from '@/modules/projects/models/ProjectFilter';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import ProjectViewer from '@/modules/projects/models/ProjectViewer';
import ProjectDemographic from '@/modules/participants/models/ProjectDemographic';

export class ProjectsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getProjects(
        skip: number,
        take: number,
        includeCount: boolean,
        filter: ProjectFilter,
        orderBy?: ProjectOrderBy,
    ): AxiosPromise<PagedResponse<Project>> {
        const queryString = [];
        if (filter && filter.getQueryString()) {
            queryString.push(filter.getQueryString());
        }

        if (orderBy) {
            const orderByString = orderBy.getQueryString();
            if (orderByString) {
                queryString.push(orderByString);
            }
        }

        return this.get<PagedResponse<Project>>(
            `${this.endpoint
            }/projects?skip=${skip}&take=${take}&$count=${includeCount}&embedOptions=instruments,organization,practitioner,participantcount${queryString.length > 0 ? '&' + queryString.join('&') : ''
            }`,
        );
    }

    public async getProject(projectId: number): Promise<Project> {
        const [err, projectResponse] = await to(
            this.get<Project>(
                `${this.endpoint}/projects/${projectId}?embedOptions=instruments,organization,practitioner,sequence,pricingplan,participantcount`,
            ),
        );

        if (err) {
            return new Project();
        }

        return new Project(projectResponse.data);
    }

    public createProject(createModel: ProjectCreate): Promise<CreateResult<Project>> {
        const self = this;
        return new Promise(async (resolve, reject) => {
            await self
                .post(`${this.endpoint}/projects`, createModel)
                .then((projectCreateResponse: AxiosResponse<CreateResult<Project>>) => {
                    resolve(projectCreateResponse.data);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public updateProject(projectId: number, updateModel: ProjectUpdate): Promise<Project> {
        const self = this;
        return new Promise(async (resolve) => {
            await self.put<Project>(`${this.endpoint}/projects/${projectId}`, updateModel).then((projectResponse: AxiosResponse<Project>) => {
                resolve(projectResponse.data);
            });
        });
    }

    public addInstrument(id: number, postModel: any): Promise<Project> {
        return new Promise(async (resolve) => {
            await this.post<Project>(`${this.endpoint}/projects/${id}/add-instrument`, { instrument: postModel }).then(
                (projectResponse: AxiosResponse<Project>) => {
                    resolve(projectResponse.data);
                },
            );
        });
    }

    public deleteProject(projectId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.delete(`${this.endpoint}/projects/${projectId}`)
                .then(() => {
                    resolve();
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    public setPartnerChecked(id: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.put(`${this.endpoint}/projects/${id}/set-partner-checked`)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public getEncryptedString(projectId: number, view: string): Promise<any> {
        const self = this;
        return new Promise<string>((resolve, reject) => {
            self.get<string>(`${this.endpoint}/projects/${projectId}/team-report-access-token?view=${view}`)
                .then((response: AxiosResponse<string>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public async retest(projectId: number, project: ProjectCreate) {
        const [err, response] = await to(this.post(`${this.endpoint}/projects/${projectId}/retest`, project));

        if (err) {
            return new CreateResult<Project>(null, response.data.creationStatus);
        }

        return new CreateResult<Project>(response.data.entity, response.data.creationStatus);
    }

    public setSequence(projectId: number, sequence: number): Promise<void> {
        const self = this;
        return new Promise(async (resolve) => {
            await self.put(`${this.endpoint}/projects/${projectId}/set-sequence?sequenceId=${sequence}`).then(() => {
                resolve();
            });
        });
    }

    public removeSequence(projectId: number): Promise<void> {
        const self = this;
        return new Promise(async (resolve) => {
            await self.delete(`${this.endpoint}/projects/${projectId}/remove-sequence`).then(() => {
                resolve();
            });
        });
    }

    public createGroup(projectId: number): Promise<OrganizationGroup> {
        const self = this;
        return new Promise(async (resolve) => {
            await self.post(`${this.endpoint}/projects/${projectId}/create-group`).then((projectCreateResponse: AxiosResponse<OrganizationGroup>) => {
                resolve(projectCreateResponse.data);
            });
        });
    }

    public async getProjectTeamInstruments(projectId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/projects/${projectId}/team-instruments`));
        if (err) {
            return [];
        }
        return response.data;
    }

    public async getDemographicProjectData(projectId: number): Promise<PagedResponse<ProjectDemographic>> {
        const [err, response] = await to(this.get(`${this.endpoint}/projects/${projectId}/demographic-data`));
        if (err) {
            return new PagedResponse<ProjectDemographic>();
        }
        return response.data;
    }

    public getParticipants(filter: Partial<ProjectParticipantFilter>): Promise<PagedResponse<ProjectParticipant>> {
        return new Promise<PagedResponse<ProjectParticipant>>(async (resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                    }
                }
            }

            await this.get<PagedResponse<ProjectParticipant>>(`${this.endpoint}/projects/${filter.projectId}/participants?${params.join('&')}`)
                .then((result: AxiosResponse<PagedResponse<ProjectParticipant>>) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public exportParticipants(projectId: number): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            await this.post(`${this.endpoint}/projects/${projectId}/export?language=en`)
            .then((result) => {
                resolve(result.data);
            }).catch((reason) => {
                reject(reason);
            })
        })
    }

    public deleteParticipant(projectId: number, participantId: number, refund: boolean = false): AxiosPromise<void> {
        return this.delete(`${this.endpoint}/projects/${projectId}/participants/${participantId}`, { applyRefund: refund });
    }

    public share(projectId: number, practitionerEmailAddress: string): Promise<CreateResult<ProjectViewer>> {
        return new Promise(async (resolve) => {
            await this.post(`${this.endpoint}/projects/${projectId}/viewers`, {
                practitionerEmailAddress,
            }).then((response: AxiosResponse<CreateResult<ProjectViewer>>) => {
                resolve(response.data);
            });
        });
    }

    public deleteShare(projectId: number, practitionerId: number) {
        return new Promise(async (resolve) => {
            await this.delete(`${this.endpoint}/projects/${projectId}/viewers/${practitionerId}`).then((response: AxiosResponse) => {
                resolve(response);
            });
        });
    }

    public async copyParticipantsToOrg(projectId: number, organizationId: number, participantIds: number[]): Promise<CreateResult<Project>> {
        const [err, response] = await to(
            this.post(`${this.endpoint}/projects/${projectId}/organization/${organizationId}/copy-surveys`, {
                participantIds,
            }),
        );

        if (err) {
            return new CreateResult<Project>(null, response.data.creationStatus);
        }

        return new CreateResult<Project>(response.data.entity, response.data.creationStatus);
    }
}

export class ProjectLink {
    link: string;
}
