import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DateTimeInputComponent extends Vue {
    @Prop({ default: null }) public value: Date;
    @Prop({ default: null }) public timeZone: string;

    public timezones: string[] = [];
    public selectedTimezone: string = null; // if a value is provided, select UTC as timezone
    public hours: number = 0;
    public minutes: number = 0;
    public isLoaded: boolean = false;

    public created() {
        this.timezones = (this as any).$moment.tz.names();
        this.selectedTimezone = (this as any).$moment.tz.guess();

        if (this.timeZone) {
            const formattedTimeZone = Vue.filter('timezoneBrowserFormat')(this.timeZone);
            if (this.timezones.includes(formattedTimeZone)) {
                this.selectedTimezone = formattedTimeZone;
            }
        }

        console.log(this.selectedTimezone);

        this.hours = this.realValue !== null ? this.realValue.getHours() : 0;
        this.minutes = this.realValue !== null ? this.realValue.getMinutes() : 0;
    }

    public get selectedDate(): Date {
        return this.value;
    }

    public set selectedDate(value: Date) {
        if (value !== this.realValue) {
            this.saveScheduledDate(value);
        }
    }

    get realValue(): Date {
        return this.value;
    }

    set realValue(value: Date) {
        this.$emit('input', value);
    }

    public saveScheduledDate(date): any {
        if (this.realValue !== null) {
            if (this.selectedTimezone) {
                this.realValue = (moment as any)
                    .tz(
                        `${this.getScheduledDate(date)}T${this.pad(this.hours.toString())}:${this.pad(this.minutes.toString())}`,
                        this.selectedTimezone,
                    )
                    .toDate();
            } else {
                this.realValue = moment(`${this.getScheduledDate(date)}T${this.pad(this.hours.toString())}:${this.pad(this.minutes.toString())}`)
                    .utc()
                    .toDate();
            }
        }
    }

    private getScheduledDate(date: Date): string {
        return `${date.getFullYear()}-${this.pad((date.getMonth() + 1).toString())}-${this.pad(date.getDate().toString())}`;
    }

    private pad(amount: string): string {
        if (amount.length === 1) {
            return '0' + amount.toString();
        } else {
            return amount.toString();
        }
    }
}
