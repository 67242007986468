import { Component } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { loginHelper, sequenceService } from '@/main';
import { OrganizationGroup } from '../../../models/Organization';
import { SequenceActionOptions, SequenceManagementBase } from './sequenceAction';
import Sequence from '../../../models/Sequence';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import PractitionerFilter from '@/modules/practitioners/models/PractitionerFilter';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component({
    validations: {
        updateSequenceInput: {
            practitioner: { required },
            selectedInstruments: { required },
            group: {
                requiredIf: requiredIf((updateModel: SequenceUpdate) => {
                    return updateModel.isGroupRequired;
                }),
            },
            startDate: {
                requiredIf: requiredIf((updateModel: SequenceUpdate) => {
                    return updateModel.useInterval;
                }),
            },
            interval: {
                requiredIf: requiredIf((updateModel: SequenceUpdate) => {
                    return updateModel.useInterval;
                }),
            },
        },
    },
    components: {
        'date-time-input': require('../../inputs/dateTimeInput.vue').default,
    },
} as any)
export default class SequenceActionComponent extends SideActionBase<SequenceUpdateActionOptions> {
    public organizationId: number = this.options.organizationId;
    public practitioners: Practitioner[] = this.options.practitioners;
    public groupIsRequired: boolean = this.options.groupIsRequired;
    public sequence: Sequence = this.options.sequenceData;

    public timeOptions: any[] = SequenceManagementBase.timeOptions;

    // tslint:disable-next-line: no-use-before-declare
    public updateSequenceInput: SequenceUpdate = new SequenceUpdate(false, this.options.sequenceData);
    public instruments: Instrument[] = [];
    public groups: OrganizationGroup[] = [];
    public submitted: boolean = false;
    public loading: boolean = true;
    public working: boolean = false;
    private practitionerService: PractitionerService = new PractitionerService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        this.groups = await this.organizationGroupService.getOrganizationGroups(this.organizationId);
        await this.$store.dispatch('instrumentsStore/fetch');
        const instruments = this.$store.getters['instrumentsStore/instruments'];
        this.instruments = instruments.filter((i) => {
            return i.isInstrumentSelectable;
        });

        if (this.instruments.length === 1) {
            // pre-select instrument
            this.updateSequenceInput.selectedInstruments = [this.instruments[0]];
        }

        this.loading = false;
    }

    public async mounted() {
        this.updateSequenceInput.isGroupRequired = this.groupIsRequired;
        if (this.practitioners.length === 0) {
            await this.practitionerService
                .getPractitioners(new PractitionerFilter({ skip: 0, take: 9999 }))
                .then((practitioners) => {
                    this.practitioners = practitioners.items;

                    const me = this.practitioners.find((u) => {
                        return loginHelper.getUser().id === u.practitionerId;
                    });
                    if (me) {
                        this.updateSequenceInput.practitioner = me;
                    }
                })
                .catch(() => {
                    this.showError(
                        `The practitioners couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                    );
                });
        }

        if (this.practitioners.length === 1) {
            this.updateSequenceInput.practitioner = this.practitioners[0];
        }
    }

    public get groupLabel(): string {
        if (this.groupIsRequired) {
            return 'Group';
        }

        return 'Group (optional)';
    }

    public async updateSequence(): Promise<void> {
        if (!(this as any).$v.$invalid) {
            if (!this.updateSequenceInput.useInterval) {
                this.updateSequenceInput.startDate = null;
                this.updateSequenceInput.interval = null;
            }

            this.working = true;
            this.showPending('Updating the sequence data');
            const sequence = await sequenceService.updateSequence(this.sequence.sequenceId, this.updateSequenceInput);
            this.clearAndShowSuccess('Update of sequence data completed');
            this.working = false;

            this.finished(sequence);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class SequenceUpdate extends SequenceManagementBase {
    constructor(isGroupRequired: boolean, sequenceData: Sequence) {
        super(isGroupRequired);

        if (sequenceData.interval !== null) {
            const interval = sequenceData.interval;
            this._timeUnit = SequenceManagementBase.getTimeOption(interval).value;
            this.interval = interval;
        } else {
            this.interval = 0;
        }

        if (sequenceData.startDate !== null) {
            this.startDate = sequenceData.startDate;
        } else {
            this.startDate = new Date();
        }

        this.group = sequenceData.group;
        this.practitioner = sequenceData.practitioner;
        this.selectedInstruments = sequenceData.instruments;

        this.useInterval = sequenceData.startDate !== null;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class SequenceUpdateActionOptions extends SequenceActionOptions {
    public sequenceData: Sequence;

    constructor(data: SequenceUpdateActionOptions) {
        super(data);

        Object.assign(this, data);

        if (!this.sequenceData) {
            throw new Error('Sequence data is required for the update');
        }
    }
}
