import { OrderByField } from './OrderByField';

export enum OrderByDirection {
    Asc = 'asc',
    Desc = 'desc',
    None = 'none',
}

export default class OrderBy {
    public fields: OrderByField[] = [];

    public reset() {
        this.fields.forEach((field: OrderByField) => {
            field.direction = OrderByDirection.None;
        });
    }

    public setSort(key: string, direction: OrderByDirection, order?: number) {
        const field = this.fields.find((f: OrderByField) => f.key === key);
        field.direction = direction;
        field.order = order;
    }

    public getQueryString(): string {
        const orderFields = this.fields.filter((f: OrderByField) => f.direction !== OrderByDirection.None);
        if (orderFields.length === 0) {
            return '';
        }

        const orders = [];
        orderFields.sort((a, b) => a.order - b.order);
        orderFields.forEach((f: OrderByField) => {
            orders.push(f.direction === OrderByDirection.Asc ? f.field : `-${f.field}`);
        });
        return `OrderBy=${orders.join(',')}`;
    }
}
