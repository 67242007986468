import Participant from './Participant';

export default class ParticipantCreate {
    public static fromParticipant(particpant: Participant): ParticipantCreate {
        return {
            emailAddress: particpant.emailAddress,
            firstName: particpant.firstName,
            lastName: particpant.lastName,
            organizationId: particpant.organizationId,
            preferredLanguage: particpant.preferredLanguage,
            phoneNumber: particpant.phoneNumber,
        } as ParticipantCreate;
    }

    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public organizationId: number = 0;
    public preferredLanguage: string = null;

    constructor(participantCreate?: any) {
        (Object as any).assign(this, participantCreate);
    }
}
