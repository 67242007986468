import { instrumentsService } from '@/main';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import PricingPlanAddActionParameters from './pricingplanAddActionParameters';

@Component
export default class PricingPlanAddActionComponent extends SideActionBase<PricingPlanAddActionParameters> {
    public pricingPlanName: string = '';
    public pricingPlanAmount: number = null;

    public working: boolean = true;

    public async addPricingPlan(evt: any): Promise<any> {
        evt.preventDefault();

        this.working = true;
        await instrumentsService.addPricingPlan(this.options.instrumentId, {
            pricingPlanName: this.pricingPlanName,
            pricingPlanAmount: this.pricingPlanAmount,
        });

        this.working = false;

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }
}
