export default class KnowledgeModelCreate {
    public name: string;
    public domain: string;

    public paperlessEcosystemId: number;
    public paperlessTeamId: number;
    public paperlessTeamName: string;
    public paperlessGroupName: string;
    public paperlessProductId: number;

    public primaryColor: string = '#000000';
    public secondaryColor: string = '#000000';
    public masterTemplate: string = '';
}
