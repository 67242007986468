import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { ParticipantState, SurveyCreate } from '../../../pages/surveys/models/participant';
import { ErrorTypes } from '../../../models/enums/ErrorTypes';

@Module({ namespaced: true })
export default class ParticipantsStore extends VuexModule {
    private _participants: SurveyCreate[] = [];
    private _step: number = 1;
    private _showInviteAction: boolean = false;

    @Mutation
    public INCREMENT_STEP() {
        this._step++;
    }

    @Mutation
    public ADD_PARTICIPANT(participant) {
        this._participants.push(participant);
    }

    @Mutation
    public SAVE_PARTICIPANTS(participants) {
        this._participants = participants;
    }

    @Mutation
    public SAVE_STEP(newStep) {
        this._step = newStep;
    }

    @Mutation
    public SET_INVITE_ACTION(showInviteAction: boolean) {
        this._showInviteAction = showInviteAction;
    }

    @Action({ rawError: true })
    public async cleanup() {
        this.context.commit('SAVE_PARTICIPANTS', []);
        this.context.commit('SAVE_STEP', 1);
    }

    @Action({ rawError: true })
    public async addParticipant(participant: SurveyCreate) {
        return new Promise((resolve, reject) => {
            if (!this._participants.some((p: SurveyCreate) => participant.participant.emailAddress === p.participant.emailAddress)) {
                if (participant.state === ParticipantState.Reused && (!participant.basedOn || !participant.basedOn.surveyId)) {
                    reject(ErrorTypes.INVALID_INPUT);
                    return;
                }

                this.context.commit('ADD_PARTICIPANT', participant);
                resolve(true);
            } else {
                reject(ErrorTypes.DUPLICATE);
            }
        });
    }

    get participants(): SurveyCreate[] {
        return this._participants;
    }

    get currentStep(): number {
        return this._step;
    }

    get showInviteAction(): boolean {
        return this._showInviteAction;
    }
}
