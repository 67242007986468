import { Language } from '@/models/Language';
import { Discount } from './Discount';

export default class Partner {
    public partnerId: number = 0;
    public companyName: string = '';
    public emailAddress: string = '';
    public preferredLanguage: Language = null;
    public practitionerCount: number = 0;

    public paperlessContactId: number = null;
    public paperlessOrganizationId: number = null;
    public discount: number = null;
    public discounts: Discount[] = [];

    constructor(data?: Partial<Partner>) {
        Object.assign(this, data);
    }
}
