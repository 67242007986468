import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class InstrumentDetailsStore extends VuexModule {
    private _currentTab: number = 0;
    @Mutation
    public TAB_CHANGED(newTab: number) {
        this._currentTab = newTab;
    }

    get currentTab(): number {
        return this._currentTab;
    }
}
