import Vue from 'vue';
import to from 'await-to-js';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
// import { CreateResult } from '@/models/CreateResult';
import KnowledgeModel from '../models/KnowledgeModel';
import KnowledgeModelFilter from '../models/KnowledgeModelFilter';
import { CreateResult } from '@/models/CreateResult';
import KnowledgeModelCreate from '../models/KnowledgeModelCreate';
import Instrument from '@/modules/instruments/Models/Instrument';

export class KnowledgeModelService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getKnowledgeModel(knowLedgeModelId: number): Promise<KnowledgeModel> {
        const [err, response] = await to(this.get(`${this.endpoint}/knowledge-models/${knowLedgeModelId}`));
        if (err) {
            return new KnowledgeModel();
        }

        return new KnowledgeModel(response.data);
    }

    public createKnowledgeModel(knowledgeModel: KnowledgeModelCreate): Promise<CreateResult<KnowledgeModel>> {
        return new Promise<CreateResult<KnowledgeModel>>((resolve, reject) => {
            this.post<CreateResult<KnowledgeModel>>(`${this.endpoint}/knowledge-models`, knowledgeModel)
                .then((response: AxiosResponse<CreateResult<KnowledgeModel>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updateKnowledgeModel(knowledgeModel: KnowledgeModel): Promise<KnowledgeModel> {
        return new Promise<KnowledgeModel>((resolve, reject) => {
            this.put<KnowledgeModel>(`${this.endpoint}/knowledge-models/${knowledgeModel.knowledgeModelId}`, {
                name: knowledgeModel.name,
                paperlessTeamId: knowledgeModel.paperlessTeamId,
                paperlessTeamName: knowledgeModel.paperlessTeamName,
                paperlessGroupName: knowledgeModel.paperlessGroupName,
                paperlessProductId: knowledgeModel.paperlessProductId,
                paperlessEcosystemId: knowledgeModel.paperlessEcosystemId,
                paperlessTemplateId: knowledgeModel.paperlessTemplateId,
                primaryColor: knowledgeModel.primaryColor,
                secondaryColor: knowledgeModel.secondaryColor,
                masterTemplate: knowledgeModel.masterTemplate,
                surveyConfiguration: knowledgeModel.surveyConfiguration ? knowledgeModel.surveyConfiguration : null,
                emailDefaultFromAddress: knowledgeModel.emailDefaultFromAddress,
                emailDefaultFromName: knowledgeModel.emailDefaultFromName,
            })
                .then((response: AxiosResponse<KnowledgeModel>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public async getKnowledgeModels(filter: Partial<KnowledgeModelFilter>): Promise<PagedResponse<KnowledgeModel>> {
        const params = [];
        for (const property in filter) {
            if (filter[property] !== null && filter[property] !== undefined) {
                if (Array.isArray(filter[property])) {
                    const propertyArray = filter[property];
                    propertyArray.forEach((element) => {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                    });
                } else {
                    params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                }
            }
        }

        const [error, response] = await to(this.get<PagedResponse<KnowledgeModel>>(`${this.endpoint}/knowledge-models?${params.join('&')}`));
        if (error) {
            return new PagedResponse<KnowledgeModel>();
        }

        return response.data;
    }

    public deleteKnowledgeModel(partnerId: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.delete(`${this.endpoint}/knowledge-models/${partnerId}`)
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getInstruments(partnerId: number): Promise<PagedResponse<Instrument>> {
        return new Promise<PagedResponse<Instrument>>((resolve, reject) => {
            this.get<PagedResponse<Instrument>>(`${this.endpoint}/knowledge-models/${partnerId}/instruments`)
                .then((response: AxiosResponse<PagedResponse<Instrument>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public setInstruments(partnerId: number, instruments: number[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.put(`${this.endpoint}/knowledge-models/${partnerId}/instruments`, { instruments })
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
