import Vue from 'vue';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { PractitionerService } from '../services/practitionerService';
import Practitioner from '../models/Practitioner';
import { ProjectFilter } from '../models/ProjectsFilter';
import he from 'he';

@Component
export default class PractitionerOrganizationsComponent extends BasePage {
    get projectsCount(): number {
        return this.projectsData.projectsCount;
    }

    public loading: boolean = true;
    public thekey: string = new Date().getTime().toString();
    public filterTimer: number = 0;

    public practitionerService: PractitionerService = new PractitionerService();
    public columns: GridColumnProps[] = [
        { field: 'ProjectName', title: 'Name', cell: this.renderProjectRouterLinkWithBreachCheck, sortable: true },
        {
            field: 'instrument',
            title: 'Instrument(s)',
            cell: this.renderInstruments,
            width: 275,
            sortable: false,
        },
        { field: 'organization', title: 'Organization', width: '225px', cell: this.renderOrganizationLink, sortable: false },
        { field: 'surveyCompletedCount', title: 'Completed', width: '125px', cell: this.renderCompleted, sortable: false },
        { field: 'startDate', title: 'Create date', cell: Vue.component('grid-date-display'), width: '150px', sortable: true },
        { field: 'closeDate', title: 'Close date', cell: Vue.component('grid-date-display'), width: '125px', sortable: true },
        { field: 'status', title: 'Status', cell: Vue.component('grid-status-display'), width: '85px', sortable: false },
    ];

    public projectsData: any = {
        projects: [],
        projectsCount: 0,
        skip: 0,
        take: 25,
        search: '',
    };

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            await this.getProjects();
        }, 400);
    }

    public practitioner: Practitioner = null;

    public async created() {
        if (this.isSuperAdmin || this.isPartner) {
            const practitionerId = parseInt(this.$route.params.practitionerId);
            this.practitioner = await this.practitionerService.getPractitioner(practitionerId);

            await this.getProjects();
        }
    }

    public getHeight() {
        return {
            height: this.projectsData.projects.length === 0 ? 40 : this.projectsData.projects.length * 40,
        };
    }

    public renderProjectRouterLinkWithBreachCheck(item: any, _, row): any {
        const projectUrl = this.$router.resolve({ name: 'project-details', params: { projectId: row.dataItem.projectId.toString() } }).href;

        row.dataItem.practitioner = this.practitioner;

        return this.determineProjectRouterComponent(item, row, projectUrl);
    }

    private renderCompleted(item: any, _, row): any {
        return item('td', `${row.dataItem.surveyCompletedCount}/${row.dataItem.surveyCount}`);
    }

    private renderOrganizationLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.organization.name),
                url: this.$router.resolve({
                    name: 'organization-details',
                    params: { organizationId: row.dataItem.organization.organizationId.toString() },
                }).href,
            },
        });
    }

    private renderInstruments(item: any, _: any, row: any): any {
        if (row.dataItem.instruments.length > 1) {
            return item(Vue.component('grid-popover-instruments'), {
                props: {
                    id: row.dataItem.projectId,
                    title: `Instruments`,
                    instruments: row.dataItem.instruments,
                    text: `${row.dataItem.instruments.length} instruments`,
                },
            });
        }

        return item('td', row.dataItem.instruments[0].name);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.projectsData.skip = event.page.skip;
        this.projectsData.take = event.page.take;

        this.getProjects();
    }

    public async getProjects(): Promise<void> {
        this.loading = true;
        await this.practitionerService
            .getProjects(
                new ProjectFilter({
                    $count: true,
                    take: this.projectsData.take,
                    skip: this.projectsData.skip,
                    search: this.projectsData.search,
                    practitionerId: +this.$route.params.practitionerId,
                }),
            )
            .then((projects) => {
                this.projectsData.projects = projects.items;
                this.projectsData.projectsCount = projects.count;
            });

        this.updateCacheKey();

        this.loading = false;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }
}
