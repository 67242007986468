import { OrderByDirection } from './OrderBy';

export class OrderByField {
    public key: string = '';
    public field: string = '';
    public direction: OrderByDirection;
    public order: number = 0;

    constructor(obj?: Partial<OrderByField>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
