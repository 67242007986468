import { loginHelper } from '@/main';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import { LanguagePermissionService } from '@/modules/settings/services/languagePermissionsService';
import { routeData } from '@/router';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PwaButtonVue from '@/components/PwaButton.vue';

@Component({
    components: {
        PwaBtn: PwaButtonVue,
    },
})
export default class RouteWrapperComponent extends Vue {
    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();
    public practitionerService: PractitionerService = new PractitionerService();

    public routes: any[] = routeData;

    public async created() {
        await this.checkDirectInvoicingPermission();
        await this.checkLanguagePermission();
    }

    public checkRoles(route): boolean {
        const checkRoles =
            route.meta.menu.roles.length === 0 || route.meta.menu.roles.filter((value) => loginHelper.getRole().includes(value)).length > 0;
        return checkRoles;
    }

    public checkPermissions(route) {
        if (!route.meta || !route.meta.menu || !route.meta.menu.permissions || route.meta.menu.permissions.length === 0) {
            return true;
        }

        if (loginHelper.getRole().includes('SuperAdmin') || loginHelper.getRole().includes('Partner')) {
            return true;
        }

        let hasPermission = false;

        if (route.meta && route.meta.menu && route.meta.menu.permissions && route.meta.menu.permissions.length > 0) {
            route.meta.menu.permissions.forEach(async (permission) => {
                switch (permission) {
                    case 'Translator':
                        hasPermission = loginHelper.hasPermission(permission);
                        break;
                    case 'DirectInvoicing':
                        hasPermission = loginHelper.hasPermission(permission);
                        break;
                    default:
                        hasPermission = false;
                }
            });
        }

        return hasPermission;
    }

    public async checkLanguagePermission() {
        if (loginHelper.hasPermission('Translator')) {
            return true;
        }

        return loginHelper.setTranslatorPermissions();
    }

    public async checkDirectInvoicingPermission() {
        if (loginHelper.hasPermission('DirectInvoicing')) {
            return true;
        }

        const practitionerId = loginHelper.getUser().practitionerId;
        if (!practitionerId) {
            return loginHelper.setInvoicePermissions(false);
        }

        const practitioner = await this.practitionerService.getPractitioner(practitionerId);
        return loginHelper.setInvoicePermissions(
            practitioner && practitioner.billingInformation != null && practitioner.billingInformation.directInvoicing,
        );
    }

    public getMenuCounter(route) {
        const menuCounters = this.$store.getters['projectStore/menuCounters'];
        if (menuCounters && menuCounters.length > 0 && menuCounters.find((x) => x.label === route)) {
            const count = menuCounters.find((x) => x.label === route).count;

            if (count) {
                return count;
            }
        }
    }

    public getTo(route) {
        return {
            name: route.name,
            params: {
                test: 123,
            },
        };
    }

    public goToMyProfile() {
        this.$router.push({ name: 'profile' });
    }

    public get email() {
        return loginHelper.getGivenName();
    }

    public signOut() {
        loginHelper.logOut();
    }

    public confirmResetPassword() {
        this.$bvModal
            .msgBoxConfirm(
                `Do you really want to reset your password? You will be logged out and will receive a mail with further instructions on '${loginHelper.getEmailAddress()}'`,
                {
                    title: 'Reset password',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Reset',
                    cancelTitle: 'no',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                },
            )
            .then(async (value) => {
                if (value) {
                    await this.resetPassword();
                }
            })
            .catch((err) => {
                // An error occurred
                console.log(err);
            });
    }

    public async resetPassword() {
        await loginHelper.forgotPassword(loginHelper.getEmailAddress());
        loginHelper.logOut();
        document.location.pathname = '/';
    }

    get isSuperAdmin(): boolean {
        return loginHelper.isInRole('SuperAdmin');
    }

    get isPractitioner(): boolean {
        return loginHelper.isInRole('Practitioner');
    }
}
