import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';
import ProgressArgs, { ProgressType } from './ProgressArgs';
import ProgressComponentVue from './ProgressComponent.vue';

export const progress = (type: ProgressType, args?: ProgressArgs) => {
    const el = document.createElement('div');
    document.body.append(el);
    const component = Vue.extend(ProgressComponentVue);
    const instance: ProgressComponentVue = new component({
        propsData: {
            type,
            title: args?.title,
            text: args?.text,
            duration: args?.duration,
        },
    });

    const animation = new Promise<void>((resolve) => {
        instance.$on('close', () => {
            instance.$el.remove();
            resolve();
        });
        instance.$mount(el);
    });

    return {
        instance,
        animation,
    };
};

const ProgressPlugin: PluginObject<any> = {
    install(app: any) {
        app.$progress = progress;
    },
};

export default ProgressPlugin;
