import BasePage from '@/models/BasePage';
import { ProjectsService } from '@/services/projectsService';
import to from 'await-to-js';
import Component from 'vue-class-component';
import QrcodeVue from 'qrcode.vue';
import { ProjectUpdate } from '@/models/Project';

@Component({
    components: {
        QrcodeVue,
    },
})
export default class AddExistingParticipantComponent extends BasePage {
    public projectId: number = null;
    public uniqueLink: string = null;
    public numberOfParticipants: number = 0;
    private projectService: ProjectsService = new ProjectsService();

    public async created() {
        this.projectId = parseInt(this.$route.params.projectId);
    }

    public async generateUniqueLink() {
        let projectData = await this.projectService.getProject(this.projectId);
        const projectUpdate = new ProjectUpdate(projectData);
        projectUpdate.anonymousParticipantsAllowed = this.numberOfParticipants;

        const [err] = await to(this.projectService.updateProject(this.projectId, projectUpdate));
        if (err) {
            this.showError(err);
            return;
        }

        projectData = await this.projectService.getProject(this.projectId);

        this.showSuccess('Unique link generated');
        this.uniqueLink = `${this.$env().SurveyUrl}survey/start?project_id=${this.projectId}&survey_key=${
            projectData.anonymousSurveySettings.surveyKey
        }`;
    }

    public async copyToClipboard(apiKey) {
        await navigator.clipboard.writeText(apiKey);

        this.clearAndShowSuccess('The unique link is copied to your clipboard.');
    }
}
