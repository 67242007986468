import BasePage from '@/models/BasePage';
import Prepaid from '@/modules/prepaid/models/Prepaid';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { PrepaidService } from '@/modules/prepaid/services/prepaidService';
import { PagedResponse } from '@/models/PagedResponse';
import Partner from '../models/Partner';
import to from 'await-to-js';
import { PrepaidFilter } from '@/modules/prepaid/models/PrepaidFilter';

@Component
export default class PrepaidOverviewComponent extends BasePage {
    @Prop({ default: null }) public partner: Partner;
    @Prop({ default: null }) public userId: number;

    public prepaids = new PagedResponse<Prepaid>();
    public prepaidService: PrepaidService = new PrepaidService();

    public columns: GridColumnProps[] = [
        { field: 'knowledgeModelName', title: 'Knowledge model' },
        { field: 'startDate', title: 'Start date', cell: Vue.component('grid-date-display') },
        { field: 'endDate', title: 'End date', cell: Vue.component('grid-date-display') },
        { field: 'totalPrepaid', title: 'Total prepaid', cell: this.renderTotalPrepaidDisplay },
        { field: 'usedPrepaid', title: 'Used Prepaid', format: '{0:c}' },
        { field: 'remainingPrepaid', title: 'Remaining Prepaid', format: '{0:c}' },
        { field: 'cost', title: 'Cost', cell: this.renderCostDisplay },
    ];
    public gridKey: string = '';
    public prepaidData = {
        items: [],
        count: 0,
        skip: 0,
        take: 25,
    };

    public async created() {
        await this.getPrepaids(this.userId);
        this.isLoaded = true;
    }

    public renderTotalPrepaidDisplay(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.totalPrepaid,
                currencyCode: 'USD',
            },
        });
    }

    public renderCostDisplay(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.cost,
                currencyCode: 'USD',
            },
        });
    }

    public openAddPrepaid(): any {
        this.$sideActions.push('side-action-add-prepaid', { partnerId: this.partner.partnerId }, async () => {
            this.clearAndShowSuccess('The prepaid is successfully added.');
            await this.getPrepaids(this.partner.partnerId);
        });
    }

    private updateCacheKey(): void {
        this.gridKey = new Date().getTime().toString();
    }

    private async getPrepaids(partnerId: number) {
        const [err, response] = await to(this.prepaidService.getPrepaids(new PrepaidFilter({ partner_id: partnerId })));
        if (err || !response) {
            return this.clearAndShowError(
                `The prepaid details couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem still exists, contact us with the support button.`,
                err,
            );
        }

        this.prepaidData.items = response.data.items;
        this.prepaidData.count = response.data.count;

        this.updateCacheKey();
    }
}
