import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridParticipantStatus extends Vue {
    @Prop() public status: boolean;

    public responseLabels: any = {};

    public created() {
        this.responseLabels.enabled = 'Enabled';
        this.responseLabels.disabled = 'Disabled';
    }
}
