import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import RetestProjectActionParameters from './retestProjectActionParameters';
import { languageService, projectsService } from '@/main';
import AddParticipantForProjectCommand from '@/commands/addParticipantForProjectCommand';
import { Project, ProjectCreate } from '@/models/Project';
import { required } from 'vuelidate/lib/validators';
import { CreateResult } from '@/models/CreateResult';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { OrganizationsService } from '@/services/organizationsService';
import practitionerMultiselectVue from '@/components/practitioner/practitioner-multiselect.vue';

@Component({
    validations: {
        projectCreate: {
            closeDate: { required },
        },
    },
    components: {
        practitionerMultiselect: practitionerMultiselectVue,
    },
} as any)
export default class RetestProjectActionComponent extends SideActionBase<RetestProjectActionParameters> {
    public projectCreate: ProjectCreate = new ProjectCreate();
    public submitted: boolean = false;
    public working: boolean = true;
    public practitioners: Practitioner[] = [];

    public get project(): Project {
        return this.options.project;
    }

    public async mounted() {
        this.working = true;
        await this.fetchPractitioners();
        this.working = false;
    }

    public async submitRetest(evt: any): Promise<any> {
        evt.preventDefault();

        this.submitted = true;
        this.projectCreate.startDate = new Date();

        if ((this as any).$v.$invalid) {
            this.showError(`The project didn't pass the validation.`);
            return;
        }

        this.working = true;
        this.showPending('Setting up new project based on this one');
        const newProject: CreateResult<Project> = await projectsService.retest(this.options.project.projectId, this.projectCreate);
        this.clearAndShowSuccess('Setup of new project was successful, please check the participants.');

        if (newProject) {
            const languages = await languageService.getLanguages();
            this.options.persons.forEach((x) => (x.preferredLanguageObject = languages.find((y) => y.languageCode === x.preferredLanguage)));

            const addParticipants = new AddParticipantForProjectCommand(this.$store, this.options.persons);
            await addParticipants.execute();
        }

        this.working = false;
        this.finished(newProject.entity.projectId);
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }

    private async fetchPractitioners() {
        const service = new OrganizationsService();
        const practitioners = await service.getShared(this.project.organizationId);
        const organization = await service.getOrganization(this.project.organizationId);
        this.practitioners = [...practitioners, organization.practitioner];
        this.projectCreate.practitioner = this.practitioners.find((p) => p.practitionerId === this.project.practitionerId);
    }
}
