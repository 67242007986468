import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridEditWrapper } from '../../../models/GridEditWrapper';
import { ParticipantState } from '../../../pages/surveys/models/participant';

export class GridAddMode {
    public label: string = '';
    public value: string = '';
    public disabled: boolean = false;

    public participantState: ParticipantState = ParticipantState.Undefined;

    constructor(gridAddModeData: GridAddMode) {
        Object.assign(this, gridAddModeData);
    }
}

// tslint:disable: max-classes-per-file
@Component
export default class GridAddModeComponent extends Vue {
    @Prop() public field: string;
    @Prop() public dataItem: GridEditWrapper<any>;
    @Prop({ default: () => [] }) public addModes: GridAddMode[];

    set model(input) {
        if (this.field) {
            const splittedItems = this.field.split('.');
            let realDataItem = this.dataItem;

            for (let i = 0; i < splittedItems.length - 1; i++) {
                realDataItem = realDataItem[splittedItems[i]];
            }

            realDataItem[splittedItems[splittedItems.length - 1]] = input;
        }
    }

    get model(): any {
        if (this.field) {
            const splittedItems = this.field.split('.');
            let realDataItem = this.dataItem;

            for (let i = 0; i < splittedItems.length; i++) {
                realDataItem = realDataItem[splittedItems[i]];
            }

            return realDataItem;
        }

        return 'none';
    }

    public change() {
        this.$emit('change');
    }
}
