import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { projectsService, $router, languageService, organizationGroupMembershipService } from '../../main';
import { Project } from '../../models/Project';
import { SurveyDetails } from '../../models/Survey';
import { PagedResponse } from '../../models/PagedResponse';
import { ParticipantState, SurveyCreate } from './models/participant';
import { GridEditWrapper } from '../../models/GridEditWrapper';
import { Language } from '../../models/Language';
import BasePage from '../../models/BasePage';
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import Participant from '@/modules/participants/models/Participant';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { OrganizationGroup } from '@/models/Organization';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import { GridAddMode } from '@/modules/participants/grid/grid-add-mode';
import { InstrumentType } from '@/models/Instrument';
import moment from 'moment';
import to from 'await-to-js';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';

@Component
export default class AddParticipantsFromGroupComponent extends BasePage {
    public surveys: ProjectParticipant[];
    public groups: OrganizationGroup[] = [];
    public groupMembers: Participant[] = [];
    public selectedGroup: OrganizationGroup = null;
    public organizationParticipants: Array<GridEditWrapper<any>> = [];
    public isLoaded: boolean = false;
    public existingParticipantsKey: string = '';
    public addModes: GridAddMode[] = [new GridAddMode({ disabled: false, label: 'Add', value: 'add', participantState: ParticipantState.Created })];

    public columns: any[] = [
        { field: 'dataItem.addMode', title: 'Add', width: 150, cell: this.renderAddMode },
        { field: 'dataItem.firstName', title: 'First name' },
        { field: 'dataItem.lastName', title: 'Last name' },
        { field: 'dataItem.emailAddress', title: 'Email address' },
        { field: 'dataItem.completionDate', title: 'Reuse?', cell: this.renderReuseData },
    ];

    public $refs!: {
        existingParticipants: any;
    };

    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    private languages: Language[] = null;
    private projectId: number = 0;
    private projectData: Project = null;
    private participantService: ParticipantsService = new ParticipantsService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        const savedParticipants = this.$store.getters['participantsStore/participants'] as SurveyCreate[];

        this.projectId = parseInt($router.currentRoute.params.projectId);
        await projectsService.getProject(this.projectId).then((value: Project) => {
            this.projectData = value;
        });

        await this.loadParticipants();

        this.languages = languageService.getLanguages();

        await this.organizationGroupService.getOrganizationGroups(this.projectData.organization.organizationId).then((organizationGroups) => {
            this.groups = organizationGroups;
        });

        const personalInstrument = this.getPersonalBaseInstrument();
        await this.participantService
            .getParticipants({
                organizationId: this.projectData.organization.organizationId,
                instrumentId: personalInstrument ? personalInstrument.instrumentId : null,
                skip: 0,
                take: 9999,
            })
            .then((participants: PagedResponse<Participant>) => {
                for (let i = 0; i < participants.items.length; i++) {
                    if (
                        !this.surveys.find((s) => {
                            return participants.items[i].participantId === s.participantId;
                        })
                    ) {
                        // surveys contains any participant with this id
                        this.organizationParticipants.push(
                            new GridEditWrapper<any>(true, {
                                addMode: personalInstrument && participants.items[i].lastCompletedSurvey != null ? 'none' : 'add',
                                participantId: participants.items[i].participantId,
                                preferredLanguageObject: this.languages.find((language) => {
                                    return participants.items[i].preferredLanguage === language.languageCode;
                                }),
                                preferredLanguage: participants.items[i].preferredLanguage,
                                firstName: participants.items[i].firstName,
                                lastName: participants.items[i].lastName,
                                emailAddress: participants.items[i].emailAddress,
                                organizationId: participants.items[i].organizationId,
                                lastCompletedSurvey: participants.items[i].lastCompletedSurvey,
                            }),
                        );
                    }
                }

                if (this.organizationParticipants.length === 0) {
                    this.showWarning('All participants of this group/organization have already been added to this project.');
                }

                this.isLoaded = true;
            });

        this.organizationParticipants.forEach((item) => {
            const matchingParticipant = savedParticipants.find((savedParticipant) => {
                return item.dataItem.id === savedParticipant.participant.participantId;
            });

            if (matchingParticipant) {
                switch (matchingParticipant.state) {
                    case ParticipantState.Added:
                        item.dataItem.addMode = 'add';
                        break;
                    case ParticipantState.Reused:
                        item.dataItem.addMode = 'reuse';
                        break;
                }
            }
        });

        if (!this.getPersonalBaseInstrument()) {
            this.columns = this.columns.filter((x) => x.field !== 'dataItem.completionDate');
        }
    }

    public async mounted() {
        this.updateCacheKey();
    }

    public async loadParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.projectId,
                    skip: 0,
                    take: 99999,
                    $count: true,
                }),
            ),
        );

        if (err) {
            this.showError('Failed to load participants');
        }

        this.surveys = response.items.map((x) => new ProjectParticipant(x));
    }

    public async loadGroupMembers(group) {
        this.isLoaded = false;
        this.groupMembers = await organizationGroupMembershipService.getGroupMembers(this.projectData.organization.organizationId, group.groupId);
        this.isLoaded = true;

        this.updateCacheKey();
    }

    public get selectedParticipants() {
        return this.organizationParticipants.filter((item) => {
            return (
                (item.dataItem.addMode === 'add' || item.dataItem.addMode === 'reuse') &&
                this.groupMembers.find((x) => x.participantId === item.dataItem.participantId)
            );
        });
    }

    public async addSelectedParticipants() {
        const selectedParticipants = this.selectedParticipants;

        for (let i = 0; i < selectedParticipants.length; i++) {
            const addMode = selectedParticipants[i].dataItem.addMode === 'add' ? ParticipantState.Added : ParticipantState.Reused;

            this.$store
                .dispatch(
                    'participantsStore/addParticipant',
                    new SurveyCreate({
                        participant: selectedParticipants[i].dataItem,
                        state: addMode,
                        basedOn: new SurveyDetails({
                            surveyId: addMode === ParticipantState.Reused ? selectedParticipants[i].dataItem.lastCompletedSurvey.surveyId : null,
                        }),
                    }),
                )
                .catch((error) => {
                    this.showError(`${selectedParticipants[i].dataItem.emailAddress} could not be added due to an error.`);
                    console.error(`Error occurred while adding ${selectedParticipants[i].dataItem.emailAddress}. Error: ${error}`);
                });
        }

        this.showSuccess('Participants successfully added.');

        await $router.push({ name: 'addSurvey' });
    }

    public existingParticipantsFiltered(): Array<GridEditWrapper<any>> {
        const participants = this.organizationParticipants.filter((item) => {
            return this.groupMembers.find((x) => x.participantId === item.dataItem.participantId);
        });

        return filterBy(participants, this.filter);
    }

    public participantSearched(input: string): void {
        this.filter.filters = [];

        const splittedInput = input.split(' ');
        for (let i = 0; i < splittedInput.length; i++) {
            this.filter.filters.push(
                { field: 'dataItem.firstName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
                { field: 'dataItem.lastName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
            );
        }

        this.updateCacheKey();
    }

    public renderAddMode(item: any, _, row: any): any {
        const addModes = this.addModes.slice(0, this.addModes.length);
        if (row.dataItem.dataItem.lastCompletedSurvey && this.getPersonalBaseInstrument()) {
            addModes.push(new GridAddMode({ disabled: false, label: 'Reuse', value: 'reuse', participantState: ParticipantState.Reused }));
        }

        return item(Vue.component('grid-add-mode'), {
            props: {
                field: 'dataItem.addMode',
                dataItem: row.dataItem,
                addModes,
            },
        });
    }

    public getPersonalBaseInstrument() {
        return this.projectData && this.projectData.instruments.find((x) => x.instrumentType === InstrumentType.Personal);
    }

    private updateCacheKey(): void {
        this.existingParticipantsKey = new Date().getTime().toString();
    }

    private renderReuseData(item: any, _: any, row: any) {
        let date = '';
        if (row.dataItem.dataItem.lastCompletedSurvey && row.dataItem.dataItem.lastCompletedSurvey.completionDate) {
            date = moment(row.dataItem.dataItem.lastCompletedSurvey.completionDate, 'YYYY-MM-DD').format('ll');
        }
        return item('td', date ? `Possible, based on survey completed on ${date}` : 'No');
    }
}
