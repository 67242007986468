import { Component } from 'vue-property-decorator';
import { CreatePartnerModel } from '@/models/CreatePartner';
import Vue from 'vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import BasePage from '@/models/BasePage';
import { Member } from '@/models/Member';
import Partner from '../models/Partner';
import { Language } from '@/models/Language';
import { $router, languageService, loginHelper } from '@/main';
import { PartnerService } from '../services/partnerService';
import PartnerUpdate from '../models/PartnerUpdate';
import { Discount } from '../models/Discount';
import { InstrumentsService } from '@/modules/instruments/services/instrumentsService';
import Instrument from '../models/Instrument';
import { required } from 'vuelidate/lib/validators';

@Component({
    components: {
        'practitioner-overview': require('@/modules/practitioners/components/practitioner-overview.vue').default,
        'prepaid-overview': require('@/modules/partners/components/prepaid-overview.vue').default,
        'instrument-overview': require('@/modules/partners/components/instrument-overview.vue').default,
        // 'price-plans-overview': require('@/modules/partners/components/price-plans-overview.vue').default,
        'account-overview': require('@/modules/partners/components/account-overview.vue').default,
    },
    validations: {
        partnerDataEdit: {
            companyName: { required },
            preferredLanguage: {
                languageCode: { required },
            },
        },
    },
})
export default class PartnerDetailsComponent extends BasePage {
    get instrumentList(): any[] {
        return this.instruments;
    }

    get selectedInstruments(): any[] {
        return this.instruments.filter((instrument: any) => {
            return instrument.selected;
        });
    }

    get instrumentsSelected(): boolean {
        return this.selectedInstruments.length !== 0;
    }

    public user: Member;
    public isLoaded: boolean = false;
    public editMode: boolean = false;
    public submitted: boolean = false;

    public partnerId: number = 0;

    public partnerData: Partner = new Partner();
    public partnerDataEdit: CreatePartnerModel = new CreatePartnerModel();
    public partnerService: PartnerService = new PartnerService();
    public instrumentsService: InstrumentsService = new InstrumentsService();

    public overviewActions: any[] = [];

    public thekey: string = new Date().getTime().toString();
    public loading: boolean = true;

    public instrumentColumns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: Vue.component('grid-checkbox') },
        { field: 'name', title: 'Instrument' },
    ];

    public defaultFilter: any = { directInvoicedOnly: null };

    private languages: Language[] = [];
    private instruments: any[] = [];

    public async created() {
        this.showPending('Loading...');
        const self = this;

        self.partnerId = parseInt($router.currentRoute.params.partnerId);
        self.user = loginHelper.getUser();

        await this.partnerService
            .getPartner(self.partnerId)
            .then((partner) => {
                self.partnerData = partner;
            })
            .catch(() => {
                this.showError(
                    `Partner data couldn't be retrieved. Please try again or, when the problem exists, contact us via the support button.`,
                );
            });

        if (self.partnerData.preferredLanguage !== null) {
            this.languages = languageService.getLanguages();
            this.setLanguageToPartner(self.partnerData, self.languages);
        }

        if (this.isSuperAdmin) {
            this.overviewActions.push({
                title: 'Move',
                function: async (practitioner) => {
                    this.$sideActions.push('move-practitioner-side-action', { partner: this.partnerData, practitioner }, () => {
                        this.showSuccess('Practitioner successfully moved');
                        this.refreshPractitionerOverview();
                    });
                },
            });
        }

        await this.getInstruments();

        this.isLoaded = true;
        this.clearNotifications();
    }

    public async startEdit() {
        this.editMode = true;

        await this.loadKnowledgeModels();
        Object.assign(this.partnerDataEdit, this.partnerData);
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.partnerDataEdit, this.partnerData);
    }

    public async loadKnowledgeModels() {
        const instruments = await this.partnerService.getInstruments(this.partnerId);

        await this.$store.dispatch('instrumentsStore/fetch');
        const listInstruments = this.$store.getters['instrumentsStore/instruments'];

        instruments.items.forEach((instrument: Instrument) => {
            if (!this.partnerData.discounts.find((x) => x.knowledgeModelId === instrument.knowledgeModelId)) {
                this.partnerData.discounts.push({
                    discount: 0,
                    knowledgeModelId: instrument.knowledgeModelId,
                    knowledgeModelName: listInstruments.find((x) => x.id === instrument.instrumentId).knowledgeModelName,
                } as Discount);
            }
        });
    }

    public async saveEdit() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showError(`The partner model didn't pass the validation.`);
            return;
        }

        this.showPending('Loading...');
        await this.updatePartner({
            companyName: this.partnerDataEdit.companyName,
            preferredLanguage: this.partnerDataEdit.preferredLanguage.languageCode,
            paperlessContactId: this.partnerDataEdit.paperlessContactId,
            paperlessOrganizationId: this.partnerDataEdit.paperlessOrganizationId,
            discount: this.partnerDataEdit.discount,
            discounts: this.partnerDataEdit.discounts.filter((x) => x.discount > 0),
        });
        this.clearNotifications();
    }

    public async enablePartner() {
        this.showPending('Loading...');
        await this.partnerService.enablePartner(this.partnerId);
        this.clearAndShowSuccess('Partner login enabled');
    }

    public async disablePartner() {
        this.showPending('Loading...');
        await this.partnerService.disablePartner(this.partnerId);
        this.clearAndShowSuccess('Partner login disabled');
    }

    public async deletePartner(): Promise<void> {
        if (confirm('Are you sure you want to delete this partner?')) {
            const self = this;
            this.showPending('Loading...');
            await this.partnerService
                .deletePartner(this.partnerId)
                .then(async () => {
                    await self.$router.push({ name: 'partners-overview' });
                    this.clearAndShowSuccess('Partner successfully deleted');
                })
                .catch((e) => {
                    self.clearAndShowError('Failed to delete partner', e);
                });
        }
    }

    public openAddPractitionerAction(): void {
        this.$sideActions.push('add-existing-practitioner-to-partner-action', { partner: this.partnerData }, () => {
            this.refreshPractitionerOverview();
        });
    }

    public refreshPractitionerOverview(): void {
        (this.$refs.practitionerOverview as any).refreshData();
    }

    public selectAllInstruments(): void {
        this.instruments.forEach((instrument: any) => {
            instrument.selected = true;
        });
    }

    public getHeight() {
        return {
            height: this.instruments.length === 0 ? 40 : this.instruments.length * 40,
        };
    }

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` ${item.dataItem.deleted ? 'bg-secondary text-muted' : ''}`;
        return tr;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }

    private async updatePartner(updatePartnerData: PartnerUpdate) {
        const self = this;

        await this.partnerService.updatePartner(this.partnerId, updatePartnerData).then((result: Partner) => {
            if (result) {
                self.partnerData = result;

                self.setLanguageToPartner(self.partnerData, self.languages);
                self.editMode = false;
            } else {
                this.showError('Error updating the partner. Please try again, or when the problem exists contact the helpdesk.');
            }
        });
    }

    private setLanguageToPartner(partnerData, languages): void {
        if (partnerData.preferredLanguage !== null) {
            const language = languages.find((lang) => {
                return lang.languageCode === partnerData.preferredLanguage;
            });
            partnerData.preferredLanguage = language;
        }
    }

    private getInstruments(): Promise<void> {
        this.instruments = [];
        return new Promise<void>(async (resolve) => {
            this.loading = true;
            await this.partnerService.getInstruments(this.partnerData.partnerId).then((instruments) => {
                this.instruments = instruments.items.map((val: Instrument) => {
                    return {
                        selected: false,
                        name: val.name,
                        id: val.instrumentId,
                        key: `instrument${val.instrumentId}`,
                    };
                });

                this.updateCacheKey();
                this.loading = false;
                resolve();
            });
        });
    }
}
