import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EmailSendStatus } from '../../../models/enums/EmailSendStatus';

@Component
export default class GridRejectSentenceComponent extends Vue {
    @Prop() public responseCode: EmailSendStatus;
    @Prop() public subject: string;
    @Prop() public emailAddress: string;
}
