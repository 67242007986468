import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EmailSendStatus } from '../../models/enums/EmailSendStatus';

@Component
export default class GridEmailStatusLabel extends Vue {
    @Prop() public status: EmailSendStatus;

    public responseLabels: any = {};

    public created() {
        // tslint:disable: no-string-literal
        this.responseLabels['none'] = 'None';
        this.responseLabels['queued'] = 'Queued';
        this.responseLabels['sending'] = 'Sending';
        this.responseLabels['sent'] = 'Sent';
        this.responseLabels['delivered'] = 'Delivered';
        this.responseLabels['notDelivered'] = 'Not delivered';
        this.responseLabels['errorInSending'] = 'Error in sending';
    }
}
