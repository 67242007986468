import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { loginHelper } from './main';

@Component
export default class AppComponent extends Vue {
    public bootstrapApp: boolean = false;

    public async created() {
        if (loginHelper.isLoggedIn()) {
            this.$store.dispatch('instrumentsStore/fetch');

            setTimeout(() => {
                Vue.nextTick(async () => {
                    this.$store.dispatch('projectStore/fetchMenuCounters');
                });
            }, 500);
        }
        this.bootstrapApp = true;
    }
}
