import { GridColumnProps } from '@progress/kendo-vue-grid';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrganizationGroup } from '../../../models/Organization';
import PageRender from '../../../models/PageRender';
import { filterBy } from '@progress/kendo-data-query';
import { GridGroupedViewProperties } from '../../../components/grid/grid-members-dropdown';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import he from 'he';

@Component
export default class GroupOverviewComponent extends PageRender {
    @Prop({ default: () => 0 }) public organizationId: number;
    public groups: OrganizationGroup[] = [];

    public isLoaded: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'name', title: 'Group', cell: this.renderRouterlink },
        { field: 'members', title: 'Members', cell: this.renderMembers },
        { field: 'createdOn', title: 'Created on' },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public filter: any = {
        search: '',
        organization: null,
        filters: [],
    };

    public cacheKey: string = '';

    private filterTimer: number = null;
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        this.filter.organization = this.organizationId;
        await this.refreshGroups();
    }
    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            await this.refreshGroups();
        }, 400);
    }

    public groupsFiltered(): OrganizationGroup[] {
        return filterBy(this.groups, this.filter);
    }

    public importGroupAction(): void {
        this.$sideActions.push('import-organization-group-action', { organizationId: this.organizationId }, async () => {
            await this.refreshGroups();
        });
    }

    public createGroupAction(): void {
        this.$sideActions.push('create-organization-group-action', { organizationId: this.organizationId }, async () => {
            await this.refreshGroups();
        });
    }
    private async refreshGroups() {
        this.isLoaded = false;

        await this.organizationGroupService.getOrganizationGroups(this.organizationId).then((groups) => {
            this.groups = groups;
            this.isLoaded = true;
            this.cacheKey = new Date().getTime().toString();
        });
    }

    private renderRouterlink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.name),
                url: this.$router.resolve({
                    name: 'group-details',
                    params: { organizationId: this.organizationId.toString(), groupId: row.dataItem.groupId.toString() },
                }).href,
            },
        });
    }

    private renderMembers(item: any, _, row): any {
        const props = new GridGroupedViewProperties({
            icon: 'users',
            detailIcon: 'user',
            textTemplate: `${row.dataItem.memberCount} group member(s)`,
            groupId: row.dataItem.groupId,
            organizationId: this.organizationId,
            members: [],
        });

        return item(Vue.component('grid-members-dropdownlist'), { props });
    }

    private renderActions(item: any, _, row): any {
        const actions = [{ title: 'Delete', function: this.deleteGroup }];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    private deleteGroup(group: any): void {
        if (confirm(`Are you sure that you want to delete the group with the name: ${group.name}`)) {
            this.showPending('Deleting group');
            this.organizationGroupService
                .deleteGroup(this.organizationId, group.groupId)
                .then(async () => {
                    this.clearAndShowSuccess('Group successfully deleted!');
                    await this.refreshGroups();
                })
                .catch((e) => {
                    this.clearAndShowError('Group could not be deleted.', e);
                });
        }
    }
}
