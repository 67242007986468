export default class AccountFilter {
    public skip: number;
    public take: number;
    public $count: boolean;
    public search: string;
    public partnerId: number;
    public practitionerId: number;

    constructor(filter: Partial<AccountFilter>) {
        Object.assign(this, filter);
    }
}
