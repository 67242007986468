import Vue from 'vue';
import Prepaid from '@/modules/prepaid/models/Prepaid';
import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { PrepaidFilter } from '../models/PrepaidFilter';
import { PagedResponse } from '@/models/PagedResponse';

export class PrepaidService extends BaseService {
    private endpoint = `${Vue.$env().InvoiceServiceEndpoint}/api/prepaid`;

    constructor() {
        super();
    }

    public async getPrepaids(filter: PrepaidFilter): Promise<AxiosResponse<PagedResponse<Prepaid>>> {
        const params = this.objectToQueryString(filter);
        return this.get(`${this.endpoint}${params}`);
    }

    public async getPrepaid(prepaidId: number): Promise<AxiosResponse<Prepaid>> {
        return this.get(`${this.endpoint}/${prepaidId}`);
    }

    public async createPrepaid(prepaid: Prepaid): Promise<AxiosResponse<Prepaid>> {
        return this.post(`${this.endpoint}`, prepaid);
    }

    public async updatePrepaid(prepaid: Prepaid): Promise<AxiosResponse<Prepaid>> {
        return this.put(`${this.endpoint}/${prepaid.prepaidId}`, prepaid);
    }

    public async deletePrepaid(prepaidId: number): Promise<AxiosResponse> {
        return this.delete(`${this.endpoint}/${prepaidId}`);
    }
}
