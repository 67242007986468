import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProgressType } from './ProgressArgs';
import ProgressSuccessVue from './components/ProgressSuccess.vue';
import ProgressCelebrateVue from './components/ProgressCelebrate.vue';
import ProgressMailVue from './components/ProgressMail.vue';
import ProgressLoadingVue from './components/ProgressLoading.vue';

@Component({
    components: {
        [ProgressType.Success]: ProgressSuccessVue,
        [ProgressType.Celebrate]: ProgressCelebrateVue,
        [ProgressType.Mail]: ProgressMailVue,
        [ProgressType.Loading]: ProgressLoadingVue,
    },
})
export default class ProgressComponent extends Vue {
    @Prop({ type: String }) public type: ProgressType;
    @Prop({ type: String }) public title?: string;
    @Prop({ type: String }) public text?: string;
    @Prop({ type: Number, default: 3000 }) public duration: number;
    public closing: boolean = false;
    public closingDuration = 250;

    public mounted() {
        if (this.duration > 0 && this.type !== ProgressType.Loading) {
            setTimeout(this.close, this.duration);
        }
    }

    public onClick() {
        if (this.type !== ProgressType.Loading) {
            this.close();
        }
    }

    public close(timeout: number = 0) {
        setTimeout(this.doClose, timeout);
    }

    public doClose() {
        this.closing = true;
        setTimeout(() => {
            this.$emit('close');
        }, this.closingDuration);
    }
}
