import { render, staticRenderFns } from "./organizations-overview.vue?vue&type=template&id=7406c429&"
import script from "./organizations-overview.ts?vue&type=script&lang=js&"
export * from "./organizations-overview.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports