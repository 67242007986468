import { BaseService } from './baseService';
import Vue from 'vue';
import Participant from '@/modules/participants/models/Participant';

export class OrganizationGroupMembershipService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getGroupMembers(organizationId: number, groupId: number): Promise<Participant[]> {
        return new Promise<Participant[]>((resolve, reject) => {
            this.get<Participant[]>(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}/members`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public createGroupMembership(organizationId: number, groupId: number, particpantId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.post<void>(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}/members/${particpantId}`)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public deleteGroupMembership(organizationId: number, groupId: number, participantId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.delete(`${this.endpoint}/organizations/${organizationId}/groups/${groupId}/members/${participantId}`)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }
}
