import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import ProjectFilter from '../models/ProjectFilter';
import { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import BaseProjectOverviewPage from './base-project-overview';

@Component({
    components: {
        'create-project': require('@/modules/projects/components/create-project.vue').default,
    },
} as any)
export default class ProjectsOverviewComponent extends BaseProjectOverviewPage {
    public columns: GridColumnProps[] = [
        { field: 'ProjectName', title: 'Name', cell: this.renderProjectRouterLinkWithBreachCheck, sortable: true },
        { field: 'instrument', title: 'Instrument(s)', cell: this.renderInstruments, sortable: false },
        { field: 'practitioner', title: 'Practitioner', cell: this.renderPractitionerLink, sortable: false },
        { field: 'organization', title: 'Organization', cell: this.renderOrganizationLink, sortable: false },
        { field: 'dateCreated', title: 'Create date', cell: Vue.component('grid-date-display'), width: '125px', sortable: true },
        { field: 'closeDate', title: 'Close date', cell: Vue.component('grid-date-display'), width: '125px', sortable: true },
        { field: 'expirationDate', title: 'Expired on', cell: Vue.component('grid-date-display'), width: ' 125px', sortable: false },
        { field: 'participantCount', title: 'Participants', width: 140, sortable: false },
    ];

    public async created() {
        this.filter = new ProjectFilter(this.defaultFilter);

        this.orderBy.setSort('closeDate', OrderByDirection.Desc);

        this.$store.dispatch('instrumentsStore/fetch');

        this.projectData.projects = new Array();
        await this.getProjects(true);

        this.$store.commit('projectStore/TAB_CHANGED', 0);
    }
}
