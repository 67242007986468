import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        inputModel: {
            organizationType: { required },
        },
    },
} as any)
export default class CompanyTypeInputComponent extends Vue {
    public organizationTypeSettings: any = {
        options: [
            { value: 'forProfit', label: 'For profit' },
            { value: 'nonProfit', label: 'NGO' },
            { value: 'academic', label: 'Academic' },
            { value: 'publicSector', label: 'Public sector' },
        ],
        labelField: 'label',
        valueField: 'value',
        placeholder: 'The type of organization',
    };

    public inputModel: any = {};
    public submitted: boolean = false;

    @Prop({ default: () => '' }) public value: string;

    get realValue(): string {
        return this.value;
    }

    set realValue(value: string) {
        this.$emit('input', value);
    }

    public onSelect(selectedOption: any): void {
        this.$emit('input', selectedOption);
    }

    public getValueObject(input: string) {
        return this.organizationTypeSettings.options.find((x) => {
            return x.value === input;
        });
    }
}
