import { Component } from 'vue-property-decorator';
import BaseProgress from './BaseProgress';
import JSConfetti from 'js-confetti';
import ProgressSuccessVue from './ProgressSuccess.vue';

@Component({
    components: {
        ProgressSuccess: ProgressSuccessVue,
    },
})
export default class ProgressCelebrate extends BaseProgress {
    public async mounted() {
        const jsConfetti = new JSConfetti();
        await jsConfetti.addConfetti();
    }
}
