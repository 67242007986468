import { Component } from 'vue-property-decorator';
import { projectsService, languageService, $router } from '../../main';
import { Project } from '../../models/Project';
import { ParticipantState, SurveyCreate } from './models/participant';
import { ErrorTypes } from '../../models/enums/ErrorTypes';
import { Language } from '../../models/Language';
import BasePage from '../../models/BasePage';
import Participant from '@/modules/participants/models/Participant';
import ExcelService from '@/services/ExcelService';
import { CsvColumn } from '@/components/participant-management/importParticipant';

enum ImportInputModes {
    Overview = 'overview',
    Import = 'import',
    Paste = 'paste',
}

@Component
export default class ImportParticipantComponent extends BasePage {
    public inputModel: string = '';
    public inputMode: ImportInputModes = ImportInputModes.Paste;

    public lineData: ParticipantImport[] = [];

    private readonly delimiter: string = ';';
    private projectId: number;
    private projectData: Project;
    private languages: Language[] = [];

    public async created() {
        const self = this;

        this.projectId = parseInt($router.currentRoute.params.projectId);
        await projectsService.getProject(this.projectId).then((value: Project) => {
            self.projectData = value;
        });

        this.languages = await languageService.getLanguages();

        if (!this.projectData || !this.languages || this.languages.length === 0) {
            this.showError(
                `Some parts of the system aren't correctly loaded. Please refresh the page. If this message continue's to show please contact us via the support button.`,
            );
        }
    }

    public async importLines() {
        const self = this;
        const promises: Array<Promise<any>> = [];

        this.lineData.forEach((x) => {
            const survey = new SurveyCreate(null);
            survey.participant = new Participant(x);
            survey.participant.organizationId = this.projectData.organization.organizationId;

            survey.participant.preferredLanguage = x.language;
            survey.participant.preferredLanguageObject = this.languages.find((l) => {
                return l.languageCode.toLowerCase() === x.language.toLowerCase();
            });

            if (survey.participant.preferredLanguageObject === undefined) {
                survey.participant.preferredLanguage = 'en';
                survey.participant.preferredLanguageObject = this.languages.find((x) => x.languageCode.toLowerCase() === 'en');
            }

            survey.state = ParticipantState.Created;

            promises.push(
                self.$store.dispatch('participantsStore/addParticipant', survey).catch((error) => {
                    if (error === ErrorTypes.DUPLICATE) {
                        alert('A person with this email address was already added to this project.');
                    }
                }),
            );
        });

        await Promise.all(promises).then(async () => {
            this.showSuccess('All lines were imported!');
            await $router.push({ name: 'addSurvey' });
        });
    }

    public fileUploaded(event) {
        // tslint:disable-next-line: no-use-before-declare
        this.loadCSV(event, new ParticipantImport());
    }

    public downloadExample() {
        const exampleColumns = [
            { label: 'emailAddress', placeholder: 'example@address.com'},
            { label: 'firstName', placeholder: 'example firstname'},
            { label: 'lastName', placeholder: 'example lastname'},
            { label: 'language', placeholder: 'Choose one of the following: ' +
                this.languages
                    .map((lang) => {
                        return `${lang.languageCode} for ${lang.languageName}`;
                    })
                    .join(', ')},
            { label: 'phonenumber', placeholder: '<optional>'},

        ]
        this.exportExampleCSV(exampleColumns);
    }

    public async exportExampleCSV(obj: CsvColumn[]): Promise<void> {
        const downloadLink = await new ExcelService().fetchExampleImport(obj);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public loadCSV<T>(e: any, object: T): void {
        const self = this;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                self.processInput(csv, self.delimiter);
            };
            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert('Cannot read file !');
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public processPastedInput(): void {
        this.processInput(this.inputModel, '\t');
    }

    public processInput(input: string, delimiter: string) {
        const properties = [];
        // tslint:disable-next-line: no-use-before-declare
        for (const prop in new ParticipantImport()) {
            properties.push(prop);
        }

        const self = this;
        const lines = input.split('\n');
        const mapping = []; // Once to be used by a mailchimp style importer?

        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let i = 0; i < properties.length; i++) {
            const matchingColumn = headers.findIndex((h) => {
                return h.toLowerCase() === properties[i].toLowerCase();
            });
            if (matchingColumn === -1) {
                alert(`File's not matching the destination format. Missing column: ${properties[i]}`);
            } else {
                mapping.push({ index: matchingColumn, property: properties[i] });
            }
        }

        for (let li = 1; li < lines.length; li++) {
            // Skip first line
            if (lines[li] === '') {
                continue;
            }

            const columns = lines[li].split(delimiter);
            const itemLine = {} as ParticipantImport;

            for (let m = 0; m < mapping.length; m++) {
                const currentMap = mapping[m];
                itemLine[currentMap.property] = columns[currentMap.index];
            }

            if (
                !itemLine.language ||
                itemLine.language === '' ||
                !itemLine.language.match(/^[A-Za-z]+$/) ||
                itemLine.language.length !== 2 ||
                !this.languages.find((l) => {
                    return l.languageCode.toLowerCase() === itemLine.language.toLowerCase();
                })
            ) {
                itemLine.language = 'en';

                this.clearNotifications();
                this.showWarning('One ore more languages are not valid. Defaulting to english.');
            }

            self.lineData.push(itemLine);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
class ParticipantImport {
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public language: string = '';
    public phonenumber: string = '';
}
