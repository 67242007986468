import { organizationGroupMembershipService } from '@/main';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

// tslint:disable: max-classes-per-file
export class GroupedViewMember {
    public link: string = '';
    public name: string = '';

    constructor(member: GroupedViewMember) {
        Object.assign(this, member);
    }
}

export class GridGroupedViewProperties {
    public icon: string = '';
    public detailIcon: string = '';
    public textTemplate: string = '';
    public members: GroupedViewMember[] = [];
    public groupId: number = 0;
    public organizationId: number = 0;

    constructor(props: GridGroupedViewProperties) {
        Object.assign(this, props);
    }
}

@Component({
    components: {
        'dropdownlist-member-name': require('../dropdownlist/member-name').default,
        'kendo-dropdownlist': DropDownList,
    },
})
export default class MembersComponent extends Vue {
    @Prop({ default: [] }) public members: GroupedViewMember[];
    @Prop({ default: '' }) public icon: string;
    @Prop({ default: '' }) public detailIcon: string;
    @Prop({ default: '' }) public textTemplate: string;
    @Prop({ default: true }) public lazy: boolean;
    @Prop({ default: 0 }) public organizationId: number;
    @Prop({ default: 0 }) public groupId: number;

    public propOrientation = 'horizontal';
    public valueRender = 'valueTemplate';
    public itemRender = 'itemTemplate';

    public memberItems: GroupedViewMember[] = [];

    public mounted() {
        if (this.members) {
            this.memberItems = this.members;
        }
    }

    public get dropdownMembers() {
        return this.memberItems;
    }

    public async goToMember(item: any) {
        await this.$router.push(item.dataItem.link);
    }

    public noDataTemplate(): string {
        return '<span>No group members</span>';
    }

    public memberDropdownButtonTemplate() {
        let textTemplate = this.textTemplate;

        textTemplate = textTemplate.replace(/{{groupCount}}/g, this.members.length.toString());

        return textTemplate;
    }

    public async fetchMembersIfNeeded() {
        if (this.members.length === 0) {
            const members = await organizationGroupMembershipService.getGroupMembers(this.organizationId, this.groupId);

            const groupedViewMembers = members.map((m) => {
                return new GroupedViewMember({
                    link: this.$router.resolve({ name: 'participant-details', params: { participantId: m.participantId.toString() } }).href,
                    name: m.firstName + ' ' + m.lastName,
                });
            });

            this.members.push(...groupedViewMembers);
        }
    }

    public async loadMembers() {
        if (this.lazy) {
            await this.fetchMembersIfNeeded();
        }
    }

    public onSelect(e) {
        if (e && e.event) {
            e.event.preventDefault();
            e.event.stopPropagation();
        }

        if (e && e.dataItem && !e.dataItem.disabled) {
            this.goToMember(e);
        }
    }

    public onClose(e) {
        e.event.preventDefault();
        e.event.stopPropagation();
    }
}
