// import { CreateResult } from '@/models/CreateResult';
import { CreateResult } from '@/models/CreateResult';
import { PagedResponse } from '@/models/PagedResponse';
import Account from '@/modules/settings/models/Account';
import AccountFilter from '@/modules/settings/models/AccountFilter';
import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
import Vue from 'vue';

export class PartnerAccountsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}/partners`;

    constructor() {
        super();
    }

    public getAccounts(partnerId: number, filter: Partial<AccountFilter>): Promise<Account[]> {
        return new Promise<Account[]>((resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                    }
                }
            }
            this.get<Account[]>(`${this.endpoint}/${partnerId}/accounts?${params.join('&')}`)
                .then((response: AxiosResponse<Account[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addAccount(partnerId: number, emailAddress: string) {
        return new Promise((resolve, reject) => {
            this.post<CreateResult<Account>>(`${this.endpoint}/${partnerId}/accounts`, {
                emailAddress,
            })
                .then((response: AxiosResponse<CreateResult<Account>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public removeAccount(partnerId: number, accountId: number): Promise<any> {
        return this.delete(`${this.endpoint}/${partnerId}/accounts/${accountId}`);
    }
}
