import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ProgressPlugin from './plugins/Progress/ProgressPlugin';
import SharedUI from '@prd/shared-ui';
import './registerServiceWorker';
import './plugins/directives/sanitize-html';

// Components
import './components/index';
import './modules/index';
import moment from 'moment-timezone';
import VCalendar from 'v-calendar';
import Clipboard from 'v-clipboard';
import VueMoment from 'vue-moment';
import { Component } from 'vue-property-decorator';
import { Quill } from 'vue2-editor';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';
import SideActionsPlugin from './plugins/sideActions/sideActions';
import { EventBus } from './events/EventBus';
import { ErrorHelper } from './helpers/errorHelper';
import { LoginHelper } from './helpers/loginHelper';
import organizationDetailsStore from './modules/organizationDetails/store/organizationDetailsStore';
import participantsStore from './modules/participants/store/participantsStore';
import projectStore from './modules/projects/store/projectStore';
import instrumentDetailsStore from './modules/instruments/store/instrumentDetailsStore';
import instrumentsStore from './modules/instruments/store/instrumentsStore';
import { EmailService } from './services/emailService';
import { InstrumentsService } from './modules/instruments/services/instrumentsService';
import { LanguageService } from './services/languageService';
import { ProjectsService } from './services/projectsService';
import { OrganizationGroupMembershipService } from './services/organizationGroupMembershipService';
import { UserSurveyService } from './services/userSurveyService';
import { SequenceService } from './services/sequenceService';
import { CustomEmailTemplateService } from './services/customEmailTemplateService';
import { ContextOptionService } from './services/contextOptionService';
import he from 'he';
import { findIana } from 'windows-iana';

Component.registerHooks(['beforeRouteLeave']);

Vue.use(SharedUI);
(Vue as any).options.components.PrdGridWrapper.options.props.enablePager.default = false;
Vue.use(VueMoment, { moment });
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VCalendar, {
    firstDayOfWeek: 2,
});
Vue.use(Clipboard);
Vue.use(ProgressPlugin);
Vue.use(SideActionsPlugin);

Vue.config.productionTip = false;

Vue.filter('decode', (value: string) => {
    if (value) {
        return he.decode(value);
    }
    return '';
});

Vue.filter('round', (value: number) => {
    if (value) {
        return Math.round(value * 100) / 100;
    }
    return 0;
});

Vue.filter('timezoneBrowserFormat', (value: string) => {
    if (value) {
        const iana = findIana(value);
        if (iana && iana.length > 0) {
            const guessedTimeZone = moment.tz.guess();
            const browserTimeZone = iana.find((x) => x === guessedTimeZone);

            if (browserTimeZone) {
                return browserTimeZone;
            }

            return iana[0];
        }
    }
    return value;
});

// tslint:disable-next-line: variable-name
window.onerror = (_msg, _url, _lineNo, _columnNo, error) => {
    console.error(error);
};

window.onabort = () => {
    console.log('abort');
};

const store = new Vuex.Store({
    state: {},
    modules: {
        projectStore,
        organizationDetailsStore,
        participantsStore,
        instrumentDetailsStore,
        instrumentsStore,
    },
});

export const eventBus = new EventBus();
export const $router = router;
import LoginService from '@/services/LoginService';
export const loginService = new LoginService({
    endpoint: Vue.$env().IdentityEndpoint,
    localStorageKey: 'instruments-auth',
    unAuthorizedHandler: () => {
        console.log('unauthorized');
    },
});

export const loginHelper = new LoginHelper();

export const projectsService = new ProjectsService();
export const instrumentsService = new InstrumentsService();
export const emailService = new EmailService();
export const languageService = new LanguageService();
export const organizationGroupMembershipService = new OrganizationGroupMembershipService();
export const userSurveyService = new UserSurveyService();
export const sequenceService = new SequenceService();
export const customEmailTemplateService = new CustomEmailTemplateService();
export const contextOptionService = new ContextOptionService();

export const errorHelper = new ErrorHelper(router);

// don't judge.. I'm sorry
const quillClipper = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');
class PlainClipboard extends quillClipper {
    public onPaste(e) {
        e.preventDefault();
        const range = (this as any).quill.getSelection();
        const text = e.clipboardData.getData('text/plain');
        const delta = new Delta().retain(range.index).delete(range.length).insert(text);
        // tslint:disable-next-line: restrict-plus-operands
        const index = text.length + range.index;
        const length = 0;
        (this as any).quill.updateContents(delta, 'user');
        (this as any).quill.setSelection(index, length, 'silent');
        (this as any).quill.scrollIntoView();
    }
}

Quill.register('modules/clipboard', PlainClipboard, true);


new Vue({
    // tslint:disable-next-line: no-string-literal
    data: { loginHelper, environment: window['environment'] },
    router,
    render: (h) => h(App),
    store,
}).$mount('#app');
