import { Language } from '../models/Language';
import { BaseService } from './baseService';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { Translation } from '@/models/Translations';
import to from 'await-to-js';
import TranslationStatus from '@/modules/translations/models/TranslationStatus';
import languages from 'language-list';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';

export class LanguageService extends BaseService {
    public endpoint = `${Vue.$env().ManagementServiceEndpoint}`;
    public translationsEndpoint = `${Vue.$env().ManagementServiceEndpoint}/translations`;

    constructor() {
        super();
    }

    public async addLanguageToInstrument(languageCode: string, instrumentId: number, knowledgeModelId: number): Promise<Language> {
        const [err, response] = await to(this.post(`${this.endpoint}/instruments/${instrumentId}/languages`, { languageCode, knowledgeModelId }));
        if (err) {
            return new Language();
        }

        return new Language(response.data);
    }

    public async removeLanguageFromInstrument(languageCode: string, instrumentId: number) {
        return this.delete(`${this.endpoint}/instruments/${instrumentId}/languages/${languageCode}`);
    }

    public getLanguages(): Language[] {
        const languageList = languages().getData();

        return languageList.map((x) => {
            return new Language({
                languageName: x.language,
                languageCode: x.code,
            });
        });
    }

    public async getLanguagesForInstrument(instrumentId: number): Promise<Language[]> {
        const response = await this.get(`${this.endpoint}/instruments/${instrumentId}/languages`);
        return response.data.map((x) => new Language(x));
    }

    public async getTranslations(knowledgeModelAlias: string, instrumentAlias: string, languageCode: string, section?: string) {
        if (instrumentAlias === 'generic') {
            instrumentAlias = null;
        }

        return new Promise<Translation[]>(async (resolve) => {
            await this.get(
                `${this.translationsEndpoint}/${knowledgeModelAlias}?language_code=${languageCode}${
                    instrumentAlias ? '&instrument_alias=' + instrumentAlias : ''
                }`,
            ).then((langValues: AxiosResponse<Translation[]>) => {
                resolve(langValues.data);
            });
        });
    }

    public async saveTranslation(translation: Translation, knowledgeModelAlias: string, instrumentAlias: string, languageCode: string) {
        if (instrumentAlias === 'generic') {
            instrumentAlias = null;
        }

        const [err] = await to(
            this.put(`${this.translationsEndpoint}/${knowledgeModelAlias}/${translation.id}`, {
                id: translation.id,
                value: translation.value,
            }),
        );
        if (err) {
            return false;
        }

        return true;
    }

    public async deleteTranslation(translation: Translation, knowledgeModelAlias: string, instrumentAlias: string, languageCode: string) {
        if (instrumentAlias === 'generic') {
            instrumentAlias = null;
        }

        const [err] = await to(this.delete(`${this.translationsEndpoint}/${knowledgeModelAlias}/${translation.id}`));
        if (err) {
            return false;
        }

        return true;
    }

    public async getTranslationStatus(knowledgeModelAlias: string, languageCode: string, instrumentAlias?: string): Promise<TranslationStatus> {
        const [err, response] = await to(this.get(`${this.translationsEndpoint}/${knowledgeModelAlias}/status?language_code=${languageCode}`));
        if (err) {
            return new TranslationStatus();
        }

        return new TranslationStatus(response.data);
    }

    public async publishTranslation(
        knowledgeModelAlias: string,
        instrumentAlias: string,
        languageCode: string,
        section: string,
        subSection?: string,
        reportAlias?: string,
    ) {
        const [err] = await to(
            this.post(`${this.translationsEndpoint}/${knowledgeModelAlias}/${instrumentAlias}/publish`, {
                languageCode,
                section,
                subSection,
                reportAlias,
            }),
        );
        if (err) {
            return false;
        }

        return true;
    }

    public async unpublishTranslation(
        knowledgeModelAlias: string,
        instrumentAlias: string,
        languageCode: string,
        section: string,
        subSection?: string,
        reportAlias?: string,
    ) {
        const [err] = await to(
            this.post(`${this.translationsEndpoint}/${knowledgeModelAlias}/${instrumentAlias}/unpublish`, {
                languageCode,
                section,
                subSection,
                reportAlias,
            }),
        );
        if (err) {
            return false;
        }

        return true;
    }

    public async addKnowledgeModelTranslation(translation: Translation, knowledgeModel: KnowledgeModel) {
        const [err] = await to(
            this.post(`${this.translationsEndpoint}/${knowledgeModel.alias}/`, {
                languageCode: translation.language.languageCode,
                instrumentId: translation.instrumentId,
                section: translation.selectedSection,
                subSection: translation.selectedSubSection,
                reportAlias: translation.reportAlias,
                value: translation.value,
                key: translation.key,
            }),
        );
        if (err) {
            return false;
        }

        return true;
    }
}
