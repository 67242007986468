import { Member } from '../models/Member';
import { loginService } from '@/main';
import jwt_decode from 'jwt-decode';

export class LoginHelper {
    public loggedIn: boolean;
    private user: Member = {} as Member;

    constructor() {
        this.loggedIn = false;
    }

    public setUser(user) {
        this.user = user;
    }

    public get decodedToken() {
        const ac = loginService.getAccessToken();
        const token = jwt_decode(ac);

        if (
            !token ||
            !token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] ||
            typeof token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'undefined'
        ) {
            debugger;
            this.logOut();
            return null;
        }

        return token;
    }

    public setTranslatorPermissions() {
        const hasPermission = this.isInRole('Translator');

        if (!this.user.permissions) {
            this.user.permissions = { Translator: hasPermission };
        } else {
            this.user.permissions.Translator = hasPermission;
        }

        return hasPermission;
    }

    public setInvoicePermissions(hasPermissions: boolean) {
        if (!this.user.permissions) {
            this.user.permissions = { DirectInvoicing: hasPermissions };
        } else {
            this.user.permissions.DirectInvoicing = hasPermissions;
        }

        return hasPermissions;
    }

    public hasPermission(permission: string): boolean {
        if (!this.isLoggedIn) {
            return false;
        }

        return (
            this.isInRole('SuperAdmin') ||
            (this.user.permissions && this.user.permissions.hasOwnProperty(permission) === true && this.user.permissions[permission])
        );
    }

    public getUser() {
        if (loginService.isLoggedIn() && (!this.user.id || this.user.id === 0)) {
            const member = new Member(this.user);
            member.email = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
            const nameId = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            if (nameId.length > 1) {
                member.id = nameId[0];
            } else {
                member.id = nameId;
            }
            member.partnerId = parseInt(this.decodedToken['PartnerId']);
            member.practitionerId = parseInt(this.decodedToken['PractitionerId']);

            this.setUser(member);
        }

        return this.user;
    }

    public is2faEnabled(): boolean {
        if (!this.isLoggedIn) {
            return false;
        }

        return (this.decodedToken['amr'] && this.decodedToken['amr'].indexOf('mfa') > -1) || (this.user && this.user.twoFactorAuthEnabled);
    }

    public isLoggedIn(): boolean {
        this.loggedIn = loginService.isLoggedIn();

        return this.loggedIn;
    }

    public logOut(): void {
        loginService.logout();
        location.href = '/';
    }

    public getRole(): string[] {
        if (!this.isLoggedIn) {
            return [];
        }

        return this.decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    }

    public isInRole(role: string): boolean {
        if (!this.isLoggedIn) {
            return false;
        }

        return this.decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].indexOf(role) > -1;
    }

    public getUserId(): string {
        if (!this.isLoggedIn) {
            return '';
        }

        return this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    }

    public getGivenName(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        const names = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        if (Array.isArray(names)) {
            return names[names.length - 1];
        }

        return names;
    }

    public getEmailAddress() {
        if (!this.isLoggedIn) {
            return '';
        }

        const names = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        if (Array.isArray(names)) {
            return names.find((x) => x.indexOf('@') > -1);
        }

        return names;
    }

    public async forgotPassword(email: string): Promise<any> {
        return await loginService.forgotPassword(email);
    }
}
