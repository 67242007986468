import { BaseService } from "./baseService";
import { CsvColumn } from "@/components/participant-management/importParticipant";
import Vue from 'vue';

export default class ExcelService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async fetchExampleImport(columns: CsvColumn[]): Promise<any> {
        if (columns.length < 1) {
            console.error('No example import could be fetched!');
            return;
        }

        const response = await this.post(
            `${this.endpoint}/export`,
            columns,
            null,
            {
                responseType: 'arraybuffer',
            },
        );

        const url = URL.createObjectURL(
            new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ExampleImport`);
        link.style.display = 'none';
        return link;
    }
}