import { loginHelper } from '@/main';
import { PagedResponse } from '@/models/PagedResponse';
import { Project } from '@/models/Project';
import ProjectFilter from '@/modules/projects/models/ProjectFilter';
import { ProjectsService } from '@/services/projectsService';
import to from 'await-to-js';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class ProjectStore extends VuexModule {
    // tslint:disable-next-line: variable-name
    private _currentTab: number = 0;
    private _menuCounters: any[] = [];

    private _projectService: ProjectsService = null;

    public tabs: any[] = [
        { index: 0, slug: 'details', name: 'Details' },
        { index: 1, slug: 'participants', name: 'Participants' },
        { index: 2, slug: 'emails', name: 'Email overview' },
        { index: 3, slug: 'settings', name: 'Automation & Settings' },
        { index: 4, slug: 'demographics', name: 'Demographic data' },
    ];

    @Action
    public async fetchMenuCounters() {
        if (loginHelper.isInRole('Partner')) {
            this.context.commit('SET_PROJECTSERVICE');
            const [err, response] = await to(this._projectService.getProjects(0, 0, true, new ProjectFilter({ uncheckedOnly: true })));
            if (err) {
                return;
            }

            this.context.commit('SET_PROJECTS_MENU_COUNTS', { label: 'unchecked', projects: response.data });
            return;
        } else if (loginHelper.isInRole('Practitioner')) {
            this.context.commit('SET_PROJECTSERVICE');
            const [err, response] = await to(this._projectService.getProjects(0, 0, true, new ProjectFilter({ expiredOnly: true })));
            if (err) {
                return;
            }

            this.context.commit('SET_PROJECTS_MENU_COUNTS', { label: 'expired', projects: response.data });
            return;
        }
    }

    @Mutation
    public SET_PROJECTSERVICE() {
        this._projectService = new ProjectsService();
    }

    @Mutation
    public SET_PROJECTS_MENU_COUNTS(payload: { label: string; projects: PagedResponse<Project> }) {
        this._menuCounters = [
            {
                label: payload.label,
                count: payload.projects.count,
            },
        ];
    }

    @Mutation
    public TAB_CHANGED(newTab: number) {
        this._currentTab = newTab;
    }

    @Mutation
    public TAB_CHANGED_BY_NAME(newTab: string) {
        const foundTab = this.tabs.find((x) => x.slug === newTab);
        if (foundTab) {
            this._currentTab = foundTab.index;
        }
    }

    get currentTab(): number {
        return this._currentTab;
    }

    get currentTabRoute(): string {
        return this.tabs.find((x) => x.index === this._currentTab).slug;
    }

    get menuCounters(): any[] {
        return this._menuCounters;
    }
}
