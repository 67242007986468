import BasePage from '@/models/BasePage';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { AxiosResponse } from 'axios';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { projectsService } from '@/main';
import { PagedResponse } from '@/models/PagedResponse';
import { Project } from '@/models/Project';
import ProjectFilter from '@/modules/projects/models/ProjectFilter';
import he from 'he';

@Component
export default class ProjectsOverviewComponent extends BasePage {
    @Prop({ default: () => 0 }) public organizationId: number;
    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public toggleOrganizationLink: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'ProjectName', title: 'Name', cell: this.renderRouterLink, width: '350px' },
        { field: 'instrument', title: 'Instrument(s)', cell: this.renderInstruments },
        { field: 'practitioner', title: 'Practitioner', width: '225px' },
        { field: 'participantCount', title: 'Participants', width: '125px' },
        { field: 'participantCompletedCount', title: 'Completed', width: '125px' },
        { field: 'startDate', title: 'Launch date', cell: Vue.component('grid-date-display'), width: '125px' },
        { field: 'closeDate', title: 'Close date', cell: Vue.component('grid-date-display'), width: '125px' },
        { field: 'status', title: 'Status', cell: Vue.component('grid-status-display'), width: '125px' },
    ];
    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['name', 'organizationType'],
    });
    public projectData: any = {
        projects: [],
        projectCount: 0,
        skip: 0,
        take: 25,
    };
    public filter = new ProjectFilter();
    public gridKey: string = '';

    public $refs!: {
        projectOverviewGrid: any;
        createProject: BModal;
    };

    public get projectCount(): number {
        return this.projectData.projectCount;
    }

    private filterTimer: number = null;

    public async created() {
        const self = this;
        this.filter.Organization = this.organizationId;
        self.projectData.projects = new Array();

        await self.getProjects(true);
    }

    public async pageChangeHandler(event: GridPageChangeEvent) {
        this.projectData.skip = event.page.skip;
        this.projectData.take = event.page.take;
        await this.getProjects(false);
    }
    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            self.projectData.skip = 0;
            await self.getProjects(true);
        }, 400);
    }

    private renderRouterLink(item: any, _, row): any {
        const projectUrl = this.$router.resolve({ name: 'project-details', params: { projectId: row.dataItem.id.toString() } }).href;

        if (
            row.dataItem._links &&
            row.dataItem._links.length &&
            row.dataItem._links.find(
                (x) =>
                    x.type === 'GET' &&
                    x.rel === 'details' &&
                    !x.attributes.includes('ownership:Owner') &&
                    !x.attributes.includes('ownership:Viewer'),
            )
        ) {
            return item(Vue.component('grid-router-link'), {
                props: {
                    title: he.decode(row.dataItem.name),
                    url: this.$router.resolve({
                        name: 'breach-of-confidence',
                        query: {
                            entity: 'project',
                            owner: `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}`,
                            url: projectUrl,
                        },
                    }).href,
                },
            });
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.name),
                url: projectUrl,
            },
        });
    }

    public async downloadExcel() {
        this.showPending('Exporting data');
        // await exportService.exportData(new ExportInfo(), null, this.organizationId);

        this.clearNotifications();
    }

    private updateCacheKey(): void {
        this.gridKey = new Date().getTime().toString();
    }

    private async getProjects(includeCount: boolean) {
        const self = this;
        self.isLoaded = false;
        await projectsService
            .getProjects(this.projectData.skip, this.projectData.take, includeCount, self.filter)
            .then((value: AxiosResponse<PagedResponse<Project>>) => {
                self.projectData.projects = value.data.items.map((p) => {
                    return {
                        id: p.projectId,
                        name: p.name,
                        startDate: p.startDate,
                        closeDate: p.closeDate,
                        instruments: p.instruments,
                        organization: p.organization.name,
                        practitioner: `${p.practitioner.firstName} ${p.practitioner.lastName}`,
                        participantCount: p.participantCount ? p.participantCount : 0,
                        participantCompletedCount: p.participantCompletedCount ? p.participantCompletedCount : 0,
                        _links: p._links,
                    };
                });

                if (includeCount) {
                    self.projectData.projectCount = value.data.count;
                }

                self.updateCacheKey();
                self.isLoaded = true;
            });
    }

    private renderInstruments(item: any, _: any, row: any): any {
        if (row.dataItem.instruments.length > 1) {
            return item(Vue.component('grid-popover-instruments'), {
                props: {
                    id: row.dataItem.id,
                    title: `Instruments`,
                    instruments: row.dataItem.instruments,
                    text: `${row.dataItem.instruments.length} instruments`,
                },
            });
        }

        return item('td', row.dataItem.instruments[0].name);
    }
}
