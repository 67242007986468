import VueRouter from 'vue-router';

export class ErrorHelper {
    private _router: VueRouter;

    constructor(router: VueRouter) {
        this._router = router;
    }

    public async throwError(message: string, returnUrl?: string) {
        let url = `/error?message=${message}`;

        if (returnUrl !== undefined) {
            url = `${url}&returnUrl=${returnUrl}`;
        }

        await this._router.push(url);
    }
}
