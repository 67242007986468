import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import ChangeDemographicQuestionParameters from './changeDemographicQuestionParameters';
import to from 'await-to-js';
import { required } from 'vuelidate/lib/validators';
import { DemoGraphicItem } from '@/models/DemographicItem';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import { OrganizationsService } from '@/services/organizationsService';

@Component({
    validations: {
        question: {
            question: { required },
            label: { required },
            answers: { required },
        },
    },
} as any)
export default class ChangeDemographicQuestionActionComponent extends SideActionBase<ChangeDemographicQuestionParameters> {
    public question: DemoGraphicItem = new DemoGraphicItem();
    public tabIndex: number = 0;
    public submitted: boolean = false;
    public otherSuffix: boolean = false;

    public questionTypes: any[] = [
        // { value: 'score', label: 'Score', id: 0 },
        { value: 'choice', label: 'Choice', id: 1 },
        // { value: 'arrangeOrder', label: 'Arrange order', id: 2 },
        // { value: 'percentage', label: 'Percentage', id: 3 },
        // { value: 'allThatApply', label: 'All that apply', id: 4 },
        { value: 'openQuestion', label: 'Open question', id: 5 },
    ];

    public questionViews: any[] = [
        { id: 0, value: 'unknown', label: 'Default' },
        // { id: 1, value: 'slider', label: 'Slider' },
        { id: 2, value: 'dropdown', label: 'Dropdown' },
        // { id: 3, value: 'imageCheckbox', label: 'Image checkbox' },
        { id: 4, value: 'otherOptionWithOpenText', label: 'Radio buttons with [other] option combined with an open text field', questionTypes: ['choice'] },
    ];

    public selectedQuestionType: any = null;
    public selectedQuestionView: any = { value: 'unknown', label: 'Default', id: 0 };
    public answers: any[] = [];
    public organizationGroupService: OrganizationGroupService = new OrganizationGroupService();
    public organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        this.question = { label: '', question: '', answers: [], enabled: true, order: this.options.order + 1 } as DemoGraphicItem;
        this.answers = this.answerObjects();
    }

    public async createQuestion() {
        this.showPending('Adding question...');

        this.question.answers = this.answers.filter((x) => x.value !== '').map((x) => x.value);
        this.question.questionType = this.selectedQuestionType.value;
        this.question.view = this.selectedQuestionView.id > 0 ? this.selectedQuestionView.value : null;

        const [err, response] = await to(
            this.organizationsService.addQuestionToDemographicModel(this.options.demographicId, this.options.organizationId, this.question),
        );
        if (err) {
            return this.clearAndShowError(err, err);
        }

        return this.finished(response.data);
    }

    public answerObjects() {
        return this.question.answers.map((x, index) => {
            return {
                value: x,
                index,
            };
        });
    }

    public deleteAnswer(index) {
        this.answers.splice(index, 1);
    }

    public addAnswer() {
        this.answers.push({ value: '', index: this.answers.length });
    }
}
