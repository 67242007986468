import PageRender from '@/models/PageRender';
import { ProjectCreate } from '@/models/Project';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CreateProjectForGroupComponent extends PageRender {
    @Prop({ default: null }) public projectCreate: ProjectCreate;
    @Prop({ default: null }) public groupId: number;
    @Prop({ default: null }) public disabledButton: boolean;

    public openCreateProjectForGroupAction(): any {
        if (this.disabledButton) {
            return;
        }
        this.$sideActions.push('create-project-for-group-action', { projectCreate: this.projectCreate, groupId: this.groupId });
    }
}
