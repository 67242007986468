import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { BModal } from 'bootstrap-vue';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import BasePage from '@/models/BasePage';
import Instrument from '../Models/Instrument';

@Component
export default class InstrumentsOverviewComponent extends BasePage {
    get projectCount(): number {
        return this.instrumentsData.projectCount;
    }

    get instruments(): Instrument[] {
        return this.instrumentsData.instruments;
    }

    @Prop({ default: null }) public partnerId?: number;
    @Prop({ default: null }) public actions: any[];

    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'name', title: 'Name (alias)', cell: this.renderRouterLink },
        { field: 'knowledgeModel', title: 'Knowledge model', cell: this.renderRouterLinkKnowledgeModel },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['name', 'organizationType'],
    });

    public $refs!: {
        projectOverviewGrid: any;
        createInstrument: BModal;
    };

    public instrumentsData: any = {
        instruments: [],
        projectCount: 0,
        skip: 0,
        take: 25,
    };

    public async instrumentCreatedCallback(instrumentData: Instrument) {
        await this.$router.push({ name: 'instrument-details', params: { instrumentId: instrumentData.instrumentId.toString() } });
    }

    public async created() {
        await this.getInstruments(true);

        this.isLoaded = true;
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.name} (${row.dataItem.alias})`,
                url: this.$router.resolve({ name: 'instrument-details', params: { instrumentId: row.dataItem.instrumentId.toString() } }).href,
            },
        });
    }

    public renderRouterLinkKnowledgeModel(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.knowledgeModelName,
                url: this.$router.resolve({ name: 'knowledge-model-details', params: { knowledgeModelId: row.dataItem.knowledgeModelId.toString() } })
                    .href,
            },
        });
    }

    public renderRelatedInstrument(item: any, _, row): any {
        const name = this.instruments.find((x) => x.instrumentId === row.dataItem.associatedIndividualInstrumentId).name;
        return item('td', name);
    }

    public async refreshData() {
        this.instrumentsData.skip = 0;
        this.instrumentsData.take = 25;
        await this.getInstruments(true);
    }

    public openCreateInstrumentAction(): any {
        this.$sideActions.push('instrument-create-action', { partnerId: this.partnerId }, async () => {
            await this.refreshData();
        });
    }

    private renderActions(item: any, _, row): any {
        const actions = [
            {
                title: 'Copy instrument',
                function: this.showCopyInstrumentAction,
            },
        ];

        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    private showCopyInstrumentAction(item: Instrument): any {
        this.$sideActions.push('instrument-copy-action', { instrumentToCopy: item }, async () => {
            await this.$store.dispatch('instrumentsStore/cleanup');
            await this.getInstruments(true);
        });
    }

    private async getInstruments(includeCount: boolean) {
        this.isLoaded = false;

        await this.$store.dispatch('instrumentsStore/fetch');
        const instruments = this.$store.getters['instrumentsStore/instruments'];

        this.instrumentsData.instruments = instruments
            .map((p) => {
                if (includeCount) {
                    this.instrumentsData.projectCount++;
                }
                return {
                    instrumentId: p.instrumentId,
                    name: p.name,
                    knowledgeModelId: p.knowledgeModelId,
                    knowledgeModelName: p.knowledgeModelName,
                    alias: p.alias,
                };
            })
            .filter((p) => {
                return p !== undefined;
            })
            .sort((a, b) => (b.knowledgeModelId > a.knowledgeModelId ? -1 : 1));

        this.isLoaded = true;
    }
}
