import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import Question from '../Models/Question';
import { InstrumentsService } from '../services/instrumentsService';
import { Translation } from '@/models/Translations';
import { languageService } from '@/main';

@Component({
    validations: {
        question: {},
    },
} as any)
export default class AddQuestionActionComponent extends SideActionBase<any> {
    public question: Question = new Question();
    public translations: Translation[] = [];
    public tabIndex: number = 0;
    public submitted: boolean = false;
    public otherSuffix: boolean = false;
    public knowledgeModel: KnowledgeModel = null;

    public questionTypes: any[] = [
        { value: 'score', label: 'Score', id: 0 },
        { value: 'choice', label: 'Choice', id: 1 },
        { value: 'arrangeOrder', label: 'Arrange order', id: 2 },
        { value: 'percentage', label: 'Percentage', id: 3 },
        { value: 'allThatApply', label: 'All that apply', id: 4 },
        { value: 'openQuestion', label: 'Open question', id: 5 },
    ];

    public questionViews: any[] = [
        { id: 0, value: 'unknown', label: 'Default' },
        { id: 1, value: 'slider', label: 'Slider' },
        { id: 2, value: 'dropdown', label: 'Dropdown' },
        { id: 3, value: 'imageCheckbox', label: 'Image checkbox' },
        {
            id: 4,
            value: 'otherOptionWithOpenText',
            label: 'Radio buttons with [other] option combined with an open text field',
            questionTypes: ['choice'],
        },
    ];

    public test: any = {
        value: 'unknown',
        label: 'Default',
    };

    public selectedQuestionType: any = this.questionTypes[0];
    public selectedQuestionView: any = { value: 'unknown', label: 'Default', id: 0 };
    public answers: any[] = [];
    private instrumentService: InstrumentsService = new InstrumentsService();
    private instrumentId: number = 0;

    public get questionTranslation() {
        const translation = this.translations.find((x) => x.key === `QUESTION_${this.question.position}`);
        return translation ? translation.value : `N/A`;
    }

    public get hasQuestionTranslated() {
        return !this.translations.find((x) => x.key === `QUESTION_${this.question.position}`);
    }

    public async created() {
        this.instrumentId = parseInt(this.$route.params.instrumentId);

        this.question.position = this.options.position;
        this.knowledgeModel = this.options.knowledgeModel;

        await this.loadTranslations();
    }

    public async createQuestion() {
        this.showPending('Adding question...');

        this.question.answers = this.answers.filter((x) => x.optionAlias !== '');
        this.question.questionType = this.selectedQuestionType.value;
        this.question.view = this.selectedQuestionView.id > 0 ? this.selectedQuestionView.value : null;
        this.question.optionCount = this.answers.length;

        const [err, question] = await to(this.instrumentService.addQuestion(this.instrumentId, this.question));
        if (err) {
            return this.clearAndShowError(err, err);
        }

        this.clearNotifications();
        return this.finished(question);
    }

    public buildAnswerObjects() {
        const answers = [];

        const optionAliasPrefix = `ANSWER_QUESTION_${this.question.position}_OPTION_`;
        switch (this.selectedQuestionType.value) {
            case 'choice':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `${optionAliasPrefix}${i + 1}`,
                        order: i,
                        translation: this.translations.find((x) => x.key === `${optionAliasPrefix}${i + 1}`),
                    });
                }
                break;
            case 'allThatApply':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `${optionAliasPrefix}${i + 1}`,
                        order: i + 1,
                        translation: this.translations.find((x) => x.key === `${optionAliasPrefix}${i + 1}`),
                    });
                }
                break;
            case 'arrangeOrder':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `${optionAliasPrefix}${i + 1}`,
                        order: i + 1,
                        translation: this.translations.find((x) => x.key === `${optionAliasPrefix}${i + 1}`),
                    });
                }
                break;
            case 'openQuestion':
                break;
            case 'percentage':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `${optionAliasPrefix}${i + 1}`,
                        order: i + 1,
                        translation: this.translations.find((x) => x.key === `${optionAliasPrefix}${i + 1}`),
                    });
                }
                break;
            case 'score':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `ANSWER_${i + 1}`,
                        order: i,
                        translation: this.translations.find((x) => x.key === `ANSWER_${i + 1}`),
                    });
                }
                break;
            default:
                break;
        }

        return (this.answers = answers);
    }

    public deleteAnswer(index) {
        this.answers.splice(index, 1);
        this.question.optionCount--;
    }

    public addAnswer() {
        this.question.optionCount++;
        let optionAliasPrefix = 'ANSWER_';
        if (
            this.selectedQuestionType.value === 'choice' ||
            this.selectedQuestionType.value === 'arrangeOrder' ||
            this.selectedQuestionType.value === 'allThatApply' ||
            this.selectedQuestionType.value === 'percentage'
        ) {
            optionAliasPrefix = `ANSWER_QUESTION_${this.question.position}_OPTION_`;
        }

        this.answers.push({
            optionAlias: `${optionAliasPrefix}${this.answers.length + 1}`,
            order: this.answers.length,
            translation: this.translations.find((x) => x.key === `${optionAliasPrefix}${this.answers.length + 1}`),
        });
    }

    public addQuestionTranslationKey() {
        this.$sideActions.push(
            'add-translation-action',
            {
                knowledgeModel: this.knowledgeModel,
                instrumentId: this.instrumentId,
                section: 1,
                subSection: 1,
                key: `QUESTION_${this.question.position}`,
            },
            async () => {
                await this.loadTranslations();
            },
        );
    }

    public addAnswerTranslationKey(key: string) {
        this.$sideActions.push(
            'add-translation-action',
            { knowledgeModel: this.knowledgeModel, instrumentId: this.instrumentId, section: 1, subSection: 2, key },
            async (translation) => {
                await this.loadTranslations();
                this.answers.find((x) => x.optionAlias === key).translation = translation;
            },
        );
    }

    public async loadTranslations() {
        const instruments = this.$store.getters['instrumentsStore/instruments'];
        const instAlias = instruments.find((x) => x.instrumentId === this.instrumentId).instrumentAlias;

        const [err, translations] = await to(languageService.getTranslations(this.knowledgeModel.alias, instAlias, 'en'));
        if (err) {
            this.showError('The translation are not retrieved due to a error');
            return;
        }
        return (this.translations = translations);
    }
}
