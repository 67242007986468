import Instrument from '@/modules/instruments/Models/Instrument';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridPopOverInstrumentComponent extends Vue {
    @Prop() public instruments: Instrument[];
    @Prop() public title: string;
    @Prop() public text: string;
    @Prop() public id: number;
}
