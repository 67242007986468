import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import ChangeQuestionParameters from '../Models/ChangeQuestionParameters';
import Question from '../Models/Question';
import { Translation } from '@/models/Translations';
import QuestionAnswer from '../Models/QuestionAnswer';
import { InstrumentsService } from '../services/instrumentsService';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { languageService } from '@/main';

@Component({
    validations: {
        question: {},
    },
} as any)
export default class ChangeQuestionActionComponent extends SideActionBase<ChangeQuestionParameters> {
    public $refs!: {
        areYouSureModal: areYouSureModalVue;
    };
    public question: Question;
    public translations: Translation[] = [];
    public isInstrumentPublished: boolean = false;
    public knowledgeModel: KnowledgeModel = null;
    public instrumentId: number = null;

    public questionTypes: any[] = [
        { value: 'score', label: 'Score', id: 0 },
        { value: 'choice', label: 'Choice', id: 1 },
        { value: 'arrangeOrder', label: 'Arrange order', id: 2 },
        { value: 'percentage', label: 'Percentage', id: 3 },
        { value: 'allThatApply', label: 'All that apply', id: 4 },
        { value: 'openQuestion', label: 'Open question', id: 5 },
    ];

    public questionViews: any[] = [
        { id: 0, value: 'unknown', label: 'Default', questionTypes: ['choice'] },
        { id: 1, value: 'slider', label: 'Slider', questionTypes: ['percentage'] },
        { id: 2, value: 'dropdown', label: 'Dropdown', questionTypes: ['choice'] },
        { id: 3, value: 'imageCheckbox', label: 'Image checkbox', questionTypes: ['choice'] },
        {
            id: 4,
            value: 'otherOptionWithOpenText',
            label: 'Radio buttons with [other] option combined with an open text field',
            questionTypes: ['choice'],
        },
    ];

    public selectedQuestionType: any = null;
    public selectedQuestionView: any = { value: 'unknown', label: 'Default', id: 0 };

    public tabIndex: number = 0;
    public answers: QuestionAnswer[] = [];
    public submitted: boolean = false;
    public otherSuffix: boolean = false;
    private _answers: QuestionAnswer[] = [];

    public get questionTranslation() {
        const translation = this.translations.find((x) => x.key === `QUESTION_${this.question.position}`);
        return translation ? translation.value : `N/A`;
    }

    public get hasQuestionTranslated() {
        return !this.translations.find((x) => x.key === `QUESTION_${this.question.position}`);
    }

    // public initialNumberOfAnswers: number = 0;
    private instrumentService: InstrumentsService = new InstrumentsService();

    public async created() {
        this.instrumentId = parseInt(this.$route.params.instrumentId);
        this.question = this.options.question;
        this.translations = this.options.translations;
        this.isInstrumentPublished = this.options.isInstrumentPublished;

        this._answers = this.options.question.answers;
        this.selectedQuestionType = this.questionTypes.find((x) => x.value === this.options.question.questionType);
        const currentQuestionView = this.questionViews.find((x) => x.value === this.options.question.view);

        this.selectedQuestionView = currentQuestionView ? currentQuestionView : this.selectedQuestionView;

        this.knowledgeModel = this.options.knowledgeModel;
        await this.reloadTranslations();

        this.answers = this.answerObjects();
    }

    public async updateQuestion() {
        this.showPending('Changing question...');

        this.question.answers = this.answers.filter((x) => x.optionAlias !== '');
        this.question.questionType = this.selectedQuestionType.value;
        this.question.view = this.selectedQuestionView.id > 0 ? this.selectedQuestionView.value : null;

        const [err, question] = await to(this.instrumentService.updateQuestion(this.options.question.instrumentId, this.question));
        if (err) {
            return this.clearAndShowError('Failed to update question!', err);
        }

        this.clearNotifications();
        return this.finished(question);
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            [this.question],
            'label',
            'question',
            false,
            'Are you sure you want to delete this question? This action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    public async deleteQuestion() {
        this.showPending('Deleting question..');
        this.$refs.areYouSureModal.hide();

        const [err, response] = await to(this.instrumentService.deleteQuestion(this.options.question.instrumentId, this.question));
        if (err) {
            return this.clearAndShowError('Failed to delete question!', err);
        }

        if (!response) {
            return this.clearAndShowError('Question has been answered, so it can not be deleted!', err);
        }

        this.clearAndShowSuccess('Question has been deleted.');
        return this.finished(response);
    }

    public deleteAnswer(index: number) {
        this.answers.splice(index, 1);
    }

    public answerObjects() {
        const answers = [];

        switch (this.selectedQuestionType.value) {
            case 'choice':
                this._answers.forEach((answer, index) => {
                    answers.push({
                        answerId: answer.answerId,
                        optionAlias: answer.optionAlias,
                        order: index,
                        translation: this.translations.find((x) => x.key === answer.optionAlias),
                    });
                });
                break;
            case 'allThatApply':
                this._answers.forEach((answer, index) => {
                    answers.push({
                        answerId: answer.answerId,
                        optionAlias: answer.optionAlias,
                        order: index,
                        translation: this.translations.find((x) => x.key === answer.optionAlias),
                    });
                });
                break;
            case 'arrangeOrder':
                this._answers.forEach((answer, index) => {
                    answers.push({
                        answerId: answer.answerId,
                        optionAlias: answer.optionAlias,
                        order: index,
                        translation: this.translations.find((x) => x.key === answer.optionAlias),
                    });
                });
                break;
            case 'openQuestion':
                break;
            case 'percentage':
                this._answers.forEach((answer, index) => {
                    answers.push({
                        answerId: answer.answerId,
                        optionAlias: answer.optionAlias,
                        order: index,
                        translation: this.translations.find((x) => x.key === answer.optionAlias),
                    });
                });
                break;
            case 'score':
                for (let i = 0; i < this.question.optionCount; i++) {
                    answers.push({
                        optionAlias: `ANSWER_${i + 1}`,
                        order: i,
                        translation: this.translations.find((x) => x.key === `ANSWER_${i + 1}`),
                    });
                }
                break;
            default:
                break;
        }

        return (this.answers = answers);
    }

    public addAnswer() {
        let optionAliasPrefix = 'ANSWER_';
        if (
            this.selectedQuestionType.value === 'choice' ||
            this.selectedQuestionType.value === 'arrangeOrder' ||
            this.selectedQuestionType.value === 'allThatApply' ||
            this.selectedQuestionType.value === 'percentage'
        ) {
            optionAliasPrefix = `ANSWER_QUESTION_${this.question.position}_OPTION_`;
        }

        this.answers.push({ optionAlias: `${optionAliasPrefix}${this.answers.length + 1}`, order: this.answers.length });
    }

    public addQuestionTranslationKey() {
        this.$sideActions.push(
            'add-translation-action',
            {
                knowledgeModel: this.knowledgeModel,
                instrumentId: this.instrumentId,
                section: 1,
                subSection: 1,
                key: `QUESTION_${this.question.position}`,
            },
            async () => {
                await this.reloadTranslations();
            },
        );
    }

    public addAnswerTranslationKey(key: string) {
        this.$sideActions.push(
            'add-translation-action',
            { knowledgeModel: this.knowledgeModel, instrumentId: this.instrumentId, section: 1, subSection: 2, key },
            async () => {
                await this.reloadTranslations();
                this.answerObjects();
            },
        );
    }

    public async reloadTranslations() {
        const instruments = this.$store.getters['instrumentsStore/instruments'];
        const instAlias = instruments.find((x) => x.instrumentId === this.instrumentId).instrumentAlias;

        const [err, translations] = await to(languageService.getTranslations(this.knowledgeModel.alias, instAlias, 'en'));
        if (err) {
            this.showError('The translation are not retrieved due to a error');
            return;
        }
        return (this.translations = translations);
    }
}
