import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { email, required } from 'vuelidate/lib/validators';
import { projectsService } from '@/main';
import to from 'await-to-js';
import { CreationStatus } from '@/models/CreateResult';
import Practitioner from '@/modules/practitioners/models/Practitioner';

@Component({
    components: {
        'practitioner-multiselect': require('@/components/practitioner/practitioner-multiselect.vue').default,
    },
    validations: {
        email: { required, email },
    },
} as any)
export default class ProjectShareActionComponent extends SideActionBase<ProjectShareOptions> {
    public submitted: boolean = false;
    public email: string = '';
    public addPractitioner: Practitioner = null;

    public async shareProject() {
        this.showPending('Sharing project..');

        const [err, response] = await to(projectsService.share(this.options.projectId, this.email));
        if (err) {
            return this.showFailedResponse('Failed to share project', err);
        }

        if (response && response.creationStatus === CreationStatus.failed) {
            return this.showError(response.reason);
        }
        this.clearNotifications();
        this.finished(response.entity);
    }

    public practitionerChanged() {
        this.email = this.addPractitioner.emailAddress;
    }

    public emailChanged() {
        this.addPractitioner = null;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectShareOptions {
    public projectId: number;
    public practitioners?: Practitioner[]

    constructor(actionParams: ProjectShareOptions) {
        Object.assign(this, actionParams);
    }
}
