import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class AreYouSureModal extends BaseModal {
    public value: string = '';
    public entityName: string = '';
    public itemsToDelete: any[] = [];
    public propertyToCheck: string = '';
    public singleDelete: boolean = true;
    public error: boolean = false;
    public text: string = null;

    public get isSingleDelete() {
        return this.singleDelete && this.propertyToCheck && this.propertyToCheck.length;
    }

    public get itemToDelete() {
        if (this.itemsToDelete.length) {
            return this.itemsToDelete[0];
        }

        return {};
    }

    public setScope(items: any[], property: string, entityName: string, single: boolean = true, text?: string) {
        this.itemsToDelete = items;
        this.propertyToCheck = property;
        this.entityName = entityName;
        this.singleDelete = single;
        this.text = text;
    }

    public async deleteEntity() {
        if (this.singleDelete && this.propertyToCheck) {
            if (!this.itemToDelete.hasOwnProperty(this.propertyToCheck)) {
                return (this.error = true);
            }

            if (this.itemToDelete[this.propertyToCheck] !== this.value) {
                return (this.error = true);
            }

            this.$emit('delete-entity', [this.itemToDelete]);
        } else {
            if (this.value !== 'DELETE') {
                return (this.error = true);
            }

            this.$emit('delete-entity', this.itemsToDelete);
        }

        this.onHide();
    }

    public onHide() {
        this.itemsToDelete = [];
        this.propertyToCheck = '';
        this.entityName = '';
        this.error = false;
        this.value = '';
    }
}
