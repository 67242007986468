import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridPopOverDateComponent extends Vue {
    @Prop() public date: Date;
    @Prop() public title: string;
    @Prop() public text: string;
    @Prop() public id: number;

    public model: Date = null;

    public async mounted() {
        this.model = this.date;
    }
}
