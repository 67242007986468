export class PricingPlan {
    public pricingPlanId: number = 0;
    public pricingPlanName: string = '';
    public pricingPlanAmount: number = 0;

    constructor(pricingPlan?: Partial<PricingPlan>) {
        Object.assign(this, pricingPlan);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class PricingPlanCreate {
    public pricingPlanName: string = '';
    public pricingPlanAmount: number = 0;

    constructor(pricingPlan?: any) {
        (Object as any).assign(this, pricingPlan);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class PricingPlanUpdate {
    public pricingPlanName: string = '';
    public pricingPlanAmount: number = 0;

    constructor(pricingPlan?: any) {
        (Object as any).assign(this, pricingPlan);
    }
}
