import { projectsService } from '@/main';
import PageRender from '@/models/PageRender';
import { Project } from '@/models/Project';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import { to } from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RetestProjectComponent extends PageRender {
    @Prop({ default: null }) public project: Project;

    get timeTillNextIteration() {
        if (this.project.sequence) {
            return this.nextIntervalDate(this.project.sequence.startDate);
        }

        return null;
    }

    public async openRetestProjectAction() {
        await this.$store.dispatch('participantsStore/cleanup');

        this.showPending('Fetching participants...');
        const persons = await this.loadParticipants();
        const uniquePersons = this.getUniqueItemsByProperties(persons, ['participantId']);

        this.clearNotifications();

        this.$sideActions.push('retest-project-action', { project: this.project, persons: uniquePersons }, async (newProjectId) => {
            await this.$router.push({ name: 'addSurvey', params: { projectId: newProjectId } });
        });
    }

    private isPropValuesEqual = (subject, target, propNames) => propNames.every((propName) => subject[propName] === target[propName]);

    private getUniqueItemsByProperties(items, propNames) {
        const propNamesArray = Array.from(propNames);

        return items.filter(
            (item, index, array) => index === array.findIndex((foundItem) => this.isPropValuesEqual(foundItem, item, propNamesArray)),
        );
    }

    private nextIntervalDate(date: Date): Date {
        const now = new Date();
        if (now > date) {
            date.setTime(date.getTime() + this.project.sequence.interval * 60 * 60 * 1000);
            return this.nextIntervalDate(date);
        } else {
            return date;
        }
    }

    private async loadParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.project.projectId,
                    skip: 0,
                    take: 99999,
                    $count: true,
                }),
            ),
        );

        if (err) {
            this.showError('Failed to load participants');
        }

        return response.items.map((x) => new ProjectParticipant(x));
    }
}
