import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { languageService } from '../../main';
import EmailTemplate from '../../modules/emailTemplates/models/emailTemplate';
import { Language } from '../../models/Language';

@Component
export default class EmailEditComponent extends Vue {
    @Prop() public templates: EmailTemplate[];
    @Prop() public placeholders: any;
    @Prop() public disabled: boolean;

    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    private languages: Language[] = [];
    private timeout: any;

    public onDataChange(index) {
        if (index != null) {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.updateTemplateContent(index);
            }, 400);
        }
    }

    public async created() {
        this.languages = await languageService.getLanguages();
    }

    public tabChange(index: any): void {
        this.templates.forEach((x) => (x.active = false));
        if (this.templates[index]) {
            this.templates[index].active = true;
        }
        this.$emit('tabChanged', index);
    }

    public getLanguageNameByLanguageCode(code: string): any {
        const language = this.languages.find((l) => l.languageCode === code);
        return language ? language.languageName : '';
    }

    public updateTemplateContent(index) {
        this.$emit('templateContentChanged', [this.templates[index]]);
    }

    public destroyed() {
        this.$emit('templateContentChanged', this.templates);
    }
}
