import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import ChangeDemographicQuestionParameters from './changeDemographicQuestionParameters';
import to from 'await-to-js';
import { required } from 'vuelidate/lib/validators';
import { DemoGraphicItem } from '@/models/DemographicItem';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import { OrganizationsService } from '@/services/organizationsService';
import Organization from '@/modules/organizations/models/Organization';
import DemoGraphicModel from '@/models/DemoGraphicModel';

@Component({
    validations: {
        question: {
            question: { required },
            label: { required },
            answers: { required },
        },
    },
} as any)
export default class ChangeDemographicQuestionActionComponent extends SideActionBase<ChangeDemographicQuestionParameters> {
    public tabIndex: number = 0;
    public submitted: boolean = false;

    public organizations: any[] = [];
    public selectedOrganization: Organization = null;
    public demographicModel: DemoGraphicModel = null;

    public organizationGroupService: OrganizationGroupService = new OrganizationGroupService();
    public organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        await this.getOrganizations();
    }

    public async copyDemographics() {
        this.showPending('Copying demographics...');

        for (let i = 0; i < this.demographicModel.questions.length; i++) {
            const question = this.demographicModel.questions[i];
            await this.addDemographicQuestion(question);
        }

        return this.finished();
    }

    public async addDemographicQuestion(question: DemoGraphicItem) {
        const [err] = await to(
            this.organizationsService.addQuestionToDemographicModel(this.options.demographicId, this.options.organizationId, question),
        );
        if (err) {
            return this.clearAndShowError(err, err);
        }
    }

    public async loadDemographicModel(organization: Organization) {
        const [err, response] = await to(this.organizationsService.getDemographicModel(organization.organizationId));

        if (err) {
            return this.clearAndShowError('Error loading demographic model', err);
        }

        this.demographicModel = response.data;
    }

    private async getOrganizations() {
        await this.organizationsService
            .getOrganizations({ skip: 0, take: 99999, embedOptions: true })
            .then((organizations) => {
                this.organizations = organizations.items.filter((x) => x.organizationId !== this.options.organizationId) as any;
            })
            .catch(() => {
                this.showError(
                    `The countries couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}
