export default class Filter {
    public getQueryString() {
        const queryString = [];
        Object.keys(this).forEach((prop: string) => {
            if (this[prop] === null || this[prop] === undefined) {
                return;
            }

            if (prop === 'searchOptions') {
                Object.keys(this[prop])
                    .filter((key) => this[prop][key])
                    .forEach((option) => {
                        queryString.push(`${prop}=${option}`);
                    });
            }

            if (typeof this[prop] === 'string' || typeof this[prop] === 'boolean' || typeof this[prop] === 'number') {
                return queryString.push(`${prop}=${this[prop]}`);
            }

            if (Array.isArray(this[prop]) && this[prop].length > 0) {
                this[prop].forEach((id: number) => {
                    queryString.push(`${prop}=${id}`);
                });
            }
        });
        return queryString.join('&');
    }
}
