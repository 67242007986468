import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { EmailSendStatus } from '@/models/enums/EmailSendStatus';
import { EmailScheduleService } from '@/services/emailScheduleService';
import PageRender from '@/models/PageRender';

export class EmailOverviewItem {
    public emailId: number;
    public sendStatus: EmailSendStatus | string;
    public recipientAddress: string;
    public subject: string;
    public date: Date;
    public scheduledAt: Date;
    public surveyId: number;
    public participantId: number;
    public recipientName: string;

    constructor(obj?: Partial<EmailOverviewItem>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable: max-classes-per-file
@Component
export default class EmailOverviewComponent extends PageRender {
    @Prop({ default: null }) public projectId: number;

    public loading: boolean = true;
    public emails: EmailOverviewItem[] = [];

    public columns: GridColumnProps[] = [
        { field: 'sendStatus', title: 'Status', cell: this.renderEmailStatus, width: 150 },
        { field: 'subject', title: 'Subject' },
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'recipientAddress', title: 'Email address' },
        { field: 'date', title: 'Date added', cell: this.renderEmailDate },
        { field: 'scheduledAt', title: 'Date scheduled/sent', cell: this.renderDateScheduled, headerCell: this.renderDateScheduledHeader },
        { field: 'actions', title: 'Actions', cell: this.renderActions, width: 100 },
    ];

    private emailScheduleService: EmailScheduleService = new EmailScheduleService();

    public async mounted() {
        await this.loadEmails();
    }

    public renderEmailDate(item: any, _, row: any): any {
        return item(Vue.component('grid-date-display'), { props: { date: row.dataItem.date } });
    }

    public renderDateScheduled(item: any, _, row: any): any {
        return item(Vue.component('grid-date-display'), { props: { date: row.dataItem.scheduledAt, showTime: true } });
    }

    public renderRouterLink(item: any, _, row: any): any {
        if (!row.dataItem.participantId) {
            return item('td', '');
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.recipientName}`,
                url: this.$router.resolve({
                    name: 'participant-details',
                    params: { participantId: row.dataItem.participantId.toString() },
                }).href,
            },
        });
    }

    public renderEmailStatus(item: any, _, row: any): any {
        return item(Vue.component('grid-email-status-label'), {
            props: {
                status: row.dataItem.sendStatus,
            },
        });
    }

    public async refresh() {
        this.loadEmails();
    }

    public async removeMailFromQueue(mail: EmailOverviewItem) {
        const result = await this.emailScheduleService.removeMailFromQueue(this.projectId, mail.emailId);
        if (result) {
            this.showSuccess('Mail removed from queue');
        } else {
            this.showError('Failed to remove mail from queue');
        }

        return this.refresh();
    }

    private renderDateScheduledHeader(h, _, props) {
        return h('grid-header-tooltip', { props: { tooltip: 'Dates are shown in your timezone.' } }, props.title);
    }

    private renderActions(h, _, row) {
        const email = new EmailOverviewItem(row.dataItem);
        const actions = [];
        if (email.sendStatus === 'queued' && (email.surveyId || email.participantId)) {
            actions.push({
                title: 'Remove from queue',
                function: (item) => {
                    this.removeMailFromQueue(item);
                },
            });
        }

        if (actions && actions.length > 0) {
            const props = { actions, item: email };
            return h(Vue.component('grid-actions'), { props });
        }

        return h('td', '-');
    }

    private async loadEmails() {
        this.loading = true;
        const emailData = await this.emailScheduleService.getEmails(this.projectId);
        this.emails = emailData.items.map((p) => new EmailOverviewItem(p));

        this.loading = false;
    }
}
