import Participant from '@/modules/participants/models/Participant';
import { Component, Prop } from 'vue-property-decorator';
import { languageService } from '../../main';
import { Language } from '../../models/Language';
import { ParticipantState } from '../../pages/surveys/models/participant';
import BaseParticipantManagement, { ParticipantThing } from './baseParticipantManagement';
import ExcelService from '@/services/ExcelService';

enum ImportInputModes {
    Overview = 'overview',
    Import = 'import',
    Paste = 'paste',
}

@Component
export default class ImportParticipantComponent extends BaseParticipantManagement {
    @Prop({ default: () => [] }) public extraFields: CsvColumn[];
    @Prop({ default: 'Import participant(s)' }) public title: string;

    public inputModel: string = '';
    public inputMode: ImportInputModes = ImportInputModes.Paste;

    public lineData: ParticipantImportBase[] = [];
    private languages: Language[] = [];
    private readonly delimiter: string = ';';
    private csvColumns: CsvColumn[] = [];

    public async created() {
        this.languages = await languageService.getLanguages();

        if (!this.languages || !this.languages.length) {
            this.showError(
                `Some parts of the system aren't correctly loaded. Please refresh the page. If this message continue's to show please contact us via the support button.`,
            );
        }

        this.csvColumns = new Array<CsvColumn>(
            { label: 'emailAddress', placeholder: 'example@address.com' },
            { label: 'firstName', placeholder: 'example firstname' },
            { label: 'lastName', placeholder: 'example lastname' },
            { label: 'phonenumber', placeholder: '<optional>' },
            {
                label: 'language',
                placeholder:
                    'Choose one of the following: ' +
                    this.languages
                        .map((lang) => {
                            return `${lang.languageCode} for ${lang.languageName}`;
                        })
                        .join(', '),
            },
        );

        if (this.extraFields.length > 0) {
            this.csvColumns.push(...this.extraFields);
        }
    }

    public importLines(): void {
        this.lineData.forEach((x: Partial<ParticipantImportBase>, index: number) => {
            const participant = new Participant(x);
            participant.organizationId = this.organizationId;

            participant.preferredLanguage = x.language;
            participant.preferredLanguageObject = this.languages.find((l) => {
                return l.languageCode === x.language;
            });

            if (participant.preferredLanguage === undefined) {
                participant.preferredLanguage = 'en';
                participant.preferredLanguageObject = this.languages.find((x) => x.languageCode.toLowerCase() === 'en');
            }

            const extraData = {};
            if (this.extraFields.length > 0) {
                for (const field of this.extraFields) {
                    extraData[field.label] = x[field.label];
                }
            }

            this.addBaseParticipant(
                new ParticipantThing({ participantData: participant, participantAddState: ParticipantState.Undefined }),
                index === this.lineData.length - 1,
                extraData,
            );
        });
    }

    public fileUploaded(event) {
        // tslint:disable: no-use-before-declare
        this.loadCSV(event, new ParticipantImportBase());
    }

    public downloadExample() {
        this.exportExampleCSV(this.csvColumns);
    }

    public async exportExampleCSV(columns: CsvColumn[]): Promise<void> {
        const downloadLink = await new ExcelService().fetchExampleImport(columns);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public loadCSV<T>(e: any, object: T): void {
        const self = this;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                self.processInput(csv, self.delimiter);
            };

            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert('Cannot read file !');
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public processPastedInput(): void {
        this.processInput(this.inputModel, '\t');
    }

    public processInput(input: string, delimiter: string) {
        const properties = [];
        for (const prop of this.csvColumns) {
            properties.push(prop.label);
        }

        const self = this;
        const lines = input.split('\n');
        const mapping = []; // Once to be used by a mailchimp style importer?

        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let i = 0; i < properties.length; i++) {
            const matchingColumn = headers.findIndex((h) => {
                return h.toLowerCase() === properties[i].toLowerCase();
            });
            if (matchingColumn === -1) {
                alert(`File's not matching the destination format. Missing column: ${properties[i]}`);
                break;
            } else {
                mapping.push({ index: matchingColumn, property: properties[i] });
            }
        }

        for (let li = 1; li < lines.length; li++) {
            // Skip first line
            if (lines[li] === null || lines[li] === '') {
                continue;
            }

            const columns = lines[li].replace('\r', '').split(delimiter);
            const itemLine = {} as ParticipantImportBase;

            for (let m = 0; m < mapping.length; m++) {
                const currentMap = mapping[m];
                itemLine[currentMap.property] = columns[currentMap.index];
            }

            if (
                !itemLine.language ||
                itemLine.language === '' ||
                !itemLine.language.match(/^[A-Za-z]+$/) ||
                itemLine.language.length !== 2 ||
                !this.languages.find((l) => {
                    return l.languageCode.toLowerCase() === itemLine.language.toLowerCase();
                })
            ) {
                itemLine.language = 'en';

                this.clearNotifications();
                this.showWarning('One ore more languages are not valid. Defaulting to english.');
            }

            self.lineData.push(itemLine);
        }
    }

    public cancelImport(): void {
        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
class ParticipantImportBase {
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phonenumber: string = '';
    public language: string = '';
    public groups: string = '';
}

export class CsvColumn {
    public label: string = '';
    public placeholder: string = '';
}
