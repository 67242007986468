export default class PillarThreshold {
    public id: number;
    public alias: string;
    public type: PillarThresholdType;
    public value: number;
    public order: number;

    constructor(pillarThreshold?: Partial<PillarThreshold>) {
        Object.assign(this, pillarThreshold);
    }
}

export enum PillarThresholdType {
    quartile = 'quartile',
    benchmark = 'benchmark',
    barChartRatio = 'barChartRatio',
}

export class PillarThresholdView {
    public alias: string;
    public type: PillarThresholdType;
    public values: ThresholdValue[];

    constructor(pillarThreshold?: Partial<PillarThresholdView>) {
        Object.assign(this, pillarThreshold);
    }

    public convertToPillarThresholds(): PillarThreshold[] {
        const thresholds = [];
        this.values.forEach(x => thresholds.push(new PillarThreshold({ alias: this.alias, type: this.type, value: x.value, order: x.order, id: x.id })))
        return thresholds;
    }
}

export class ThresholdValue {
    public id: number;
    public value: number;
    public order: number;

    constructor(pillarThreshold?: Partial<ThresholdValue>) {
        Object.assign(this, pillarThreshold);
    }
}