import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { organizationGroupMembershipService } from '../../../main';
import { CreateResult, CreationStatus } from '../../../models/CreateResult';
import { OrganizationGroup } from '../../../models/Organization';
import { EntityCreateState } from '../../../pages/surveys/models/participant';
import { EntityCreationWrapper, ParticipantThing } from '../../participant-management/baseParticipantManagement';
import { CsvColumn } from '../../participant-management/importParticipant';
import Participant from '@/modules/participants/models/Participant';
import ParticipantCreate from '@/modules/participants/models/ParticipantCreate';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { ParticipantFilter } from '@/modules/participants/models/ParticipantFilter';
import { OrganizationGroupService } from '@/services/organizationGroupService';

class ParticipantGroup {
    public participantData: Participant = null;
    public groups: Array<EntityCreationWrapper<OrganizationGroup>> = [];
    public showGroups?: boolean = false;

    constructor(participantData: ParticipantGroup) {
        Object.assign(this, participantData);
    }
}

// tslint:disable: max-classes-per-file
export class ImportGroupMembersOptions {
    public organizationId: number = 0;

    constructor(data: ImportGroupMembersOptions) {
        Object.assign(this, data);
    }
}

@Component({
    components: {
        'add-existing-participant': require('../../participant-management/addExistingParticipant.vue').default,
        'add-new-participant': require('../../participant-management/addNewParticipant.vue').default,
        'import-participant': require('../../participant-management/importParticipant.vue').default,
        'creation-status-display': require('../../displays/creationStatus.vue').default,
    },
})
export default class GroupImportActionComponent extends SideActionBase<ImportGroupMembersOptions> {
    public selectedPersons: Array<EntityCreationWrapper<ParticipantGroup>> = [];
    public extraCsvFields: CsvColumn[] = [];
    public groups: OrganizationGroup[] = [];
    public synchronise: boolean = false;
    public processed: boolean = false;
    public showImport: boolean = false;
    public showOverview: boolean = false;

    private deletedParticipantGroups: any[] = [];
    private groupCache: OrganizationGroup[] = [];
    private participantService: ParticipantsService = new ParticipantsService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async created() {
        this.extraCsvFields.push({ label: 'groups', placeholder: `The group(s) you want to add this person to, separate with ','` });

        this.groups = await this.organizationGroupService.getOrganizationGroups(this.options.organizationId);

        this.groups.forEach(async (x) => {
            x.members = await organizationGroupMembershipService.getGroupMembers(this.options.organizationId, x.groupId);
        });
    }

    public async addParticipant(participant: ParticipantThing, finished: boolean, extraData: any) {
        if (extraData.groups) {
            const groups = extraData.groups.split(',');

            this.selectedPersons.push(
                new EntityCreationWrapper<ParticipantGroup>({
                    entity: new ParticipantGroup({
                        participantData: participant.participantData,
                        groups: groups.map((g) => {
                            return new EntityCreationWrapper<OrganizationGroup>({
                                entity: new OrganizationGroup({ name: g.trim() }),
                            });
                        }),
                    }),
                }),
            );
        }

        if (finished) {
            await this.handleSynchStuff();
            this.showOverview = true;
        }
    }

    public removeParticipantFromSelection(_: EntityCreationWrapper<ParticipantGroup>, index: number): void {
        this.selectedPersons.splice(index, 1);
    }

    public removeGroupFromParticpant(index: number, gIndex: number): void {
        const person = this.selectedPersons[index];
        person.entity.groups.splice(gIndex, 1);
    }

    public async finishImportAction(): Promise<void> {
        this.showPending('Adding people to group');

        if (this.deletedParticipantGroups.length !== 0 && confirm(`Are you sure you want to remove data that isn't inside the imported lines?`)) {
            for (const deletedParticipantGroup of this.deletedParticipantGroups) {
                try {
                    await organizationGroupMembershipService.deleteGroupMembership(
                        this.options.organizationId,
                        deletedParticipantGroup.groupId,
                        deletedParticipantGroup.participantId,
                    );
                } catch {
                    console.error(`Combination couldn't be deleted`, deletedParticipantGroup);
                }
            }
        }

        for (const participantThing of this.selectedPersons) {
            participantThing.creationStatus = EntityCreateState.Processing;

            await this.participantService
                .createParticipant(ParticipantCreate.fromParticipant(participantThing.entity.participantData))
                .then(async (participantData: CreateResult<Participant>) => {
                    if (participantData.creationStatus === CreationStatus.created || participantData.creationStatus === CreationStatus.duplicate) {
                        participantThing.entity.participantData.participantId = participantData.entity.participantId;
                        participantThing.creationStatus = EntityCreateState.Created;
                    } else {
                        participantThing.creationStatus = EntityCreateState.Failed;
                        participantThing.errorReason = 'Creating this person failed. Possibly this person already exists in this organization.';
                    }
                })
                .catch((reason: any) => {
                    participantThing.creationStatus = EntityCreateState.Failed;
                    participantThing.errorReason = `Creating this person for organization failed. ${reason}`;
                });

            for (const group of participantThing.entity.groups) {
                const groupThing = await this.findOrCreateGroup(group.entity);

                if (groupThing) {
                    await organizationGroupMembershipService
                        .createGroupMembership(this.options.organizationId, groupThing.id ?? groupThing.groupId, participantThing.entity.participantData.participantId)
                        .then(() => {
                            group.creationStatus = EntityCreateState.Created;
                        })
                        .catch(() => {
                            group.creationStatus = EntityCreateState.Failed;
                            group.errorReason = 'Adding this person to the group failed.';
                        });
                } else {
                    group.creationStatus = EntityCreateState.Failed;
                    group.errorReason = 'Group resulted in an error.';
                }
            }
        }

        this.clearAndShowSuccess('All people are added!');
        this.processed = true;
    }

    public closeSelectedMode(): void {
        this.cancel();
    }

    public finishIt(): void {
        this.finished();
    }

    private async findOrCreateGroup(source: OrganizationGroup): Promise<OrganizationGroup> {
        const key = source.name.replace(' ', '_').replace(`'`, '').replace('"', '').toLowerCase();

        let searchedGroup = this.groupCache[key];
        if (!searchedGroup) {
            searchedGroup = this.groups.find((g) => {
                return g.name.toUpperCase() === source.name.toUpperCase();
            });
        }

        if (!searchedGroup) {
            const createdGroup = await this.organizationGroupService.createGroup(this.options.organizationId, source);
            if (createdGroup) {
                searchedGroup = createdGroup;
            }
        }

        if (searchedGroup) {
            this.groupCache[key] = searchedGroup;
            return searchedGroup;
        }

        return null;
    }

    private async handleSynchStuff() {
        if (this.synchronise) {
            const participants = [];
            const response = await this.participantService.getParticipants(
                new ParticipantFilter({ organizationId: this.options.organizationId, skip: 0, take: 999999 }),
            );
            participants.push(...response.items);

            for (let i = 0; i < participants.length; i++) {
                const participant = participants[i];
                const importedPerson = this.selectedPersons.find((p) => p.entity.participantData.emailAddress === participant.emailAddress);

                if (importedPerson) {
                    participant.groups = this.groups.filter((x) => x.members.filter((y) => y.emailAddress === participant.emailAddress).length > 0);
                    console.info('Participant was found!');
                    for (let y = 0; y < participant.groups.length; y++) {
                        const participantGroup = participant.groups[y];
                        let isGroupMatched = false;
                        console.info('Matching for participantGroup', participantGroup);
                        for (let z = 0; z < importedPerson.entity.groups.length; z++) {
                            const foundOrCreatedGroupFromImport = await this.findOrCreateGroup(importedPerson.entity.groups[z].entity);
                            if (participantGroup.groupId === foundOrCreatedGroupFromImport.groupId) {
                                console.info('Group was matched!');
                                isGroupMatched = true;
                            }
                        }

                        if (!isGroupMatched) {
                            console.warn('Group was not matched');
                            this.deletedParticipantGroups.push({ participantId: participant.participantId, groupId: participantGroup.groupId });
                        }
                    }
                } else {
                    console.warn('Participant was not found');
                    participant.groups = this.groups.filter((x) => x.members.filter((y) => y.emailAddress === participant.emailAddress).length > 0);
                    for (let y = 0; y < participant.groups.length; y++) {
                        const deletedParticipantGroup = participant.groups[y];
                        console.warn(`Removing group  ${deletedParticipantGroup.id} for participant ${participant.emailAddress}`);
                        this.deletedParticipantGroups.push({ participantId: participant.participantId, groupId: deletedParticipantGroup.groupId });
                    }
                }
            }
        }
    }
}
