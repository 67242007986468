import { Component } from 'vue-property-decorator';
import { loginHelper, loginService } from '../../../main';
import { Member } from '../../../models/Member';
import BasePage from '@/models/BasePage';

@Component
export default class AccessCheckComponent extends BasePage {
    public form: any = {
        email: '',
        password: '',
        submitted: false,
        failed: false,
    };

    public get isProject() {
        return this.entity === 'project';
    }
    public entity: string = '';
    public owner: string = '';
    public url: string = '';

    public created() {
        if (!this.$route.query || !Object.keys(this.$route.query).length) {
            return this.$router.go(-1);
        }

        this.entity = this.$route.query.entity.toString();
        this.url = this.$route.query.url.toString();
        this.owner = this.$route.query.owner.toString();
        const keys = ['entity', 'owner', 'url'];
        const query = { ...this.$route.query };

        keys.forEach((key: string) => {
            if (query[key]) {
                delete query[key];
            }
        });

        if (Object.keys(this.$route.query).length !== Object.keys(query).length) {
            this.$router.replace({ query });
        }
    }

    public cancel() {
        this.$router.go(-1);
    }

    public async go() {
        this.$router.push(this.url);
    }
}
