import { languageService } from '@/main';
import { Language } from '@/models/Language';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import Translator from '@/modules/translations/models/Translator';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { LanguagePermissionService } from '../services/languagePermissionsService';

export class AvailableLanguage {
    public languageCode: string;
    public languageName: string;
    public selectedInstrumentIds: number[] = [];

    constructor(obj?: Partial<AvailableLanguage>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

@Component
export default class UserLanguagePermissionsActionComponent extends SideActionBase<EditLanguagePermissionsActionOptions> {
    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public translator: Translator = this.options.translator;

    public submitted = false;
    public loading = false;
    public selectedLanguages: number[] = [];
    public languages: Language[] = [];

    public permissions: any[] = [];
    public instruments: any[] = [];
    public knowledgeModels: KnowledgeModel[] = [];
    public dirtyLanguages: boolean = false;
    public availableLanguages: AvailableLanguage[] = [];

    public languagesOptions(instrumentId: number) {
        const instrumentLang = this.instruments.find((w) => w.id === instrumentId);

        const knowledgeModel = this.knowledgeModels.find((z) => z.knowledgeModelId === instrumentLang.knowledgeModelId);
        if (knowledgeModel) {
            const knowledgeModelInstrument = knowledgeModel.instruments.find((y) => y.instrumentId === instrumentId);
            return knowledgeModelInstrument.languages.map((x) => {
                const lang = this.languages.find((y) => y.languageCode === x);
                if (!lang) {
                    let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
                    const name = languageNames.of(x);

                    return {
                        value: x,
                        text: `${name} (${x})`,
                    };
                }
                return {
                    value: lang.languageCode,
                    text: `${lang.languageName} (${lang.languageCode})`,
                };
            });
        }
    }

    public getDisplayLanguage(languageCode: string) {
        let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
        let name = languageNames.of(languageCode);

        if (name === languageCode) {
            const lang = this.languages.find((y) => y.languageCode === languageCode);
            name = lang.languageName;
        }

        return name;
    }

    public instrumentOptions(lang: string) {
        const instIds = [];

        this.knowledgeModels.forEach((x) => {
            if (x.instruments) {
                x.instruments.forEach((y) => {
                    if (y.languages && y.languages.indexOf(lang) > -1) {
                        instIds.push(y.instrumentId);
                    }
                });
            }
        });

        return instIds.map((instId) => {
            return this.instruments.find((x) => x.instrumentId === instId);
        });
    }

    public async created() {
        this.languages = await languageService.getLanguages();
        await this.$store.dispatch('instrumentsStore/fetch');
        this.instruments = this.$store.getters['instrumentsStore/instruments'];

        const knowledgeModelIds = [];
        this.instruments.forEach((instrument) => {
            if (knowledgeModelIds.indexOf(instrument.knowledgeModelId) === -1) {
                knowledgeModelIds.push(instrument.knowledgeModelId);
            }
        });

        for (let i = 0; i < knowledgeModelIds.length; i++) {
            const [err, response] = await to(this.knowledgeModelService.getKnowledgeModel(knowledgeModelIds[i]));
            this.knowledgeModels.push(response);

            response.instruments.forEach((x) => {
                x.languages.forEach((y) => {
                    const availableLanguage = this.availableLanguages.find((x) => x.languageCode === y);
                    if (!availableLanguage) {
                        this.availableLanguages.push(new AvailableLanguage({ languageCode: y }));
                    }
                });
            });
        }

        this.translator.instrumentPermissions.forEach((x) => {
            const instrumentLang = this.availableLanguages.find((w) => w.languageCode === x.languageCode);

            if (!instrumentLang.selectedInstrumentIds) {
                instrumentLang.selectedInstrumentIds = [x.instrumentId];
            } else {
                instrumentLang.selectedInstrumentIds.push(x.instrumentId);
            }
        });

        this.isLoaded = true;
    }

    public async updatePermissions(instIds: number[], language: AvailableLanguage): Promise<any> {
        this.submitted = true;

        this.showPending('Saving permissions...');
        this.loading = true;

        const [err, response] = await to(this.languagePermissionService.updatePermissions(instIds, language.languageCode, this.translator.userId));
        if (err || !response) {
            this.loading = false;
            return this.clearAndShowError(`The prepaid couldn't be added due to an error, please try again or contact an admin.`, err);
        }
        this.clearAndShowSuccess('Permissions saved successfully');
    }

    public close(): void {
        this.finished();
    }
}

// tslint:disable: max-classes-per-file
export class EditLanguagePermissionsActionOptions {
    public translator: Translator = new Translator();

    constructor(data: EditLanguagePermissionsActionOptions) {
        Object.assign(this, data);
    }
}
