import { BillingInformation } from '@/modules/practitioners/models/BillingInformation';
import { Country } from './Country';
import { Language } from './Language';
import { State } from './State';

export class CreatePractitionerModel {
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public companyName: string = '';
    public preferredLanguage: Language = null;
    public instruments: number[] = [];

    public billingInformation: BillingInformation = new BillingInformation();
    public addressLine1: string = '';
    public addressLine2: string = '';
    public zipCode: string = '';
    public city: string = '';
    public stateId: number = 0;
    public countryId: number = 0;
    public countryName: string = '';
    public stateName: string = '';
    public taxNumber: string = '';

    public directInvoicing: boolean = false;

    public paperlessContactId: number = null;
    public paperlessOrganizationId: number = null;

    public partner: { id: number; name: string };

    public country: Country = null;
    public state: State = null;
}
