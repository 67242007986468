import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { PartnerService } from '@/modules/partners/services/partnerService';

@Component
export default class AddExistingPractitionersActionComponent extends SideActionBase<any> {
    public practitionersList: Practitioner[] = []; // single s causes weird problem that this property is always undefined
    public selectedPractitioners: Practitioner[] = [null];

    private alreadySelectedPractitioners: Practitioner[] = [];
    private practitionerService: PractitionerService = new PractitionerService();
    private partnerService: PartnerService = new PartnerService();

    public get practitioners(): Practitioner[] {
        return this.practitionersList.filter((p) => {
            const selectedPractitioner = this.selectedPractitioners.find((sp) => {
                return p !== null && sp !== null && sp.practitionerId === p.practitionerId;
            });
            const alreadySelectedPractitioner = this.alreadySelectedPractitioners.find((sp) => {
                return p !== null && sp !== null && sp.practitionerId === p.practitionerId;
            });
            if (!selectedPractitioner && !alreadySelectedPractitioner) {
                return p;
            }
        });
    }

    public async created() {
        await this.partnerService
            .getPractitioners(this.options.partner.id)
            .then((response) => {
                for (const p of response.items) {
                    this.alreadySelectedPractitioners.push(p);
                }
            });

        await this.practitionerService
        .getPractitioners({ take: 9999, skip: 0 }).then((response) => {
            for (const p of response.items) {
                this.practitionersList.push(p);
            }
        });
    }

    public practitionerSelected(_: Practitioner, oldPractitioner: Practitioner) {
        if (!oldPractitioner) {
            this.selectedPractitioners.push(null);
        }
    }

    public async addParticipantsToGroup(): Promise<void> {
        this.showPending(`Adding the selected participants to partner ${this.options.partner.firstName} ${this.options.partner.lastName}`);
        for (const selectedParticipant of this.selectedPractitioners) {
            if (selectedParticipant !== null) {
                try {
                    await this.partnerService.addToGroup(selectedParticipant.practitionerId, this.options.partner.id);
                } catch {
                    this.showError(`There was a problem adding ${selectedParticipant.firstName} ${selectedParticipant.lastName} to the group.`);
                }
            }
        }
        this.clearAndShowSuccess(`Adding the selected participants successfully`);

        this.finished();
    }
}
