import PageRender from '@/models/PageRender';
import ParticipantProject from '@/modules/participants/models/ParticipantProject';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class EmailPreviewComponent extends PageRender {
    @Prop() public emailTemplate: string;
    @Prop({ default: () => 'en' }) public activeLanguage: string;
    @Prop({ default: () => new ProjectParticipant() }) participant: ProjectParticipant;
    public mergeTagsEnabled: boolean = true;
    public body: string = '';
    public timeoutTime: number = 500;
    public replaceTimeout: any;

    @Watch('emailTemplate')
    public onEmailTemplateChange() {
        this.updateBody();
    }

    @Watch('mergeTagsEnabled')
    public onMergeTagsEnabledChanged() {
        this.updateBody();
    }

    public mounted() {
        this.body = this.emailTemplate;
        this.updateBody();
    }

    public processPlaceholders(variables: any) {
        let tempTemplate = this.emailTemplate;
        if (!tempTemplate) {
            return this.showError('No template found');
        }

        for (const variable in variables) {
            const reg = new RegExp('{{' + variable + '}}', 'gm');
            tempTemplate = tempTemplate.replace(reg, variables[variable]);
        }

        this.body = tempTemplate;
    }

    public updateBody() {
        if (this.replaceTimeout) {
            clearTimeout(this.replaceTimeout);
        }

        if (this.mergeTagsEnabled) {
            this.replaceTimeout = setTimeout(() => this.processPlaceholders(this.participant), this.timeoutTime);
        }
        this.body = this.emailTemplate;
    }
}
