import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SurveyDetails } from '@/models/Survey';
import moment from 'moment';

@Component
export default class CreateOrganizationGroupComponent extends Vue {
    @Prop({ default: [] }) public surveys: SurveyDetails[];

    public getFormattedDate(completionDate) {
        return moment(completionDate, 'YYYY-MM-DD').format('ll');
    }
}
