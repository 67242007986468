export class Language {
    public languageName: string = '';
    public languageCode: string = '';

    constructor(language?: Partial<Language>) {
        if (language) {
            Object.assign(this, language);

            if (!this.languageName) {
                let languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
                this.languageName = languageNames.of(this.languageCode);
            }
        }
    }
}
