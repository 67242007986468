import { Country } from '@/models/Country';
import { State } from '@/models/State';

export class BillingInformation {
    public addressLine1: string = '';
    public addressLine2: string = '';
    public zipCode: string = '';
    public city: string = '';
    public stateId: number = 0;
    public countryId: number = 0;
    public countryName?: string = '';
    public stateName?: string = '';
    public taxNumber: string = '';

    public directInvoicing: boolean = false;
    public paperlessContactId: number = null;
    public paperlessOrganizationId: number = null;

    public country?: Country = null;
    public state?: State = null;
}
