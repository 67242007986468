import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import LanguagePermission from '../models/LanguagePermission';

export class LanguagePermissionService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public updatePermissions(instIds: number[], languageCode: string, userId: number): Promise<LanguagePermission> {
        return new Promise<LanguagePermission>((resolve, reject) => {
            this.put<LanguagePermission>(`${this.endpoint}/language-permissions/${userId}`, {
                instruments: instIds,
                languageCode: languageCode,
            })
                .then((response: AxiosResponse<LanguagePermission>) => {
                    resolve(new LanguagePermission(response.data));
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getLanguagePermissions(userId?: number): Promise<LanguagePermission[]> {
        return new Promise<LanguagePermission[]>((resolve, reject) => {
            this.get<LanguagePermission[]>(`${this.endpoint}/language-permissions${userId ? '/' + userId : ''}`)
                .then((response: AxiosResponse<LanguagePermission[]>) => {
                    resolve(response.data.map((x) => new LanguagePermission(x)));
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
