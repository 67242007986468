import Practitioner from '@/modules/practitioners/models/Practitioner';
import { OrganizationsService } from '@/services/organizationsService';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';

@Component
export default class TransferOrganizationRightsActionComponent extends SideActionBase<TransferOrganizationRightsOptions> {
    public selectedPractitioner: Practitioner = null;
    private organizationsService: OrganizationsService = new OrganizationsService();

    public mounted(): void {
        if (this.options.sharedPractitioners && this.options.sharedPractitioners.length === 1) {
            this.selectedPractitioner = this.options.sharedPractitioners[0];
        }
    }

    public async transferRights(org: any) {
        if (!this.selectedPractitioner) {
            return;
        }

        const [err] = await to(this.organizationsService.transferAdminRights(this.options.organizationId, this.selectedPractitioner.practitionerId));
        if (err) {
            return this.showError('Failed to transfer administrator rights of organization');
        }

        this.finished(org);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class TransferOrganizationRightsOptions {
    public organizationId: number;
    public sharedPractitioners: Practitioner[];

    constructor(actionParams: TransferOrganizationRightsOptions) {
        Object.assign(this, actionParams);
    }
}
