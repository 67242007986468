import { Component } from 'vue-property-decorator';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import { Country } from '@/models/Country';
import { State } from '@/models/State';
import BasePage from '@/models/BasePage';
import { $router, languageService, loginHelper } from '@/main';
import Partner from '@/modules/partners/models/Partner';
import { Member } from '@/models/Member';
import { PractitionerService } from '../services/practitionerService';
import PractitionerUpdate from '../models/PractitionerUpdate';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { BillingInformation } from '../models/BillingInformation';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { Language } from '@/models/Language';
import { CountryService } from '@/services/countryService';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import { BModal } from 'bootstrap-vue';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import Organization from '@/modules/organizations/models/Organization';

@Component({
    components: {
        'prepaid-overview': require('@/modules/practitioners/components/prepaid-overview.vue').default,
        'instrument-overview': require('@/modules/practitioners/components/instrument-overview.vue').default,
        'organizations-overview': require('@/modules/practitioners/components/organizations-overview.vue').default,
        'projects-overview': require('@/modules/practitioners/components/projects-overview.vue').default,
    },
    validations: {
        practitionerDataEdit: {
            firstName: { required },
            lastName: { required },
            emailAddress: { required, email },
            billingInformation: {
                paperlessOrganizationId: {
                    required: requiredIf((billingInformation: BillingInformation) => {
                        return billingInformation && billingInformation.directInvoicing;
                    }),
                },
                paperlessContactId: {
                    required: requiredIf((billingInformation: BillingInformation) => {
                        return billingInformation && billingInformation.directInvoicing;
                    }),
                },
            },
            country: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner && practitioner.billingInformation && practitioner.billingInformation.directInvoicing;
                }),
            },
            state: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner && practitioner.billingInformation && practitioner.billingInformation.directInvoicing;
                }),
            },
        },
    },
})
export default class PractitionerDetailsComponent extends BasePage {
    public user: Member;
    public isLoaded: boolean = false;
    public editMode: boolean = false;
    public submitted: boolean = false;

    public partnerData: Partner = new Partner();

    public countryOptions: any[] = [];
    public stateOptions: any[] = [];
    public myOrganizations: Organization[] = [];
    public thekey: string = new Date().getTime().toString();

    public $refs!: {
        invitePractitioner: BModal;
        pendDeleteModal: BModal;
        softDeleteModal: areYouSureModalVue;
        hardDeleteModal: areYouSureModalVue;
    };

    public get deleteScope() {
        return [[this.practitionerData], 'emailAddress', 'practitioner', false];
    }

    public get sharedOrganizations() {
        return this.myOrganizations.filter((x) => x.isSharedWith);
    }
    public get ownedOrganizations() {
        return this.myOrganizations.filter((x) => x.isOwner);
    }

    private practitionerId: number = 0;

    private countries: Country[] = [];
    private languages: Language[] = [];

    private practitionerData: Practitioner = new Practitioner();
    private practitionerDataEdit: PractitionerUpdate = new PractitionerUpdate();
    private practitionerService: PractitionerService = new PractitionerService();
    private partnerService: PartnerService = new PartnerService();
    private countryService: CountryService = new CountryService();

    public async created() {
        this.practitionerId = parseInt($router.currentRoute.params.practitionerId);
        this.user = loginHelper.getUser();

        if (this.isPractitioner && this.practitionerId !== this.user.practitionerId) {
            this.clearNotifications();
            return this.$router.push({ name: 'project-overview' });
        }

        this.showPending('Loading practitioner details..');
        await this.practitionerService
            .getPractitioner(this.practitionerId)
            .then((practitioner) => {
                this.practitionerData = practitioner;
                if (this.practitionerData && this.practitionerData.practitionerId === 0) {
                    return this.$router.push({ name: 'practitioner-overview' });
                }

                Object.assign(this.practitionerDataEdit, this.practitionerData);
            })
            .catch(() => {
                this.showError(
                    `Practitioner data couldn't be retrieved. Please try again or, when the problem exists, contact us via the support button.`,
                );
            });

        if (!this.isPractitioner) {
            const partner = await this.partnerService.getPartner(this.practitionerData.partnerId);
            if (partner) {
                this.partnerData = partner;
            }
        } else {
            this.partnerData = new Partner({ partnerId: this.practitionerData.partnerId, companyName: this.practitionerData.partnerName });
        }

        await this.countryService.getCountries().then((countries) => {
            this.countries = countries;
            this.countries.forEach((country) => {
                this.countryOptions.push({ id: country.id, name: country.name });
            });

            this.setCountryToPractitioner(this.practitionerData, this.countries);
            this.stateOptions = this.practitionerData.billingInformation.country.states;
            this.setStateToPractitioner(this.practitionerData, this.practitionerData.billingInformation.country);
        });

        if (this.practitionerData.preferredLanguage !== null) {
            this.languages = await languageService.getLanguages();
        }

        this.isLoaded = true;
        this.clearNotifications();
    }

    public startEdit(): void {
        this.editMode = true;

        Object.assign(this.practitionerDataEdit, this.practitionerData);

        this.practitionerDataEdit.country = this.countryOptions.find((x) => {
            return this.practitionerData.billingInformation.countryId === x.id;
        });

        this.onCountryChange(this.practitionerDataEdit.country);

        this.practitionerDataEdit.state = this.stateOptions.find((x) => {
            return this.practitionerData.billingInformation.stateId === x.id;
        });

        this.setLanguageToPractitioner(this.practitionerDataEdit, this.languages);
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.practitionerDataEdit, this.practitionerData);
    }

    public async saveEdit() {
        this.submitted = true;
        this.showPending('Loading...');

        if ((this as any).$v.$invalid) {
            return this.showError(`The practitioner didn't pass the validation.`);
        }

        const practitionerUpdate: PractitionerUpdate = {
            practitionerId: this.practitionerId,
            firstName: this.practitionerDataEdit.firstName,
            lastName: this.practitionerDataEdit.lastName,
            emailAddress: this.practitionerDataEdit.emailAddress,
            phoneNumber: this.practitionerDataEdit.phoneNumber,
            companyName: this.practitionerDataEdit.companyName,
            preferredLanguage: (this.practitionerDataEdit.preferredLanguage as Language).languageCode,
            billingInformation:
                this.practitionerData.billingInformation && this.practitionerData.billingInformation.directInvoicing
                    ? {
                        taxNumber: this.practitionerDataEdit.billingInformation.taxNumber,
                        addressLine1: this.practitionerDataEdit.billingInformation.addressLine1,
                        addressLine2: this.practitionerDataEdit.billingInformation.addressLine2,
                        zipCode: this.practitionerDataEdit.billingInformation.zipCode,
                        city: this.practitionerDataEdit.billingInformation.city,
                        countryId: this.practitionerDataEdit.country ? this.practitionerDataEdit.country.id : null,
                        stateId: this.practitionerDataEdit.state ? this.practitionerDataEdit.state.id : null,
                        directInvoicing: false,
                        paperlessContactId: this.practitionerDataEdit.billingInformation.paperlessContactId,
                        paperlessOrganizationId: this.practitionerDataEdit.billingInformation.paperlessOrganizationId,
                    }
                    : null,
        };

        await this.updatePractitioner(practitionerUpdate);
    }

    public async enablePractitioner() {
        this.showPending('Loading...');
        await this.practitionerService.enablePractitioner(this.practitionerId);
        this.practitionerData.disabled = false;

        this.clearNotifications();
    }

    public async disablePractitioner() {
        this.showPending('Loading...');
        await this.practitionerService.disablePractitioner(this.practitionerId);

        this.practitionerData.disabled = true;
        this.clearNotifications();
    }

    public showModalCreateAccount() {
        this.$refs.invitePractitioner.show();
    }

    public async showPendingDeleteModal() {
        this.$refs.pendDeleteModal.show();
        const organizationsResponse = await this.practitionerService.getOrganizations(this.practitionerId);
        this.myOrganizations = organizationsResponse.items;
    }

    public async createPractitionerAccount() {
        this.showPending('Loading...');
        await this.practitionerService.createAccountForPractitioner(this.practitionerData.practitionerId, this.practitionerData.emailAddress);

        this.practitionerData.hasAccount = true;
        this.$refs.invitePractitioner.hide();
        this.clearAndShowSuccess('Account created, invite mail scheduled.');
    }

    public softDelete() {
        this.$refs.softDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.softDeleteModal.show();
    }

    public hardDelete() {
        this.$refs.hardDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.hardDeleteModal.show();
    }

    public async handleDelete(removeAllData: boolean) {
        this.$refs.pendDeleteModal.hide();
        this.$refs.softDeleteModal.hide();
        this.$refs.hardDeleteModal.hide();

        this.showPending('Deleting the practitioner.');
        await this.practitionerService
            .deletePractitioner(this.practitionerId, removeAllData)
            .then(async () => {
                await this.$router.push({ name: 'practitioner-overview' });
                this.clearAndShowSuccess('The practitioner is successfully deleted.');
            })
            .catch((e) => {
                this.clearAndShowError('Failed to delete the practitioner.', e);
            });
    }

    public onCountryChange(ev: any) {
        if (!ev) {
            return;
        }

        this.stateOptions = this.countries.find((x) => {
            return x.id === ev.id;
        }).states;

        if (this.practitionerDataEdit.state) {
            this.practitionerDataEdit.state = new CreatePractitionerModel().state;
        }
    }

    public getLanguageName(languageCode) {
        const language = this.languages.find((x) => {
            return x.languageCode === languageCode.toLowerCase();
        });

        if (!language) {
            return 'N/A';
        }

        return language.languageName;
    }

    private async updatePractitioner(practitionerData: PractitionerUpdate) {
        await this.practitionerService
            .updatePractitioner(this.practitionerId, practitionerData)
            .then((result: Practitioner) => {
                if (result) {
                    this.clearAndShowSuccess('Practitioner details are updated.');
                    this.practitionerData = result;
                    this.setCountryToPractitioner(this.practitionerData, this.countries);
                    this.setStateToPractitioner(this.practitionerData, this.practitionerData.billingInformation.country);
                    this.editMode = false;
                } else {
                    this.clearAndShowError(
                        'The practitioner could not be updated. Please try again, or when the problem exists contact the helpdesk.',
                        null,
                    );
                }
            })
            .catch((e) => {
                this.clearAndShowError(
                    'The practitioner could not be updated. Please try again, or when the problem exists contact the helpdesk.',
                    e,
                );
            });
    }

    private setCountryToPractitioner(practitionerData: Practitioner, countries: Country[]) {
        if (practitionerData.billingInformation && practitionerData.billingInformation.country) {
            const selectedCountry = countries.find((country) => {
                return practitionerData.billingInformation.countryId === country.id;
            });
            practitionerData.billingInformation.country = selectedCountry;
        } else {
            if (!practitionerData.billingInformation) {
                practitionerData.billingInformation = new BillingInformation();
            }
            practitionerData.billingInformation.country = new Country();
        }
    }

    private setStateToPractitioner(practitionerData: Practitioner, country: Country) {
        if (practitionerData.billingInformation && practitionerData.billingInformation.state) {
            const selectedState = country.states.find((state) => {
                return practitionerData.billingInformation.stateId === state.id;
            });
            practitionerData.billingInformation.state = selectedState;
        } else {
            if (!practitionerData.billingInformation) {
                practitionerData.billingInformation = new BillingInformation();
            }
            practitionerData.billingInformation.state = new State();
        }
    }

    private setLanguageToPractitioner(practitionerData, languages): void {
        if (practitionerData.preferredLanguage !== null) {
            const language = languages.find((lang) => {
                return lang.languageCode === practitionerData.preferredLanguage;
            });
            practitionerData.preferredLanguage = language;
        }
    }
}
