export class Menu {
    public showInMenu: boolean;
    public label: string;
    public icon: string;
    public name: string;
    public showCounter: boolean;
    public variant: string = 'primary-clean';

    public roles: string[];
    public permissions: string[];

    constructor(data: Partial<Menu>) {
        if (data !== null) {
            Object.assign(this, data);
        }

        if (typeof this.roles === 'undefined') {
            this.roles = [];
        }

        if (typeof this.permissions === 'undefined') {
            this.permissions = [];
        }
    }
}
