import { InstrumentsService } from '@/modules/instruments/services/instrumentsService';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        instrument: {
            name: { required },
        },
    },
} as any)
export default class AddInstrumentAction extends SideActionBase<CreateInstrumentSideActionOptions> {
    public instrument: InstrumentCreate = new InstrumentCreate();

    public submitted = false;
    public loading = false;

    private instrumentService: InstrumentsService = new InstrumentsService();

    public async mounted() {
        if (this.isPractitioner) {
            this.cancel();
        }

        this.isLoaded = true;
    }

    public async createInstrument(evt: any): Promise<any> {
        evt.preventDefault();
        this.submitted = true;

        if ((this as any).$v.$invalid) {
            this.showError(`The instrument didn't pass the validation.`);
            return;
        }

        this.loading = true;
        const instrument = await this.instrumentService.createInstrument(this.instrument, this.options.knowledgeModelId);
        if (!instrument) {
            this.loading = false;
            return this.clearAndShowError(`The instrument couldn't be created due to an error, please try again or contact an admin.`, null);
        }

        this.clearAndShowSuccess('Instrument created successfully');
        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.clearNotifications();
        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
export class CreateInstrumentSideActionOptions {
    public knowledgeModelId: number;

    constructor(data: CreateInstrumentSideActionOptions) {
        Object.assign(this, data);
    }
}

export class InstrumentCreate {
    public name: string;
}
