import Instrument from '@/modules/instruments/Models/Instrument';

export default class KnowledgeModel {
    public name: string = 'Knowledge Model';
    public knowledgeModelId: number = 0;
    public domain?: string;

    public paperlessEcosystemId: string;
    public paperlessTeamId: number;
    public paperlessTeamName: string;
    public paperlessGroupName: string;
    public paperlessProductId: number;
    public paperlessTemplateId: number;

    public emailDefaultFromAddress: string;
    public emailDefaultFromName: string;

    public primaryColor?: string;
    public secondaryColor?: string;
    public masterTemplate?: string;
    public alias: string = '';
    public instruments?: Instrument[] = [];
    public surveyConfiguration?: SurveyConfiguration = new SurveyConfiguration();

    constructor(obj?: Partial<KnowledgeModel>) {
        Object.assign(this, obj);
    }
}

export class SurveyConfiguration {
    public disableBackNavigation: boolean = false;
}
