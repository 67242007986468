import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import BasePage from '@/models/BasePage';
import DemoGraphicModel from '@/models/DemoGraphicModel';
import to from 'await-to-js';
import { OrganizationsService } from '@/services/organizationsService';
import { DemoGraphicItem } from '@/models/DemographicItem';

@Component
export default class OrganizationDemographicModelComponent extends BasePage {
    @Prop({ default: () => 0 }) public organizationId: number;
    public isLoaded: boolean = false;
    public model: DemoGraphicModel = { questions: [], orgId: null, id: null } as DemoGraphicModel;

    public get orderedQuestions(): DemoGraphicItem[] {
        return this.model.questions.sort((a: DemoGraphicItem, b: DemoGraphicItem) => {
            return a.order - b.order;
        });
    }

    public questionColumns: GridColumnProps[] = [
        { field: 'label', title: 'Label' },
        { field: 'question', title: 'Description' },
        { field: 'questionType', title: 'Type', className: 'text-capitalize' },
        { field: 'answers', title: 'Possible values', cell: this.renderAnswers },
        { field: 'enabled', title: 'Enabled', cell: this.renderBoolean },
        { field: 'order', width: '100px', title: 'Reorder', cell: this.renderReorder },
    ];

    public updateKey: string = new Date().getTime().toString();
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        await this.getDemographicModel();

        this.isLoaded = true;
    }

    public async reOrderQuestions(question: DemoGraphicItem, spaces: number) {
        const previous = [...this.orderedQuestions];
        const questionInList = previous.find((x) => x.id === question.id);
        if (!questionInList || questionInList.order + spaces === 0 || questionInList.order + spaces > previous.length) {
            return;
        }

        const questionToUpdate = previous.find((x) => x.order === question.order + spaces);

        if (!questionToUpdate || questionToUpdate.order - spaces === 0 || questionToUpdate.order - spaces > previous.length) {
            return;
        }

        questionToUpdate.order -= spaces;
        questionInList.order += spaces;

        const questions = [questionToUpdate, questionInList];
        await this.saveUpdatedQuestions(questions);

        this.showSuccess('The questions have been reordered');
    }

    public renderReorder(h, _, row): any {
        const props = {
            dataItem: row.dataItem,
            field: row.field,
            total: this.orderedQuestions.length,
        };
        return h(Vue.component('grid-arrange-order'), {
            props,
            on: {
                move: async (question, spaces) => this.reOrderQuestions(question, spaces),
            },
        });
    }

    public renderAnswers(h, _, row): any {
        if (row.dataItem.questionType === 'open') {
            return h('td', 'Specified by each participant');
        }

        const props = {
            icon: '',
            detailIcon: '',
            textTemplate: '{{count}} value(s)',
            elements: row.dataItem.answers,
        };

        return h(Vue.component('grid-string-dropdownlist'), { props });
    }

    public renderInstruments(item: any, _: any, row: any): any {
        return item(Vue.component('grid-popover-instruments'), {
            props: {
                id: row.dataItem.id,
                title: `Instruments`,
                instruments: row.dataItem.instruments,
                text: `${row.dataItem.instruments.length} instruments`,
            },
        });
    }

    public renderBoolean(h, _, row) {
        return h('td', row.dataItem.enabled ? 'Yes' : 'No');
    }

    public openAddDemographicQuestion() {
        this.$sideActions.push(
            'add-demographic-question-action',
            { organizationId: this.organizationId, demographicId: this.model.id, order: this.orderedQuestions.length },
            async () => {
                this.showSuccess('The question is successfully added.');
                await this.getDemographicModel();
            },
        );
    }

    public openCopyDemographicQuestions() {
        this.$sideActions.push(
            'copy-demographic-question-action',
            { organizationId: this.organizationId, demographicId: this.model.id },
            async () => {
                this.isLoaded = false;

                this.showSuccess('The questions are successfully copied.');
                await this.getDemographicModel();

                this.isLoaded = true;
            },
        );
    }

    public openChangeDemographicQuestion(row): any {
        this.$sideActions.push(
            'change-demographic-question-action',
            { question: row.dataItem, organizationId: this.organizationId, demographicId: this.model.id },
            async () => {
                this.showSuccess('The question is successfully changed.');
                await this.getDemographicModel();
            },
        );
    }

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` cursor-pointer`;
        return tr;
    }

    private async getDemographicModel() {
        const [err, response] = await to(this.organizationsService.getDemographicModel(this.organizationId));
        if (err) {
            return this.showError('Failed to load demographic model');
        }
        this.model = response.data;
    }

    private async saveUpdatedQuestions(questions: DemoGraphicItem[]) {
        questions.forEach(async (question) => {
            const [err, response] = await to(
                this.organizationsService.updateQuestionToDemographicModel(this.model.id, this.organizationId, question),
            );
            if (err) {
                return this.showError('Failed to save demographic order');
            }
        });
    }
}
