import BasePage from '@/models/BasePage';
import { Component } from 'vue-property-decorator';
import InstrumentsOverviewComponent from '../components/instruments-overview';
import Instrument from '../Models/Instrument';

@Component({
    components: {
        'instruments-overview': require('@/modules/instruments/components/instruments-overview.vue').default,
    },
} as any)
export default class InstrumentOverviewComponent extends BasePage {
    public $refs!: {
        instrumentOverview: InstrumentsOverviewComponent;
    };

    public async InstrumentCreatedCallback(instrumentData: Instrument) {
        await this.$router.push({ name: 'instrument-details', params: { instrumentId: instrumentData.instrumentId.toString() } });
    }
}
