import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import Instrument from '@/modules/partners/models/Instrument';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import Prepaid from '../models/Prepaid';
import { PrepaidService } from '../services/prepaidService';

@Component({
    validations: {
        prepaid: {
            totalPrepaid: { required },
            cost: { required },
            startDate: { required },
            endDate: { required },
        },
        selectedKnowledgeModel: { required },
    },
} as any)
export default class AddPrepaidActionComponent extends SideActionBase<AddPrepaidActionOptions> {
    public prepaid = new Prepaid();
    public prepaidService: PrepaidService = new PrepaidService();
    public practitionerService: PractitionerService = new PractitionerService();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();
    public partnerService: PartnerService = new PartnerService();
    public partnerId: number = this.options.partnerId;
    public practitionerId: number = this.options.practitionerId;

    public submitted: boolean = false;
    public loading: boolean = false;
    public isLoaded: boolean = false;

    public selectedKnowledgeModel: any = null;
    public instruments: Instrument[] = [];

    public get instrumentOptions() {
        const options = [];

        this.instruments.forEach((instrument) => {
            if (!options.find((x) => x.id === instrument.knowledgeModelId)) {
                options.push({
                    id: instrument.knowledgeModelId,
                    name: instrument.knowledgeModel.name,
                });
            }
        });

        return options;
    }

    public setKnowledgeModel(knowledgeModel) {
        this.prepaid.knowledgeModelId = knowledgeModel.id;
    }

    public async mounted() {
        if (this.isPractitioner) {
            this.cancel();
        }

        await this.loadOwnedKnowledgeModels();

        this.isLoaded = true;
    }

    public async addPrepaid(evt: any): Promise<any> {
        evt.preventDefault();

        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('Missing some values to save this prepaid');
            return;
        }

        this.prepaid.knowledgeModelId = this.selectedKnowledgeModel.id;
        this.prepaid.knowledgeModelName = this.selectedKnowledgeModel.name;
        this.prepaid.remainingPrepaid = this.prepaid.totalPrepaid;
        this.showPending('Saving prepaid...');
        this.loading = true;

        this.prepaid.partnerId = this.partnerId;
        this.prepaid.practitionerId = this.practitionerId;

        const [err, response] = await to(this.prepaidService.createPrepaid(this.prepaid));
        if (err || !response) {
            this.loading = false;
            return this.clearAndShowError(`The prepaid couldn't be added due to an error, please try again or contact an admin.`, err);
        }

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.cancel();
    }

    private async loadOwnedKnowledgeModels() {
        const knowledgeModels = await this.knowledgeModelService.getKnowledgeModels({});

        if (this.partnerId) {
            const instruments = await this.partnerService.getInstruments(this.partnerId);

            instruments.items.forEach((instrument) => {
                instrument.knowledgeModel = knowledgeModels.items.find((x) => x.knowledgeModelId === instrument.knowledgeModelId);
            });

            this.instruments = [...instruments.items];

            if (this.instrumentOptions.length === 1) {
                this.selectedKnowledgeModel = this.instrumentOptions[0];
                return;
            }

            this.instruments = [...instruments.items];
        } else if (this.practitionerId) {
            const instruments = await this.practitionerService.getInstruments(this.practitionerId);

            instruments.items.forEach((instrument) => {
                instrument.knowledgeModel = knowledgeModels.items.find((x) => x.knowledgeModelId === instrument.knowledgeModelId);
            });

            this.instruments = [...instruments.items] as Instrument[];

            if (this.instrumentOptions.length === 1) {
                this.selectedKnowledgeModel =  this.instrumentOptions[0];
                return;
            }
        }
    }
}

// tslint:disable: max-classes-per-file
export class AddPrepaidActionOptions {
    public partnerId: number;
    public practitionerId: number;
    constructor(data: AddPrepaidActionOptions) {
        Object.assign(this, data);
    }
}
