import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { PagedResponse } from '@/models/PagedResponse';
import BasePage from '@/models/BasePage';
import Participant from '../models/Participant';
import { ParticipantFilter } from '../models/ParticipantFilter';
import { ParticipantsService } from '../services/participantsService';
import { BModal } from 'bootstrap-vue';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import to from 'await-to-js';
import he from 'he';

@Component
export default class ParticipantsOverviewComponent extends BasePage {
    get participantCount(): number {
        return this.participantsData.participantCount;
    }
    get selectedParticipant(): any[] {
        return this.participantsData.participants.filter((participant: any) => {
            return participant.selected;
        });
    }

    public participant: Participant;
    @Prop({ default: null }) public partnerId?: number;
    @Prop({ default: null }) public actions: any[];

    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public onlyProjectCountZero: boolean = false;
    public participantsService: ParticipantsService = new ParticipantsService();

    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: Vue.component('grid-checkbox') },
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'emailAddress', title: 'Email address', cell: this.renderEmailAddress },
        { field: 'organizationName', title: 'Organization', cell: this.renderRouterLinkOrg },
        { field: 'projectCount', title: 'Project count', width: 120 },
    ];

    public $refs!: {
        pendDeleteModal: BModal;
        softDeleteModal: areYouSureModalVue;
        hardDeleteModal: areYouSureModalVue;
    };

    public participantsData: any = {
        participants: [],
        participantCount: 0,
        skip: 0,
        take: 25,
        search: '',
    };

    public get deleteScope() {
        return [
            [this.selectedParticipant],
            '',
            this.selectedParticipant.length ? ' participant' : `${this.selectedParticipant.length} participants`,
            false,
        ];
    }

    private filterTimer: number = null;

    public async participantCreatedCallback(participantData: Participant) {
        await this.$router.push({ name: 'participant-details', params: { participantId: participantData.participantId.toString() } });
    }

    public async created() {
        await this.getParticipants(true);
    }

    public reloadParticipants(event: GridPageChangeEvent): void {
        this.participantsData.skip = event.page.skip;
        this.participantsData.take = event.page.take;
        this.onlyProjectCountZero = false;
        // tslint:disable-next-line: no-floating-promises
        this.getParticipants(false);
    }

    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            if (this.onlyProjectCountZero) {
                this.participantsData.participants = this.participantsData.participants.filter((x) => x.projectCount === 0);
                return;
            }

            self.participantsData.skip = 0;
            await self.getParticipants(true);
        }, 400);
    }

    public renderEmailAddress(item: any, _, row): any {
        return item('td', row.dataItem.emailAddress ? row.dataItem.emailAddress : '(empty)');
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.firstName ? row.dataItem.firstName : '(empty)'} ${row.dataItem.lastName ? row.dataItem.lastName : '(empty)'}`,
                url: this.$router.resolve({ name: this.getRouterLinkName(), params: { participantId: row.dataItem.participantId.toString() } }).href,
            },
        });
    }

    public renderRouterLinkOrg(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.organizationId ? he.decode(row.dataItem.organizationName) : '(empty)'}`,
                url: this.$router.resolve({ name: 'organization-details', params: { organizationId: row.dataItem.organizationId.toString() } }).href,
            },
        });
    }

    public get participantsSelected(): boolean {
        return this.selectedParticipant.length !== 0;
    }

    public deselectAllParticipants(): void {
        this.participantsData.participants.forEach((participant: any) => {
            participant.selected = false;
        });
    }

    public selectAllParticipants(): void {
        this.participantsData.participants.forEach((participant: any) => {
            participant.selected = true;
        });
    }

    public async refreshData() {
        this.participantsData.skip = 0;
        this.participantsData.take = 25;
        await this.getParticipants(true);
    }

    public softDelete() {
        this.$refs.softDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.softDeleteModal.show();
    }

    public hardDelete() {
        this.$refs.hardDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.hardDeleteModal.show();
    }

    public async handleDelete(keepSurveyData: boolean): Promise<boolean> {
        this.$refs.pendDeleteModal.hide();
        this.$refs.softDeleteModal.hide();
        this.$refs.hardDeleteModal.hide();

        this.showPending('Deleting participant...');
        const participantService = new ParticipantsService();
        for (let i = 0; i < this.selectedParticipant.length; i++) {
            const participant = this.selectedParticipant[i];
            const [err] = await to(participantService.deleteParticipant(participant.participantId, keepSurveyData));
            if (err) {
                this.clearAndShowError(`Failed to delete participant: ${[participant.firstName, participant.lastName].join(' ')}`);
                return false;
            }
        }
        this.clearAndShowSuccess('Participants successfully deleted');
        this.getParticipants(true);
        this.onlyProjectCountZero = false;
        return true;
    }

    private getRouterLinkName(): string {
        return 'participant-details';
    }

    private async getParticipants(includeCount: boolean) {
        this.isLoaded = false;
        await this.participantsService
            .getParticipants(
                new ParticipantFilter({
                    $count: includeCount,
                    take: this.participantsData.take,
                    skip: this.participantsData.skip,
                    search: this.participantsData.search,
                    includeDemographicData: false,
                    includeProjectCount: true,
                }),
            )
            .then((value: PagedResponse<Participant>) => {
                this.participantsData.participants = value.items
                    .map((p) => {
                        p.selected = false;

                        return p;
                    })
                    .filter((p) => {
                        return p !== undefined;
                    });

                if (includeCount) {
                    this.participantsData.participantCount = value.count;
                }

                this.isLoaded = true;
            });
    }
}
