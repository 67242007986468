import SideActionBase from "@/plugins/sideActions/sideActionBase";
import { Component } from 'vue-property-decorator';
import PillarThresholdAddActionParameters from "./pillarThresholdAddActionParameters";
import { PillarThresholdType } from "@/models/PillarThreshold";
import { instrumentsService } from "@/main";
import InstrumentPillar from "@/models/InstrumentPillar";
import he from 'he';

@Component
export default class PillarThresholdAddActionComponent extends SideActionBase<PillarThresholdAddActionParameters> {
    public pillarThresholds: PillarThresholdCreate[] = [];
    public instrumentPillars: InstrumentPillar[] = [];
    public aliasOptions: string[] = [];
    public typeOptions: string[] = [];
    public selectedPillar: InstrumentPillar = null;
    public selectedThresholdType: PillarThresholdType = null;

    public async created() {
        await this.getInstrumentPillarsForInstrument(this.options.instrumentId);
    }

    public aliasLabel(pillar: InstrumentPillar) {
        return he.decode(pillar.alias);
    }

    public onAliasSelect(ev: InstrumentPillar) {
        this.selectedPillar = ev;
        this.selectedThresholdType = null;
        this.pillarThresholds = [];
        this.getTypeOptions();
    }

    public onTypeSelect(ev: PillarThresholdType) {
        this.selectedThresholdType = ev;
        this.pillarThresholds = [];
        switch (ev) {
            case PillarThresholdType.quartile:
                for (var i = 0; i < 4; i++) {
                    this.pillarThresholds.push(new PillarThresholdCreate({ pillar: this.selectedPillar, type: ev, order: i }))
                }
                break;
            case PillarThresholdType.benchmark:
                for (var i = 0; i < 3; i++) {
                    this.pillarThresholds.push(new PillarThresholdCreate({ pillar: this.selectedPillar, type: ev, order: i }))
                }
                break;
            case PillarThresholdType.barChartRatio:
                for (var i = 0; i < 2; i++) {
                    this.pillarThresholds.push(new PillarThresholdCreate({ pillar: this.selectedPillar, type: ev, order: i }))
                }
                break;
            default:
                break;
        }
    }

    public async createPillarThreshold(ev: any) {
        ev.preventDefault();
        switch (this.selectedThresholdType) {
            case PillarThresholdType.quartile:
                this.createQuartilePillarThreshold();
                break;
            case PillarThresholdType.benchmark:
                this.createBenchmarkPillarThreshold();
                break;
            case PillarThresholdType.barChartRatio:
                this.createBarChartRatioPillarThreshold();
                break;
            default:
                break;
        }
        
    }

    private async createQuartilePillarThreshold() {
        const totalValue = parseFloat(this.pillarThresholds.reduce((x, y) => x += y.value, 0).toPrecision(3))
        if (totalValue === 100.00) {
            await this.addInstrumentPillarThresholds(this.options.instrumentId, this.pillarThresholds);
        } else {
            this.showError("Total value of all quartiles must be 100!", 2500)
        }
    }

    private async createBarChartRatioPillarThreshold() {
        if (this.pillarThresholds.at(0).value < this.pillarThresholds.at(1).value) {
            await this.addInstrumentPillarThresholds(this.options.instrumentId, this.pillarThresholds);
        } else {
            this.showError("The maximal value must be higher than the minimal value!", 2500)
        }
    }

    private async createBenchmarkPillarThreshold() {
        const negative = this.pillarThresholds.slice(0, 1).reduce((x, y) => x -= +y.value, this.pillarThresholds.at(1).value)
        const positive = this.pillarThresholds.slice(1, 3).reduce((x, y) => x += +y.value, 0)
        if (negative < 0 || positive > 100) {
            this.showError("The positive or negative deviation(s) are too high!", 2500)
        } else {
            await this.addInstrumentPillarThresholds(this.options.instrumentId, this.pillarThresholds);
        }
    }

    private getTypeOptions() {
        const currentTypes: string[] = this.options.currentSettings.filter(x => x.alias === this.selectedPillar.alias).map(x => x.type.toString());
        const possibleTypes: string[] = Object.keys(PillarThresholdType).map(key => PillarThresholdType[key]).filter(value => typeof value === 'string') as string[]
        this.typeOptions = possibleTypes.filter(x => !currentTypes.includes(x));
    }

    private async getInstrumentPillarsForInstrument(instrumentId: number) {
        await instrumentsService
            .getInstrumentPillars(instrumentId)
            .then((pillars) => {
                this.instrumentPillars = pillars;
                this.isLoaded = true;
            })
            .catch(() => {
                this.showError(
                    `The pillars couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }

    private async addInstrumentPillarThresholds(instrumentId: number, pillarThresholds: PillarThresholdCreate[]) {
        this.showPending(`Adding Pillar Thresholds... 0/${pillarThresholds.length}`);
        let thresholdsAdded: number = 0;

        pillarThresholds.forEach(async (threshold: PillarThresholdCreate) => {
            await instrumentsService.addInstrumentPillarThreshold(instrumentId, threshold)
                .then((threshold) => {
                    thresholdsAdded ++;
                    if (thresholdsAdded == pillarThresholds.length) {
                        this.clearAndShowSuccess(`All ${thresholdsAdded} Pillar Thresholds have been added!`);
                        this.finished();
                    }
                    else if(threshold) {
                        this.clearNotifications();
                        this.showPending(`Adding Pillar Thresholds... ${thresholdsAdded}/${pillarThresholds.length}`)
                    }
                })
                .catch(() => {
                    this.clearAndShowError(
                        `The threshold couldn't be saved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`
                    );
                });
        })
    }
}

export class PillarThresholdCreate {
    public pillar: InstrumentPillar;
    public type: PillarThresholdType;
    public value: number;
    public order: number;

    constructor(pillar?: Partial<PillarThresholdCreate>) {
        Object.assign(this, pillar);
    }
}