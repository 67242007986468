export default class LanguagePermission {
    public emailAddress: string;
    public instrumentPermissions: InstrumentPermission[] = [];

    constructor(obj?: Partial<LanguagePermission>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
export class InstrumentPermission {
    public instrumentAlias: string;
    public instrumentId: number;
    public instrumentName: string;

    public knowledgeModelAlias: string;
    public knowledgeModelId: number;
    public knowledgeModelName: string;

    public languageCode: string;
}
