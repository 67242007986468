import { instrumentsService } from "@/main";
import SideActionBase from "@/plugins/sideActions/sideActionBase";
import to from "await-to-js";
import { Component } from 'vue-property-decorator';

@Component
export default class InstrumentExportSideActionComponent extends SideActionBase<{ instrumentId: number; }> {
    public fromDate: string = '';
    public toDate: string = '';
    public submitted: boolean = false;

    public async triggerExport(ev: any) {
        ev.preventDefault();
        this.submitted = true;
        if ((this.fromDate != '' && this.toDate != '' && new Date(this.fromDate) < new Date(this.toDate)) || (this.fromDate == '' && this.toDate == '')) {
            await this.exportInstrumentData();
            this.finished();
        } else {
            return this.showError("From date should be earlier than to date!");
        }
    }
    
    public async exportInstrumentData() {
        let fromDate = null;
        let toDate = null;
        if (this.fromDate != '' && this.toDate != '') {
            fromDate = new Date(this.fromDate);
            toDate = new Date(this.toDate);
        }
        const [err, response] = await to(instrumentsService.exportInstrumentData(this.options.instrumentId, fromDate, toDate));
        
        if (err) {
            return this.showError("Failed to export participants");
        } 
        return this.showSuccess("A download link with the data will be send to your email address");
    }
}
