import OrderBy, { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import { OrderByField } from '@/models/OrderAndFilter/OrderByField';

export default class PractitionerOrderBy extends OrderBy {
    public fields = [
        new OrderByField({
            key: 'emailAddress',
            field: 'EmailAddress',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'name',
            field: 'FirstName',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'companyName',
            field: 'CompanyName',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'partnerName',
            field: 'Partner',
            direction: OrderByDirection.None,
        }),
    ];
}
