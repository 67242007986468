import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import Partner from '@/modules/partners/models/Partner';

@Component({
    components: {
        'create-partner': require('@/modules/partners/components/create-partner.vue').default,
    },
})
export default class CreatePartnerActionComponent extends SideActionBase<any> {
    public stop() {
        this.cancel();
    }

    public partnerCreated(result: Partner) {
        this.finished(result);
    }
}
