import { Component } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { CreationStatus } from '../../../models/CreateResult';
import BasePage from '@/models/BasePage';
import { CreatePartnerModel } from '@/models/CreatePartner';
import { PartnerService } from '@/modules/partners/services/partnerService';
import PartnerCreate from '@/modules/partners/models/PartnerCreate';

@Component({
    validations: {
        createPartner: {
            companyName: { required },
            emailAddress: { required, email },
            preferredLanguage: { required },
            paperlessOrganizationId: { required },
            paperlessContactId: { required },
            discount: { required },
            instruments: { required },
        },
    },
} as any)
export default class CreatePartnerComponent extends BasePage {
    public instrumentOptions: any[] = [];

    public submitted: boolean = false;
    public loading: boolean = false;
    public createPartner: CreatePartnerModel = new CreatePartnerModel();
    public partnerService: PartnerService = new PartnerService();

    public async created() {
        await this.getInstruments();
    }

    public cancel(): void {
        this.createPartner = new CreatePartnerModel();
        this.$emit('cancel');
    }

    public async submit(): Promise<void> {
        this.submitted = true;

        if (!(this as any).$v.$invalid) {
            this.loading = true;
            this.showPending('Creating partner..');

            const partner = {
                emailAddress: this.createPartner.emailAddress,
                companyName: this.createPartner.companyName,
                preferredLanguage: this.createPartner.preferredLanguage.languageCode,
                paperlessContactId: this.createPartner.paperlessContactId,
                paperlessOrganizationId: this.createPartner.paperlessOrganizationId,
                discount: this.createPartner.discount,
                instruments: this.createPartner.instruments,
            } as PartnerCreate;

            const createdPartner = await this.partnerService.createPartner(partner);

            if (createdPartner.creationStatus === CreationStatus.created) {
                this.loading = false;
                this.$emit('created', createdPartner.entity);
                this.showSuccess('Successfully created partner');
            } else {
                this.loading = false;
                this.showError('failed to create partner');
            }
        }
    }

    private async getInstruments() {
        await this.$store.dispatch('instrumentsStore/fetch');
        const instruments = this.$store.getters['instrumentsStore/instruments'];

        instruments.forEach((instrument) => {
            this.instrumentOptions.push({ value: instrument.instrumentId, text: instrument.name });
        });
    }
}
