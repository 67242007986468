import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import ChangeDemographicQuestionParameters from './changeDemographicQuestionParameters';
import to from 'await-to-js';
import { DemoGraphicItem } from '@/models/DemographicItem';
import { required } from 'vuelidate/lib/validators';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import { OrganizationsService } from '@/services/organizationsService';
import he from 'he';

@Component({
    validations: {
        question: {
            question: { required },
            label: { required },
            answers: { required },
        },
    },
} as any)
export default class ChangeDemographicQuestionActionComponent extends SideActionBase<ChangeDemographicQuestionParameters> {
    public $refs!: {
        areYouSureModal: areYouSureModalVue;
    };
    public question: DemoGraphicItem;
    public questionTypes: any[] = [
        // { value: 'score', label: 'Score', id: 0 },
        { value: 'choice', label: 'Choice', id: 1 },
        // { value: 'arrangeOrder', label: 'Arrange order', id: 2 },
        // { value: 'percentage', label: 'Percentage', id: 3 },
        // { value: 'allThatApply', label: 'All that apply', id: 4 },
        { value: 'openQuestion', label: 'Open question', id: 5 },
    ];

    public questionViews: any[] = [
        { id: 0, value: 'unknown', label: 'Default', questionTypes: ['choice'] },
        // { id: 1, value: 'slider', label: 'Slider', questionTypes: ['percentage'] },
        { id: 2, value: 'dropdown', label: 'Dropdown', questionTypes: ['choice'] },
        // { id: 3, value: 'imageCheckbox', label: 'Image checkbox', questionTypes: ['choice'] },
        {
            id: 4,
            value: 'otherOptionWithOpenText',
            label: 'Radio buttons with [other] option combined with an open text field',
            questionTypes: ['choice'],
        },
    ];

    public selectedQuestionType: any = null;
    public selectedQuestionView: any = { value: 'unknown', label: 'Default', id: 0 };

    public tabIndex: number = 0;
    public answers: any[] = [];
    public submitted: boolean = false;
    public otherSuffix: boolean = false;

    public initialNumberOfAnswers: number = 0;
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        this.question = this.options.question;
        this.answers = this.answerObjects();
        this.selectedQuestionType = this.questionTypes.find((x) => x.value === this.options.question.questionType);
        const currentQuestionView = this.questionViews.find((x) => x.value === this.options.question.view);
        this.selectedQuestionView = currentQuestionView ? currentQuestionView : this.selectedQuestionView;
        this.initialNumberOfAnswers = this.options.question.answers.length;
    }

    public async updateQuestion() {
        this.showPending('Changing question...');

        this.question.answers = this.answers.filter((x) => x.value !== '').map((x) => he.decode(x.value));
        this.question.questionType = this.selectedQuestionType.value;
        this.question.view = this.selectedQuestionView.id > 0 ? this.selectedQuestionView.value : null;

        const [err, response] = await to(
            this.organizationsService.updateQuestionToDemographicModel(this.options.demographicId, this.options.organizationId, this.question),
        );
        if (err) {
            return this.clearAndShowError('Failed to update question!', err);
        }

        return this.finished(response.data);
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            [this.question],
            'label',
            'question',
            false,
            'Are you sure you want to delete this question? This action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    public async deleteDemographicQuestion() {
        this.showPending('Deleting question..');
        this.$refs.areYouSureModal.hide();

        const [err, response] = await to(
            this.organizationsService.deleteQuestionFromDemographicModel(this.options.demographicId, this.options.organizationId, this.question),
        );
        if (err) {
            return this.clearAndShowError('Failed to delete question!', err);
        }

        return this.finished(response.data);
    }

    public answerObjects() {
        return this.question.answers.map((x, index) => {
            return {
                value: he.decode(x),
                index,
            };
        });
    }

    public deleteAnswer(index) {
        this.question.answers.splice(index, 1);
        this.answers.splice(index, 1);
    }

    public addAnswer() {
        this.answers.push({ value: '', index: this.answers.length });
    }
}
