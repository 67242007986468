import { Component } from 'vue-property-decorator';
import { $router, loginHelper } from '../../../main';
import { OrganizationCreate, OrganizationUpdate } from '../../../models/Organization';
import { UpdateResult } from '../../../models/UpdateResult';
import CompanyTypeInputComponent from '../../../components/inputs/organizationTypeInput';
import BasePage from '../../../models/BasePage';
import { OrganizationShareOptions } from '@/components/side-actions/organization-action/organizationShareAction';
import { Country } from '@/models/Country';
import { State } from '@/models/State';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { OrganizationsService } from '@/services/organizationsService';
import { CountryService } from '@/services/countryService';
import Organization from '../models/Organization';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import { TransferOrganizationRightsOptions } from '@/modules/organizations/side-actions/transfer-organization-rights-action';
import to from 'await-to-js';

@Component({
    components: {
        'participants-overview': require('../components/participant-overview.vue').default,
        'projects-overview': require('../components/project-overview.vue').default,
        'group-overview': require('../components/group-overview.vue').default,
        'sequence-overview': require('../components/sequence-overview.vue').default,
        'subscription-overview': require('../components/subscription-overview.vue').default,
        'demographic-model': require('../components/organization-demographic-model.vue').default,
    },
})
export default class OrganizationDetailsComponent extends BasePage {
    public isSharedPractitioner: boolean = false;
    public editMode: boolean = false;
    public loading: boolean = true;
    public organizationData: Organization = new Organization();
    public organizationSubscription: OrganizationSubscription = null;
    public organizationDataEdit: OrganizationUpdate = new OrganizationUpdate();
    public practitioner: Practitioner = new Practitioner({ emailAddress: '', firstName: '', lastName: '', practitionerId: 0 });
    public countryOptions: any[] = [];
    public stateOptions: any[] = [];
    public instrumentColumns: GridColumnProps[] = [{ field: 'name', title: 'Instrument(s)' }];
    public thekey: string = new Date().getTime().toString();
    public activeSubscriptionLoaded: boolean = false;
    public practitionersLoaded: boolean = false;
    public $refs!: {
        orgTypeInput: CompanyTypeInputComponent;
        areYouSureModal: areYouSureModalVue;
    };

    public get practitioners(): Practitioner[] {
        if (this.practitioner) {
            return [this.practitioner];
        } else {
            return [];
        }
    }

    public get givenName() {
        return loginHelper.getGivenName();
    }

    public get owner() {
        if (this.organizationData && this.organizationData.practitioner) {
            return loginHelper.getUser().practitionerId === this.organizationData.practitioner.practitionerId;
        }
    }

    public get instrumentList(): any[] {
        return this.organizationSubscription.instruments;
    }

    public get tabIndex(): number {
        return this.$store.getters['organizationDetailsStore/currentTab'];
    }

    public set tabIndex(newTabIndex: number) {
        this.$store.commit('organizationDetailsStore/TAB_CHANGED', newTabIndex);
    }

    private organizationId: number = 0;
    private countries: Country[] = [];
    private organizationsService: OrganizationsService = new OrganizationsService();
    private countryService: CountryService = new CountryService();

    public async created() {
        this.organizationId = parseInt($router.currentRoute.params.organizationId);
        const [err, response] = await to(this.organizationsService.getOrganization(this.organizationId));
        if (err) {
            this.showFailedResponse('Failed to load organization', err);
            return this.$router.push({ name: 'organizations-overview' });
        }
        this.organizationData = response;

        this.loading = false;

        Promise.all([this.getOrganizationSubscription(), this.loadPractitioners()]);

        await this.countryService.getCountries().then((countries) => {
            this.countries = countries;
            this.countries.forEach((country) => {
                this.countryOptions.push({ id: country.id, name: country.name });
            });

            this.setCountryToOrganization(this.organizationData, this.countries);
            this.stateOptions = this.organizationData.selectedCountry.states;
            this.setStateToOrganization(this.organizationData, this.organizationData.selectedCountry);
        });
    }

    public async loadPractitioners() {
        this.practitioner = this.organizationData.practitioner;

        this.isSharedPractitioner =
            this.organizationData.sharedPractitioners.find((x) => x.practitionerId === loginHelper.getUser().practitionerId) != null;

        if (
            this.isPractitioner &&
            !this.isSharedPractitioner &&
            this.organizationData.practitioner.practitionerId !== loginHelper.getUser().practitionerId
        ) {
            this.clearNotifications();
            return this.$router.push({ name: 'organizations-overview' });
        }

        this.practitionersLoaded = true;
    }

    public startEdit(): void {
        this.editMode = true;
        Object.assign(this.organizationDataEdit, this.organizationData);
        this.organizationDataEdit.organizationType = this.$refs.orgTypeInput.getValueObject(this.organizationData.organizationType);

        this.organizationDataEdit.country = this.countryOptions.find((x) => {
            return this.organizationData.countryId === x.id;
        });

        this.organizationDataEdit.state = this.stateOptions.find((x) => {
            return this.organizationData.stateId === x.id;
        });
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.organizationDataEdit, this.organizationData);
    }

    public shareOrganizationSideAction() {
        this.$sideActions.push(
            'organization-share-action',
            new OrganizationShareOptions({
                organizationId: this.organizationId,
            }),
            async () => {
                await this.refreshSharedPractitioners();
            },
        );
    }

    public transferAdminRightsOrganizationSideAction() {
        this.$sideActions.push(
            'transfer-organization-rights-action',
            new TransferOrganizationRightsOptions({
                organizationId: this.organizationId,
                sharedPractitioners: this.organizationData.sharedPractitioners,
            }),
            async () => {
                await this.refreshSharedPractitioners();
            },
        );
    }
    public async refreshSharedPractitioners() {
        this.loading = true;

        await this.organizationsService.getOrganization(this.organizationId).then((data) => {
            this.organizationData = data;
        });

        this.loading = false;
    }

    public async deleteMyOrganizationShare() {
        await this.deleteOrganizationShare(loginHelper.getUser().practitionerId);
        this.$router.push({ name: 'organizations-overview' });
    }

    public async deleteOrganizationShare(practitionerId: number): Promise<void> {
        this.showPending('Deleting organization share..');
        await this.organizationsService
            .deletedShare(this.organizationId, practitionerId)
            .then(() => {
                const index = this.organizationData.sharedPractitioners.findIndex((x) => x.practitionerId === practitionerId);
                this.organizationData.sharedPractitioners.splice(index, 1);
                this.clearAndShowSuccess('Organization share deleted');
            })
            .catch((err) => {
                this.showFailedResponse('Failed to delete organization share', err);
            });
    }

    public async handleDelete(): Promise<void> {
        this.showPending('Deleting organization..');
        this.$refs.areYouSureModal.hide();
        await this.organizationsService
            .deleteOrganization(this.organizationId)
            .then(() => {
                this.$router.push({ name: 'organizations-overview' });
                this.showSuccess('Organization deleted.');
            })
            .catch((err) => {
                this.showFailedResponse('Failed to delete organization', err);
            })
            .finally(() => {
                this.clearNotifications();
            });
    }

    public async saveEdit() {
        const self = this;
        this.showPending('Saving organization details.');
        await this.organizationsService
            .updateOrganization(this.organizationId, {
                name: this.organizationDataEdit.name,
                vat: this.organizationDataEdit.vat,
                countryId: this.organizationDataEdit.country ? this.organizationDataEdit.country.id : null,
                stateId: this.organizationDataEdit.state ? this.organizationDataEdit.state.id : null,
                city: this.organizationDataEdit.city,
                zipCode: this.organizationDataEdit.zipCode,
                address: this.organizationDataEdit.address,
                organizationType: this.organizationDataEdit.organizationType.value,
                contactName: this.organizationDataEdit.contactName,
                practitionerId: this.organizationDataEdit.practitioner.practitionerId,
                retentionTime: this.organizationDataEdit.retentionTime,
                applyToPrevious: this.organizationDataEdit.applyToPrevious,
            })
            .then((result: UpdateResult<Organization>) => {
                if (result.updated) {
                    self.organizationData = result.entity;

                    this.setCountryToOrganization(this.organizationData, this.countries);
                    this.stateOptions = this.organizationData.selectedCountry.states;
                    this.setStateToOrganization(this.organizationData, this.organizationData.selectedCountry);

                    this.clearAndShowSuccess('Organization details successfully saved.');
                } else {
                    this.clearAndShowError(
                        'An error occurred while saving the organization details. Please try again or, when the problem exists, contact us via the support button.',
                        result,
                    );
                }
            })
            .catch((e) => {
                this.clearAndShowError(
                    'An error occurred while saving the organization details. Please try again or, when the problem exists, contact us via the support button.',
                    e,
                );
            });
        await this.organizationsService.updateRetentionTime(this.organizationId, {
            retentionTime: this.organizationDataEdit.retentionTime,
            applyToPreviousProjects: this.organizationDataEdit.applyToPrevious,
        })
        .then((result: UpdateResult<Organization>) => {
            if (result.updated) {
                self.organizationData = result.entity;
                self.organizationDataEdit.applyToPrevious = false;
                self.editMode = false;

                this.clearAndShowSuccess('Updated organization retention time successfully.');
            } else {
                this.clearAndShowError(
                    'An error occurred while updating the organization retention time. Please try again or, when the problem exists, contact us via the support button.',
                    result,
                );
            }
        })
        .catch((e) => {
            this.clearAndShowError(
                'An error occurred while updating the organization retention time. Please try again or, when the problem exists, contact us via the support button.',
                e,
            );
        })
        .finally(() => {
            this.clearNotifications();
        });
    }

    public onCountryChange(ev: any) {
        this.stateOptions = this.countries.find((x) => {
            return x.id === ev.id;
        }).states;

        if (this.organizationDataEdit.state) {
            this.organizationDataEdit.state = new State();
        }
    }

    public getHeight() {
        return {
            height: this.organizationSubscription.instruments.length === 0 ? 40 : this.organizationSubscription.instruments.length * 40,
        };
    }

    public async openOrganizationDashboard() {
        const orgToken = await this.organizationsService.getDashboardToken(this.organizationId);
        const url = `${this.$env().SurveyUrl}dashboard/organization/${this.organizationId}?token=${encodeURIComponent(orgToken.token)}&language=en`;
        window.open(url);
    }

    public renderOrganizationType(value): any {
        const options = [
            { value: 'forProfit', label: 'For profit' },
            { value: 'nonProfit', label: 'NGO' },
            { value: 'academic', label: 'Academic' },
            { value: 'publicSector', label: 'Public sector' },
        ];

        const option = options.find((x) => x.value === value);
        return option ? option.label ?? 'No type specified' : 'No type specified';
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            [this.organizationData],
            'name',
            'organization',
            true,
            'Are you sure you want to delete this organization? This action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    private setCountryToOrganization(organizationData: Organization, countries: Country[]) {
        if (organizationData.countryId) {
            const selectedCountry = countries.find((country) => {
                return organizationData.countryId === country.id;
            });
            organizationData.selectedCountry = selectedCountry;
        } else {
            organizationData.selectedCountry = new Country();
        }

        if (!organizationData.selectedCountry) {
            organizationData.selectedCountry = new Country();
        }
    }

    private setStateToOrganization(organizationData: Organization, country: Country) {
        if (organizationData.stateId) {
            const selectedState = country.states.find((state) => {
                return organizationData.stateId === state.id;
            });
            organizationData.selectedState = selectedState;
        } else {
            organizationData.selectedState = new State();
        }

        if (!organizationData.selectedState) {
            organizationData.selectedState = new State();
        }
    }

    private async getOrganizationSubscription() {
        const subscriptions = await this.organizationsService.getOrganizationSubscriptions(this.organizationId);
        if (subscriptions.items) {
            this.organizationSubscription = subscriptions.items.find((x) => x.isActive);

            if (this.organizationSubscription) {
                this.organizationSubscription.pricePerSeat = this.organizationSubscription.price / this.organizationSubscription.seats;
            }
        }
        this.activeSubscriptionLoaded = true;
    }
}
