import { Component, Prop } from 'vue-property-decorator';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { loginHelper } from '../../../main';
import BasePage from '@/models/BasePage';
import InstrumentPricing from '../models/InstrumentPricing';

@Component
export default class PricePlanSelectionComponent extends BasePage {
    @Prop() public practitioner: CreatePractitionerModel;
    @Prop() public instrumentPricing: InstrumentPricing[];
    public pricingOptions: any[] = [];
    public selectedPricing: number[] = [];
    private partnerService: PartnerService = new PartnerService();

    public async created() {
        this.$props.instrumentPricing.map((x) => this.selectedPricing.push(...x.pricingPlans.map((y) => y.value)));
        await this.getPricing();
    }

    public handleChange() {
        const selected = this.pricingOptions
            .filter((x) => x.pricingPlans.some((y) => this.selectedPricing.includes(y.value)))
            .map((x) => {
                return new InstrumentPricing({
                    instrument: x.instrument,
                    pricingPlans: x.pricingPlans.filter((y) => this.selectedPricing.includes(y.value)),
                });
            });
        this.$emit('selectedPricing', selected);
    }

    private async getPricing() {
        this.pricingOptions = [];
        const instruments = this.$store.getters['instrumentsStore/instruments'];

        this.practitioner.instruments.forEach(async (instrument) => {
            const instData = instruments.find((x) => x.id == instrument || x.instrumentId == instrument);
            await this.partnerService
                .getPricePlans(instrument, this.practitioner.partner.id ? this.practitioner.partner.id : loginHelper.getUser().id)
                .then((pricingPlans) => {
                    this.pricingOptions.push({
                        instrument: { name: instData.name, id: instData.id ?? instData.instrumentId },
                        pricingPlans: pricingPlans.map((x) => {
                            return { value: x.pricingPlanId, text: x.pricingPlanName + ` (${x.pricingPlanAmount})` };
                        }),
                    });
                });
        });
    }
}
