export class DemoGraphicItem {
    public id: number;
    public label: string;
    public value: string;
    public question: string;
    public questionType: string;
    public answers: string[] = [];
    public enabled: boolean;
    public order: number = 0;
    public view?: string;
    // public languages: string[] = [];
}


