import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import BasePage from '@/models/BasePage';
import Organization from '../models/Organization';
import { OrganizationsService } from '@/services/organizationsService';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import to from 'await-to-js';
import he from 'he';

@Component({
    props: {
        defaultFilter: { showAll: false, activeSubscriptionOnly: false, expiredSubscriptionOnly: false, overSubscriptionSeatsOnly: false },
    },
} as any)
export default class OrganizationOverviewComponent extends BasePage {
    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: Vue.component('grid-checkbox') },
        { field: 'name', title: 'Organization', cell: this.renderRouterLink },
        { field: 'organizationType', title: 'Organization type', cell: this.renderOrganizationType },
        { field: 'partner.companyName', title: 'Partner', cell: this.renderPartnerLink },
        { field: 'practitioner.firstName', title: 'Practitioner', cell: this.renderPractitionerLink },
        { field: 'projectCount', title: 'Project count', width: 120 },
    ];

    public defaultFilter: any;

    public $refs!: {
        areYouSureModal: areYouSureModalVue;
    };

    public organizationData: any = {
        organization: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };
    public get selectedOrganizations(): any[] {
        return this.organizationData.organization.filter((participant: any) => {
            return participant.selected;
        });
    }

    private filterTimer: number = null;
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async organizationCreatedCallback(organization: Organization) {
        await this.$router.push({ name: 'organization-details', params: { organizationId: organization.organizationId.toString() } });
    }

    public async created() {
        await this.getOrganizations();
        this.isLoaded = true;
    }

    public async reloadOrgs(event: GridPageChangeEvent) {
        this.organizationData.skip = event.page.skip;
        this.organizationData.take = event.page.take;

        await this.getOrganizations();
    }

    public get participantsSelected(): boolean {
        return this.selectedOrganizations.length !== 0;
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.organizationData.skip = 0;
            await this.getOrganizations();
        }, 400);
    }

    public renderPractitionerLink(item: any, _, row): any {
        if (!row.dataItem.practitioner) {
            return item('td');
        }

        if (this.isPractitioner) {
            return item('td', `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}`);
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.practitioner ? `${row.dataItem.practitioner.firstName} ${row.dataItem.practitioner.lastName}` : '(empty)'}`,
                url: this.$router.resolve({
                    name: 'practitioner-details',
                    params: { practitionerId: row.dataItem.practitioner.practitionerId.toString() },
                }).href,
            },
        });
    }

    public renderOrganizationType(item: any, _, row): any {
        const options = [
            { value: 'forProfit', label: 'For profit' },
            { value: 'nonProfit', label: 'NGO' },
            { value: 'academic', label: 'Academic' },
            { value: 'publicSector', label: 'Public sector' },
        ];

        const option = options.find((x) => x.value === row.dataItem.organizationType);

        return item('td', option ? option.label ?? 'No type specified' : 'No type specified');
    }

    public renderPartnerLink(item: any, _, row): any {
        if (!row.dataItem.partner) {
            return item('td');
        }

        if (this.isPractitioner) {
            return item('td', row.dataItem.partner.companyName);
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.partner ? row.dataItem.partner.companyName : '(empty)'}`,
                url: this.$router.resolve({ name: 'partner-details', params: { partnerId: row.dataItem.partner.partnerId.toString() } }).href,
            },
        });
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.name),
                url: this.$router.resolve({ name: 'organization-details', params: { organizationId: row.dataItem.organizationId.toString() } }).href,
            },
        });
    }

    public mergeOrganizationSideAction() {
        this.$sideActions.push('organization-merge-action', {}, async () => {
            this.clearAndShowSuccess('The organizations have been successfully merged');
            await this.getOrganizations();
        });
    }

    public openCreateOrganizationSideAction() {
        this.$sideActions.push('organization-create-action', {}, async () => {
            this.clearAndShowSuccess('The organization has been successfully created');
            await this.getOrganizations();
        });
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            this.selectedOrganizations,
            'name',
            this.selectedOrganizations.length === 1 ? ' organization' : `${this.selectedOrganizations.length} organizations`,
            false,
            `Are you sure you want to delete ${
                this.selectedOrganizations.length === 1 ? 'this organization' : 'these organizations'
            }? This action cannot be reverted.`,
        );
        this.$refs.areYouSureModal.show();
    }

    public async handleDelete(): Promise<boolean> {
        this.showPending('Deleting organizations.');
        this.$refs.areYouSureModal.hide();

        for (let i = 0; i < this.selectedOrganizations.length; i++) {
            const organization = this.selectedOrganizations[i];
            const [err] = await to(this.organizationsService.deleteOrganization(organization.organizationId));
            if (err) {
                this.clearAndShowError(`Failed to delete organization: ${[organization.firstName, organization.lastName].join(' ')}`);
                return false;
            }
        }

        this.clearAndShowSuccess('Organizations successfully deleted');
        this.getOrganizations();
        return true;
    }

    private async getOrganizations(includeCount: boolean = true) {
        const self = this;

        self.isLoaded = false;
        await this.organizationsService
            .getOrganizations(
                Object.assign(this.defaultFilter, {
                    $count: includeCount,
                    take: this.organizationData.take,
                    skip: this.organizationData.skip,
                    search: this.organizationData.search,
                    embedOptions: true,
                }),
            )
            .then((organizations) => {
                self.organizationData.organization = organizations.items.map((org) => {
                    org.selected = false;
                    return org;
                });
                self.organizationData.count = organizations.count;
                self.isLoaded = true;
            });
    }
}
