import { instrumentsService, languageService } from '@/main';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { Prop, Component } from 'vue-property-decorator';

import BasePage from '@/models/BasePage';
import Vue from 'vue';
import Question from '../Models/Question';
import { Translation } from '@/models/Translations';
import Instrument from '../Models/Instrument';
import to from 'await-to-js';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

@Component
export default class QuestionOverviewComponent extends BasePage {
    @Prop({ default: () => null }) public instrument: Instrument;
    @Prop({ default: () => null }) public knowledgeModel: KnowledgeModel;

    @Prop({ default: () => ({ field: 'position', dir: 'asc' }) }) public orderBy?: SortDescriptor;

    public questions: Question[] = [];
    public questionTranslations: Translation[] = [];

    public get isActive(): boolean {
        return this.instrument.active;
    }

    public columns: GridColumnProps[] = [
        { field: 'questionId', title: 'Id', width: '100px' },
        { field: '', title: 'Translation key', cell: this.renderTranslation, width: 200 },
        { field: 'questionType', title: 'Type', width: 200 },
        { field: 'reversed', title: 'Reversed', cell: this.renderBoolean, width: 100 },
        { field: 'optionCount', title: 'Option count', width: 150, cell: this.renderOptionCount },
        { field: 'view', title: 'View', width: 200, cell: this.renderView },
        { field: 'position', title: 'Position', width: 100 },
        { field: 'section', title: 'Section', width: 100 },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public async created() {
        await this.getQuestions(this.instrument.instrumentId);
        await this.getQuestionTranslations();
        this.clearNotifications();
        this.isLoaded = true;
    }

    public async getQuestionTranslations() {
        const [err, translations] = await to(languageService.getTranslations(this.knowledgeModel.alias, this.instrument.instrumentAlias, 'en'));
        if (err) {
            this.showError('The translation are not retrieved due to a error');
            return;
        }
        return (this.questionTranslations = translations);
    }

    public renderTranslation(item: any, _: any, row: any): any {
        return item(
            Vue.component('grid-translation-display'),
            {
                props: {
                    question: row.dataItem,
                    translations: this.questionTranslations,
                },
                on: {
                    mouseover: () => {
                        console.log(`QUESTION_${row.dataItem.position}`);
                    },
                },
            },
            `QUESTION_${row.dataItem.position}`,
        );
    }

    public renderView(item: any, _: any, row: any): any {
        return item('td', row.dataItem.view ? row.dataItem.view : 'default');
    }

    public renderOptionCount(item: any, _: any, row: any): any {
        return item('td', row.dataItem.optionCount ? row.dataItem.optionCount : row.dataItem.answers.length);
    }

    public renderBoolean(item: any, _: any, row: any): any {
        return item('td', row.dataItem.reversed ? 'Yes' : 'No');
    }

    public renderAmount(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.pricingPlanAmount,
                currencyCode: 'USD',
            },
        });
    }

    public renderActions(item: any, _: any, row: any): any {
        const actions = [
            { title: 'Edit', function: this.openUpdateQuestionAction },
            // { title: 'Delete', function: this.deleteQuestion },
        ];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    public openAddQuestionAction(): any {
        this.$sideActions.push(
            'question-model-add-action',
            {
                translations: this.questionTranslations.filter((x) => x.section === 'survey'),
                position: this.questions.length + 1,
                knowledgeModel: this.knowledgeModel,
            },
            async () => {
                await this.getQuestions(this.instrument.instrumentId);
                await this.getQuestionTranslations();
            },
        );
    }

    public openUpdateQuestionAction(question: Question): any {
        this.$sideActions.push(
            'question-model-update-action',
            {
                question,
                translations: this.questionTranslations.filter((x) => x.section === 'survey'),
                isInstrumentPublished: this.isActive,
                knowledgeModel: this.knowledgeModel,
            },
            async () => {
                await this.getQuestions(this.instrument.instrumentId);
            },
        );
    }

    public openUpdateQuestionActionRow(row: { dataItem: Question }): any {
        this.openUpdateQuestionAction(row.dataItem);
    }

    private async getQuestions(id: number) {
        await instrumentsService
            .getInstrumentQuestions(id)
            .then((questions) => {
                this.questions = orderBy(questions, [this.orderBy]);
            })
            .catch(() => {
                this.showError(
                    `The pricing plans couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }

    // private async deleteQuestion(pricingPlan: any) {
    //     if (confirm(`Are you sure that you want to delete the pricing plan with the name: ${pricingPlan.pricingPlanName}`)) {
    //         this.showPending('Deleting pricing plan');
    //         await instrumentsService
    //             .deleteQuestion(pricingPlan.pricingPlanId)
    //             .then(async () => {
    //                 this.clearAndShowSuccess('Pricing plan successfully deleted!');
    //                 await this.getQuestions(this.instrumentId);
    //             })
    //             .catch((e) => {
    //                 this.clearAndShowError('Pricing plan could not be deleted.', e);
    //             });
    //     }
    // }
}
