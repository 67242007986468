import { loginHelper, loginService } from '@/main';
import BasePage from '@/models/BasePage';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import QrcodeVue from 'qrcode.vue';
import OtpInput from 'otp-input-vue2';
import { AxiosError } from 'axios';

@Component({
    components: {
        QrcodeVue,
        OtpInput,
    },
})
export default class APIKeysProfileOverviewComponent extends BasePage {
    public qrCodeLink: string = '';
    public isCodeValid: boolean = true;
    public isCodeSent: boolean = false;
    public is2faEnabled: boolean = false;

    public async mounted() {
        this.is2faEnabled = loginHelper.is2faEnabled();
    }

    public async setup2fa() {
        const [err, response] = await to(loginService.setupTwoFactorAuth());

        if (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response.data && axiosError.response.data['Message']) {
                this.showWarning(
                    `Cannot setup Two-factor authentication. Response: '${axiosError.response.data['Message']}', this is probably because you already have 2FA enabled.`,
                );

                return;
            }

            this.showError(err);
            return;
        }

        this.qrCodeLink = response.data.Uri;
    }

    public async onCompleteHandler(code) {
        if (!this.isCodeSent) {
            await this.postOTP(code);
        }
    }

    public async postOTP(code) {
        this.isCodeSent = true;
        const [err] = await to(loginService.enableTwoFactorAuth(code));

        if (err) {
            this.isCodeValid = false;
            return;
        }

        this.showSuccess(
            'Congratulations! Two-factor authentication is now enabled. Next time you login, you will be asked for a code from your authenticator app.',
        );
        const user = loginHelper.getUser();
        user.twoFactorAuthEnabled = true;
        loginHelper.setUser(user);

        this.is2faEnabled = true;
        this.isCodeSent = false
    }
}
