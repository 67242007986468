import Participant from '@/modules/participants/models/Participant';
import { Component, Prop } from 'vue-property-decorator';
import BasePage from '../../models/BasePage';
import { ParticipantState, EntityCreateState } from '../../pages/surveys/models/participant';

@Component
export default class BaseParticipantManagement extends BasePage {
    @Prop({ required: true }) public organizationId: number;

    protected addBaseParticipant(participantData: ParticipantThing, finished: boolean, extraData?: any): void {
        this.$emit('add-participant', participantData, finished, extraData);
    }

    protected cancel(): void {
        this.$emit('cancel');
    }
}

// tslint:disable: max-classes-per-file
export class EntityCreationWrapper<T> {
    public entity: T;

    public creationStatus?: EntityCreateState = EntityCreateState.Undefined;
    public errorReason?: string = '';

    constructor(data: EntityCreationWrapper<T>) {
        Object.assign(this, data);
    }
}

export class ParticipantThing {
    public participantData: Participant;
    public participantAddState: ParticipantState;

    constructor(data: ParticipantThing) {
        Object.assign(this, data);
    }
}
