import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import KnowledgeModelCreate from '../models/KnowledgeModelCreate';
import { KnowledgeModelService } from '../services/knowledgeModelService';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        knowledgeModel: {
            name: { required },
            domain: { required },
            primaryColor: { required },
            secondaryColor: { required },
        },
    },
} as any)
export default class AddKnowledgeModelAction extends SideActionBase<CreateKnowledgeModelSideActionOptions> {
    public knowledgeModel: KnowledgeModelCreate = new KnowledgeModelCreate();

    public submitted = false;
    public loading = false;

    private knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public async mounted() {
        if (this.isPractitioner) {
            this.cancel();
        }

        this.isLoaded = true;
    }

    public async createKnowledgeModel(evt: any): Promise<any> {
        evt.preventDefault();
        this.submitted = true;

        if ((this as any).$v.$invalid) {
            this.showError(`The knowledge model didn't pass the validation.`);
            return;
        }

        this.loading = true;
        const [err] = await to(this.knowledgeModelService.createKnowledgeModel(this.knowledgeModel));
        if (err) {
            this.loading = false;
            return this.clearAndShowError(`The knowledge model couldn't be created due to an error, please try again or contact an admin.`, err);
        }

        this.clearAndShowSuccess('Knowledge model created successfully');
        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.clearNotifications();
        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
export class CreateKnowledgeModelSideActionOptions {
    // public knowledgeModel: KnowledgeModel;

    constructor(data: CreateKnowledgeModelSideActionOptions) {
        Object.assign(this, data);
    }
}
