import { AxiosResponse } from 'axios';
import { BaseService } from './baseService';
import EmailTemplate from '../modules/emailTemplates/models/emailTemplate';
import { UpdateResult } from '../models/UpdateResult';
import Vue from 'vue';

export class CustomEmailTemplateService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getTemplates(projectId: number, template: string, languages: string[]): Promise<EmailTemplate[]> {
        return new Promise<EmailTemplate[]>((resolve, reject) => {
            let languageCodesQueryString = '';
            if (languages !== null && languages.length > 0) {
                languageCodesQueryString = languages
                    .map((val: string) => {
                        return `languages=${val}`;
                    })
                    .join('&');
            } else {
                languageCodesQueryString = 'languages=en';
            }

            this.get<EmailTemplate[]>(
                `${this.endpoint}/custom-email-templates?project_id=${projectId}&template=${template}&${languageCodesQueryString}`,
            )
                .then((response: AxiosResponse<EmailTemplate[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public upsertTemplates(projectId: number, template: string, emailTemplates: EmailTemplate[]): Promise<UpdateResult<EmailTemplate>> {
        return new Promise<UpdateResult<EmailTemplate>>((resolve, reject) => {
            this.put<UpdateResult<EmailTemplate>>(
                `${this.endpoint}/custom-email-templates?project_id=${projectId}&template=${template}`,
                emailTemplates,
            )
                .then((response: AxiosResponse<UpdateResult<EmailTemplate>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
