import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import BasePage from '@/models/BasePage';
import { KnowledgeModelService } from '../services/knowledgeModelService';

@Component({})
export default class KnowlegdeModelOverviewComponent extends BasePage {
    public actions: any[] = [];
    public knowlegdeModelService: KnowledgeModelService = new KnowledgeModelService();
    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public columns: GridColumnProps[] = [{ field: 'name', title: 'Name', cell: this.renderRouterlink }];

    public knowledgeModelData: any = {
        knowledgeModels: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };

    private filterTimer: number = null;

    public async created() {
        await this.getKnowlegdeModels(true);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.knowledgeModelData.skip = event.page.skip;
        this.knowledgeModelData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getKnowlegdeModels(false);
    }

    public renderRouterlink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.name,
                url: this.$router.resolve({ name: 'knowledge-model-details', params: { knowledgeModelId: row.dataItem.knowledgeModelId.toString() } })
                    .href,
            },
        });
    }

    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            self.knowledgeModelData.skip = 0;
            await self.getKnowlegdeModels(true);
        }, 400);
    }

    public async refreshData() {
        this.knowledgeModelData.skip = 0;
        this.knowledgeModelData.take = 25;
        await this.getKnowlegdeModels(true);
    }

    public openCreateknowledgeModelAction(): any {
        this.$sideActions.push('knowledge-model-create-action', null, async () => {
            await this.refreshData();
        });
    }

    private async getKnowlegdeModels(includeCount: boolean) {
        this.isLoaded = false;

        const response = await this.knowlegdeModelService.getKnowledgeModels({
            skip: this.knowledgeModelData.skip,
            take: this.knowledgeModelData.take,
            $count: includeCount,
            search: this.knowledgeModelData.search,
        });

        this.knowledgeModelData.knowledgeModels = response.items;

        if (includeCount) {
            this.knowledgeModelData.count = response.count;
        }

        this.isLoaded = true;
    }
}
