import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { projectsService, languageService, $router } from '../../main';
import { Project } from '../../models/Project';
import { ParticipantState, SurveyCreate } from './models/participant';
import { ErrorTypes } from '../../models/enums/ErrorTypes';
import { Language } from '../../models/Language';
import { required, email } from 'vuelidate/lib/validators';
import Participant from '@/modules/participants/models/Participant';

@Component({
    validations: {
        inputModel: {
            firstName: { required },
            lastName: { required },
            emailAddress: { required, email },
            preferredLanguage: { required },
        },
    },
} as any)
export default class AddNewParticipantComponent extends Vue {
    public languages: Language[] = [];
    public inputModel: any = {
        preferredLanguage: {},
    };
    public submitted: boolean = false;
    public newEntityAfterAdding: boolean = true;
    private projectId: number = 0;
    private projectData: Project = null;

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);
        this.projectData = await projectsService.getProject(this.projectId);
        this.languages = await languageService.getLanguagesForInstrument(this.projectData.instruments[0].instrumentId);
        this.inputModel.preferredLanguage = this.languages.find((x) => x.languageCode.toLowerCase() === 'en');
    }

    public addParticipant(): void {
        if ((this as any).$v.$invalid) {
            return;
        }

        const self = this;
        const survey = new SurveyCreate(null);
        survey.participant = new Participant(this.inputModel);
        survey.participant.organizationId = this.projectData.organization.organizationId;
        survey.participant.preferredLanguage = this.inputModel.preferredLanguage.languageCode;
        survey.participant.preferredLanguageObject = this.inputModel.preferredLanguage;
        survey.state = ParticipantState.Created;

        this.$store
            .dispatch('participantsStore/addParticipant', survey)
            .then(async () => {
                if (self.newEntityAfterAdding) {
                    self.submitted = false;
                    self.inputModel = {};
                } else {
                    await $router.push({ name: 'addSurvey' });
                }
            })
            .catch((error) => {
                if (error === ErrorTypes.DUPLICATE) {
                    alert('A person with this emailAddress was already added to this project.');
                }
            });
    }
}
