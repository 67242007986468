import { BaseService } from './baseService';
import { PagedResponse } from '../models/PagedResponse';
import { SurveyDetails } from '../models/Survey';
import { AxiosResponse } from 'axios';

import Vue from 'vue';

export class UserSurveyService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getSurveys(userId: number): Promise<PagedResponse<SurveyDetails>> {
        return new Promise<PagedResponse<SurveyDetails>>((resolve, reject) => {
            this.get<PagedResponse<SurveyDetails>>(`${this.endpoint}/users/${userId}/surveys?embedOptions=project`)
                .then((data: AxiosResponse<PagedResponse<SurveyDetails>>) => {
                    resolve(data.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
