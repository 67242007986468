import { Language } from '@/models/Language';

export default class ParticipantUpdate {
    public participantId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public organizationId: number = 0;
    public preferredLanguage: string | Language = null;
    public userId?: number = null;

    constructor(userUpdate?: any) {
        (Object as any).assign(this, userUpdate);
    }
}
