import { render, staticRenderFns } from "./addGroupMembers.vue?vue&type=template&id=07d64820&"
import script from "./addGroupMembers.ts?vue&type=script&lang=js&"
export * from "./addGroupMembers.ts?vue&type=script&lang=js&"
import style0 from "./addGroupMembers.vue?vue&type=style&index=0&id=07d64820&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports