import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';

@Component({
    components: {
        'add-demographic-data': require('@/modules/participants/components/add-demographic-data.vue').default,
    },
})
export default class OrganizationAddDemographicDataActionComponent extends SideActionBase<OrganizationAddDemographicDataOptions> {
    public addedDemographicData(org: any): void {
        this.finished(org);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class OrganizationAddDemographicDataOptions {
    public organizationId: number;
    public participants: any[];

    constructor(actionParams: OrganizationAddDemographicDataOptions) {
        Object.assign(this, actionParams);
    }
}
