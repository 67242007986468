import Vue from 'vue';
import { AxiosResponse } from 'axios';
import { BaseService } from './baseService';
import EmailTemplate from '../modules/emailTemplates/models/emailTemplate';

export class EmailService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;
    constructor() {
        super();
    }

    public updateTemplate(id, model) {
        return this.put(`${this.endpoint}/emails/:id`, model, [['id', id]]);
    }

    public async getTemplates(template?: string, languages?: string[]): Promise<EmailTemplate[]> {
        return new Promise<EmailTemplate[]>((resolve, reject) => {
            let languageCodesQueryString = '';

            if (template && (languages === null || languages.length === 0)) {
                reject('Empty languages');
            }

            if (template && languages !== null && languages.length > 0) {
                languageCodesQueryString += `?template=${template}&`;
                languageCodesQueryString += languages
                    .map((val: string) => {
                        return `languages=${val}`;
                    })
                    .join('&');
            }

            this.get<EmailTemplate[]>(`${this.endpoint}/emails${languageCodesQueryString}`)
                .then((response: AxiosResponse<EmailTemplate[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
