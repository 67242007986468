import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { organizationGroupMembershipService } from '../../../main';
import BasePage from '../../../models/BasePage';
import { AddGroupMembersOptions } from '../../../components/side-actions/group-action/addGroupMembers';
import Participant from '@/modules/participants/models/Participant';

@Component
export default class OrganizationGroupMembersOverviewComponent extends BasePage {
    @Prop({ default: 0 }) public organizationId: number;
    @Prop({ default: 0 }) public groupId: number;

    public members: Participant[] = [];
    public key: number = new Date().getTime();

    public memberColumns: GridColumnProps[] = [
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'emailAddress', title: 'Email address' },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public async created() {
        this.members = await this.loadGroupMembers();
    }

    public async loadGroupMembers(): Promise<Participant[]> {
        const members = await organizationGroupMembershipService.getGroupMembers(this.organizationId, this.groupId);
        return (this.members = members);
    }
    public notifyAlteration(): void {
        this.$emit('membershipsAltered');
        this.key = new Date().getTime();
    }

    public showAddMembersAction(): void {
        this.$sideActions.push(
            'add-group-member-action',
            new AddGroupMembersOptions({ groupId: this.groupId, members: this.members, organizationId: this.organizationId }),
            async () => {
                await this.loadGroupMembers();
                this.notifyAlteration();
            },
        );
    }

    private renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.firstName} ${row.dataItem.lastName}`,
                url: this.$router.resolve({ name: 'participant-details', params: { participantId: row.dataItem.participantId.toString() } }).href,
            },
        });
    }

    private renderActions(item: any, _, row): any {
        const actions = [{ title: 'Delete', function: this.deleteMember }];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    private deleteMember(member): void {
        if (confirm(`Are you sure that you want to delete ${member.firstName} from the group?`)) {
            this.showPending('Deleting person from group');

            organizationGroupMembershipService
                .deleteGroupMembership(this.organizationId, this.groupId, member.participantId)
                .then(async () => {
                    this.clearAndShowSuccess('Person successfully deleted!');
                    await this.loadGroupMembers();
                    this.notifyAlteration();
                })
                .catch((e) => {
                    this.clearAndShowError('Person could not be deleted.', e);
                });
        }
    }
}
