export class CreateResult<T> {
    public entity: T = null;
    public creationStatus: CreationStatus;
    public reason: string;

    constructor(entity: T, creationStatus: CreationStatus) {
        this.entity = entity;
        this.creationStatus = creationStatus;
    }
}

export enum CreationStatus {
    created = 'created',
    failed = 'failed',
    duplicate = 'duplicate',
}
