import Vue from 'vue';
import to from 'await-to-js';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '@/services/baseService';
import Partner from '../models/Partner';
import { AxiosResponse } from 'axios';
import PartnerUpdate from '../models/PartnerUpdate';
import PartnerFilter from '../models/PartnerFilter';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import PartnerCreate from '../models/PartnerCreate';
import { CreateResult } from '@/models/CreateResult';
import Instrument from '../models/Instrument';
import PricePlan from '../models/PricePlan';
import Organization from '@/modules/organizations/models/Organization';

export class PartnerService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getPartner(partnerId: number): Promise<Partner> {
        const [err, response] = await to(this.get(`${this.endpoint}/partners/${partnerId}`));
        if (err) {
            return new Partner();
        }

        return new Partner(response.data);
    }

    public createPartner(partnerCreate: PartnerCreate): Promise<CreateResult<Partner>> {
        return new Promise<CreateResult<Partner>>((resolve, reject) => {
            this.post<CreateResult<Partner>>(`${this.endpoint}/partners`, partnerCreate)
                .then((response: AxiosResponse<CreateResult<Partner>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public updatePartner(partnerId: number, partnerUpdate: PartnerUpdate): Promise<Partner> {
        return new Promise<Partner>((resolve, reject) => {
            this.put<Partner>(`${this.endpoint}/partners/${partnerId}`, partnerUpdate)
                .then((response: AxiosResponse<Partner>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getPartners(filter: Partial<PartnerFilter>): Promise<PagedResponse<Partner>> {
        return new Promise<PagedResponse<Partner>>(async (resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                    }
                }
            }

            await this.get<PagedResponse<Partner>>(`${this.endpoint}/partners?${params.join('&')}`)
                .then((result: AxiosResponse<PagedResponse<Partner>>) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deletePartner(partnerId: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.delete(`${this.endpoint}/partners/${partnerId}`)
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getPractitioners(partnerId: number): Promise<PagedResponse<Practitioner>> {
        return new Promise<PagedResponse<Practitioner>>((resolve, reject) => {
            this.get<PagedResponse<Practitioner>>(`${this.endpoint}/partners/${partnerId}/practitioners`)
                .then((response: AxiosResponse<PagedResponse<Practitioner>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getInstruments(partnerId: number): Promise<PagedResponse<Instrument>> {
        return new Promise<PagedResponse<Instrument>>((resolve, reject) => {
            this.get<PagedResponse<Instrument>>(`${this.endpoint}/partners/${partnerId}/instruments`)
                .then((response: AxiosResponse<PagedResponse<Instrument>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getOrganizations(): Promise<PagedResponse<Organization>> {
        return new Promise<PagedResponse<Organization>>((resolve, reject) => {
            this.get<PagedResponse<Organization>>(`${this.endpoint}/organizations`)
                .then((response: AxiosResponse<PagedResponse<Organization>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public setInstruments(partnerId: number, instruments: number[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.put(`${this.endpoint}/partners/${partnerId}/instruments`, { instruments })
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getPricePlans(instrumentId: number, partnerId: number): Promise<PricePlan[]> {
        return new Promise<PricePlan[]>((resolve, reject) => {
            this.get<PricePlan[]>(`${this.endpoint}/partners/${partnerId}/instruments/${instrumentId}/price-plans`)
                .then((response: AxiosResponse<PricePlan[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public setPricePlans(partnerId: number, instrumentId: number, pricePlans: number[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.post(`${this.endpoint}/partners/${partnerId}/instruments/${instrumentId}/price-plans`, { pricePlanIds: pricePlans })
                .then((response: AxiosResponse<boolean>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public disablePartner(partnerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/partners/${partnerId}/disable`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public enablePartner(partnerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/partners/${partnerId}/enable`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addToGroup(practitionerId: number, partnerId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/partners/${partnerId}/add-to-group?practitionerId=${practitionerId}`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
