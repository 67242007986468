import { projectsService } from './../../../main';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import Participant from '../models/Participant';
import { Language } from '@/models/Language';
import { ParticipantsService } from '../services/participantsService';
import ParticipantUpdate from '../models/ParticipantUpdate';
import BasePage from '@/models/BasePage';
import { languageService } from '@/main';
import he from 'he';
import { ProjectsService } from '@/services/projectsService';

@Component
export default class ParticipantDetailsComponent extends BasePage {
    public isLoaded: boolean = false;
    public editMode: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'projectName', title: 'Project', cell: this.renderProjectRouterLinkWithBreachCheck },
        { field: 'organizationName', title: 'Organization', cell: this.renderRouterLinkOrg },
    ];

    public participantsService: ParticipantsService = new ParticipantsService();
    public projectsService: ProjectsService = new ProjectsService();
    public participant: Participant = new Participant();
    public participantEdit: ParticipantUpdate = new ParticipantUpdate();
    public $refs!: {
        projectsOverviewGrid: any;
    };

    public projectsData: any = {
        projects: [],
        projectsCount: 0,
        skip: 0,
        take: 10,
    };
    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    public get participantId(): number {
        return parseInt(this.$router.currentRoute.params.participantId);
    }

    private languages: Language[] = [];

    public beforeCreate() {
        this.showPending('Loading...');
    }

    public async created() {
        await this.loadParticipant();
    }

    public async getParticipantDetails() {
        await this.participantsService.getParticipant(this.participantId).then((data) => {
            this.participant = data;
        });
    }

    public startEdit(): void {
        this.editMode = true;

        Object.assign(this.participantEdit, this.participant);

        this.participantEdit.preferredLanguage = this.languages.find((x) => {
            return x.languageCode === this.participant.preferredLanguage;
        });
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.participantEdit, this.participant);
    }

    public setLanguage(language: Language): void {
        this.participantEdit.preferredLanguage = language;
    }

    public async saveEdit() {
        this.showPending('Loading...');
        await this.updateParticipant({
            participantId: this.participantEdit.participantId,
            firstName: this.participantEdit.firstName,
            lastName: this.participantEdit.lastName,
            emailAddress: this.participantEdit.emailAddress,
            preferredLanguage: (this.participantEdit.preferredLanguage as Language).languageCode,
            organizationId: this.participantEdit.organizationId,
        } as ParticipantUpdate);
        this.clearNotifications();
    }

    public renderProjectRouterLinkWithBreachCheck(item: any, _, row): any {
        const projectUrl = this.$router.resolve({ name: 'project-details', params: { projectId: row.dataItem.projectId.toString() } }).href;

        return this.determineProjectRouterComponent(item, row, projectUrl);
    }

    public renderRouterLinkOrg(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.organization.name),
                url: this.$router.resolve({ name: 'organization-details', params: { organizationId: row.dataItem.organizationId.toString() } }).href,
            },
        });
    }

    public projectsFiltered(): Participant[] {
        const projects = filterBy(this.projectsData.projects, this.filter);
        return projects.slice(this.projectsData.skip, this.projectsData.take + this.projectsData.skip) as Participant[];
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.projectsData.skip = event.page.skip;
        this.projectsData.take = event.page.take;
    }

    public filterChanged(input: string): void {
        this.filter.filters = [];

        const splittedInput = input.split(' ');
        for (let i = 0; i < splittedInput.length; i++) {
            this.filter.filters.push(
                { field: 'projectName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
                { field: 'organizationName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
            );
        }

        this.projectsData.skip = 0;
    }

    public getLanguageName(languageCode) {
        const language = this.languages.find((x) => {
            return x.languageCode === languageCode.toLowerCase();
        });

        if (!language) {
            return 'N/A';
        }

        return language.languageName;
    }

    private async updateParticipant(participantData: ParticipantUpdate) {
        const self = this;

        await this.participantsService.updateParticipant(this.participantId, participantData).then((result: Participant) => {
            self.editMode = false;
            self.participant = result;
        });
    }

    private async loadParticipant() {
        this.isLoaded = false;
        await this.getParticipantDetails();
        const projects = await this.participantsService.getParticipantProjects(0, 25, true, this.participantId);

        this.projectsData.projects = projects.items;
        this.projectsData.projectsCount = projects.count;

        if (this.participant.preferredLanguage !== null) {
            this.languages = languageService.getLanguages();
        }

        this.isLoaded = true;
        this.clearNotifications();
    }

    public openParticipantDemographicDataManagementSideAction() {
        this.$sideActions.push('participant-manage-demographic-data-action', { participant: this.participant }, async () => {
            this.isLoaded = false;
            await this.getParticipantDetails();
            this.isLoaded = true;
        });
    }
}
