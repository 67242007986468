import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridDateDisplayComponent extends Vue {
    @Prop() public date: Date;
    @Prop() public dataItem: any;
    @Prop() public field: string;
    @Prop() public showTime: boolean;

    public model: Date = null;

    public async mounted() {
        let date = this.date;
        if (date === undefined && this.dataItem !== undefined) {
            date = this.dataItem[this.field];
        }
        this.model = date;
    }
}
