import { SurveyDetails } from '@/models/Survey';
import { EmailOverviewItem } from '@/modules/projects/components/email-overview';

export default class ProjectParticipant {
    public deleted: boolean;
    public emailAddress: string;
    public firstName: string;
    public lastName: string;
    public participantId: number;
    public preferredLanguage: string;
    public scheduledEmails: EmailOverviewItem[] = [];

    public surveyLink: string = '';
    public reportLink: string;

    public surveys: SurveyDetails[];

    public selected: boolean = false;
    public expanded: boolean = false;
    public isAnonymous: boolean = false;

    constructor(obj?: Partial<ProjectParticipant>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
