import { AxiosPromise, AxiosResponse } from 'axios';

import Vue from 'vue';
import to from 'await-to-js';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '@/services/baseService';
import Participant from '../models/Participant';
import { ParticipantFilter } from '../models/ParticipantFilter';
import ParticipantUpdate from '../models/ParticipantUpdate';
import ParticipantCreate from '../models/ParticipantCreate';
import { CreateResult } from '@/models/CreateResult';
import { Project } from '@/models/Project';
import ParticipantOrderBy from '@/pages/surveys/models/ParticipantOrderBy';

export class ParticipantsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
         super();
    }

    public async createParticipant(participantData: ParticipantCreate): Promise<CreateResult<Participant>> {
        return new Promise<CreateResult<Participant>>((resolve, reject) => {
            this.post<CreateResult<Participant>>(`${this.endpoint}/participants`, participantData)
                .then((response: AxiosResponse<CreateResult<Participant>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public async getParticipant(participantId: number): Promise<Participant> {
        const [err, response] = await to(this.get(`${this.endpoint}/participants/${participantId}`));
        if (err) {
            return new Participant();
        }

        return new Participant(response.data);
    }

    public updateParticipant(participantId: number, participantData: ParticipantUpdate): Promise<Participant> {
        return new Promise<Participant>((resolve, reject) => {
            this.put<Participant>(`${this.endpoint}/participants/${participantId}`, participantData)
                .then((response: AxiosResponse<Participant>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getParticipants(filter: Partial<ParticipantFilter>, orderBy?: ParticipantOrderBy): Promise<PagedResponse<Participant>> {
        return new Promise<PagedResponse<Participant>>(async (resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        if (property === 'organizationId') {
                            params.push('organization_id=' + encodeURIComponent(filter[property]));
                        } else {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                        }
                    }
                }
            }

            if (orderBy) {
                const orderByString = orderBy.getQueryString();
                if (orderByString) {
                    params.push(orderByString);
                }
            }

            await this.get<PagedResponse<Participant>>(
                `${this.endpoint}/participants?${params.join('&')}&embedOptions=LastCompletedSurvey${filter.includeDemographicData ? ',DemographicData' : ''}${
                    filter.includeProjectCount ? ',ProjectCount' : ''
                }`,
            )
                .then((result: AxiosResponse<PagedResponse<Participant>>) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public disableParticipant(participantId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/participant/${participantId}/disable`)
                .then(() => {
                    resolve();
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deleteParticipant(participantId: number, keepSurveyData: boolean): AxiosPromise {
        return this.delete(`${this.endpoint}/participants/${participantId}?keepSurveyData=${keepSurveyData}`, {
            keepSurveyData,
        });
    }

    public async updateMultipleDemographicData(demographicData: any[], organizationId: number, replaceExistingData: boolean) {
        return this.post(`${this.endpoint}/organizations/${organizationId}/demographic-data`, { demographicData, replaceExistingData });
    }

    public async updateMultipleDemographicDataOnProject(demographicData: any[], projectId: number, replaceExistingData: boolean) {
        return this.post(`${this.endpoint}/projects/${projectId}/demographic-data`, { demographicData, replaceExistingData });
    }

    public async updateDemographicData(participant: Participant) {
        return this.put(`${this.endpoint}/participants/${participant.participantId}/demographic-data`, {
            demographicData: participant.demographicData,
            replaceExistingData: true,
        });
    }

    public getParticipantProjects(skip: number, take: number, includeCount: boolean, participantId: number): Promise<PagedResponse<Project>> {
        return new Promise<PagedResponse<Project>>(async (resolve, reject) => {
            await this.get<PagedResponse<Project>>(`${this.endpoint}/projects?skip=${skip}&take=${take}&$count=${includeCount}&participant_id=${participantId}&embedOptions=practitioner,organization`)
                .then((result: AxiosResponse<PagedResponse<Project>>) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
