import { GridAddMode } from '@/modules/participants/grid/grid-add-mode';
import Participant from '@/modules/participants/models/Participant';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridEditWrapper } from '../../models/GridEditWrapper';
import { PagedResponse } from '../../models/PagedResponse';
import BaseParticipantManagement, { ParticipantThing } from './baseParticipantManagement';

@Component
export default class AddExistingParticipantComponent extends BaseParticipantManagement {
    @Prop({ default: () => [] }) public addModes: GridAddMode[];
    @Prop({ required: false, default: () => [] }) public alreadySelectedParticipants: Participant[];

    public selectedParticipants: any = [];
    public organizationParticipants: Array<GridEditWrapper<any>> = [];
    public isLoaded: boolean = false;
    public existingParticipantsKey: string = '';

    public columns: any[] = [
        { field: 'dataItem.addMode', title: 'Add', cell: this.renderAddMode },
        { field: 'dataItem.firstName', title: 'First name' },
        { field: 'dataItem.lastName', title: 'Last name' },
        { field: 'dataItem.emailAddress', title: 'Email address' },
        { field: 'dataItem.completionDate', title: 'Date completed', cell: this.renderDate },
    ];

    public $refs!: {
        existingParticipants: any;
    };

    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    private participantService: ParticipantsService = new ParticipantsService();

    public async created() {
        await this.participantService
            .getParticipants({ organizationId: this.organizationId, skip: 0, take: 9999 })
            .then((participants: PagedResponse<Participant>) => {
                for (let i = 0; i < participants.items.length; i++) {
                    if (
                        !this.alreadySelectedParticipants.find((s) => {
                            return participants.items[i].participantId === s.participantId;
                        })
                    ) {
                        // surveys contains any participant with this id
                        this.organizationParticipants.push(
                            new GridEditWrapper<any>(true, {
                                addMode: 'add',
                                id: participants.items[i].participantId,
                                preferredLanguage: participants.items[i].preferredLanguage,
                                firstName: participants.items[i].firstName,
                                lastName: participants.items[i].lastName,
                                emailAddress: participants.items[i].emailAddress,
                                originalParticipant: participants.items[i],
                            }),
                        );
                    }
                }

                this.isLoaded = true;
            });
    }

    public async mounted() {
        this.updateCacheKey();
    }

    public addSelectedParticipants(): void {
        const self = this;

        const selectedParticipants = this.existingParticipantsFiltered().filter((item) => {
            return item.dataItem.addMode !== 'none';
        });
        for (let i = 0; i < selectedParticipants.length; i++) {
            const selectedAddMode = self.addModes.find((addMode) => {
                return addMode.value === selectedParticipants[i].dataItem.addMode;
            });
            if (selectedAddMode) {
                self.addBaseParticipant(
                    new ParticipantThing({
                        participantData: selectedParticipants[i].dataItem.originalParticipant,
                        participantAddState: selectedAddMode.participantState,
                    }),
                    i === selectedParticipants.length - 1,
                );
            }
        }

        this.showSuccess('Participants successfully added.');
    }

    public cancel(): void {
        this.$emit('cancel');
    }

    public renderDate(item: any, _, row: any): any {
        return item(Vue.component('grid-date-display'), { props: { date: row.dataItem.dataItem.completionDate } });
    }

    public existingParticipantsFiltered(): Array<GridEditWrapper<any>> {
        return filterBy(this.organizationParticipants, this.filter);
    }

    public participantSearched(input: string): void {
        this.filter.filters = [];

        const splittedInput = input.split(' ');
        for (let i = 0; i < splittedInput.length; i++) {
            this.filter.filters.push(
                { field: 'dataItem.firstName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
                { field: 'dataItem.lastName', value: splittedInput[i], ignoreCase: true, logic: 'or', filters: null, operator: 'contains' },
            );
        }

        this.updateCacheKey();
    }

    public renderAddMode(item: any, _, row: any): any {
        return item(Vue.component('grid-add-mode'), {
            props: {
                field: 'dataItem.addMode',
                dataItem: row.dataItem,
                addModes: this.addModes.slice(0, this.addModes.length),
            },
        });
    }

    private updateCacheKey(): void {
        this.existingParticipantsKey = new Date().getTime().toString();
    }
}
