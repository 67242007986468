import Vue from 'vue';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '../../../services/baseService';
import InvoiceFilter from '../models/InvoiceFilter';
import Invoice from '../models/Invoice';
import { AxiosResponse } from 'axios';

export class InvoiceService extends BaseService {
    private endpoint = `${Vue.$env().InvoiceServiceEndpoint}/api/invoice`;

    constructor() {
        super();
    }

    public async getInvoices(invoiceFilter: InvoiceFilter): Promise<AxiosResponse<PagedResponse<Invoice>>> {
        const params = this.objectToQueryString(invoiceFilter);

        return this.get(`${this.endpoint}${params}`);
    }

    public async getInvoiceLines(invoiceId: number, invoiceFilter: InvoiceFilter): Promise<AxiosResponse<Invoice>> {
        const params = this.objectToQueryString(invoiceFilter);

        return this.get(`${this.endpoint}/details/${invoiceId}${params}`);
    }

    public async updateInvoice(invoice: Invoice): Promise<AxiosResponse<Invoice>> {
        return this.put(`${this.endpoint}`, invoice);
    }
}
