import { loginHelper } from '@/main';
import BasePage from '@/models/BasePage';
import { Component } from 'vue-property-decorator';
import PractitionersOverviewComponent from '../components/practitioner-overview';
import Practitioner from '../models/Practitioner';
import { PractitionerService } from '../services/practitionerService';

@Component({
    components: {
        'practitioner-overview': require('../components/practitioner-overview.vue').default,
    },
    props: {
        defaultFilter: { directInvoicedOnly: null, missingPaperlessOnly: null, performanceView: null },
    },
} as any)
export default class PractitionerOverviewComponent extends BasePage {
    public defaultFilter: any;

    public overviewActions: any[] = [];
    public practitionerService: PractitionerService = new PractitionerService();

    public $refs!: {
        practitionerOverview: PractitionersOverviewComponent;
    };

    public async practitionerCreatedCallback(practitionerData: Practitioner) {
        await this.$router.push({ name: 'practitioner-details', params: { practitionerId: practitionerData.practitionerId.toString() } });
    }

    public async created() {
        if (this.isPractitioner) {
            return this.$router.push({ name: 'project-overview' });
        }
    }
}
