import { Dialog } from '@/models/Dialog';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component
export default class ConfirmDialogComponent extends Vue {
    @Prop({ default: false }) public isShow: boolean;

    @Prop() public dialog: Dialog;

    public handleClickButton(value) {
        this.$emit('confirm', value);
    }
}
