import { Component } from 'vue-property-decorator';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import PractitionerUpdate from '@/modules/practitioners/models/PractitionerUpdate';
import BasePage from '@/models/BasePage';
import { Member } from '@/models/Member';
import { Language } from '@/models/Language';
import { loginHelper } from '@/main';

@Component({
    components: {
        'prepaid-overview': require('@/modules/practitioners/components/prepaid-overview.vue').default,
        'api-keys-overview': require('@/modules/practitioners/components/api-keys-overview.vue').default,
        'two-factor-setting': require('@/modules/profile/components/2fa.vue').default,
    },
})
export default class ProfileComponent extends BasePage {
    public account: Member;
    public isLoaded: boolean = false;
    public editMode: boolean = false;

    public profile: Practitioner = new Practitioner();
    public practitionerDataEdit: CreatePractitionerModel = new CreatePractitionerModel();
    public practitionerService: PractitionerService = new PractitionerService();
    private practitionerId: number = 0;

    private languages: Language[] = [];

    public async created() {
        this.account = loginHelper.getUser();
        if (this.isPractitioner) {
            this.profile = await this.practitionerService.getPractitioner(this.account.practitionerId);
        }

        this.isLoaded = true;
    }

    public startEdit(): void {
        this.editMode = true;

        Object.assign(this.practitionerDataEdit, this.profile);
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.practitionerDataEdit, this.profile);
    }

    public async saveEdit() {
        this.showPending('Loading...');
        await this.updatePractitioner({
            emailAddress: this.practitionerDataEdit.emailAddress,
            firstName: this.practitionerDataEdit.firstName,
            lastName: this.practitionerDataEdit.lastName,
            companyName: this.practitionerDataEdit.companyName,
            billingInformation: {
                taxNumber: this.practitionerDataEdit.taxNumber,
                addressLine1: this.practitionerDataEdit.addressLine1,
                addressLine2: this.practitionerDataEdit.addressLine2,
                zipCode: this.practitionerDataEdit.zipCode,
                city: this.practitionerDataEdit.city,
                countryId: this.practitionerDataEdit.country ? this.practitionerDataEdit.country.id : null,
                stateId: this.practitionerDataEdit.state ? this.practitionerDataEdit.state.id : null,
            },
            preferredLanguage: this.practitionerDataEdit.preferredLanguage,
        } as any as PractitionerUpdate);
        this.clearNotifications();
    }

    public showSurveyOverviewAction(): void {
        this.$sideActions.push('survey-overview-action', {}, () => {
            console.log('Nothing to see here');
        });
    }

    public async updatePractitioner(practitionerData: PractitionerUpdate) {
        const self = this;

        await this.practitionerService.updatePractitioner(this.practitionerId, practitionerData).then((result: Practitioner) => {
            self.editMode = false;

            self.profile = result;
            self.setLanguageToPractitioner(self.profile, self.languages);
        });
    }

    private setLanguageToPractitioner(practitionerData, languages): void {
        if (practitionerData.preferredLanguage !== null) {
            const language = languages.find((lang) => {
                return lang.id === practitionerData.preferredLanguage.id;
            });
            practitionerData.preferredLanguage = language;
        }
    }
}
