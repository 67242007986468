import { saveExcel } from '@progress/kendo-vue-excel-export';
import { GridColumnProps, GridPageChangeEvent, GridPagerSettings } from '@progress/kendo-vue-grid';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import BasePage from '@/models/BasePage';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import Participant from '@/modules/participants/models/Participant';
import { SurveyService } from '@/services/surveyService';
import EmailActionParameters, { EmailRecipient, EmailType } from '@/components/side-actions/email-actions/emailActionParams';
import { Project } from '@/models/Project';
import { $router, loginHelper, projectsService } from '@/main';
import AreYouSureModal from '@/components/modals/are-you-sure-modal';
import to from 'await-to-js';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import DetailComponent from './participant-detail-component.vue';
import { EmailSendStatus } from '@/models/enums/EmailSendStatus';
import { BModal } from 'bootstrap-vue';
import Instrument from '@/modules/instruments/Models/Instrument';
import QrcodeVue from 'qrcode.vue';

@Component({
    components: {
        'confirm-dialogue': require('@/components/confirm/confirmDialogue.vue').default,
        'survey-detail': DetailComponent,
        QrcodeVue,
    },
})
export default class ParticipantOverviewComponent extends BasePage {
    @Prop({ default: () => null }) public projectData: Project;

    public get projectActive(): boolean {
        return (new Date(this.projectData.closeDate) > new Date() && this.projectData.closeOnClosedDate) || !this.projectData.closeOnClosedDate;
    }

    public get selectedParticipants(): ProjectParticipant[] {
        return this.participants.filter((participant: ProjectParticipant) => {
            return participant.selected;
        });
    }

    public get nonReuseSelectedParticipants(): ProjectParticipant[] {
        return this.participants.filter((participant: ProjectParticipant) => {
            return !participant.deleted && !participant.isAnonymous && participant.selected && participant.surveys.every((x) => !x.parentSurveyId);
        });
    }

    public get participants(): ProjectParticipant[] {
        return this.participantsData.participants;
    }

    public get deletableParticipants(): ProjectParticipant[] {
        if (this.selectedParticipants.length > 0) {
            return this.selectedParticipants;
        }

        return this.participants;
    }

    public get invitationParticipants(): ProjectParticipant[] {
        if (this.selectedParticipants.length > 0) {
            return this.selectedParticipants.filter((participant: ProjectParticipant) => {
                return (
                    (!participant.deleted || !participant.isAnonymous) &&
                    !participant.scheduledEmails.length &&
                    participant.surveys.some((survey) => !survey.surveyCompleted)
                );
            });
        }

        const participants = this.allParticipants.filter((participant: ProjectParticipant) => {
            return (
                (!participant.deleted || !participant.isAnonymous) &&
                !participant.scheduledEmails.length &&
                participant.surveys.some((survey) => !survey.surveyCompleted)
            );
        });

        return this.getUniqueItemsByProperties(participants, ['participantId']);
    }

    public get resendInvitationParticipants(): ProjectParticipant[] {
        if (this.selectedParticipants.length > 0) {
            return this.selectedParticipants.filter((participant: ProjectParticipant) => {
                return (
                    (!participant.deleted || !participant.isAnonymous) &&
                    participant.scheduledEmails.length > 0 &&
                    participant.surveys.some((survey) => !survey.surveyCompleted)
                );
            });
        }

        return [];
    }

    public get reminderParticipants(): ProjectParticipant[] {
        if (this.selectedParticipants.length > 0) {
            return this.selectedParticipants.filter((participant: ProjectParticipant) => {
                return (
                    (!participant.deleted || !participant.isAnonymous) &&
                    participant.scheduledEmails.length > 0 &&
                    participant.surveys.some((survey) => !survey.surveyCompleted)
                );
            });
        }

        const participants = this.allParticipants.filter((participant: ProjectParticipant) => {
            return (
                (!participant.deleted || !participant.isAnonymous) &&
                participant.scheduledEmails.length > 0 &&
                participant.surveys.some((survey) => !survey.surveyCompleted)
            );
        });

        return this.getUniqueItemsByProperties(participants, ['participantId']);
    }

    public get reportParticipants(): ProjectParticipant[] {
        if (this.selectedParticipants.length > 0) {
            return this.selectedParticipants.filter((participant: ProjectParticipant) => {
                const instrument = this.projectData.instruments.find((x) => participant.surveys.find((y) => y.instrumentId === x.instrumentId));
                if (!instrument) {
                    return false;
                }

                const showReportLink = instrument.reports.filter((x) => x.reportType === 'personal').length > 0;
                return (
                    (!participant.deleted || !participant.isAnonymous) &&
                    !instrument.isAnonymous &&
                    participant.surveys.some((x) => x.surveyCompleted) &&
                    showReportLink
                );
            });
        }

        const surveys = this.allParticipants.filter((participant: ProjectParticipant) => {
            const instrument = this.projectData.instruments.find((x) => participant.surveys.find((y) => y.instrumentId === x.instrumentId));
            if (!instrument) {
                return false;
            }

            const showReportLink = instrument.reports.filter((x) => x.reportType === 'personal').length > 0;
            return (
                (!participant.deleted || !participant.isAnonymous) &&
                !instrument.isAnonymous &&
                participant.surveys.some((x) => x.surveyCompleted) &&
                showReportLink
            );
        });
        return this.getUniqueItemsByProperties(surveys, ['participantId']);
    }

    public get participantsSelected(): boolean {
        return this.selectedParticipants.length !== 0;
    }

    public get canCopyParticipants(): boolean {
        return this.selectedParticipants.some(x => x.surveys.some(x => x.surveyCompleted && this.isPersonalInstrument(x.instrumentId)));
    }

    public isPersonalInstrument(instrumentId: number) {
        return this.projectData.instruments.find(x => x.instrumentId === instrumentId)?.instrumentType === 'personal';
    }

    public get canDeleteSelectedParticipants(): boolean {
        return this.deletableParticipants.length !== 0 && this.deletableParticipants.length === this.selectedParticipants.length;
    }

    public get canSendInvitations(): boolean {
        return this.invitationParticipants.length !== 0 && this.invitationParticipants.length >= 1;
    }

    public get canResendInvitations(): boolean {
        return this.resendInvitationParticipants.length !== 0 && this.resendInvitationParticipants.length >= 1;
    }

    public get canSendReminders(): boolean {
        return this.reminderParticipants.length !== 0 && this.reminderParticipants.length >= 1;
    }

    public get canSendReports(): boolean {
        return this.reportParticipants.length !== 0 && this.reportParticipants.length >= 1;
    }

    public get tabIndex(): number {
        return this.$store.getters['projectStore/currentTab'];
    }

    get isViewer() {
        return !!this.projectData.viewers.find((x) => x.practitionerId === loginHelper.getUser().practitionerId);
    }

    public get isAnonymousLinkProject() {
        return this.projectData && this.projectData.anonymousSurveySettings != null;
    }

    public get startSurveyLink() {
        if (this.projectData && this.projectData.anonymousSurveySettings && this.projectData.anonymousSurveySettings.surveyKey) {
            return `${this.$env().SurveyUrl}survey/start?project_id=${this.projectId}&survey_key=${this.projectData.anonymousSurveySettings.surveyKey
                }`;
        }
        return '';
    }

    public loading: boolean = true;
    public bigProject: boolean = false;
    public projectId: number;
    public surveyService: SurveyService = new SurveyService();
    public thekey: string = new Date().getTime().toString();
    public showConfirm: boolean = false;
    public emails: any[] = [];
    public cellTemplate: string = 'myTemplate';
    public progressModalCount: number = 0;
    public progressModalTitle: string = '';
    public progress: number = 0;
    public allParticipants: ProjectParticipant[] = [];
    public allParticipantsLoaded: boolean = false;
    public $refs!: {
        areYouSureModal: AreYouSureModal;
        progressModal: BModal;
    };

    public pageable: GridPagerSettings = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 100, 500, 1000, 5000, 10000],
        previousNext: true,
    };

    public participantsData: any = {
        participants: [],
        skip: 0,
        take: 500,
        count: 0,
        search: '',
    };
    public dialog = {
        title: 'One or more surveys can be refunded',
        message: 'Do you want to refund these surveys?',
        button: {
            yes: 'Yes',
            no: 'No',
        },
    };

    public multiSurveyColumns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: this.renderCheckbox },
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'emailAddress', title: 'Email address' },
        { field: 'completedSurveys', title: 'Surveys completed', cell: this.renderCompletedSurveys, width: 180 },
        // { field: 'completionDate', title: 'Date completed', cell: this.renderDateOrCounter, width: 180 },
        { field: 'surveyLink', title: 'Open survey', cell: this.renderSurveyLink, width: 180 },
        { field: 'reportLink', title: 'Open report', cell: this.renderReportLink, width: 180 },
        { field: 'scheduledEmails', title: 'Last sent email', cell: this.renderLastSendEmail },
    ];

    public surveyColumns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: this.renderCheckbox },
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'instrumentName', title: 'Instrument', cell: this.renderInstrumentName },
        { field: 'emailAddress', title: 'Email address' },
        { field: 'completionDate', title: 'Date completed', cell: this.renderDateOrCounter, width: 180 },
        { field: 'surveyLink', title: 'Open survey', cell: this.renderSurveyLink, width: 180 },
        { field: 'reportLink', title: 'Open report', cell: this.renderReportLink, width: 180 },
        { field: 'lastEmailSubject', title: 'Last sent email', cell: this.renderLastSendEmail },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['participant.firstName', 'participant.lastName', 'emailAddress'],
    });

    private filterTimer: number = null;

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` ${item.dataItem.deleted || item.dataItem.isAnonymous ? 'bg-secondary text-muted' : ''}`;
        return tr;
    }

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);
    }

    public async mounted() {
        if (this.projectData && this.projectData.instruments && this.projectData.instruments.every((x) => x.isAnonymous)) {
            this.surveyColumns = this.surveyColumns.filter((x) => x.field !== 'reportLink');
            this.multiSurveyColumns = this.multiSurveyColumns.filter((x) => x.field !== 'reportLink');
        }

        if (this.isAnonymousLinkProject) {
            this.surveyColumns = this.surveyColumns.filter((x) => x.field !== 'surveyLink');
            this.multiSurveyColumns = this.multiSurveyColumns.filter((x) => x.field !== 'surveyLink');
            this.surveyColumns = this.surveyColumns.filter((x) => x.field !== 'reportLink');
            this.multiSurveyColumns = this.multiSurveyColumns.filter((x) => x.field !== 'reportLink');
            this.surveyColumns = this.surveyColumns.filter((x) => x.field !== 'emailAddress');
            this.multiSurveyColumns = this.multiSurveyColumns.filter((x) => x.field !== 'emailAddress');
            this.surveyColumns = this.surveyColumns.filter((x) => x.field !== 'lastEmailSubject');
            this.multiSurveyColumns = this.multiSurveyColumns.filter((x) => x.field !== 'lastEmailSubject');
        }

        await this.loadParticipants();

        if (this.participants.some((x) => x.deleted)) {
            this.surveyColumns.push({ field: 'deleted', title: 'Deleted', cell: this.renderIsDeleted, width: 100 });
            this.multiSurveyColumns.push({ field: 'deleted', title: 'Deleted', cell: this.renderIsDeleted, width: 100 });
        }

        if (this.projectData.participantCount < 1000) {
            this.allParticipants = await this.loadAllParticipants();
        } else {
            this.allParticipantsLoaded = true;
            this.bigProject = true;
        }
    }

    public async updated() {
        if (
            this.$store.getters['participantsStore/showInviteAction'] &&
            this.tabIndex === 1 &&
            this.loading === false &&
            this.allParticipantsLoaded === true
        ) {
            this.$store.commit('participantsStore/SET_INVITE_ACTION', false);

            await this.refresh();

            this.sendInvitations();
        }
    }

    public async loadParticipants() {
        this.loading = true;
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.projectId,
                    skip: this.participantsData.skip,
                    take: this.participantsData.take,
                    $count: true,
                    search: this.participantsData.search,
                }),
            ),
        );

        if (err) {
            this.showError('Failed to load participants');
            return [];
        }

        this.participantsData.count = response.count;
        this.participantsData.participants = response.items.map((x) => new ProjectParticipant(x));

        this.thekey = new Date().getTime().toString();
        this.loading = false;
    }

    public async loadAllParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.projectId,
                    skip: 0,
                    take: this.participantsData.count,
                    $count: false,
                }),
            ),
        );

        if (err) {
            this.clearAndShowError('Failed to load participants for export');
            return [];
        }

        this.allParticipantsLoaded = true;
        return response.items.map((x) => new ProjectParticipant(x));
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.participantsData.skip = event.page.skip;
        this.participantsData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.loadParticipants();
    }

    public renderRouterLink(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        if (row.dataItem.isAnonymous) {
            return item('td', `${row.dataItem.firstName} ${row.dataItem.lastName}`);
        }

        return item(Vue.component('grid-router-link'), {
            props: {
                title: `${row.dataItem.firstName} ${row.dataItem.lastName}`,
                url: this.$router.resolve({
                    name: 'participant-details',
                    params: { participantId: row.dataItem.participantId.toString() },
                }).href,
            },
        });
    }

    public renderCheckbox(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        return item(Vue.component('grid-checkbox'), {
            props: {
                dataItem: row.dataItem,
                field: 'selected',
            },
            on: {
                change: () => { },
            },
        });
    }

    public renderInstrumentName(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        return item('td', row.dataItem.surveys.length > 0 ? row.dataItem.surveys[0].instrumentName : 'N/A');
    }

    public expandChange(event) {
        event.event.stopPropagation();
        Vue.set(event.dataItem, event.target.$props.expandField, event.value);
    }

    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            self.participantsData.skip = 0;
            await self.loadParticipants();
        }, 400);
    }

    public renderCompletedSurveys(item: any, _: any, row: { dataItem: ProjectParticipant }) {
        return item('td', `${row.dataItem.surveys.filter((x) => x.completionDate).length}/${row.dataItem.surveys.length}`);
    }

    public renderSurveyLink(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        return item(Vue.component('grid-personal-link-button'), {
            props: {
                title: 'Survey',
                url: `${row.dataItem.surveyLink}`,
                disabled: row.dataItem.deleted || row.dataItem.surveys.every((x) => x.completionDate),
            },
        });
    }

    public async copyToClipboard() {
        await navigator.clipboard.writeText(this.startSurveyLink);

        this.clearAndShowSuccess('The unique link is copied to your clipboard.');
    }

    public renderDateOrCounter(item: any, _: any, row: any): any {
        const survey = row.dataItem.surveys.length ? row.dataItem.surveys[0] : null;

        if (survey.parentSurveyId) {
            const date = moment(survey.completionDate, 'YYYY-MM-DD').format('ll');
            return item('td', `${date} (reuse)`);
        }

        if (survey.completionDate || (!survey.questionTracker && !survey.completionDate) || survey.questionTracker.questionsAnswered === 0) {
            return item(Vue.component('grid-date-display'), { props: { date: survey.completionDate } });
        } else {
            return item(Vue.component('grid-popover-question'), {
                props: {
                    id: survey.surveyId,
                    title: 'Last answered question',
                    date: survey.questionTracker.lastAnsweredQuestionDate,
                    text: `At question #${survey.questionTracker.questionsAnswered}`,
                },
            });
        }
    }

    public renderReportLink(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        const participant = row.dataItem;
        const instruments = this.projectData.instruments.filter((x) => participant.surveys.find((y) => y.instrumentId === x.instrumentId));
        const showReportLink =
            instruments.filter((instrument: Instrument) => {
                return instrument.reports.find((report) => report.reportType === 'personal') && !instrument.isAnonymous;
            }).length > 0;

        if (showReportLink) {
            return item(Vue.component('grid-personal-link-button'), {
                props: {
                    title: 'Report',
                    url: `${participant.reportLink}`,
                    disabled: participant.surveys.every((x) => !x.surveyCompleted) || participant.deleted,
                },
            });
        } else {
            return item('td', 'N/A');
        }
    }

    public renderIsDeleted(item: any, _: any, row: { dataItem: ProjectParticipant }): any {
        return item('td', row.dataItem.deleted ? 'Deleted' : '-');
    }

    public async goToAddParticipants() {
        await this.$store.dispatch('participantsStore/cleanup');
        await this.$router.push({ name: 'addSurvey', params: { projectId: this.projectId.toString() } });
    }

    public async exportToExcel() {
        if (this.bigProject) {
            const [err, response] = await to(projectsService.exportParticipants(this.projectId));

            if (err) {
                return this.showError("Failed to export participants");
            }
            return this.showSuccess("A download link with the data will be send to your email address");
        } else {
            const participants = this.bigProject ? this.participantsData.participants : this.allParticipants;

            saveExcel({
                data: participants.map((val: ProjectParticipant) => {
                    return {
                        firstName: val.firstName,
                        lastName: val.lastName,
                        emailAddress: val.emailAddress,
                        completionDate: val.surveys.length ? val.surveys[0].completionDate : 'N/A',
                        surveyLink: `${val.surveyLink}`,
                        reportLink: `${val.reportLink}`,
                    };
                }),
                fileName: this.projectData.name + ' - survey overview.xlsx',
                columns: [
                    { field: 'firstName', title: 'First name' },
                    { field: 'lastName', title: 'Last name' },
                    { field: 'emailAddress', title: 'Email address' },
                    { field: 'completionDate', title: 'Date completed' },
                    { field: 'surveyLink', title: 'Link to survey' },
                    { field: 'reportLink', title: 'Link to report' },
                ],
            });
        }
    }

    public deselect(): void {
        this.participants.forEach((survey: any) => {
            survey.selected = false;
        });
    }

    public selectAllParticipants(): void {
        this.participants.forEach((survey: any) => {
            survey.selected = true;
        });
    }

    public showDeleteConfirmDialogue() {
        if (this.isSuperAdmin || this.isPartner) {
            this.$refs.areYouSureModal.show();
            this.showConfirm = true;
        } else {
            this.deleteParticipants(this.deletableParticipants, false);
        }
    }

    public confirmDelete(refund: boolean) {
        this.showConfirm = false;
        this.deleteParticipants(this.deletableParticipants, refund);
    }

    public showAreYouSureModal() {
        const singleDelete = this.deletableParticipants.length === 1;

        this.$refs.areYouSureModal.setScope(
            this.deletableParticipants,
            singleDelete && this.deletableParticipants.every((x) => !x.isAnonymous) ? 'emailAddress' : '',
            singleDelete
                ? `${this.deletableParticipants[0].firstName} ${this.deletableParticipants[0].lastName} from this project`
                : 'participants from this project',
            singleDelete,
            'Are you sure you want to do this, this action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    public showManageWarning(type: string) {
        let message = `Do you really want to ${type} the survey(s) of the selected participant(s)?`;

        if (type === 'restart') {
            message += ` Be careful! this will delete all answers and the participant will have to start over again.`;
        }

        this.$bvModal
            .msgBoxConfirm(message, {
                title: `${type.charAt(0).toUpperCase() + type.slice(1)} survey(s)`,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: type === 'restart' ? 'danger' : 'primary',
                okTitle: `${type.charAt(0).toUpperCase() + type.slice(1)}`,
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            })
            .then(async (value) => {
                if (value) {
                    await this.manageParticipantSurveys(this.nonReuseSelectedParticipants, type);
                }
            })
            .catch((err) => {
                // An error occurred
                console.log(err);
            });
    }

    public async manageParticipantSurveys(participants: ProjectParticipant[], type: string) {
        if (participants.length === 0) {
            return;
        }

        const surveyCount = participants
            .map((x) => x.surveys.length)
            .reduce((x, y) => {
                return x + y;
            }, 0);

        this.$refs.progressModal.show();
        this.progressModalTitle = `${type === 're-open' ? 'Re-opening' : 'Restarting'} survey${surveyCount > 1 ? 's' : ''}`;
        this.progressModalCount = surveyCount;

        let errorTriggered = 0;
        for (const participant of participants) {
            for (const surveyId of participant.surveys.map((x) => x.surveyId)) {
                if (type === 're-open') {
                    const [err] = await to(this.surveyService.openSurvey(this.projectId, surveyId));
                    if (err) {
                        this.showError(`Failed to re-open a survey of ${participant.emailAddress}`);
                        errorTriggered++;
                    }
                } else if (type === 'restart') {
                    const [err] = await to(this.surveyService.restartSurvey(this.projectId, surveyId));
                    if (err) {
                        this.showError(`Failed to re-open a survey of ${participant.emailAddress}`);
                        errorTriggered++;
                    }
                }

                this.progress++;
            }
        }

        if (errorTriggered === 0) {
            this.showSuccess(`The survey${surveyCount > 1 ? 's are' : ' is'} successfully re-opened`);
        } else if (errorTriggered > 0 && errorTriggered !== surveyCount) {
            this.showWarning(
                `Successfully re-opened ${surveyCount - errorTriggered} survey${surveyCount - errorTriggered > 1 ? 's' : ''
                } , but failed to re-open ${errorTriggered} survey${errorTriggered > 1 ? 's' : ''}`,
            );
        } else {
            this.showError(`Failed to re-open the survey${surveyCount > 1 ? 's' : ''}`);
        }

        this.$refs.progressModal.hide();
        await this.refresh();
    }

    public async deleteParticipants(participants: ProjectParticipant[], refund: boolean = false) {
        if (participants.length === 0) {
            return;
        }
        this.$refs.areYouSureModal.hide();
        this.$refs.progressModal.show();
        this.progressModalCount = participants.length;
        this.progressModalTitle = 'Deleting participants...';
        this.progress = 0;

        let errorTriggered = 0;
        for (const participant of participants) {
            const [err] = await to(projectsService.deleteParticipant(this.projectId, participant.participantId, refund));
            if (err) {
                this.showError(`Failed to delete ${participant.emailAddress}`);
                errorTriggered++;
            }

            this.progress++;
        }

        if (errorTriggered === 0) {
            this.showSuccess(`The participant${participants.length > 1 ? 's are' : ' is'} successfully removed`);
        } else if (errorTriggered > 0 && errorTriggered !== participants.length) {
            this.showWarning(
                `Successfully deleted ${participants.length - errorTriggered} participant${participants.length - errorTriggered > 1 ? 's' : ''
                } , but failed to delete ${errorTriggered} participant${errorTriggered > 1 ? 's' : ''}`,
            );
        } else {
            this.showError(`Failed to delete the participant${participants.length > 1 ? 's' : ''}`);
        }

        this.$refs.progressModal.hide();
        await this.refresh();
    }

    public resendInvitations(): void {
        this.sendInvitations(true);
    }

    public sendInvitations(resend: boolean = false): void {
        let surveys = [];
        if (!resend) {
            surveys = this.invitationParticipants;
        } else {
            surveys = this.resendInvitationParticipants;
        }

        const templateName = `invite`;

        this.$sideActions.push(
            'email-action',
            new EmailActionParameters<any>('Participant Invite', {
                template: templateName,
                emailType: EmailType.SurveyInvite,
                knowledgeModelAlias: this.projectData.knowledgeModel.alias,
                manualSelected: !!this.selectedParticipants.length,
                placeholderObject: {
                    projectName: '',
                    fullName: '',
                    firstName: '',
                    lastName: '',
                    surveyLink: '',
                    deadline: '',
                    practitionerFirstName: '',
                    practitionerLastName: '',
                    practitionerFullName: '',
                    context: '',
                },
                projectId: this.projectId,
                timeZone: this.projectData.timeZone,
                recipients: surveys.map((participant: ProjectParticipant) => {
                    return new EmailRecipient({
                        data: {
                            participantId: participant.participantId,
                            fullName: participant.firstName + ' ' + participant.lastName,
                            firstName: participant.firstName,
                            lastName: participant.lastName,
                            emailAddress: participant.emailAddress,
                            surveyLink: `${participant.surveyLink}`,
                            deadline: `${moment(this.projectData.closeDate)
                                .locale(participant.preferredLanguage ? participant.preferredLanguage : 'en')
                                .format('dddd')} ${moment(this.projectData.closeDate)
                                    .locale(participant.preferredLanguage ? participant.preferredLanguage : 'en')
                                    .format('LL')}`,
                            practitionerFirstName: this.projectData.practitioner.firstName,
                            practitionerLastName: this.projectData.practitioner.lastName,
                            practitionerFullName: this.projectData.practitioner.firstName + ' ' + this.projectData.practitioner.lastName,
                            context: this.projectData.context,
                            preferredLanguage: participant.preferredLanguage,
                        },
                        fullName: participant.firstName + ' ' + participant.lastName,
                        emailAddress: participant.emailAddress,
                        firstName: participant.firstName,
                        lastName: participant.lastName,
                        survey: participant.surveys[0],
                        preferredLanguage: participant.preferredLanguage,
                    });
                }),
            }),
            () => {
                this.refresh();
            },
        );
    }

    public sendReminders(): void {
        const surveys = this.reminderParticipants;
        const templateName = `survey_reminder`;
        this.$sideActions.push(
            'email-action',
            new EmailActionParameters<any>('reminder', {
                template: templateName,
                emailType: EmailType.SurveyReminder,
                knowledgeModelAlias: this.projectData.knowledgeModel.alias,
                manualSelected: !!this.selectedParticipants.length,
                placeholderObject: {
                    projectName: '',
                    fullName: '',
                    firstName: '',
                    lastName: '',
                    surveyLink: '',
                    deadline: '',
                    practitionerFirstName: '',
                    practitionerLastName: '',
                    practitionerFullName: '',
                    context: '',
                },
                projectId: this.projectId,
                timeZone: this.projectData.timeZone,
                recipients: surveys.map((participant: ProjectParticipant) => {
                    return new EmailRecipient({
                        data: {
                            participantId: participant.participantId,
                            fullName: participant.firstName + ' ' + participant.lastName,
                            firstName: participant.firstName,
                            lastName: participant.lastName,
                            emailAddress: participant.emailAddress,
                            surveyLink: `${participant.surveyLink}`,
                            deadline: `${moment(this.projectData.closeDate)
                                .locale(participant.preferredLanguage ? participant.preferredLanguage : 'en')
                                .format('dddd')} ${moment(this.projectData.closeDate)
                                    .locale(participant.preferredLanguage ? participant.preferredLanguage : 'en')
                                    .format('LL')}`,
                            practitionerFirstName: this.projectData.practitioner.firstName,
                            practitionerLastName: this.projectData.practitioner.lastName,
                            practitionerFullName: this.projectData.practitioner.firstName + ' ' + this.projectData.practitioner.lastName,
                            context: this.projectData.context,
                            preferredLanguage: participant.preferredLanguage,
                        },
                        fullName: participant.firstName + ' ' + participant.lastName,
                        emailAddress: participant.emailAddress,
                        firstName: participant.firstName,
                        lastName: participant.lastName,
                        survey: participant.surveys[0],
                        preferredLanguage: participant.preferredLanguage,
                    });
                }),
            }),
            () => {
                this.refresh();
            },
        );
    }

    public sendReports(): void {
        const surveys = this.reportParticipants;
        const templateName = `report_link`;

        this.$sideActions.push(
            'email-action',
            new EmailActionParameters<any>('report', {
                template: templateName,
                emailType: EmailType.SurveyReport,
                knowledgeModelAlias: this.projectData.knowledgeModel.alias,
                manualSelected: !!this.selectedParticipants.length,
                placeholderObject: {
                    projectName: '',
                    fullName: '',
                    firstName: '',
                    lastName: '',
                    reportLink: '',
                    practitionerFirstName: '',
                    practitionerLastName: '',
                    practitionerFullName: '',
                    context: '',
                },
                projectId: this.projectId,
                timeZone: this.projectData.timeZone,
                recipients: surveys.map((participant: ProjectParticipant) => {
                    return new EmailRecipient({
                        data: {
                            participantId: participant.participantId,
                            firstName: participant.firstName,
                            lastName: participant.lastName,
                            fullName: participant.firstName + ' ' + participant.lastName,
                            reportLink: `${participant.reportLink}`,
                            practitionerFirstName: this.projectData.practitioner.firstName,
                            practitionerLastName: this.projectData.practitioner.lastName,
                            practitionerFullName: this.projectData.practitioner.firstName + ' ' + this.projectData.practitioner.lastName,
                            context: this.projectData.context,
                            preferredLanguage: participant.preferredLanguage,
                        },
                        emailAddress: participant.emailAddress,
                        firstName: participant.firstName,
                        lastName: participant.lastName,
                        fullName: participant.firstName + ' ' + participant.lastName,
                        survey: participant.surveys[0],
                        preferredLanguage: participant.preferredLanguage,
                    });
                }),
            }),
            () => {
                this.refresh();
            },
        );
    }

    public showCopyToOrgSideAction() {
        this.$sideActions.push('copy-participants-to-org', {
            projectId: this.projectId,
            currentOrganizationId: this.projectData.organizationId,
            participants: this.selectedParticipants
        }, (createdProject) => {
            console.log(createdProject);
            this.$router.push({ name: 'project-details', params: { projectId: createdProject.projectId.toString() } });
        });
    }

    public async refresh() {
        this.loading = true;
        this.participantsData.skip = 0;
        await this.loadParticipants();
        this.loading = false;
    }

    public getLanguagesFromParticipants(participants: Participant[]): string[] {
        return participants.map((p) => {
            return p.preferredLanguage;
        });
    }

    private renderLastSendEmail(item, _, row: { dataItem: ProjectParticipant }) {
        const lastSendEmail = row.dataItem.scheduledEmails && row.dataItem.scheduledEmails.length > 0 ? row.dataItem.scheduledEmails[0] : null;

        if (!lastSendEmail) {
            return item('td', '-');
        }

        const properties = {
            responseCode: EmailSendStatus.None,
            subject: '',
            emailAddress: '',
        };

        if (lastSendEmail) {
            properties.responseCode = lastSendEmail.sendStatus as EmailSendStatus;
            properties.subject = lastSendEmail.subject;
            properties.emailAddress = row.dataItem.emailAddress;
        }

        return item(Vue.component('grid-reject-sentence'), { props: properties });
    }

    private isPropValuesEqual = (subject, target, propNames) => propNames.every((propName) => subject[propName] === target[propName]);

    private getUniqueItemsByProperties(items, propNames) {
        const propNamesArray = Array.from(propNames);

        return items.filter(
            (item, index, array) => index === array.findIndex((foundItem) => this.isPropValuesEqual(foundItem, item, propNamesArray)),
        );
    }
}
