import { languageService } from '@/main';
import BasePage from '@/models/BasePage';
import to from 'await-to-js';
import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import {
    InstrumentStatus,
    InstrumentTranslationStatus,
    PublicationStatusEnum,
    TranslationSectionEnum,
    TranslationSubSectionEnum,
} from '../models/TranslationStatus';

@Component
export default class TranslationStatusComponent extends BasePage {
    @Prop() public section: TranslationSectionEnum;
    @Prop() public subSection: TranslationSubSectionEnum;
    @Prop() public status: InstrumentStatus;
    @Prop() public reportAlias: string;
    @Prop() public instrumentAlias: string;
    @Prop() public knowledgeModelAlias: string;

    public published: boolean = false;

    public get publicationStatus() {
        const statusObj = this.translationStatus;
        return statusObj.status;
    }

    public get translationRoute() {
        return {
            name: 'translations',
            params: {
                knowledgeModelAlias: this.knowledgeModelAlias,
                instrumentAlias: this.instrumentAlias,
                languageCode: this.translationStatus.languageCode,
                section: this.section,
                subSection: this.subSection,
                reportAlias: this.reportAlias,
            },
        };
    }

    public mounted() {
        this.published = this.translationStatus.status == 'published';
    }

    public get translationStatus(): InstrumentTranslationStatus {
        if (!this.status || !this.status.translationStatus.length) {
            return new InstrumentTranslationStatus({ status: 'n/a' });
        }
        const statusObj = this.status.translationStatus.find(
            (x) => x.section === this.section && x.subSection === this.subSection && x.reportAlias === this.reportAlias,
        );

        if (statusObj) {
            return statusObj;
        }

        return new InstrumentTranslationStatus({ status: 'n/a' });
    }

    public async updatePublicationStatus(status) {
        let [err] = [null];
        if (status) {
            [err] = await to(
                languageService.publishTranslation(
                    this.knowledgeModelAlias,
                    this.instrumentAlias,
                    this.translationStatus.languageCode,
                    this.section.toString(),
                    this.subSection.toString(),
                    this.reportAlias,
                ),
            );
        } else {
            [err] = await to(
                languageService.unpublishTranslation(
                    this.knowledgeModelAlias,
                    this.instrumentAlias,
                    this.translationStatus.languageCode,
                    this.section.toString(),
                    this.subSection.toString(),
                    this.reportAlias,
                ),
            );
        }

        if (err) {
            this.showError('The status change is not processed due to a error');
            return;
        } else {
            this.translationStatus.status = status
                ? PublicationStatusEnum[PublicationStatusEnum.published]
                : PublicationStatusEnum[PublicationStatusEnum.new];
        }

        this.clearAndShowSuccess('Publish status changed!');
    }
}
