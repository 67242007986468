import Vue from 'vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { PractitionerService } from '../services/practitionerService';
import Practitioner from '../models/Practitioner';
import Organization from '@/modules/organizations/models/Organization';

@Component
export default class PractitionerOrganizationsComponent extends BasePage {
    public organizations: Organization[] = [];
    public loading: boolean = true;
    public thekey: string = new Date().getTime().toString();

    public practitionerService: PractitionerService = new PractitionerService();
    public columns: GridColumnProps[] = [{ field: 'name', title: 'Name', cell: this.renderRouterLink }];

    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    public practitioner: Practitioner = null;

    public async created() {
        if (this.isSuperAdmin || this.isPartner) {
            const practitionerId = parseInt(this.$route.params.practitionerId);
            this.practitioner = await this.practitionerService.getPractitioner(practitionerId);

            await this.getOrganizations();
        }
    }

    public getHeight() {
        return {
            height: this.organizations.length === 0 ? 40 : this.organizations.length * 40,
        };
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.name,
                url: this.$router.resolve({ name: 'organization-details', params: { organizationId: row.dataItem.organizationId.toString() } }).href,
            },
        });
    }

    private async getOrganizations(): Promise<void> {
        this.organizations = [];

        await this.practitionerService.getOrganizations(this.practitioner.practitionerId).then((instruments) => {
            this.organizations.push(...instruments.items);
        });

        this.updateCacheKey();

        this.loading = false;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }
}
