import { Component } from 'vue-property-decorator';
import { CreatePartnerModel } from '@/models/CreatePartner';
import { PartnerService } from '@/modules/partners/services/partnerService';
import Partner from '@/modules/partners/models/Partner';
import PartnerUpdate from '@/modules/partners/models/PartnerUpdate';
import BasePage from '@/models/BasePage';
import { Member } from '@/models/Member';
import { Language } from '@/models/Language';
import { loginHelper } from '@/main';

@Component({
    components: {
        'two-factor-setting': require('@/modules/profile/components/2fa.vue').default,
    },
})
export default class PartnerProfileComponent extends BasePage {
    public account: Member;
    public isLoaded: boolean = false;
    public editMode: boolean = false;

    public profile: Partner = new Partner();
    public partnerDataEdit: CreatePartnerModel = new CreatePartnerModel();
    public partnerService: PartnerService = new PartnerService();
    private partnerId: number = 0;

    private languages: Language[] = [];

    public async created() {
        this.account = loginHelper.getUser();
        this.profile = await this.partnerService.getPartner(this.account.partnerId);

        this.isLoaded = true;
    }

    public startEdit(): void {
        this.editMode = true;

        Object.assign(this.partnerDataEdit, this.profile);
    }

    public undoEdit(): void {
        this.editMode = false;
        Object.assign(this.partnerDataEdit, this.profile);
    }

    public async saveEdit() {
        this.showPending('Loading...');
        await this.updatePartner({
            companyName: this.partnerDataEdit.companyName,
            preferredLanguage: this.partnerDataEdit.preferredLanguage,
        } as any as PartnerUpdate);
        this.clearNotifications();
    }

    public showSurveyOverviewAction(): void {
        this.$sideActions.push('survey-overview-action', {}, () => {
            console.log('Nothing to see here');
        });
    }

    public async updatePartner(partnerData: PartnerUpdate) {
        const self = this;

        await this.partnerService.updatePartner(this.partnerId, partnerData).then((result: Partner) => {
            self.editMode = false;

            self.profile = result;
            self.setLanguageToPartner(self.profile, self.languages);
        });
    }

    private setLanguageToPartner(partnerData, languages): void {
        if (partnerData.preferredLanguage !== null) {
            const language = languages.find((lang) => {
                return lang.id === partnerData.preferredLanguage.id;
            });
            partnerData.preferredLanguage = language;
        }
    }
}
