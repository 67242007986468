import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import AddOrganizationSubscriptionActionParameters from './addOrganizationSubscriptionActionParameters';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { OrganizationsService } from '@/services/organizationsService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';

@Component
export default class AddOrganizationSubscriptionAction extends SideActionBase<AddOrganizationSubscriptionActionParameters> {
    public organizationSubscription: OrganizationSubscription = new OrganizationSubscription();

    public instruments: any[] = [];
    public selectedInstruments: number[] = [];

    public isLoaded: boolean = false;
    public thekey: string = new Date().getTime().toString();

    public working: boolean = false;
    public knowledgeModelId: number = null;

    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '80px', cell: Vue.component('grid-checkbox') },
        { field: 'name', title: 'Instrument' },
    ];

    private organizationsService: OrganizationsService = new OrganizationsService();
    private practitionerService: PractitionerService = new PractitionerService();

    public instrumentSelected(instruments) {
        let currentKnowledgeModelId = null;
        instruments.forEach((instrumentId) => {
            const instrument = this.instruments.find((x) => x.instrumentId === instrumentId);
            if (!currentKnowledgeModelId) {
                currentKnowledgeModelId = instrument.knowledgeModelId;
            } else if (currentKnowledgeModelId !== instrument.knowledgeModelId) {
                this.selectedInstruments.pop();
                this.thekey = new Date().getTime().toString();

                this.showWarning('A combination of the selected instruments in one subscription is not allowed.');
                return;
            }
        });

        this.knowledgeModelId = currentKnowledgeModelId;
    }

    public async created() {
        if (this.isPractitioner) {
            this.cancel();
        }

        await this.getInstruments();
    }

    public onSeatsChange() {
        this.organizationSubscription.price = this.organizationSubscription.pricePerSeat * this.organizationSubscription.seats;
    }

    public onPricePerSeatChange() {
        this.organizationSubscription.price = this.organizationSubscription.pricePerSeat * this.organizationSubscription.seats;
    }

    public async addSubscription(evt: any): Promise<any> {
        evt.preventDefault();

        this.working = true;

        this.organizationSubscription.instruments.push(...this.selectedInstruments);
        this.organizationSubscription.price = this.organizationSubscription.pricePerSeat * this.organizationSubscription.seats;
        this.organizationSubscription.knowledgeModelId = this.knowledgeModelId;

        await this.organizationsService.addOrganizationSubscription(this.options.organizationId, this.organizationSubscription);
        this.working = false;

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.clearNotifications();
        this.cancel();
    }

    private async getInstruments() {
        const instruments = await this.practitionerService.getInstruments(this.options.practitionerId);
        this.instruments = instruments.items;

        this.updateCacheKey();

        this.isLoaded = true;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }
}
