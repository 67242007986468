import { AxiosPromise, AxiosResponse } from 'axios';
import { SurveyDetails } from '../models/Survey';
import { BaseService } from './baseService';
import { SurveyCreate } from '../pages/surveys/models/participant';
import { CreateResult } from '../models/CreateResult';

import Vue from 'vue';

export class SurveyService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
         super();
    }

    public async createSurvey(projectId: number, participant: SurveyCreate): Promise<CreateResult<SurveyDetails>> {
        return new Promise<CreateResult<SurveyDetails>>((resolve, reject) => {
            this.post<CreateResult<SurveyDetails>>(`${this.endpoint}/projects/${projectId}/surveys`, {
                participantId: participant.participant.participantId,
                projectId,
                surveyType: participant.surveyType,
                reusePersonalData: participant.basedOn != null && participant.basedOn.surveyId != null ? true : false,
            })
                .then((data: AxiosResponse<CreateResult<SurveyDetails>>) => {
                    resolve(data.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deleteSurvey(projectId: number, surveyId: number, refund: boolean): AxiosPromise<void> {
        return this.delete(`${this.endpoint}/projects/${projectId}/surveys/${surveyId}`, { applyRefund: refund });
    }

    public async openSurvey(projectId: number, surveyId: number): AxiosPromise<void> {
        return await this.post(`${this.endpoint}/projects/${projectId}/surveys/${surveyId}/open`);
    }

    public async restartSurvey(projectId: number, surveyId: number): AxiosPromise<void> {                                                    
        return await this.post(`${this.endpoint}/projects/${projectId}/surveys/${surveyId}/restart`);
    }
}
