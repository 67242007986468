import Practitioner from '@/modules/practitioners/models/Practitioner';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import Partner from '../models/Partner';
import { PartnerService } from '../services/partnerService';

@Component
export default class AddPrepaidActionComponent extends SideActionBase<MovePractitionerSideActionOptions> {
    get partnerOptions() {
        return this.partners.map((p) => {
            return {
                partnerId: p.partnerId,
                name: `${p.companyName}`,
            };
        });
    }

    public partner: Partner = this.options.partner;
    public practitioner: Practitioner = this.options.practitioner;
    public selectedNewPartner: Partner = null;
    public partners: Partner[] = [];

    public submitted = false;

    private partnerService: PartnerService = new PartnerService();
    private practitionerService: PractitionerService = new PractitionerService();

    public async mounted() {
        if (this.isPractitioner) {
            this.cancel();
        }

        const partnerResponse = await this.partnerService.getPartners({ skip: 0, take: 9999 });
        if (partnerResponse && partnerResponse.items) {
            this.partners = partnerResponse.items;
        }

        this.isLoaded = true;
    }

    public async movePractitioner(): Promise<any> {
        this.submitted = true;

        const [err] = await to(this.practitionerService.move(this.practitioner.practitionerId, this.selectedNewPartner.partnerId));
        if (err) {
            return this.clearAndShowError(`The practitioner couldn't be moved due to an error, please try again or contact an admin.`, err);
        }

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
export class MovePractitionerSideActionOptions {
    public practitioner: Practitioner;
    public partner: Partner;

    constructor(data: MovePractitionerSideActionOptions) {
        Object.assign(this, data);
    }
}
