import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class OfflineBarComponent extends Vue {
    public offline: boolean = !window.navigator.onLine;

    public onOnline = () => {
        this.offline = false;
    };

    public beforeCreate() {
        window.addEventListener('offline', () => {
            this.offline = true;
        });
        window.addEventListener('online', () => {
            this.offline = false;
        });
    }
}
