import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridLoaderComponent extends Vue {
    @Prop() public loading: boolean;
    @Prop() public margin: number;

    public minHeight: number = 300;
    public timeoutTime: number = 300;
    public stretchTimeout: any;

    public mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }
    public updated() {
        this.onWindowResize();
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    public onWindowResize() {
        if (this.stretchTimeout) {
            clearTimeout(this.stretchTimeout);
        }
        // this.stretchTimeout = setTimeout(this.stretchElement, this.timeoutTime);
    }

    public stretchElement() {
        let el = null;
        if (this.$slots && this.$slots.default) {
            const slot = this.$slots.default.length > 1 ? this.$slots.default[1] : this.$slots.default[0];
            el = slot.elm;
        }
        if (!el) {
            return;
        }

        let margin = 130; // Margin for the powered by bright instruments and support button
        if (this.margin) {
            margin += this.margin;
        }

        const windowHeight = window.innerHeight;
        const targetHeight = windowHeight - el.parentElement.offsetTop - margin;
        const height = targetHeight > this.minHeight ? `${targetHeight}px` : `${this.minHeight}px`;
        el.style.height = height;
    }
}
