import { SequenceUpdate } from '../components/side-actions/sequence-actions/sequenceEditAction';
import { Project } from '../models/Project';
import Sequence from '../models/Sequence';
import { BaseService } from './baseService';
import { AxiosResponse } from 'axios';

import Vue from 'vue';

export class SequenceService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public createProject(sequenceId: number): Promise<Project> {
        return new Promise<Project>(async (resolve) => {
            await this.post<Project>(`${this.endpoint}/sequences/${sequenceId}/create-project`).then((result) => {
                resolve(result.data);
            });
        });
    }

    public updateSequence(sequenceId: number, updateData: SequenceUpdate): Promise<Sequence> {
        return new Promise<Sequence>((resolve, reject) => {
            this.put<Sequence>(`${this.endpoint}/sequences/${sequenceId}`, updateData)
                .then((response: AxiosResponse<Sequence>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public deleteSequence(sequenceId: number): Promise<void> {
        return new Promise<void>(async (resolve) => {
            await this.delete(`${this.endpoint}/sequences/${sequenceId}`).then(() => {
                resolve();
            });
        });
    }
}
