import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { OrganizationSubscription } from '@/models/OrganizationSubscription';
import BasePage from '@/models/BasePage';
import { PagedResponse } from '@/models/PagedResponse';
import { OrganizationsService } from '@/services/organizationsService';
import Organization from '../models/Organization';

@Component
export default class OrganizationSubscriptionOverviewComponent extends BasePage {
    get subscriptionCount(): number {
        return this.subscriptionData.subscriptionCount;
    }

    @Prop({ default: () => null }) public organization: Organization;
    public isLoaded: boolean = false;

    public subscriptionData: any = {
        subscriptions: [],
        subscriptionCount: 0,
        skip: 0,
        take: 999,
    };

    public subscriptionColumns: GridColumnProps[] = [
        { field: 'seats', title: 'Seats' },
        { field: 'price', title: 'Price', cell: this.renderPrice },
        { field: 'startDate', title: 'Start date', cell: Vue.component('grid-date-display') },
        { field: 'renewalDate', title: 'Renewal date', cell: Vue.component('grid-date-display') },
        { field: 'instruments', title: 'Instruments', cell: this.renderInstruments },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public thekey: string = new Date().getTime().toString();
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        await this.getSubscriptions(true);
    }

    public async pageChangeHandler(event: GridPageChangeEvent) {
        this.subscriptionData.skip = event.page.skip;
        this.subscriptionData.take = event.page.take;
        await this.getSubscriptions(false);
    }

    public getHeight() {
        return {
            height: this.subscriptionData.subscriptions.length === 0 ? 40 : this.subscriptionData.subscriptions.length * 40,
        };
    }

    public renderPrice(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.price,
                currencyCode: 'USD',
            },
        });
    }

    public renderInstruments(item: any, _: any, row: any): any {
        return item(Vue.component('grid-popover-instruments'), {
            props: {
                id: row.dataItem.id,
                title: `Instruments`,
                instruments: row.dataItem.instruments,
                text: `${row.dataItem.instruments.length} instruments`,
            },
        });
    }

    public renderActions(item: any, _: any, row: any): any {
        const actions = [{ title: 'Change', function: this.openChangeSubscription }];
        const props = { actions, item: row.dataItem };
        return item(Vue.component('grid-actions'), { props });
    }

    public openAddSubscription() {
        this.$sideActions.push(
            'add-organization-subscription-action',
            { organizationId: this.organization.id, practitionerId: this.organization.practitioner.practitionerId },
            async () => {
                this.clearAndShowSuccess('The Subscription is successfully added.');
                await this.getSubscriptions(true);
            },
        );
    }

    public openChangeSubscription(subscription: OrganizationSubscription): any {
        this.$sideActions.push(
            'change-organization-subscription-action',
            { subscription, practitionerId: this.organization.practitioner.practitionerId },
            async () => {
                this.clearAndShowSuccess('The subscription is successfully changed.');
                await this.getSubscriptions(true);
            },
        );
    }

    private async getSubscriptions(includeCount: boolean) {
        this.isLoaded = false;
        await this.organizationsService
            .getOrganizationSubscriptions(this.organization.id)
            .then((subscriptions: PagedResponse<OrganizationSubscription>) => {
                this.subscriptionData.subscriptions = subscriptions.items.map((val) => {
                    return {
                        id: val.id,
                        seats: val.seats,
                        price: val.price,
                        startDate: val.startDate,
                        renewalDate: val.renewalDate,
                        instruments: val.instruments,
                        participantCount: val.participantCount,
                        knowLedgeModelId: val.knowledgeModelId,
                    };
                });

                if (includeCount) {
                    this.subscriptionData.subscriptionCount = subscriptions.count;
                }

                this.isLoaded = true;
                this.thekey = new Date().getTime().toString();
            });
    }
}
