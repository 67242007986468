import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { InstrumentsService } from '../services/instrumentsService';
import InstrumentCopyActionParameters from '../Models/InstrumentCopyActionParameters';
import Instrument from '../Models/Instrument';

@Component
export default class ChangeQuestionActionComponent extends SideActionBase<InstrumentCopyActionParameters> {
    public instrument: Instrument = new Instrument();
    public instrumentService: InstrumentsService = new InstrumentsService();

    public mounted() {}

    public async copyInstrument() {
        this.showPending('Copying instrument...');

        const [error, result] = await to(this.instrumentService.copyInstrument(this.options.instrumentToCopy.instrumentId, this.instrument));
        if(error){
            return this.clearAndShowError(error);
        }

        this.clearNotifications();
        this.finished(result);
    }
}
