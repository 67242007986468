import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import moment from 'moment';

export enum ProjectStatus {
    undefined = 'undefined',
    open = 'open',
    closing = 'closing',
    closed = 'closed',
}

@Component
export default class StatusDisplayComponent extends Vue {
    @Prop() public startDate: Date;
    @Prop() public closeDate: Date;

    get projectStatus(): ProjectStatus {
        const now = new Date();
        if (now > moment(this.closeDate).toDate()) {
            return ProjectStatus.closed;
        }

        const timeSpan = moment(this.closeDate).diff(this.startDate, 'hours') * 0.1;
        if (
            moment(now)
                .add(timeSpan, 'hours')
                .toDate() > moment(this.closeDate).toDate()
        ) {
            return ProjectStatus.closing;
        }

        if (now >= moment(this.startDate).toDate()) {
            return ProjectStatus.open;
        }

        return ProjectStatus.open;
    }
}
