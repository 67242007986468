import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class ListDisplayComponent extends Vue {
    @Prop() public list: any[];
    @Prop() public field: string;

    public getValue(item: any, field: string) {
        if (field) {
            return this.getNestedValue(item, field);
        } else {
            return item;
        }
    }

    public click(): void {
        this.$emit('click');
    }

    public getNestedValue(item: any, field: string): any {
        const splittedItems = field.split('.');
        let realDataItem = item;

        for (const fieldName of splittedItems) {
            realDataItem = realDataItem[fieldName];
        }

        return realDataItem;
    }
}
