import { CreateResult } from '@/models/CreateResult';
import PageRender from '@/models/PageRender';
import Account from '@/modules/settings/models/Account';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import { OrganizationsService } from '@/services/organizationsService';
import { Component, Prop } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import { PartnerAccountsService } from '../services/partnerAccountsService';

@Component({
    validations: {
        email: { required, email },
    },
} as any)
export default class ShareOrganizationComponent extends SideActionBase<any> {
    public partnerId: number = this.options.partnerId;

    public submitted: boolean = false;
    public email: string = '';

    private partnerAccountsService: PartnerAccountsService = new PartnerAccountsService();

    public cancel(): void {
        this.email = '';
        this.$emit('cancel');
    }

    public async submit(): Promise<void> {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showError(`The email address didn't pass the validation.`);
            return;
        }

        this.showPending('Adding account to partner...');

        await this.partnerAccountsService
            .addAccount(this.partnerId, this.email)
            .then((account: CreateResult<Account>) => {
                if (account && account.creationStatus === 'duplicate') {
                    return this.clearAndShowError('Duplicate email address, there is al ready an account with this email address');
                }

                this.clearAndShowSuccess('The account has successfully been added. It can take up a minute the account is visible in this list');
                this.$emit('finished');
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    this.clearAndShowError(`The e-mail address ${this.email} is unknown to us. Try a different e-mail address!`, err);
                } else {
                    this.clearAndShowError('The account could not be added', err);
                }
            });
    }
}
