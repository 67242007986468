import Sequence from './Sequence';
import { BillingInfoUpdate } from './BillingInfo';
import { PricingPlan } from './PricingPlan';
import moment from 'moment';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import Organization from '@/modules/organizations/models/Organization';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import ProjectViewer from '@/modules/projects/models/ProjectViewer';
import Instrument from '@/modules/instruments/Models/Instrument';

export enum ProjectStatus {
    Closed,
}

export class Project {
    public projectId: number;
    public name: string = '';
    public instruments: Instrument[] = [];
    public viewers: ProjectViewer[] = [];
    public teamAggregates: Instrument[] = [];
    public practitioner: Practitioner = null;
    public practitionerId: number;
    public organization: any = null;
    public organizationId: number = null;

    public participantCompletedCount: number;
    public participantCount: number;

    public surveyCompletedCount: number;
    public surveyCount: number;

    public startDate: Date;
    public closeDate: Date;
    public closeOnClosedDate: boolean = false;
    public dateCreated: Date;
    public expirationDate: Date;
    public Completed: number;
    public Status: ProjectStatus;
    public deleted: boolean;
    public checkedByPartner: boolean;

    public completeAlert: boolean;
    public emailProfile: boolean;
    public demographicInfo: boolean;
    public surveyCompleteAlert: boolean;
    public context: string;
    public contextOption: number;
    public schedule: ProjectSchedule[] = null;
    public timeZone: string = '';

    public anonymousSurveySettings: AnonymousSurveySettings = null;

    public sequence: Sequence;
    public demographicsEnabled: boolean;
    public knowledgeModel: KnowledgeModel = null;
    public combineInstrumentSurveys: boolean = false;
    public exportDataUrl?: ExportData = null;
    public _links: HATEOASLink[] = [];

    public welcomeTexts: { languageCode: string; text: string }[] = [];
    public thankYouTexts: { languageCode: string; text: string }[] = [];

    /**
     *
     */
    constructor(obj?: Partial<Project>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectCreate {
    public name: string;
    public context?: string;

    public teamInstrumentId: number;
    public practitionerId: number;
    public organizationId: number = null;
    public instId?: number = null;
    public pricingPlanId: number;
    public knowledgeModelId: number;
    public instruments: ProjectInstrument[] = [];

    public startDate: Date;
    public closeDate: Date = moment().add(7, 'days').toDate();
    public timeZone: string = '';

    public subscription: number;
    public additionalInstruments: number[] = [];
    public mandatoryInstruments: number[] = [];
    public contextMandatory: boolean = false;
    public combineInstrumentSurveys: boolean = false;

    private localPractitioner?: Practitioner = null;
    private localOrganization?: Organization = null;
    private localInstrument?: Instrument = null;
    private localPricingPlan?: PricingPlan = null;

    public ProjectCreate() {
        this.localOrganization = new Organization();
        this.localInstrument = new Instrument();
        this.localPractitioner = new Practitioner();
        this.localPricingPlan = new PricingPlan();
    }

    /* Necessary for multiselect */
    set practitioner(p: Practitioner) {
        this.practitionerId = p?.practitionerId;
        this.localPractitioner = p;
    }

    get practitioner() {
        return this.localPractitioner;
    }

    set organization(o: any) {
        this.organizationId = o.organizationId;
        this.localOrganization = o;
    }

    get organization() {
        return this.localOrganization;
    }

    set instrument(o: any) {
        if (o && o.id) {
            this.localInstrument = o;
        } else {
            this.localInstrument = null;
        }
    }

    get instrument() {
        return this.localInstrument;
    }

    set pricingPlan(o: any) {
        this.pricingPlanId = o ? o.value : null;
        this.localPricingPlan = o;
    }

    get pricingPlan() {
        return this.localPricingPlan;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectUpdate {
    public completeAlert: boolean;
    public context?: string;
    public demographicsEnabled: boolean;
    public emailProfile: boolean;
    public closeDate: Date;
    public closeOnClosedDate: boolean;
    public instruments: ProjectInstrument[] = [];
    public projectId: number;
    public projectName: string;
    public practitionerId: number;
    public sequence?: number;
    public startDate: Date;
    public surveyCompleteAlert: boolean;
    public anonymizeDataAfterSurveyComplete: boolean;
    public anonymousParticipantsAllowed?: number;

    public useOrganizationDemographicQuestions: boolean;
    public useParticipantDemographicData: boolean;
    public combineInstrumentSurveys: boolean = false;
    public welcomeTexts: any[] = [];
    public thankYouTexts: any[] = [];
    public schedule: ProjectSchedule = new ProjectSchedule();
    public timeZone: string = '';

    constructor(obj?: Project) {
        if (obj) {
            const projectUpdate = new ProjectUpdate();

            Object.keys(obj).forEach(function (key) {
                if (projectUpdate.hasOwnProperty(key)) {
                    projectUpdate[key] = obj[key];
                }
            });

            Object.assign(this, projectUpdate);

            this.projectName = obj.name;

            if (obj.sequence && obj.sequence.sequenceId) {
                this.sequence = obj.sequence.sequenceId;
            }

            const instrumentsProjectCreate: ProjectInstrument[] = [];
            obj.instruments.forEach((instrument) => {
                const projectInstrument = new ProjectInstrument();

                projectInstrument.instrumentId = instrument.instrumentId;

                if (instrument.pricingPlan) {
                    projectInstrument.pricingPlanId = instrument.pricingPlan.pricingPlanId;
                } else if (instrument.subscriptionId) {
                    projectInstrument.subscriptionId = instrument.subscriptionId;
                } else if (instrument.pricingPlanId) {
                    projectInstrument.pricingPlanId = instrument.pricingPlanId;
                }

                instrumentsProjectCreate.push(projectInstrument);
            });

            this.instruments = instrumentsProjectCreate;

            this.welcomeTexts = obj.welcomeTexts.filter((x) => x.text && x.text.length > 0);
            this.thankYouTexts = obj.thankYouTexts.filter((x) => x.text && x.text.length > 0);

            if (obj.anonymousSurveySettings) {
                this.anonymousParticipantsAllowed = obj.anonymousSurveySettings.allowedParticipants;
            }
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectSchedule {
    public template: string = '';
    public offsetDays: number = null;
    public reminderOffset: ReminderOffset = ReminderOffset.Unknown;

    constructor(obj?: Partial<ProjectSchedule>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export enum ReminderOffset {
    Unknown = 0,
    BeforeCloseDate = 1,
    AfterSurveyCreateDate = 2,
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectInstrument {
    public instrumentId: number;
    public subscriptionId?: number;
    public pricingPlanId?: number;

    constructor(obj?: Partial<ProjectInstrument>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file

export class HATEOASLink {
    public href: string;
    public rel: string;
    public type: string;
    public attributes: string[] = [];
}

export class ExportData {
    public url: string;
    public languages: string[];
}

export class AnonymousSurveySettings {
    public allowedParticipants: number;
    public surveyKey: string;
}
