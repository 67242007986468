import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import ProjectActionComponent from '@/components/side-actions/project-action/projectAction';
import ProjectFilter from '../models/ProjectFilter';
import BaseProjectOverviewPage from './base-project-overview';

@Component({
    components: {
        'create-project': require('@/modules/projects/components/create-project.vue').default,
    },
} as any)
export default class ProjectsOverviewComponent extends BaseProjectOverviewPage {
    public columns: GridColumnProps[] = [
        { field: 'ProjectName', title: 'Name', cell: this.renderProjectRouterLinkWithBreachCheck, width: 600, sortable: true },
        {
            field: 'instrument',
            title: 'Instrument(s)',
            cell: this.renderInstruments,
            width: 275,
            sortable: false,
            headerCell: this.renderInstrumentsHeader,
        },
        { field: 'practitioner', title: 'Practitioner', width: 225, cell: this.renderPractitionerLink, sortable: false },
        { field: 'organization', title: 'Organization', width: 225, cell: this.renderOrganizationLink, sortable: false },
        { field: 'participantCompletedCount', title: 'Completed', width: 125, cell: this.renderCompleted, sortable: false },
        { field: 'closeDate', title: 'Close date', cell: Vue.component('grid-date-display'), width: 125, sortable: true },
        { field: 'status', title: 'Status', cell: Vue.component('grid-status-display'), width: 85, sortable: false },
    ];

    public async created() {
        this.filter = new ProjectFilter(this.defaultFilter);
        this.$store.dispatch('instrumentsStore/fetch');

        if (this.isSuperAdmin) {
            this.columns.splice(2, 0, { field: 'knowledgeModel.name', title: 'Knowledge model', cell: this.renderKnowledgeModelLink, sortable: false });
            this.columns.splice(7, 0,  { field: 'startDate', title: 'Create date', cell: Vue.component('grid-date-display'), width: '150px', sortable: true },);
        }

        this.projectData.projects = new Array();
        await this.getProjects(true);

        this.$store.commit('projectStore/TAB_CHANGED', 0);
    }

    public createProjectSideAction(): void {
        this.$sideActions.push('project-create-action', new ProjectActionComponent());
    }
}
