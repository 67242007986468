import BasePage from '@/models/BasePage';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { Component, Prop } from 'vue-property-decorator';
import { PagedResponse } from '@/models/PagedResponse';
import to from 'await-to-js';
import Practitioner from '../models/Practitioner';
import { BaseService } from '@/services/baseService';

@Component
export default class APIKeysPractitionerOverviewComponent extends BasePage {
    @Prop({ default: null }) public practitioner: Practitioner;
    public apiKey?: string = null;
    public apiKeyService: ApiKeyService = new ApiKeyService();

    public columns: GridColumnProps[] = [
        { field: 'name', title: 'Name', width: 200 },
        // { field: 'description', title: 'Start date', cell: Vue.component('grid-date-display') },
        { field: 'description', title: 'Description' },
        { field: 'knowledgeModel.name', title: 'Knowledge model', width: 300 },
        { field: 'disabled', title: 'Active', cell: this.renderActive, width: 100 },
    ];
    public gridKey: string = '';
    public apiKeyData = {
        items: [],
        count: 0,
        skip: 0,
        take: 25,
    };

    public get publicAPiDocumentationUrl(): string {
        return Vue.$env().PublicAPiDocumentationUrl;
    }

    public async created() {
        await this.getAPIKeys();
        this.isLoaded = true;
    }

    public openAddOrChangeAPIKey(item?: any): void {
        this.$sideActions.push(
            'api-key-action',
            { practitioner: this.practitioner, apiKey: item ? item.dataItem : null } as ApiKeyActionParameters,
            async (apiKey: ApiKeyResponse) => {
                this.clearNotifications();

                if (apiKey && apiKey.isNew) {
                    this.showWarning('The API key is only shown once, so make sure to copy it to your clipboard.', true);
                    this.showSuccess('The API key is successfully added.', null, true);
                } else {
                    this.showSuccess('The API key is successfully updated.');
                }
                this.apiKey = apiKey.apiKey;

                await this.getAPIKeys();
            },
        );
    }

    public async copyToClipboard(apiKey) {
        await navigator.clipboard.writeText(apiKey);

        this.clearAndShowSuccess('The API key is copied to your clipboard.');
    }

    public renderActive(h, _, row) {
        return h('td', row.dataItem.disabled ? 'No' : 'Yes');
    }

    private updateCacheKey(): void {
        this.gridKey = new Date().getTime().toString();
    }

    private async getAPIKeys() {
        const [err, response] = await to(this.apiKeyService.getApiKeys());
        if (err || !response) {
            return;
            // return this.clearAndShowError(
            //     `The api keys couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem still exists, contact us with the support button.`,
            //     err,
            // );
        }

        this.apiKeyData.items = response.items;
        this.apiKeyData.count = response.count;

        this.updateCacheKey();
    }
}

import Vue from 'vue';
import ApiKey from '../models/APIKeys/ApiKey';
import { CreateApiKeyRequest, UpdateApiKeyRequest } from '../side-actions/api-key-action';
export class ApiKeyService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getApiKeys(): Promise<PagedResponse<ApiKey>> {
        const [err, response] = await to(this.get<PagedResponse<ApiKey>>(`${this.endpoint}/api-keys`));
        if (err) {
            throw err;
        }

        return response.data;
    }

    public async createApiKey(apiKey: CreateApiKeyRequest): Promise<ApiKeyResponse> {
        const [err, response] = await to(this.post<ApiKeyResponse>(`${this.endpoint}/api-keys`, apiKey));
        if (err) {
            throw err;
        }

        return response.data;
    }

    public async updateApiKey(apiKey: UpdateApiKeyRequest): Promise<ApiKeyResponse> {
        const [err, response] = await to(this.put<ApiKeyResponse>(`${this.endpoint}/api-keys/${apiKey.apiKeyId}`, apiKey));
        if (err) {
            throw err;
        }

        return response.data;
    }
}

export class ApiKeyResponse {
    apiKeyId: string;
    apiKey: string;
    isNew: boolean;
}

export class ApiKeyActionParameters {
    practitioner: Practitioner;
    apiKey: ApiKey;
}
