import { BModal } from 'bootstrap-vue';
import PageRender from '@/models/PageRender';

export default class BaseModal extends PageRender {
    public $refs!: {
        modal: BModal;
    };

    public show() {
        this.$refs.modal.show();
    }

    public hide() {
        this.$refs.modal.hide();
    }
}
