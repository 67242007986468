import Vue from 'vue';
import { languageService } from '../../main';
import { Component, Prop } from 'vue-property-decorator';
import { Language } from '../../models/Language';

@Component
export default class LanguageInputComponent extends Vue {
    @Prop({ default: () => new Language() }) public value: Language;
    @Prop({ default: () => false }) public submitted: boolean;
    public languageSettings: any = {
        options: [],
        labelField: 'languageName',
        valueField: 'id',
        placeholder: 'The preferred language',
    };

    public languages: Language[] = [];

    get realValue(): Language {
        return this.value;
    }

    set realValue(value: Language) {
        this.$emit('input', value);
    }

    public async created() {
        this.languages = languageService.getLanguages();
        this.languageSettings.options.push(...this.languages);
    }

    public onSelect(selectedOption: Language): void {
        this.$emit('input', selectedOption);
    }
}
