import { DemoGraphicItem } from '@/models/DemographicItem';

export default class ProjectDemographic {
    public demographicData: DemoGraphicItem[] = [];
    public name: string;
    public participantId: number;

    constructor(obj?: Partial<ProjectDemographic>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
