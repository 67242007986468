import { GridColumnProps } from '@progress/kendo-vue-grid';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import PageRender from '@/models/PageRender';
import Sequence from '@/models/Sequence';
import { SequenceActionOptions } from '@/components/side-actions/sequence-actions/sequenceAction';
import { sequenceService } from '@/main';
import { SequenceUpdateActionOptions } from '@/components/side-actions/sequence-actions/sequenceEditAction';
import AddParticipantForProjectCommand from '@/commands/addParticipantForProjectCommand';
import { OrganizationsService } from '@/services/organizationsService';
import { OrganizationGroupService } from '@/services/organizationGroupService';

@Component
export default class SequenceOverviewComponent extends PageRender {
    @Prop({ default: () => 0 }) public organizationId: number;
    @Prop({ required: true, default: () => [] }) public practitioners: Practitioner[];

    public cacheKey: string = '';
    public sequences: Sequence[] = [];
    public loading: boolean = true;
    public columns: GridColumnProps[] = [
        { field: 'group.name', title: 'Group', cell: this.renderRouterlink },
        { field: 'startDate', title: 'Start date', cell: Vue.component('grid-date-display') },
        { field: 'interval', title: 'Interval', cell: Vue.component('grid-interval-display') },
        { field: 'gridActions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    private organizationsService: OrganizationsService = new OrganizationsService();
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public async mounted() {
        await this.refreshData();
    }

    public showCreateSequenceAction(): void {
        this.$sideActions.push(
            'create-sequence-action',
            new SequenceActionOptions({ organizationId: this.organizationId, groupIsRequired: true, practitioners: this.practitioners }),
            async () => {
                await this.refreshData();
            },
        );
    }

    private async refreshData(): Promise<void> {
        this.loading = true;

        this.sequences = await this.organizationsService.getSequences(this.organizationId);
        this.cacheKey = new Date().getTime().toString();
        this.loading = false;
    }

    private renderRouterlink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.group.name,
                url: this.$router.resolve({
                    name: 'group-details',
                    params: { organizationId: this.organizationId.toString(), groupId: row.dataItem.group.id.toString() },
                }).href,
            },
        });
    }

    private renderActions(item: any, _, row): any {
        const actions = [
            { title: 'Edit sequence', function: this.showEditSequenceAction },
            { title: 'Create new project for this group', function: this.createIterationForSequence },
            { title: 'Delete', function: this.deleteSequence },
        ];
        const props = { actions, item: row.dataItem };

        return item(Vue.component('grid-actions'), { props });
    }

    private showEditSequenceAction(selectedSequence: Sequence): void {
        this.$sideActions.push(
            'update-sequence-action',
            new SequenceUpdateActionOptions({
                groupIsRequired: true,
                organizationId: this.organizationId,
                practitioners: this.practitioners,
                sequenceData: selectedSequence,
            }),
            async () => {
                await this.refreshData();
            },
        );
    }

    private deleteSequence(selectedSequence: Sequence): void {
        if (confirm('Are you sure that you want to delete the sequence for the group: ' + selectedSequence.group.name)) {
            this.showPending('Deleting sequence');

            sequenceService
                .deleteSequence(selectedSequence.sequenceId)
                .then(async () => {
                    this.clearAndShowSuccess('Sequence successfully deleted!');
                    await this.refreshData();
                })
                .catch((e) => {
                    this.clearAndShowError('Sequence could not be deleted.', e);
                });
        }
    }

    private async createIterationForSequence(selectedSequence: Sequence): Promise<void> {
        this.showPending('Creating project');
        const createdProject = await sequenceService.createProject(selectedSequence.sequenceId);
        this.clearAndShowSuccess('Project created');

        const organizationGroup = await this.organizationGroupService.getOrganizationGroup(this.organizationId, selectedSequence.group.id);
        const addParticipants = new AddParticipantForProjectCommand(this.$store, organizationGroup.members);

        try {
            await addParticipants.execute();
            await this.$router.push({ name: 'addSurvey', params: { projectId: createdProject.projectId.toString() } });
        } catch (e) {
            console.error(e);
        }
    }
}
