export default class TranslationStatus {
    public instruments: InstrumentStatus[] = [];

    constructor(obj?: Partial<TranslationStatus>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class InstrumentStatus {
    public instrumentId: number;
    public instrumentName: string;
    public instrumentAlias: string;

    public translationStatus: InstrumentTranslationStatus[];
}

export class InstrumentTranslationStatus {
    public languageCode: string;
    public section: TranslationSectionEnum;
    public subSection: TranslationSubSectionEnum;
    public status: string;
    public reportAlias?: string;

    constructor(obj?: Partial<InstrumentTranslationStatus>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export enum TranslationSectionEnum {
    'generic' = 0,
    'survey' = 1,
    'reports' = 2,
}

export enum TranslationSubSectionEnum {
    'generic' = 0,
    'surveyQuestions' = 1,
    'surveyAnswers' = 2,
    'reportsWeb' = 3,
    'reportsPowerpoint' = 4,
}

export enum PublicationStatusEnum {
    'unknown' = -1,
    'new' = 0,
    'published' = 1,
}
