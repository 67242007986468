import { Component } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';
import { Member } from '@/models/Member';
import { loginHelper } from '@/main';

@Component({
    components: {
        'two-factor-setting': require('@/modules/profile/components/2fa.vue').default,
    },
})
export default class AdminProfileComponent extends BasePage {
    public account: Member;
    public isLoaded: boolean = false;


    public async created() {
        this.account = loginHelper.getUser();
        this.isLoaded = true;
    }
}
