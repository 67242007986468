import { Component, Prop } from 'vue-property-decorator';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import { Country } from '@/models/Country';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import BasePage from '@/models/BasePage';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { CountryService } from '@/services/countryService';
import { loginHelper } from '@/main';

@Component({
    validations: {
        practitioner: {
            firstName: { required },
            lastName: { required },
            companyName: { required },
            emailAddress: { required, email },
            preferredLanguage: { required },
            paperlessOrganizationId: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            partner: { required },
            paperlessContactId: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            addressLine1: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            zipCode: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            city: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            country: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            state: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
            taxNumber: {
                required: requiredIf((practitioner: CreatePractitionerModel) => {
                    return practitioner.directInvoicing;
                }),
            },
        },
    },
} as any)
export default class CreatePractitionerComponent extends BasePage {
    public partnerId: number = null;
    @Prop() public practitioner: CreatePractitionerModel;
    public partnerOptions: any[] = [];
    public submitted: boolean = false;
    public disabledPartnerSelector: boolean = false;
    private partnerService: PartnerService = new PartnerService();

    public async created() {
        if (this.isSuperAdmin || this.isPartner) {
            await this.getPartners();
        }
    }

    private async getPartners() {
        if (this.isPartner) {
            this.partnerId = loginHelper.getUser().partnerId;
            const partner = await this.partnerService.getPartner(this.partnerId);
            if (partner) {
                this.partnerOptions.push({
                    id: partner.partnerId,
                    name: partner.companyName,
                });

                if (this.partnerOptions.length) {
                    this.practitioner.partner = this.partnerOptions[0];
                    this.disabledPartnerSelector = true;
                }
            }
            return;
        }

        await this.partnerService
            .getPartners({ skip: 0, take: 99999 })
            .then((partners) => {
                partners.items.forEach((partner) => {
                    this.partnerOptions.push({
                        id: partner.partnerId,
                        name: partner.companyName,
                    });
                });

                if (this.partnerId && this.partnerOptions.find((x) => x.id === this.partnerId)) {
                    this.practitioner.partner = this.partnerOptions.find((x) => x.id === this.partnerId);
                }
            })
            .catch(() => {
                this.showError(
                    `The partners couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}
