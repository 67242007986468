import Component from 'vue-class-component';
import to from 'await-to-js';
import BasePage from '@/models/BasePage';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import { LanguagePermissionService } from '@/modules/settings/services/languagePermissionsService';
import { languageService } from '@/main';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { PagedResponse } from '@/models/PagedResponse';
import TranslationStatusComponent from '@/modules/translations/components/translation-status.vue';
import { Language } from '@/models/Language';
import LanguagePermission, { InstrumentPermission } from '@/modules/settings/models/LanguagePermission';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component({
    components: {
        'translation-status': TranslationStatusComponent,
    },
})
export default class Translations extends BasePage {
    public isLoading: boolean = true;
    public selectedKnowledgeModel: KnowledgeModel = null;

    public knowledgeModels: KnowledgeModel[] = [];
    public languagePermissions: LanguagePermission[] = [];
    public allLanguages: Language[] = [];
    public languages: Language[] = [];
    public instruments: Instrument[] = [];

    public translationStatuses: any[] = [];

    public reloaded: number = 0;

    public practitionersService: PractitionerService = new PractitionerService();
    public partnersService: PartnerService = new PartnerService();
    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public async created() {
        await this.getLanguagePermissions();
        await this.getKnowledgeModels();

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        if (this.knowledgeModels && this.knowledgeModels.length) {
            await this.loadTranslationsKnowledgeModel(this.selectedKnowledgeModel);
        } else {
            this.showError(`You don't have rights to a knowledge model`);
        }

        await this.$store.dispatch('instrumentsStore/fetch');

        this.isLoading = false;
    }

    public async loadTranslationsKnowledgeModel(knowledgeModel: KnowledgeModel) {
        this.selectedKnowledgeModel = knowledgeModel;

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        const [err, response] = await to(this.knowledgeModelService.getKnowledgeModel(knowledgeModel.knowledgeModelId));
        this.selectedKnowledgeModel.instruments = response.instruments;

        this.languages = [];
        for (let i = 0; i < response.instruments.length; i++) {
            const instrument = response.instruments[i];

            if (instrument.languages) {
                for (let z = 0; z < instrument.languages.length; z++) {
                    if (
                        (this.isPractitioner || this.isPartner) &&
                        !this.languagePermissions[0].instrumentPermissions.find((x) => x.instrumentId === instrument.instrumentId)
                    ) {
                        continue;
                    }

                    const instrumentLang = instrument.languages[z];
                    const lang = this.allLanguages.find((x) => x.languageCode === instrumentLang);

                    if (lang && !this.languages.find((x) => x.languageCode === lang.languageCode)) {
                        this.languages.push(lang);
                    }
                }
            }
        }

        this.languages.sort((a, b) => (a.languageName > b.languageName ? 1 : -1));

        await this.loadTranslationStatus();
    }

    public async loadTranslationStatus() {
        this.translationStatuses = [];
        this.instruments = [];

        if (!this.isSuperAdmin) {
            this.instruments = this.languagePermissions[0].instrumentPermissions
                .filter((x) => this.selectedKnowledgeModel.instruments.find((y) => y.instrumentId === x.instrumentId))
                .map((x) => new Instrument(x));
        } else {
            this.instruments = this.selectedKnowledgeModel.instruments.map((x) => new Instrument(x));
        }

        for (let i = 0; i < this.languages.length; i++) {
            let language = this.languages[i];

            const [err, status] = await to(languageService.getTranslationStatus(this.selectedKnowledgeModel.alias, language.languageCode));
            if (err) {
                this.showError('The translation are not retrieved due to a error');
                return;
            }

            this.translationStatuses.push({
                languageCode: language.languageCode,
                status: status,
            });
        }
    }

    public getStatus(languageCode: string, instrumentId: number) {
        const status = this.translationStatuses.find((x) => x.languageCode === languageCode);
        if (status && status.status) {
            return status.status.instruments.find((y) => y.instrumentId === instrumentId);
        }
    }

    public instrumentReports(languageCode: string, instrumentId: number) {
        // check if default lang is part of languages or else fallback to first
        let lang = this.languages.find((x) => x.languageCode === languageCode);
        if (!lang) {
            lang = this.languages[0];
        }

        const instrumentStatus = this.getStatus(languageCode, instrumentId);
        if (!instrumentStatus) {
            return {
                languageCode: '',
                section: 'reports',
                status: 'new',
                subSection: '',
                reportAlias: '',
            };
        }

        return instrumentStatus.translationStatus.filter((x) => x.reportAlias);
    }

    private async getKnowledgeModels() {
        await this.knowledgeModelService.getKnowledgeModels({}).then((response: PagedResponse<KnowledgeModel>) => {
            const knowledgeModels = response.items.map((k) => {
                return {
                    ...k,
                    knowledgeModelId: k.knowledgeModelId,
                    name: k.name,
                    alias: k.alias,
                };
            });

            if (!this.isSuperAdmin) {
                this.languagePermissions[0].instrumentPermissions.forEach((permission: InstrumentPermission) => {
                    const knowledgeModel = knowledgeModels.find((x) => x.knowledgeModelId === permission.knowledgeModelId);
                    if (knowledgeModel && !this.knowledgeModels.find((x) => x.knowledgeModelId === knowledgeModel.knowledgeModelId)) {
                        this.knowledgeModels.push(knowledgeModel);
                    }
                });
            } else {
                this.knowledgeModels = knowledgeModels;
            }

            this.selectedKnowledgeModel = this.knowledgeModels[0];
        });
    }

    private async getLanguagePermissions() {
        this.languagePermissions = await this.languagePermissionService.getLanguagePermissions();
    }
}
