import { Component } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';

@Component({
    components: {
        'practitioner-profile': require('@/modules/profile/components/practitioner-profile.vue').default,
        'partner-profile': require('@/modules/profile/components/partner-profile.vue').default,
        'admin-profile': require('@/modules/profile/components/admin-profile.vue').default,
    },
})
export default class ProfileComponent extends BasePage {}
