import BasePage from '@/models/BasePage';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SideActionBase<T> extends BasePage {
    @Prop() public options: T;

    public mounted() {
        (this.$el.parentElement.parentElement.parentElement as any).focus();
    }

    public cancel(): void {
        this.$emit('cancel');
    }

    public finished(param?: any): void {
        this.$emit('finished', param);
    }
}
