// import { CreateResult } from '@/models/CreateResult';
import { PagedResponse } from '@/models/PagedResponse';
import { BaseService } from '@/services/baseService';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Account from '../models/Account';
import AccountFilter from '../models/AccountFilter';

export class AccountsService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public getAccount(accountId: number): Promise<Account> {
        return new Promise<Account>((resolve, reject) => {
            this.get<Account>(`${this.endpoint}/accounts/${accountId}`)
                .then((response: AxiosResponse<Account>) => {
                    resolve(new Account(response.data));
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public unlockAccount(accountId: number): Promise<Account> {
        return new Promise<Account>((resolve, reject) => {
            this.post<Account>(`${this.endpoint}/accounts/${accountId}/unlock`, null)
                .then((response: AxiosResponse<Account>) => {
                    resolve(new Account(response.data));
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public getAccounts(filter: Partial<AccountFilter>): Promise<PagedResponse<Account>> {
        return new Promise<PagedResponse<Account>>((resolve, reject) => {
            const params = [];
            for (const property in filter) {
                if (filter[property] !== null && filter[property] !== undefined) {
                    if (Array.isArray(filter[property])) {
                        const propertyArray = filter[property];
                        propertyArray.forEach((element) => {
                            params.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                        });
                    } else {
                        params.push(encodeURIComponent(property) + '=' + encodeURIComponent(filter[property]));
                    }
                }
            }
            this.get<PagedResponse<Account>>(`${this.endpoint}/accounts?${params.join('&')}`)
                .then((response: AxiosResponse<PagedResponse<Account>>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public addUserRole(accountId, role: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/accounts/${accountId}/roles/${role}`, null)
                .then((response: AxiosResponse) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public removeUserRole(accountId, role: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.delete(`${this.endpoint}/accounts/${accountId}/roles/${role}`, null)
                .then((response: AxiosResponse) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
