import { Translation } from '@/models/Translations';
import Question from '@/modules/instruments/Models/Question';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridPopOverInstrumentComponent extends Vue {
    @Prop() public translations: Translation[];
    @Prop() public question: Question;

    get translation(): string {
        const translation = this.translations.find((x) => x.key === `QUESTION_${this.question.position}`);

        return translation ? translation.value : 'Not yet translated';
    }
}
