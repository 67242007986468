import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class GridArrangeOrderComponent extends Vue {
    @Prop() public dataItem: any;
    @Prop() public total: number;

    public moveUp(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit('move', this.$props.dataItem, -1);
        }
    }

    public moveDown(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit('move', this.$props.dataItem, 1);
        }
    }
}
