import Filter from '@/models/OrderAndFilter/Filter';

export default class ProjectFilter extends Filter {
    public search: string = '';
    public searchOptions = {
        project: true,
        practitioner: true,
        organization: true,
    };
    public Organization?: number;
    public InstrumentIds: number[] = [];

    constructor(obj?: any) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
