import { Language } from '@/models/Language';

export default class Participant {
    public selected: boolean = false;
    public participantId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public organizationId: number = 0;
    public userId?: number = null;
    public preferredLanguage: string = '';
    public preferredLanguageObject: Language = null;
    public demographicData: DemographicData[] = [];
    public lastCompletedSurvey: any = null;
    public projectCount: number = null;

    public groups: any;

    constructor(participant?: any) {
        (Object as any).assign(this, participant);
    }

    public getFullName(): string {
        let parts = [this.firstName, this.lastName];
        parts = parts.filter((value) => {
            return !!value;
        });

        return parts.join(' ');
    }
}

export class DemographicData {
    public label: string;
    public value: string;

    constructor(data?: Partial<DemographicData>) {
        (Object as any).assign(this, data);
    }
}
