import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridPersonalLinkButtonComponent extends Vue {
    @Prop() public title: string;
    @Prop() public url: string;
    @Prop() public disabled: boolean;

    public openlink(url: string) {
        url = this.url;
        window.open(url);
    }
}
