import { Component } from 'vue-property-decorator';
import { instrumentsService } from '@/main';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import PricingPlanUpdateActionParameters from './pricingplanUpdateActionParameters';

@Component
export default class PricingPlanAddActionComponent extends SideActionBase<PricingPlanUpdateActionParameters> {
    public pricingPlanName: string = this.options.pricingPlan.pricingPlanName;
    public pricingPlanAmount: string = this.options.pricingPlan.pricingPlanAmount.toFixed(2);

    public working: boolean = true;

    public async updatePricingPlan(evt: any): Promise<any> {
        evt.preventDefault();

        this.working = true;
        await instrumentsService.updatePricingPlan(this.options.instrumentId, this.options.pricingPlan.pricingPlanId, {
            pricingPlanName: this.pricingPlanName,
            pricingPlanAmount: Number(this.pricingPlanAmount),
        });

        this.working = false;

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }
}
