import { InvoiceStatus } from './enums/InvoiceStatus';
import InvoiceLine from './InvoiceLine';

export default class Invoice {
    public id = 0;
    public name = '';
    public paperlessOrganizationId = 0;
    public paperlessContactId = 0;
    public invoiceStatus: InvoiceStatus = InvoiceStatus.Unknown;
    public invoiceLines: InvoiceLine[] = [];

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
