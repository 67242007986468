import { GridColumnProps } from '@progress/kendo-vue-grid';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import BasePage from '@/models/BasePage';
import { KnowledgeModelService } from '../services/knowledgeModelService';
import to from 'await-to-js';
import KnowledgeModel from '../models/KnowledgeModel';
import { InstrumentType } from '@/models/Instrument';

@Component({})
export default class KnowledgeModelDetailComponent extends BasePage {
    public knowledgeModelId: number = 0;
    public knowledgeModel: KnowledgeModel = new KnowledgeModel();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();
    public isLoaded: boolean = false;

    public selectedInstruments: number[] = [1, 4, 7];

    public columns: GridColumnProps[] = [
        { field: 'name', title: 'Name', cell: this.renderRouterLink },
        { field: 'instrumentType', title: 'Instrument type', cell: this.renderBoolean },
    ];

    public get instruments() {
        return this.$store.getters['instrumentsStore/instruments'];
    }

    public async created() {
        this.knowledgeModelId = parseInt(this.$route.params.knowledgeModelId);

        await this.getKnowledgeModel();

        this.isLoaded = true;
    }

    public async updateKnowledgeModel() {
        this.showPending('Updating knowledge model...');
        const [error, response] = await to(this.knowledgeModelService.updateKnowledgeModel(this.knowledgeModel));
        if (error) {
            this.clearAndShowError('Failed to update knowledge model', error);
            this.knowledgeModel = new KnowledgeModel();
            return;
        }

        if (response) {
            this.knowledgeModel = response;
        }
        this.editMode = false;
        this.clearAndShowSuccess('Knowledge model updated successfully');
    }

    public openAddTranslationAction() {
        this.$sideActions.push('add-translation-action', { knowledgeModel: this.knowledgeModel }, async () => {});
    }

    public openCreateInstrumentAction(): any {
        this.$sideActions.push('instrument-create-action', { knowledgeModelId: this.knowledgeModelId }, async () => {
            await this.getKnowledgeModel();
        });
    }
    private async getKnowledgeModel(): Promise<void> {
        const [error, knowledgeModel] = await to(this.knowledgeModelService.getKnowledgeModel(this.knowledgeModelId));
        if (error) {
            this.clearAndShowError('Failed to load knowledge model', error);
            this.knowledgeModel = new KnowledgeModel();
            return;
        }

        this.knowledgeModel = new KnowledgeModel(knowledgeModel);
    }

    private renderBoolean(h, _, row) {
        return h(
            'td',
            row.dataItem.instrumentType === InstrumentType.Group
                ? 'Group'
                : row.dataItem.instrumentType === InstrumentType.Personal
                ? 'Personal'
                : 'Unknown',
        );
    }

    private renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.name,
                url: this.$router.resolve({ name: 'instrument-details', params: { instrumentId: row.dataItem.instrumentId.toString() } }).href,
            },
        });
    }
}
