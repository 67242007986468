import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { OrganizationGroup } from '../../../models/Organization';
import { OrganizationGroupService } from '@/services/organizationGroupService';

enum GroupCreateMode {
    Unknown = 'unknown',
    Single = 'single',
    Multiple = 'multiple',
    Import = 'import',
}

@Component
export default class CreateGroupActionComponent extends SideActionBase<any> {
    public createMode: GroupCreateMode = GroupCreateMode.Unknown;
    public createData: any[] = [];

    public groupInput: any = {
        name: '',
    };
    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public created() {
        this.addNewGroup();
    }

    public addNewGroup(): void {
        this.createData.push({ name: '' });

        this.$nextTick(() => {
            const index = this.createData.length - 1;
            if (index > 0) {
                const input = this.$refs.groupName[index];
                input.focus();
            }
        });
    }

    public create(e): void {
        e.preventDefault();

        this.showPending('Creating all the groups');

        const allCreates = new Array<Promise<OrganizationGroup>>();
        for (let i = 0; i < this.createData.length; i++) {
            const createItem = this.createData[i];

            allCreates.push(this.organizationGroupService.createGroup(this.options.organizationId, createItem));
        }

        Promise.all(allCreates)
            .finally(() => {
                this.clearAndShowSuccess('All groups are created!');
                this.finished();
            })
            .catch((rejectedEntity: any) => {
                this.clearAndShowError(`Creation of the group ${rejectedEntity.name} failed.`, rejectedEntity);
            });
    }
}
