import Component from 'vue-class-component';
import { AxiosResponse } from 'axios';
import to from 'await-to-js';
import { VueEditor } from 'vue2-editor';
import BasePage from '@/models/BasePage';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import { emailService } from '@/main';
import EmailTemplate from '@/modules/emailTemplates/models/emailTemplate';
import { Watch } from 'vue-property-decorator';

@Component({
    components: {
        VueEditor,
    },
})
export default class EmailTemplates extends BasePage {
    public allEmails: EmailTemplate[] = [];
    public isLoading: boolean = true;
    public selectedKnowledgeModel: KnowledgeModel = null;
    public knowledgeModels: KnowledgeModel[] = [];
    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    private knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public async created() {
        await this.loadKnowledgeModels();
        this.allEmails = await this.getEmailTemplates();

        this.isLoading = false;
    }

    public get emails() {
        if (!this.selectedKnowledgeModel) {
            return;
        }

        if (this.selectedKnowledgeModel.alias === 'generic') {
            const genericTemplates = [];
            this.allEmails.forEach((x) => {
                if (this.knowledgeModels.filter((y) => y.alias.startsWith(x.template.substr(0, 3))).length === 0) {
                    genericTemplates.push(x);
                }
            });

            return genericTemplates;
        }

        const templates = [];
        const otherKnowledgeModels = this.knowledgeModels.filter((x) => x.alias !== this.selectedKnowledgeModel.alias);
        for (let i = 0; i < this.allEmails.length; i++) {
            let needToAdd = true;
            const email = this.allEmails[i];
            if (email.template.includes(this.selectedKnowledgeModel.alias)) {
                otherKnowledgeModels
                    .filter((z) => !this.selectedKnowledgeModel.alias.includes(z.alias))
                    .forEach((y) => {
                        if (needToAdd) {
                            needToAdd = !email.template.includes(y.alias);
                        }
                    });

                if (needToAdd) {
                    templates.push(email.id);
                }
            }
        }

        return this.allEmails.filter((x) => templates.indexOf(x.id) > -1);
    }

    public async getEmailTemplates() {
        const [err, response] = await to<EmailTemplate[]>(emailService.getTemplates());
        if (err) {
            this.showError('The content of the email has not retrieved due to a error');
            return;
        }

        return response;
    }

    public async saveContent(mail: EmailTemplate) {
        this.showPending(`Updating the email content.`);

        const [err, response] = await to<AxiosResponse>(emailService.updateTemplate(mail.id, mail));
        if (err) {
            this.clearAndShowError('The content of the email has not updated due to a error.', err);
            return;
        }

        this.clearAndShowSuccess('The content of this email has been updated');
        return response.data;
    }

    private async loadKnowledgeModels() {
        const response = await this.knowledgeModelService.getKnowledgeModels({
            skip: 0,
            take: 99,
            $count: false,
        });

        this.knowledgeModels = response.items;

        this.selectedKnowledgeModel = response.items[0];

        this.knowledgeModels.push(
            new KnowledgeModel({
                knowledgeModelId: 9999,
                alias: 'generic',
                name: 'Generic',
            }),
        );
    }
}
