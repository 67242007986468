import { Vue, Component, Prop } from 'vue-property-decorator';
import { GridColumnProps, GridGroupableSettings, GridPagerSettings, GridSortSettings } from '@progress/kendo-vue-grid';
import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ScrollMode } from '@progress/kendo-vue-grid/dist/npm/ScrollMode';
import { orderBy } from '@progress/kendo-data-query';

@Component
export default class GridWrapper extends Vue {
    @Prop({ type: Boolean }) public flat: boolean;
    @Prop({ type: Boolean }) public fixedHeight: boolean;

    // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridProps/
    @Prop() public alternatePerGroup?: boolean;
    @Prop() public cancel?: CallableFunction;
    @Prop() public cellRender?: CallableFunction;
    @Prop() public cellClick?: CallableFunction;
    @Prop() public columnMenu?: boolean;
    @Prop() public columnVirtualization?: boolean;
    @Prop() public columnreorder?: CallableFunction;
    @Prop() public columnresize?: CallableFunction;
    @Prop() public columns?: GridColumnProps[];
    @Prop() public dataItems?: any[];
    @Prop() public datastatechange?: CallableFunction;
    @Prop() public edit?: CallableFunction;
    @Prop() public editField?: string;
    @Prop() public expandField?: string;
    @Prop() public filter?: CompositeFilterDescriptor;
    @Prop() public filterCellRender?: CallableFunction;
    @Prop() public filterable?: boolean;
    @Prop() public filterchange?: CallableFunction;
    @Prop() public group?: GroupDescriptor[];
    @Prop() public groupable?: GridGroupableSettings | boolean;
    @Prop() public groupchange?: CallableFunction;
    @Prop() public headerCellRender?: CallableFunction;
    @Prop() public headerselectionchange?: CallableFunction;
    @Prop() public itemchange?: CallableFunction;
    @Prop({ default: 50 }) public pageSize?: number;
    @Prop({
        default: () => {
            return { buttonCount: 5, info: true, type: 'numeric', pageSizes: [25, 50, 100, 500, 1000, 5000, 10000], previousNext: true } as GridPagerSettings;
        },
    })
    public pageable?: GridPagerSettings | any;
    @Prop() public remove?: CallableFunction;
    @Prop() public reorderable?: boolean;
    @Prop({ default: true }) public resizable?: boolean;
    @Prop({ default: 34 }) public rowHeight?: number;
    @Prop() public rowRender?: string | CallableFunction;
    @Prop() public save?: CallableFunction;
    @Prop() public scrollable?: ScrollMode;
    @Prop() public selectedField?: string;
    @Prop() public selectionchange?: CallableFunction;
    @Prop() public sort?: SortDescriptor;
    @Prop() public sortable?: GridSortSettings;
    @Prop() public sortchange?: CallableFunction;
    @Prop() public detail?: any;
    @Prop() public total?: number;

    @Prop({ default: 0 }) public skip: number;
    @Prop({ default: 25 }) public take: number;

    @Prop({ default: () => ({ field: 'id', dir: 'asc' }) }) public orderBy?: SortDescriptor;

    public $refs!: {
        grid: any;
    };

    private get _dataItems() {
        return orderBy(this.dataItems, [this.orderBy]);
    }

    public mounted() {
        this.setHeight();
        this.$watch('dataItems', () => {
            this.setHeight();
        });
    }

    public getData() {
        return this._dataItems;
    }

    public getTotal() {
        return this.total;
    }

    public setHeight() {
        if (this.detail || !this.fixedHeight) {
            return;
        }

        let height = 0;
        const scrollbarHeight = 25;
        const defaultHeight = 65;
        const headerHeight: number = this.getHeaderHeight();
        const footerHeight: number = this.getFooterHeight();
        const rowHeight: number = this.getRowHeight();

        if (this._dataItems && this._dataItems.length) {
            height =
                headerHeight +
                footerHeight +
                (this.pageable ? (this._dataItems.length > this.take ? this.take : this._dataItems.length) : this._dataItems.length) * rowHeight;
        } else {
            height = defaultHeight;
        }

        if (this.pageable) {
            height += 46;
        }
        height += scrollbarHeight;
        this.$refs.grid.$el.style.height = height.toString() + 'px';
    }

    public pageChangeHandler(event: { page: { skip: number; take: number } }) {
        Vue.set(this, 'skip', event.page.skip);
        Vue.set(this, 'take', event.page.take);

        this.$emit('pagechange', event);
    }

    public rowClicked(e) {
        this.$emit('rowclick', e);
    }

    public expandChange(event: any) {
        Vue.set(event.dataItem, event.target.$props.expandField, event.value);
    }

    private getRowHeight(): number {
        if (this.$el && this.$el.querySelector) {
            const tableRow = this.$el.querySelector('tbody tr');
            return parseFloat(getComputedStyle(tableRow).height);
        }
        return this.rowHeight;
    }

    private getHeaderHeight(): number {
        if (this.$el && this.$el.querySelector) {
            const headRow = this.$el.querySelector('thead tr');
            return parseFloat(getComputedStyle(headRow).height);
        }
        return 34;
    }

    private getFooterHeight(): number {
        if (this.$el && this.$el.querySelector) {
            const footerRow = this.$el.querySelector('tfoot tr');
            if (footerRow) {
                return parseFloat(getComputedStyle(footerRow).height);
            }
        }
        return 47;
    }
}
