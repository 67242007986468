export class ProjectParticipantFilter {
    public skip: number;
    public take: number;
    public $count: boolean;
    public search: string;
    public projectId: number;

    constructor(filter: Partial<ProjectParticipantFilter>) {
        Object.assign(this, filter);
    }
}
