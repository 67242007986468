import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SurveyCreate } from '../../pages/surveys/models/participant';

@Component
export default class GridDeleteParticipant extends Vue {
    @Prop() public dataItem;

    get participants(): SurveyCreate[] {
        return this.$store.getters['participantsStore/participants'];
    }

    public removeParticipant() {
        const index = this.participants.findIndex((p) => this.dataItem.participant && p.participant === this.dataItem.participant);

        if (confirm('Are you sure you want to delete this participant?')) {
            this.participants.splice(index, 1);
        }
    }
}
