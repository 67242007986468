import Filter from '@/models/OrderAndFilter/Filter';
export default class PractitionerFilter extends Filter {
    public organizationId: number = null;
    public skip: number;
    public take: number;

    public search: string;
    public searchOptions = {
        emailaddress: true,
        partner: true,
        practitioner: true,
    };

    public $count: boolean;
    public includeDeleted?: boolean;

    public directInvoicingOnly?: boolean;
    public missingPaperlessOnly?: boolean;
    public embedOptions?: boolean;

    public InstrumentIds?: number[] = [];
    public KnowledgeModelIds?: number[] = [];

    constructor(obj?: Partial<PractitionerFilter>) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
