import { Component } from 'vue-property-decorator';
import BasePage from '../../../models/BasePage';
import { CreationStatus } from '../../../models/CreateResult';
import { BModal } from 'bootstrap-vue';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { CreatePractitionerModel } from '@/models/CreatePractitioner';
import createPractitionerVue from './create-practitioner.vue';
import instrumentSelectionVue from './instrument-selection.vue';
import priceplanSelectionVue from './priceplan-selection.vue';
import InstrumentPricing from '../models/InstrumentPricing';
import { PractitionerService } from '../services/practitionerService';
import { loginHelper } from '@/main';
import PractitionerCreate from '../models/PractitionerCreate';

@Component({
    components: {
        createPractitioner: createPractitionerVue,
        instrumentSelection: instrumentSelectionVue,
        priceplanSelection: priceplanSelectionVue,
    },
})
export default class CreatePractitionerWrapperComponent extends BasePage {
    public showWizard: boolean = true;
    public partnerService: PartnerService = new PartnerService();
    public progress: number = 0;
    public finishing: boolean = false;
    public toHandlePractitioner: CreatePractitionerModel = new CreatePractitionerModel();
    public instrumentPricing: InstrumentPricing[] = [];
    public currentStep: number = 1;

    private practitionerService: PractitionerService = new PractitionerService();

    public steps: any = {
        1: { display: 'Practitioner Information', name: 'createPractitionerVue', stepNumber: 1, isShowing: this.currentStep === 1 },
        2: { display: 'Instruments', name: 'addPractitionerInstrumentsVue', stepNumber: 2, isShowing: this.currentStep === 2 },
        3: { display: 'Pricing plans', name: 'addPractitionerPricingPlanVue', stepNumber: 3, isShowing: this.currentStep === 3 },
        lastStep: 3,
        finished: false,
    };

    public $refs!: {
        progressModal: BModal;
        createPractitioner: createPractitionerVue;
        instrumentSelection: instrumentSelectionVue;
        pricePlanSelection: priceplanSelectionVue;
    };

    public async created() {
        this.showWizard = this.$route.meta.showWizard;
    }

    public async next() {
        let next = this.currentStep + 1;
        if (next > this.steps.lastStep) {
            next = this.steps.lastStep;
        }

        if (this.currentStep == 1) {
            this.$refs.createPractitioner.submitted = true;
            if (!this.$refs.createPractitioner.$v.practitioner.$invalid) {
                await this.goTo(this.steps[next].stepNumber);
            }
        } else if (this.currentStep == 2) {
            this.$refs.instrumentSelection.submitted = true;
            if (!this.$refs.instrumentSelection.$v.practitioner.instruments.$invalid) {
                await this.goTo(this.steps[next].stepNumber);
            }
        }
    }

    public async goTo(nextPage: number) {
        if (this.currentStep == nextPage) {
            return;
        }
        this.currentStep = nextPage;
    }

    public back() {
        this.goTo(this.currentStep - 1);
    }

    public cancel() {
        this.$router.push({ name: 'practitioner-overview' });
    }

    public handlePricingSelect(values) {
        this.instrumentPricing = values;
    }

    public async finish(): Promise<void> {
        this.finishing = true;

        this.$refs.progressModal.show()

        const practitioner = {
            emailAddress: this.toHandlePractitioner.emailAddress,
            firstName: this.toHandlePractitioner.firstName,
            lastName: this.toHandlePractitioner.lastName,
            companyName: this.toHandlePractitioner.companyName,
            preferredLanguage: this.toHandlePractitioner.preferredLanguage.languageCode,
            partnerId: this.toHandlePractitioner.partner ? this.toHandlePractitioner.partner.id : loginHelper.getUser().id,
            instruments: this.toHandlePractitioner.instruments,
            billingInformation: null,
        } as PractitionerCreate;

        const createdPractitioner = await this.practitionerService.createPractitioner(practitioner);

        if (createdPractitioner && createdPractitioner.creationStatus === CreationStatus.created) {
            this.showSuccess('Successfully created practitioner');
            this.progress++
        } else {
            if (createdPractitioner && createdPractitioner.creationStatus === CreationStatus.duplicate) {
                this.showError(
                    `A practitioner with the email address ${this.toHandlePractitioner.emailAddress} already exists. Any questions about this practitioner? Please drop a support message`,
                    10000,
                );
            } else {
                this.showError('failed to create practitioner');
            }
        }

        if (this.instrumentPricing && this.instrumentPricing.length > 0) {
            this.instrumentPricing.forEach(async (instrument) => {
                const pricingPlansAdded = await this.practitionerService.setPricePlans(createdPractitioner.entity.practitionerId, instrument.instrument.id, instrument.pricingPlans.map(x => x.value));
                if (!pricingPlansAdded) {
                    this.showError(`failed to add pricing plans for instrument: ${instrument.instrument.name}`);
                }
                this.progress++
            })
        }

        this.steps.finished = true;
        this.$refs.progressModal.hide();
        this.proceedToPractitioner(createdPractitioner.entity.practitionerId);
    }

    public proceedToPractitioner(practitionerId) {
        this.clearNotifications();
        this.$router.push({ name: 'practitioner-details', params: { practitionerId } });
    }
}