import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { PractitionerService } from '../services/practitionerService';

@Component
export default class MergePractitionerAction extends SideActionBase<any> {
    public practitionerList: Practitioner[] = [];
    public practitionerOptions: any[] = [];

    public fromPractitioner: Practitioner = null;
    public toPractitioner: Practitioner = null;

    private practitionersService: PractitionerService = new PractitionerService();

    public async created() {
        await this.getPractitioners().then(() => {
            this.practitionerList.forEach((practitioner) => {
                this.practitionerOptions.push({
                    practitionerId: practitioner.practitionerId,
                    name: `${practitioner.firstName} ${practitioner.lastName} (${practitioner.emailAddress}) - ${practitioner.partnerName} - ${practitioner.hasAccount ? 'Has account' : 'No account'}`,
                });
            });
        });
    }

    public async mergePractitioners(): Promise<any> {
        await this.practitionersService.mergePractitioners(this.fromPractitioner.practitionerId, this.toPractitioner.practitionerId);

        this.finished();
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }

    public FromPractitionerOptions(): any[] {
        return this.practitionerOptions.filter((practitioner) => {
            return !this.toPractitioner || (this.toPractitioner && practitioner.practitionerId !== this.toPractitioner.practitionerId);
        });
    }

    public ToPractitionerOptions(): any[] {
        return this.practitionerOptions.filter((practitioner) => {
            return !this.fromPractitioner || (this.fromPractitioner && practitioner.practitionerId !== this.fromPractitioner.practitionerId);
        });
    }

    private async getPractitioners() {
        await this.practitionersService
            .getPractitioners({ skip: 0, take: 9999 })
            .then((practitioners) => {
                this.practitionerList = practitioners.items as any;
            })
            .catch(() => {
                this.showError(
                    `The countries couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}
