export enum ProgressType {
    Loading = 'Loading',
    Success = 'Success',
    Celebrate = 'Celebrate',
    Failed = 'Failed',
    Mail = 'Mail',
}

export default interface ProgressArgs {
    title?: string;
    text?: string;
    duration?: number;
}
