import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { projectsService, $router, languageService } from '../../main';
import { Project } from '../../models/Project';
import { SurveyDetails } from '../../models/Survey';
import { PagedResponse } from '../../models/PagedResponse';
import { ParticipantState, SurveyCreate } from './models/participant';
import { GridEditWrapper } from '../../models/GridEditWrapper';
import { Language } from '../../models/Language';
import BasePage from '../../models/BasePage';
import { filterBy, CompositeFilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import Participant from '@/modules/participants/models/Participant';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { SurveyService } from '@/services/surveyService';
import { GridAddMode } from '@/modules/participants/grid/grid-add-mode';
import { InstrumentType } from '@/models/Instrument';
import to from 'await-to-js';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import { GridPagerSettings } from '@progress/kendo-vue-data-tools/dist/npm/pager/GridPagerSettings';
import ParticipantOrderBy from './models/ParticipantOrderBy';
import { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';

@Component
export default class AddExistingParticipantComponent extends BasePage {
    public selectedParticipants: any = [];

    public surveys: ProjectParticipant[];
    public organizationParticipants: Array<GridEditWrapper<any>> = [];
    public isLoaded: boolean = false;
    public bigOrganization: boolean = false;
    public existingParticipantsKey: string = '';
    public participantService: ParticipantsService = new ParticipantsService();
    public surveyService: SurveyService = new SurveyService();
    public addModes: GridAddMode[] = [new GridAddMode({ disabled: false, label: 'Add', value: 'add', participantState: ParticipantState.Created })];
    public pageable: GridPagerSettings = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [500, 1000, 2500],
        previousNext: true,
    };

    public participantSearch: string = '';
    public skip: number = 0;
    public take: number = 500;
    public pageSize: number = 500;

    public columns: any[] = [
        { field: 'dataItem.addMode', title: 'Add', width: 150, cell: this.renderAddMode, sortable: false },
        { field: 'dataItem.firstName', title: 'First name', sortable: true },
        { field: 'dataItem.lastName', title: 'Last name', sortable: true },
        { field: 'dataItem.emailAddress', title: 'Email address', sortable: true },
        { field: 'dataItem.completionDate', title: 'Date completed', cell: this.renderDate, sortable: true },
    ];

    public orderBy = new ParticipantOrderBy();
    public sort: any[] = [];
    public sortable = {
        allowUnsort: true,
        mode: 'multiple',
    };

    public $refs!: {
        existingParticipants: any;
    };

    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    private languages: Language[] = null;
    private projectId: number = 0;
    private projectData: Project = null;
    private filterTimer: number = null;

    public get total() {
        return this.organizationParticipants.length;
    }

    public pageChangeHandler(event: { page: { skip: number; take: number } }) {
        Vue.set(this, 'skip', event.page.skip);
        Vue.set(this, 'take', event.page.take);
    }

    public async sortChangeHandler(e) {
        this.sort = e.sort;
        this.orderBy.reset();
        this.sort.forEach((sort: { field: string, dir: OrderByDirection}, index: number) => {
            this.orderBy.setSort(sort.field, sort.dir, index);
        });
        const completionSort = this.orderBy.fields.find((x) => x.key === "dataItem.completionDate" && x.direction != OrderByDirection.None);
        const dateSort = completionSort ? completionSort.direction == OrderByDirection.Asc ? `-${completionSort.field.split('.').pop()}` : `${completionSort.field.split('.').pop()}` : null;
        await this.getParticipants(dateSort);
    }

    public getPersonalBaseInstrument() {
        return this.projectData && this.projectData.instruments.find((x) => x.instrumentType === InstrumentType.Personal);
    }

    public async getParticipants(dateSort?: string) {
        const personalInstrument = this.getPersonalBaseInstrument();
        await this.participantService
            .getParticipants({
                organizationId: this.projectData.organization.organizationId,
                instrumentId: personalInstrument ? personalInstrument.instrumentId : null,
                skip: 0,
                take: 99999,
                $count: false,
                search: this.participantSearch,
            }, this.orderBy)
            .then((participants: PagedResponse<Participant>) => {
                const previousOrgParticipants = this.organizationParticipants;
                this.organizationParticipants = [];
                let tomorrow = new Date(Date.now() + 1);
                const orgparts = participants.items
                .sort((a, b) => {
                    let dateA = a.lastCompletedSurvey ? a.lastCompletedSurvey.completionDate ? new Date(a.lastCompletedSurvey.completionDate) : tomorrow : tomorrow;
                    let dateB = b.lastCompletedSurvey ? b.lastCompletedSurvey.completionDate ? new Date(b.lastCompletedSurvey.completionDate) : tomorrow : tomorrow;
                    switch (dateSort) {
                        case "-CompletionDate":
                            return dateB.getTime() < dateA.getTime() ? 1 : -1;
                        case "CompletionDate":
                            return dateA.getTime() < dateB.getTime() ? 1 : -1;
                        default:
                            return;
                    }
                });
                for (const orgParticipant of orgparts) {
                    if (
                        !this.surveys.find((s) => {
                            return orgParticipant.participantId === s.participantId;
                        })
                    ) {
                        const wasInPreviousList = previousOrgParticipants.find((x) => x.dataItem.participantId == orgParticipant.participantId);
                        // surveys contains any participant with this id
                        this.organizationParticipants.push(
                            new GridEditWrapper<any>(true, {
                                addMode: wasInPreviousList ? wasInPreviousList.dataItem.addMode?? 'none' : 'none',
                                participantId: orgParticipant.participantId,
                                preferredLanguageObject: this.languages.find((language) => {
                                    return orgParticipant.preferredLanguage === language.languageCode;
                                }),
                                preferredLanguage: orgParticipant.preferredLanguage,
                                firstName: orgParticipant.firstName,
                                lastName: orgParticipant.lastName,
                                emailAddress: orgParticipant.emailAddress,
                                organizationId: orgParticipant.organizationId,
                                lastCompletedSurvey: orgParticipant.lastCompletedSurvey,
                            }),
                        );
                    }
                }
                this.isLoaded = true;
                this.updateCacheKey()
            });
    }

    public async created() {
        const savedParticipants = this.$store.getters['participantsStore/participants'] as SurveyCreate[];

        this.projectId = parseInt($router.currentRoute.params.projectId);
        await projectsService.getProject(this.projectId).then((value: Project) => {
            this.projectData = value;
        });

        await this.loadParticipants();

        this.languages = languageService.getLanguages();

        await this.getParticipants();

        this.organizationParticipants.forEach((item) => {
            const matchingParticipant = savedParticipants.find((savedParticipant) => {
                return item.dataItem.id === savedParticipant.participant.participantId;
            });

            if (matchingParticipant) {
                switch (matchingParticipant.state) {
                    case ParticipantState.Added:
                        item.dataItem.addMode = 'add';
                        break;
                    case ParticipantState.Reused:
                        item.dataItem.addMode = 'reuse';
                        break;
                }
            }
        });

        if (!this.getPersonalBaseInstrument()) {
            this.columns = this.columns.filter((x) => x.field !== 'dataItem.completionDate');
        }
    }

    public async mounted() {
        this.updateCacheKey();
    }

    public selectReuseParticipants() {
        this.existingParticipantsFiltered().forEach((item) => {
            if ((item.dataItem.addMode === 'none' || item.dataItem.addMode === 'add') && item.dataItem.lastCompletedSurvey) {
                item.dataItem.addMode = 'reuse';
            }
        });

        this.updateCacheKey();
    }

    public selectAllParticipants() {
        this.existingParticipantsFiltered().forEach((item) => {
            if (item.dataItem.addMode === 'none') {
                item.dataItem.addMode = 'add';
            }
        });

        this.updateCacheKey();
    }

    public async loadParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.projectId,
                    skip: 0,
                    take: 99999,
                    $count: true,
                }),
            ),
        );

        if (err) {
            this.showError('Failed to load participants');
        }

        this.surveys = response.items.map((x) => new ProjectParticipant(x));
    }

    public async addSelectedParticipants() {
        const selectedParticipants = this.organizationParticipants.filter((item) => {
            return item.dataItem.addMode === 'add' || item.dataItem.addMode === 'reuse';
        });
        for (let i = 0; i < selectedParticipants.length; i++) {
            const addMode = selectedParticipants[i].dataItem.addMode === 'add' ? ParticipantState.Added : ParticipantState.Reused;

            this.$store
                .dispatch(
                    'participantsStore/addParticipant',
                    new SurveyCreate({
                        participant: selectedParticipants[i].dataItem,
                        state: addMode,
                        basedOn: new SurveyDetails({
                            surveyId: addMode === ParticipantState.Reused ? selectedParticipants[i].dataItem.lastCompletedSurvey.surveyId : null,
                        }),
                        // email: new Email()
                    }),
                )
                .catch((error) => {
                    this.showError(`${selectedParticipants[i].dataItem.emailAddress} could not be added due to an error.`);
                    console.error(`Error occurred while adding ${selectedParticipants[i].dataItem.emailAddress}. Error: ${error}`);
                });
        }

        this.showSuccess('Participants successfully added.');

        await $router.push({ name: 'addSurvey' });
    }

    public renderDate(item: any, _, row: any): any {
        let date = null;
        if (row.dataItem.dataItem.lastCompletedSurvey) {
            date = row.dataItem.dataItem.lastCompletedSurvey.completionDate;
        }

        return item(Vue.component('grid-date-display'), { props: { date } });
    }

    public existingParticipantsFiltered(): Array<GridEditWrapper<any>> {
        const participants = this.organizationParticipants.slice(this.skip, this.skip + this.take);

        return filterBy(orderBy(participants, this.sort), this.filter);
    }

    public participantSearched(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            await self.getParticipants();

            this.skip = 0;
        }, 400);

        this.updateCacheKey();
    }

    public renderAddMode(item: any, _, row: any): any {
        const addModes = this.addModes.slice(0, this.addModes.length);
        if (row.dataItem.dataItem.lastCompletedSurvey && this.getPersonalBaseInstrument()) {
            addModes.push({ disabled: false, label: 'Reuse', value: 'reuse', participantState: ParticipantState.Reused });
        }

        return item(Vue.component('grid-add-mode'), {
            props: {
                field: 'dataItem.addMode',
                dataItem: row.dataItem,
                addModes,
            },
        });
    }

    private updateCacheKey(): void {
        this.existingParticipantsKey = new Date().getTime().toString();
    }
}
