import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { loginService } from '@/main';

@Component({
    components: {
        vueDropzone: vue2Dropzone,
    },
})
export default class LogoUploader extends Vue {
    @Prop({ default: null }) public knowledgeModelId: number;
    @Prop({ default: null }) public templateId: number;

    public getDropZoneOptions() {
        return {
            url: `${Vue.$env().ManagementServiceEndpoint}/knowledge-models/${this.knowledgeModelId}/logo`,
            thumbnailWidth: 150,
            maxFilesize: 10,
            uploadMultiple: false,
            headers: {
                Authorization: `Bearer ${loginService.getAccessToken()}`,
            },
            paramName: 'file',
        };
    }

    public uploadDone(_, b) {
        this.$emit('upload-done', b);
    }
}
