import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridIsLockedStatus extends Vue {
    @Prop() public status: boolean;

    public responseLabels: any = {};

    public created() {
        this.responseLabels.locked = 'Locked';
        this.responseLabels.open = 'Open';
    }
}
