import moment from 'moment';

export class OrganizationSubscription {
    public id: number = 0;
    public orgId?: number;
    public seats: number = 0;
    public price: number = 0;
    public pricePerSeat?: number = 30;
    public startDate: Date;
    public renewalDate: Date = moment().add(1, 'years').subtract(1, 'days').toDate();
    public isActive: boolean = false;

    public instruments: any[] = [];
    public participantCount: number = 0;
    public knowledgeModelId?: number;
}
