import PillarThreshold from '@/models/PillarThreshold';
import { ConnectedInstrument, InstrumentReport, InstrumentType } from '@/models/Instrument';
import { PricingPlan } from '@/models/PricingPlan';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';

export default class Instrument {
    public instrumentId: number = 0;
    public active?: boolean = false;
    public deleted?: boolean = false;
    public connectedInstruments?: ConnectedInstrument[] = [];
    public contextOption?: string;

    public arePreviousResultsReusable?: boolean = false;
    public languages?: string[] = [];

    public name: string = '';
    public instrumentName?: string = '';
    public alias: string = '';
    public instrumentAlias?: string = '';

    public dataValidTtl: number = 0;
    public isAnonymous: boolean;
    public surveyDuration?: number;
    public surveyPriority?: number;
    public demographicQuestionsAllowed: boolean;
    public groupQuestionsBySection: boolean;
    public instrumentType: InstrumentType = null;
    public contextLocation: string;

    public aggregates?: Instrument[] = [];
    public reports: InstrumentReport[] = [];

    public isTeamInstrument?: boolean = false;
    public isContextRequired?: boolean = false;
    public isInstrumentSelectable?: boolean = false;

    public surveyUrl?: string;
    public reportUrl?: string;
    public processedUrl?: string;

    public knowledgeModel?: KnowledgeModel;
    public knowledgeModelId?: number;
    public knowledgeModelName?: string;
    public knowledgeModelAlias?: string;

    public instrumentPillars?: string[] = [];
    public pillarThresholds?: PillarThreshold[] = [];

    public pricingPlans: PricingPlan[] = [];
    public pricingPlanOptions?: any[] = [];

    public pricingPlan?: PricingPlan = null;
    public pricingPlanId?: number = null;

    public subscriptionId?: number = null;

    constructor(instrument?: Partial<Instrument> | any) {
        if (instrument) {
            (Object as any).assign(this, instrument);

            if (instrument.id) {
                this.instrumentId = instrument.id;
            }

            if (instrument.name) {
                this.instrumentName = instrument.name;
            } else if (instrument.instrumentName) {
                this.name = instrument.instrumentName;
            }

            if (instrument.alias) {
                this.instrumentAlias = instrument.alias;
            } else if (instrument.instrumentAlias) {
                this.alias = instrument.instrumentAlias;
            }
        }
    }
}
