import { PagedResponse } from '@/models/PagedResponse';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import BasePage from '@/models/BasePage';
import Partner from '../models/Partner';
import { PartnerService } from '../services/partnerService';

@Component({})
export default class PartnerOverviewComponent extends BasePage {
    public actions: any[] = [];
    public partnerService: PartnerService = new PartnerService();
    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public columns: GridColumnProps[] = [
        { field: 'companyName', title: 'Partner', cell: this.renderCompanyName },
        { field: 'practitionerCount', title: '# of practitioners' },
    ];

    public partnerData: any = {
        partners: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };

    private filterTimer: number = null;

    public async partnerCreatedCallback(partnerData: Partner) {
        await this.$router.push({ name: 'partner-details', params: { partnerId: partnerData.partnerId.toString() } });
    }

    public async created() {
        await this.getPartners(true);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.partnerData.skip = event.page.skip;
        this.partnerData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getPartners(false);
    }

    public renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.name,
                url: this.$router.resolve({ name: 'partner-details', params: { partnerId: row.dataItem.id.toString() } }).href,
            },
        });
    }

    public renderCompanyName(item: any, _: any, row: any): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.companyName ? row.dataItem.companyName : '(Company name not set)',
                url: this.$router.resolve({ name: 'partner-details', params: { partnerId: row.dataItem.id.toString() } }).href,
            },
        });
    }

    public renderRemainingPartnerPrepaid(item: any, _: any, row: any): any {
        return item(Vue.component('grid-money-display'), {
            props: {
                value: row.dataItem.remainingPrepaid,
                currencyCode: 'USD',
            },
        });
    }

    public filterChanged(): void {
        const self = this;
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            self.partnerData.skip = 0;
            await self.getPartners(true);
        }, 400);
    }

    public async refreshData() {
        this.partnerData.skip = 0;
        this.partnerData.take = 25;
        await this.getPartners(true);
    }

    public openCreatePartnerAction(): any {
        this.$sideActions.push('partner-create-action', null, async () => {
            await this.refreshData();
        });
    }

    private async getPartners(includeCount: boolean) {
        this.isLoaded = false;

        await this.partnerService
            .getPartners({ skip: this.partnerData.skip, take: this.partnerData.take, $count: includeCount, search: this.partnerData.search })
            .then((value: PagedResponse<Partner>) => {
                this.partnerData.partners = value.items.map((p) => {
                    return {
                        id: p.partnerId,
                        companyName: p.companyName,
                        preferredLanguage: p.preferredLanguage,
                        practitionerCount: p.practitionerCount,
                    };
                });

                if (includeCount) {
                    this.partnerData.count = value.count;
                }

                this.isLoaded = true;
            });
    }
}
