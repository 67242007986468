import SideActionBase from '@/plugins/sideActions/sideActionBase';
import { GridColumnProps } from '@progress/kendo-vue-grid/dist/npm/interfaces/GridColumnProps';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import PricePlan from '../models/PricePlan';
import { PartnerService } from '../services/partnerService';
import Instrument from '../models/Instrument';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import { InstrumentsService } from '@/modules/instruments/services/instrumentsService';

@Component
export default class EditInstrumentPricePlanActionComponent extends SideActionBase<EditInstrumentPricePlanActionOptions> {
    get pricePlanList(): any[] {
        return this.pricePlans.sort((a, b) => {
            return a.pricingPlanId < b.pricingPlanId ? -1 : 1;
        });
    }

    public partnerService: PartnerService = new PartnerService();
    public practitionerService: PractitionerService = new PractitionerService();
    public instrumentService: InstrumentsService = new InstrumentsService();
    public partnerId: number = this.options.partnerId;
    public practitionerId: number = this.options.practitionerId;
    public instrument: Instrument = this.options.instrument;
    public pricePlans: any[] = [];
    public thekey: string = new Date().getTime().toString();
    public loading: boolean = false;

    public columns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: this.renderCheckbox },
        { field: 'enabled', title: 'Enabled', width: '200px', cell: this.renderPill },
        { field: 'name', title: 'Price plan' },
        { field: 'amount', title: 'Price per survey' },
    ];

    public async mounted() {
        if (this.isPractitioner) {
            this.cancel();
        }

        await this.getPricePlans();

        this.isLoaded = true;
    }

    public setPricePlans(): void {
        const selectedPricePlans = this.pricePlans.filter((inst) => inst.selected);

        this.showPending('Saving pricing plan permission(s).');

        this.partnerService
            .setPricePlans(
                this.partnerId,
                this.instrument.instrumentId,
                selectedPricePlans.map((x) => x.pricingPlanId),
            )
            .then(async () => {
                this.clearAndShowSuccess('The pricing plan permission(s) are successfully changed.');
                await this.getPricePlans();
            })
            .catch((e) => {
                this.clearAndShowError('Failed to save the pricing plan permission(s).', e);
            });
    }

    public setPricePlansPractitioner(): void {
        const selectedPricePlans = this.pricePlans.filter((inst) => inst.selected);

        this.showPending('Saving pricing plan permission(s).');

        this.practitionerService
            .setPricePlans(
                this.practitionerId,
                this.instrument.instrumentId,
                selectedPricePlans.map((x) => x.pricingPlanId),
            )
            .then(async () => {
                this.clearAndShowSuccess('The pricing plan permission(s) are successfully changed.');
                await this.getPricePlans();
            })
            .catch((e) => {
                this.clearAndShowError('Failed to save the pricing plan permission(s).', e);
            });
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }

    public getHeight() {
        return {
            height: this.pricePlans.length === 0 ? 40 : this.pricePlans.length * 40,
        };
    }

    public rowRender(_h, tr, _, item) {
        tr.data.class += ` ${item.dataItem.deleted ? 'bg-secondary text-muted' : ''}`;
        tr.data.class += ` ${item.dataItem.enabled ? 'text-success' : ''}`;
        tr.data.class += ` ${!item.dataItem.enabled ? 'text-danger' : ''}`;
        return tr;
    }

    private updateCacheKey(): void {
        this.thekey = new Date().getTime().toString();
    }

    private renderCheckbox(item: any, _: any, row: any): any {
        return item(Vue.component('grid-checkbox'), {
            props: {
                dataItem: row.dataItem,
                field: 'selected',
            },
            on: {
                change: () => {
                    if (this.partnerId && !this.practitionerId) {
                        this.setPricePlans();
                    } else if (this.practitionerId) {
                        this.setPricePlansPractitioner();
                    }
                },
            },
        });
    }

    private async getPricePlans(): Promise<void> {
        this.pricePlans = [];

        if (this.partnerId && !this.practitionerId) {
            await this.partnerService.getPricePlans(this.instrument.instrumentId, this.partnerId).then((pricePlansResponse) => {
                const pricePlansMapped = pricePlansResponse.map((val: PricePlan) => {
                    return {
                        selected: true,
                        enabled: true,
                        name: val.pricingPlanName,
                        pricingPlanId: val.pricingPlanId,
                        amount: val.pricingPlanAmount,
                        key: `price-plan-${val.pricingPlanId}`,
                    };
                });

                this.pricePlans.push(...pricePlansMapped);
            });

            const listPricePlans = await this.instrumentService.getInstrumentPricingPlans(this.instrument.instrumentId);
            listPricePlans.forEach((element) => {
                if (!this.pricePlans.find((x) => x.pricingPlanId === element.pricingPlanId)) {
                    this.pricePlans.push({
                        selected: false,
                        enabled: false,
                        name: element.pricingPlanName,
                        pricingPlanId: element.pricingPlanId,
                        amount: element.pricingPlanAmount,
                        key: `price-plan-${element.pricingPlanId}`,
                    });
                }
            });
        } else if (this.practitionerId) {
            await this.practitionerService.getPricePlans(this.instrument.instrumentId, this.practitionerId).then((pricePlansResponse) => {
                const pricePlansMapped = pricePlansResponse.map((val: PricePlan) => {
                    return {
                        selected: true,
                        enabled: true,
                        name: val.pricingPlanName,
                        pricingPlanId: val.pricingPlanId,
                        amount: val.pricingPlanAmount,
                        key: `price-plan-${val.pricingPlanId}`,
                    };
                });

                this.pricePlans.push(...pricePlansMapped);
            });

            const listPricePlans = await this.partnerService.getPricePlans(this.instrument.instrumentId, this.partnerId);

            listPricePlans.forEach((element) => {
                if (!this.pricePlans.find((x) => x.pricingPlanId === element.pricingPlanId)) {
                    this.pricePlans.push({
                        selected: false,
                        enabled: false,
                        name: element.pricingPlanName,
                        pricingPlanId: element.pricingPlanId,
                        amount: element.pricingPlanAmount,
                        key: `price-plan-${element.pricingPlanId}`,
                    });
                }
            });
        }

        this.updateCacheKey();

        this.isLoaded = true;
    }

    private renderPill(item: any, _: any, row: any): any {
        return item('td', {}, [row.dataItem.enabled ? 'Yes' : 'No']);
    }
}

// tslint:disable: max-classes-per-file
export class EditInstrumentPricePlanActionOptions {
    public instrument: Instrument;
    public partnerId: number;
    public practitionerId: number;

    constructor(data: EditInstrumentPricePlanActionOptions) {
        Object.assign(this, data);
    }
}
