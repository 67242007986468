import { Component } from 'vue-property-decorator';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import Participant, { DemographicData } from '../models/Participant';
import { ParticipantsService } from '../services/participantsService';
import to from 'await-to-js';

// tslint:disable-next-line: max-classes-per-file
@Component
export default class ManageDemographicDataParticipant extends SideActionBase<any> {
    public submitted: boolean = false;
    public participant: Participant = null;

    public newLabel: string = '';
    public newValue: string = '';

    public dirty: boolean = false;

    private participantService: ParticipantsService = new ParticipantsService();

    public async created() {
        this.participant = this.options.participant;
    }

    public cancelCreate(): void {
        this.cancel();
    }

    public async submit() {
        this.showPending('Updating demographic data of participant');

        const [err] = await to(this.participantService.updateDemographicData(this.participant));
        if (err) {
            return this.showFailedResponse('Failed to update demographic data', err);
        }

        this.clearNotifications();

        this.finished();
    }

    public addDemographicData() {
        this.participant.demographicData.push(new DemographicData({ label: this.newLabel, value: this.newValue }));

        this.newLabel = this.newValue = '';

        this.dirty = true;
    }

    public deleteDemographicDataItem(index) {
        this.participant.demographicData.splice(index, 1);

        this.dirty = true;
    }
}
