

export default class InstrumentPricing {
    public instrument: PricingInst;
    public pricingPlans: any[];

    constructor(obj?: Partial<InstrumentPricing>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PricingInst {
    public name: string;
    public id: number;
}
