import { Project } from '@/models/Project';
import Partner from '@/modules/partners/models/Partner';
import { BillingInformation } from './BillingInformation';
import Instrument from './Instrument';

export default class Practitioner {
    public practitionerId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public company: string = '';
    public companyName: string = '';
    public partnerName: string = '';
    public partner: Partner = null;
    public partnerId: number = 0;
    public emailAddress: string = '';
    public preferredLanguage: string = null;
    public disabled: boolean = false;
    public deleted: boolean = false;
    public hasAccount: boolean = false;
    public instruments: Instrument[] = [];
    public projects: Project[] = [];

    public billingInformation: BillingInformation = null;

    constructor(data?: Partial<Practitioner>) {
        Object.assign(this, data);
    }
}
