import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';

@Component({
    components: {
        'share-organization': require('../../../pages/organizations/shareOrganization.vue').default,
    },
})
export default class OrganizationShareActionComponent extends SideActionBase<OrganizationShareOptions> {
    public organizationShared(org: any): void {
        this.finished(org);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class OrganizationShareOptions {
    public organizationId: number;

    constructor(actionParams: OrganizationShareOptions) {
        Object.assign(this, actionParams);
    }
}
