import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { OrganizationAddDemographicDataOptions } from '@/components/side-actions/participant-actions/participantAddDemographicDataAction';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import Participant from '@/modules/participants/models/Participant';
import { PagedResponse } from '@/models/PagedResponse';
import BasePage from '@/models/BasePage';
import { OrganizationsService } from '@/services/organizationsService';
import { BModal } from 'bootstrap-vue';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import to from 'await-to-js';

@Component
export default class OrganizationParticipantOverviewComponent extends BasePage {
    @Prop({ default: () => 0 }) public organizationId: number;
    @Prop({ type: Boolean }) public owner: boolean;
    public organizationParticipants: any[] = [];
    public organizationParticipantCount = 0;
    public isLoaded: boolean = false;
    public participantData: any = {
        participant: [],
        participantCount: 0,
        skip: 0,
        take: 25,
    };
    public participantColumns: GridColumnProps[] = [
        { field: 'selected', title: ' ', width: '50px', cell: Vue.component('grid-checkbox') },
        { field: 'participant', title: 'Participant', cell: this.renderRouterLink },
        { field: 'emailAddress', title: 'Email address' },
        { field: 'demographicData', title: 'Demographic data', cell: this.renderDemographicData, width: 170 },
        { field: 'lastCompleted', title: 'Last completed survey', width: 180, cell: this.renderDate },
        { field: 'projectCount', title: 'Project count', width: 120 },
    ];
    public filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };
    public updateKey: string = new Date().getTime().toString();
    public $refs!: {
        pendDeleteModal: BModal;
        softDeleteModal: areYouSureModalVue;
        hardDeleteModal: areYouSureModalVue;
    };

    public get canDelete() {
        if (this.isSuperAdmin) {
            return true;
        }

        return this.owner;
    }

    public get deleteScope() {
        return [
            [this.selectedParticipant],
            '',
            this.selectedParticipant.length ? ' participant' : `${this.selectedParticipant.length} participants`,
            false,
        ];
    }

    public get participantCount(): number {
        return this.participantData.participantCount;
    }

    public get participantsFiltered(): any[] {
        return filterBy(this.organizationParticipants, this.filter);
    }

    public get selectedParticipant(): any[] {
        return this.organizationParticipants.filter((participant: any) => {
            return participant.selected;
        });
    }

    public get enabledParticipants(): any[] {
        return this.selectedParticipant.filter((participant: any) => {
            return !participant.disabled;
        });
    }

    public get canDisableParticipants(): boolean {
        return this.enabledParticipants.length !== 0 && this.enabledParticipants.length >= 1;
    }

    public get selectedEnabledParticipants(): any[] {
        return this.selectedParticipant.filter((participant: any) => {
            return !participant.disabled;
        });
    }

    public get participantsSelected(): boolean {
        return this.selectedParticipant.length !== 0;
    }

    private participantService: ParticipantsService = new ParticipantsService();
    private organizationsService: OrganizationsService = new OrganizationsService();
    private filterTimer: number = null;

    public async created() {
        await this.getParticipants();
    }

    public reloadParticipants(event: GridPageChangeEvent): void {
        this.participantData.skip = event.page.skip;
        this.participantData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getParticipants();
    }

    public async reload() {
        this.participantData.skip = 0;
        await this.getParticipants();
    }

    public deselectAllParticipants(): void {
        this.organizationParticipants.forEach((participant: any) => {
            participant.selected = false;
        });
    }

    public selectAllParticipants(): void {
        this.organizationParticipants.forEach((participant: any) => {
            participant.selected = true;
        });
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.participantData.skip = 0;
            await this.getParticipants();
        }, 400);
    }

    public async addDemographicDataSideAction() {
        this.isLoaded = false;
        this.showPending('Loading all participants..');
        const participants = await this.getAllParticipants();

        this.$sideActions.push(
            'organization-add-meta-data-action',
            new OrganizationAddDemographicDataOptions({
                organizationId: this.organizationId,
                participants,
            }),
            async () => {
                await this.reload();
            },
        );

        this.clearNotifications();
        this.isLoaded = true;
    }

    public async exportDemographicData() {
        this.showPending('Exporting all participants demographic data..');
        await this.organizationsService.exportDemographicData(this.organizationId);
        this.clearNotifications();
    }

    public disableParticipants(): void {
        if (this.selectedEnabledParticipants.length === 0) {
            return;
        }

        const disableParticipantPromises = [] as Array<Promise<void>>;
        for (const participant of this.selectedEnabledParticipants) {
            disableParticipantPromises.push(this.participantService.disableParticipant(participant.id));
        }

        Promise.all(disableParticipantPromises)
            .then(async () => {
                await this.getParticipants();
                this.showSuccess('The participant(s) is successfully disabled');
            })
            .catch(() => {
                this.showError('Failed to disable one of the participants');
            });
    }

    public softDelete() {
        this.$refs.softDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.softDeleteModal.show();
    }

    public hardDelete() {
        this.$refs.hardDeleteModal.setScope.apply(this, this.deleteScope);
        this.$refs.hardDeleteModal.show();
    }

    public async handleDelete(keepSurveyData: boolean): Promise<boolean> {
        this.$refs.pendDeleteModal.hide();
        this.$refs.softDeleteModal.hide();
        this.$refs.hardDeleteModal.hide();

        this.showPending('Deleting participant...');
        const participantService = new ParticipantsService();
        for (let i = 0; i < this.selectedParticipant.length; i++) {
            const participant = this.selectedParticipant[i];
            const [err] = await to(participantService.deleteParticipant(participant.participantId, keepSurveyData));
            if (err) {
                this.clearAndShowError(`Failed to delete participant: ${[participant.firstName, participant.lastName].join(' ')}`);
                return false;
            }
        }
        this.clearAndShowSuccess('Participants successfully deleted');
        this.getParticipants();
        return true;
    }

    public renderDate(item: any, _, row: any): any {
        let date = null;
        if (row.dataItem.lastCompletedSurvey) {
            date = row.dataItem.lastCompletedSurvey.completionDate;
        }

        return item(Vue.component('grid-date-display'), { props: { date } });
    }

    private renderRouterLink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: row.dataItem.participant,
                url: this.$router.resolve({ name: 'participant-details', params: { participantId: row.dataItem.participantId.toString() } }).href,
            },
        });
    }

    private renderDemographicData(h, _, row): any {
        const demographicData = row.dataItem.demographicData.map((x) => {
            return `${x.label} | ${x.value}`;
        });

        const props = {
            icon: '',
            detailIcon: '',
            textTemplate: '{{count}} element(s)',
            elements: demographicData,
        };

        return h(Vue.component('grid-string-dropdownlist'), { props });
    }

    private async getAllParticipants() {
        const participants = await this.participantService.getParticipants({
            organizationId: this.organizationId,
            skip: 0,
            take: this.organizationParticipantCount > 0 ? this.organizationParticipantCount : 999,
        });

        return participants.items.map((val) => {
            return {
                selected: false,
                participantId: val.participantId,
                firstName: val.firstName,
                lastName: val.lastName,
                participant: val.firstName + ' ' + val.lastName,
                emailAddress: val.emailAddress,
                demographicData: val.demographicData,
            };
        });
    }

    private async getParticipants() {
        this.isLoaded = false;
        await this.participantService
            .getParticipants({
                organizationId: this.organizationId,
                skip: this.participantData.skip,
                take: this.participantData.take,
                $count: true,
                includeDemographicData: true,
                includeProjectCount: true,
                search: this.participantData.search
            })
            .then((participants: PagedResponse<Participant>) => {
                this.organizationParticipantCount = participants.count;
                this.organizationParticipants = participants.items.map((val) => {
                    return {
                        selected: false,
                        participantId: val.participantId,
                        firstName: val.firstName,
                        lastName: val.lastName,
                        participant: val.firstName + ' ' + val.lastName,
                        emailAddress: val.emailAddress,
                        groups: val.groups,
                        lastCompletedSurvey: val.lastCompletedSurvey,
                        projectCount: val.projectCount,
                        demographicData: val.demographicData,
                    };
                });

                this.participantData.participantCount = participants.count;

                this.isLoaded = true;
            });
    }
}
