import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import to from 'await-to-js';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import BasePage from '@/models/BasePage';
import { PagedResponse } from '@/models/PagedResponse';
import InvoiceFilter from '../models/InvoiceFilter';
import { InvoiceType } from '../models/enums/InvoiceType';
import Invoice from '../models/Invoice';
import { InvoiceStatus } from '../models/enums/InvoiceStatus';
import { InvoiceService } from '../services/invoiceService';
import he from 'he';

@Component({})
export default class PartnerInvoicesView extends BasePage {
    public invoices = new PagedResponse<Invoice>();
    public invoiceService: InvoiceService = new InvoiceService();
    public invoiceFilter = new InvoiceFilter({
        receiver: InvoiceType.Partner,
    });

    public columns: GridColumnProps[] = [{ field: 'name', title: 'Name', cell: this.renderRouterlink }];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['name'],
    });

    public async mounted() {
        if (this.isSuperAdmin) {
            this.columns.push({ field: 'invoiceStatus', title: 'Invoice status', cell: this.renderInvoiceStatus, width: '150px' });
            this.columns.push({ field: 'actions', title: 'Actions', cell: this.renderActions, width: '100px' });
        }

        await this.getInvoices();

        this.isLoaded = true;
    }

    public renderRouterlink(item: any, _, row): any {
        return item(Vue.component('grid-router-link'), {
            props: {
                title: he.decode(row.dataItem.name),
                url: this.$router.resolve({
                    name: 'invoice-partner-details',
                    params: { invoiceType: 'partner', invoiceId: row.dataItem.id.toString() },
                }).href,
            },
        });
    }

    private renderInvoiceStatus(h, _, row) {
        const invoice = new Invoice(row.dataItem);
        const props = {
            status: invoice.invoiceStatus,
        };
        return h(Vue.component('grid-invoice-status'), { props });
    }

    private renderActions(h, _, row) {
        const invoice = new Invoice(row.dataItem);

        const actions = [];

        if (invoice.invoiceStatus === InvoiceStatus.Open) {
            actions.push({
                title: 'To verify',
                function: (item) => {
                    this.changeInvoiceStatus(item, InvoiceStatus.ToVerify);
                },
            });
        }

        if (invoice.invoiceStatus === InvoiceStatus.ToVerify) {
            actions.push({
                title: 'Open',
                function: (item) => {
                    this.changeInvoiceStatus(item, InvoiceStatus.Open);
                },
            });
            actions.push({
                title: 'Sent',
                function: (item) => {
                    this.changeInvoiceStatus(item, InvoiceStatus.Sent);
                },
            });
            actions.push({
                title: 'Discard',
                function: (item) => {
                    this.changeInvoiceStatus(item, InvoiceStatus.Discarded);
                },
            });
        }

        const props = { actions, item: invoice };
        return h(Vue.component('grid-actions'), { props });
    }

    private async changeInvoiceStatus(item: any, status: InvoiceStatus) {
        const invoice = new Invoice(item);
        this.showPending('Updating invoice...');
        invoice.invoiceStatus = status;
        const [err] = await to(this.invoiceService.updateInvoice(invoice));
        if (err) {
            return this.clearAndShowError('Updating invoice failed!', err);
        }

        this.clearAndShowSuccess('Updating invoice success!');

        const index = this.invoices.items.findIndex((x) => x.id === item.id);
        if (index > -1) {
            this.invoices.items[index].invoiceStatus = status;
        }
    }

    private async getInvoices() {
        const [err, response] = await to(this.invoiceService.getInvoices(this.invoiceFilter));
        if (err || !response) {
            return this.clearAndShowError(
                `The invoices couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem still exists, contact us with the support button.`,
                err,
            );
        }
        this.invoices = response.data;
    }
}
