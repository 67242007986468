import { AxiosResponse } from 'axios';
import { PagedResponse } from '../models/PagedResponse';
import { BaseService } from './baseService';
import Vue from 'vue';
import to from 'await-to-js';
import ScheduleEmail from '@/models/ScheduleEmail';
import { EmailOverviewItem } from '@/modules/projects/components/email-overview';

export class EmailScheduleService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getEmails(projectId: number): Promise<PagedResponse<EmailOverviewItem>> {
        const [err, response] = await to(this.get<PagedResponse<EmailOverviewItem>>(`${this.endpoint}/projects/${projectId}/email-schedule`));
        if (err) {
            return Promise.reject(err);
        }

        return response.data;
    }

    public async scheduleProjectEmail(emailData: ScheduleEmail): Promise<void> {
        return new Promise((resolve, reject) => {
            this.post(`${this.endpoint}/projects/${emailData.projectId}/email-schedule`, {
                participantId: emailData.participantId,
                scheduledDate: emailData.scheduledAt,
                ccAddress: emailData.cc,
                bccAddress: emailData.bcc,
                template: emailData.template,
            })
                .then((response: AxiosResponse) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }

    public async removeMailFromQueue(projectId: number, emailId: number) {
        const [err] = await to(this.delete(`${this.endpoint}/projects/${projectId}/email-schedule/${emailId}`));
        if (err) {
            return false;
        }

        return true;
    }
}
