import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { loginHelper } from '@/main';
import Organization from '@/modules/organizations/models/Organization';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import { OrganizationsService } from '@/services/organizationsService';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { ProjectsService } from '@/services/projectsService';
import { CreateResult, CreationStatus } from '@/models/CreateResult';
import { Project } from '@/models/Project';

@Component
export default class CopyParticipantsToOrgAction extends SideActionBase<any> {
    public projectId: number = 0;
    public orgId: number = 0;
    public loading: boolean = true;
    public organizations: Organization[] = [];
    public organization: Organization = null;
    public practitionerService = new PractitionerService();
    public organizationService = new OrganizationsService();
    public partnerService = new PartnerService();
    public projectService = new ProjectsService();

    public async mounted() {
        this.projectId = this.options.projectId;
        await this.getOrganizations();
    }

    public async copyParticipantsToOrg() {
        this.loading = true;
        const newProject: CreateResult<Project> = await this.projectService.copyParticipantsToOrg(this.projectId, this.organization.organizationId, this.options.participants.map(x => x.participantId));
        if (newProject.creationStatus !== CreationStatus.created) {
            return this.showFailedResponse('Failed to create project', newProject.reason);
        }

        this.finished(newProject.entity);
        this.showSuccess('Participant(s) copied to new project');
    }

    private async getOrganizations(): Promise<void> {
        this.organizations = [];
        const user = loginHelper.getUser();
        if (this.isSuperAdmin) {
            await this.organizationService.getOrganizations({ take: 9999, skip: 0}).then((organizations) => {
                this.organizations.push(...organizations.items);
            });
        }
        else if (this.isPractitioner && this.isPowerUser) {
            await this.practitionerService.getOrganizations(user.practitionerId).then((organizations) => {
                this.organizations.push(...organizations.items);
            });
        }

        this.organizations = this.organizations.filter((x) => x.organizationId !== this.options.currentOrganizationId);

        this.loading = false;
    }
}