import { Discount } from '@/modules/partners/models/Discount';
import { Language } from './Language';

export class CreatePartnerModel {
    public companyName: string = '';
    public emailAddress: string = '';
    public instruments: number[] = [];

    public preferredLanguage: Language = null;
    public paperlessContactId: number = null;
    public paperlessOrganizationId: number = null;
    public discount: number = null;
    public discounts?: Discount[] = [];
}
