import VueRouter, { Route } from 'vue-router';
import { Menu } from '@/models/MetaInformation';
import Vue from 'vue';
import { loginHelper } from '@/main';
import billingRoutes from './router-billing';
import translationRoutes from './router-translations';

Vue.use(VueRouter);

export const routeData = [
    { name: 'login', path: '/login', component: () => import(/* webpackChunkName: "login" */ '@/modules/profile/views/login.vue') },
    {
        name: 'Projects',
        path: '/projects',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'project-overview' },
        children: [
            {
                name: 'project-overview',
                path: '',
                component: require('@/modules/projects/views/overview.vue').default,
                props: { default: true, defaultFilter: { activeOnly: null } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'layout', label: 'Overview' }) },
            },
            {
                name: 'uncheckProjects',
                path: 'unchecked',
                component: require('@/modules/projects/views/unchecked.vue').default,
                props: { default: true, defaultFilter: { uncheckedOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'layout', label: 'Unchecked', showCounter: true, roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'dueProjects',
                path: 'due',
                component: require('@/modules/projects/views/overview.vue').default,
                props: { default: true, defaultFilter: { activeOnly: false } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'layout', label: 'Closed' }) },
            },
            {
                name: 'expiredProjects',
                path: 'expired',
                component: require('@/modules/projects/views/expired.vue').default,
                props: { default: true, defaultFilter: { expiredOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'layout', label: 'Expired', showCounter: true, roles: ['SuperAdmin', 'Practitioner'] }) },
            },
            {
                name: 'project-base',
                path: '/projects/:projectId/',
                component: require('@/modules/projects/views/project-base.vue').default,
                children: [
                    {
                        path: '/projects/:projectId/add-survey',
                        component: require('@/pages/surveys/addWrapper.vue').default,
                        children: [
                            {
                                meta: { showWizard: true },
                                name: 'addSurvey',
                                path: '',
                                component: require('@/pages/surveys/addWizard.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: false, showWizard: false },
                                name: 'addNewParticipant',
                                path: 'new',
                                component: require('@/pages/surveys/addNewParticipant.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: false, showWizard: false },
                                name: 'addParticipantsFromGroup',
                                path: 'group',
                                component: require('@/pages/surveys/addParticipantsFromGroup.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: false, showWizard: false },
                                name: 'addExistingParticipant',
                                path: 'existing',
                                component: require('@/pages/surveys/addExistingParticipant.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: false, showWizard: false },
                                name: 'importParticipant',
                                path: 'import',
                                component: require('@/pages/surveys/importParticipant.vue').default,
                            },
                           
                            {
                                meta: { redirectOnLoad: true, showWizard: true },
                                name: 'setupEmail',
                                path: 'setup-email',
                                component: require('@/pages/surveys/setupEmail.vue').default,
                            },
                        ],
                    },
                    {
                        meta: { redirectOnLoad: false, showWizard: false },
                        name: 'generate-unique-link',
                        path: 'generate-unique-link',
                        component: require('@/pages/surveys/generate-unique-link.vue').default,
                    },
                    { name: 'project-details', path: ':tab?', component: require('@/modules/projects/views/details.vue').default },

                ],
                meta: { menu: new Menu({ showInMenu: false }) },
            },
        ],
        meta: {
            menu: new Menu({ showInMenu: true, icon: 'layout', label: 'Projects', variant: 'success-clean' }),
        },
    },
    {
        name: 'Practitioners',
        path: '/practitioners',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'practitioner-overview' },
        children: [
            {
                name: 'practitioner-overview',
                path: '',
                props: { defaultFilter: { directInvoicedOnly: null } },
                component: require('@/modules/practitioners/views/overview.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Overview', roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'direct-invoiced-practitioners',
                path: 'direct',
                component: require('@/modules/practitioners/views/overview.vue').default,
                props: { defaultFilter: { directInvoicedOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Direct invoiced', roles: ['SuperAdmin'] }) },
            },
            {
                name: 'practitioners-performance',
                path: 'performance',
                component: require('@/modules/practitioners/views/overview.vue').default,
                props: { defaultFilter: { performanceView: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Performance', roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'missing-info-practitioners',
                path: 'missing',
                component: require('@/modules/practitioners/views/overview.vue').default,
                props: { defaultFilter: { missingPaperlessOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Missing information', roles: ['SuperAdmin'] }) },
            },
            {
                name: 'practitioner-details',
                path: '/practitioners/:practitionerId/',
                component: require('@/modules/practitioners/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
            {
                name: 'practitioner-base',
                path: '/practitioners-base',
                component: require('@/modules/projects/views/project-base.vue').default,
                children: [
                    {
                        meta: { showWizard: true },
                        path: '/practitioners',
                        component: require('@/modules/practitioners/components/addPractitionerWrapper.vue').default,
                        children: [
                            {
                                meta: { redirectOnLoad: false, showWizard: true },
                                name: 'createPractitioner',
                                path: 'create',
                                component: require('@/modules/practitioners/components/create-practitioner.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: true, showWizard: true },
                                name: 'addPractitionerInstruments',
                                path: 'practitionerInstruments',
                                component: require('@/modules/practitioners/components/instrument-selection.vue').default,
                            },
                            {
                                meta: { redirectOnLoad: true, showWizard: true },
                                name: 'addPractitionerPricingPlan',
                                path: 'practitionerPricingPlans',
                                component: require('@/modules/practitioners/components/priceplan-selection.vue').default,
                            },
                        ],
                    },
                ],
                meta: { menu: new Menu({ showInMenu: false }) },
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'users',
                label: 'Practitioners',
                roles: ['SuperAdmin', 'Partner'],
                variant: 'primary-clean',
            }),
        },
    },
    {
        name: 'Partners',
        path: '/partners',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'partners-overview' },
        children: [
            {
                name: 'partners-overview',
                path: '',
                component: require('@/modules/partners/views/overview.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Overview' }) },
            },
            {
                name: 'partner-details',
                path: '/partners/:partnerId/',
                component: require('@/modules/partners/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
        ],
        meta: {
            menu: new Menu({ showInMenu: true, icon: 'users', label: 'Partners', roles: ['SuperAdmin'], variant: 'warning-clean' }),
        },
    },
    {
        name: 'Organizations',
        path: '/organizations',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'organizations-overview' },
        children: [
            {
                name: 'organizations-overview',
                path: '',
                props: { default: true, defaultFilter: { showAll: true } },
                component: require('@/modules/organizations/views/overview.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'home', label: 'Overview' }) },
            },
            {
                name: 'active-subscription-organizations',
                path: 'active-subscriptions',
                component: require('@/modules/organizations/views/overview.vue').default,
                props: { default: true, defaultFilter: { activeSubscriptionOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'home', label: 'Active subscriptions', roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'expired-subscription-organizations',
                path: 'expired-subscriptions',
                component: require('@/modules/organizations/views/overview.vue').default,
                props: { default: true, defaultFilter: { expiredSubscriptionOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'home', label: 'Expired subscriptions', roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'over-subscription-seats-organizations',
                path: 'over-seats',
                component: require('@/modules/organizations/views/overview.vue').default,
                props: { default: true, defaultFilter: { overSubscriptionSeatsOnly: true } },
                meta: { menu: new Menu({ showInMenu: true, icon: 'home', label: 'Over subscription seats', roles: ['SuperAdmin', 'Partner'] }) },
            },
            {
                name: 'organization-details',
                path: ':organizationId/',
                component: require('@/modules/organizations/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
            {
                name: 'group-details',
                path: ':organizationId/groups/:groupId',
                component: require('@/pages/organizations/groups/details.vue').default,
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'home',
                label: 'Organizations',
                roles: ['SuperAdmin', 'Partner', 'Practitioner'],
                variant: 'dark-clean',
            }),
        },
    },
    {
        name: 'Knowledge models',
        path: '/knowledge-models',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'knowledge-models-overview' },
        children: [
            {
                name: 'knowledge-models-overview',
                path: '',
                component: require('@/modules/knowledge-models/views/overview.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'book', label: 'Knowledge models' }) },
            },
            {
                name: 'instruments-overview',
                path: 'instruments',
                component: require('@/modules/instruments/views/overview.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'pencil', label: 'Instruments' }) },
            },
            {
                name: 'instrument-details',
                path: 'instruments/:instrumentId/',
                component: require('@/modules/instruments/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
            {
                name: 'knowledge-model-details',
                path: 'knowledge-model/:knowledgeModelId/',
                component: require('@/modules/knowledge-models/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
        ],
        meta: {
            menu: new Menu({ showInMenu: true, icon: 'book', label: 'Instruments', roles: ['SuperAdmin'], variant: 'danger-clean' }),
        },
    },
    {
        name: 'Participants',
        path: '/participants',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'participants-overview' },
        children: [
            {
                name: 'participants-overview',
                path: '',
                component: require('@/modules/participants/views/overview.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'users',
                        label: 'Overview',
                        roles: ['SuperAdmin', 'Partner', 'Practitioner'],
                    }),
                },
            },
            {
                name: 'participant-details',
                path: '/participants/:participantId',
                component: require('@/modules/participants/views/details.vue').default,
                meta: { menu: new Menu({ showInMenu: false }) },
            },
        ],
        meta: {
            menu: new Menu({ showInMenu: true, icon: 'users', label: 'Participants', variant: 'success-clean' }),
        },
    },
    {
        name: 'documentation',
        path: '/documentation',
        component: require('@/pages/routeWrapper.vue').default,
        children: [
            {
                name: 'how-to-use',
                path: '/how-to-use',
                beforeEnter: (to, from) => {
                    window.open('https://docs.google.com/document/d/1u92tAZI1qF1flioszlsuGdvKkgd9Rmbxnpfy9umQ6zY/edit#heading=h.85j4umlhx40e')
                },
                meta: { menu: new Menu({ showInMenu: true, icon: 'target', label: 'How to use' }) },
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'book',
                label: 'Documentation',
                variant: 'primary-clean',
            }),
        },
    },
    ...billingRoutes,
    ...translationRoutes,
    {
        name: 'Settings',
        path: '/settings',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'accounts' },
        children: [
            {
                name: 'accounts',
                path: '/settings/accounts',
                component: require('@/modules/settings/views/accounts.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'users', label: 'Accounts', roles: ['SuperAdmin'] }) },
            },

            {
                name: 'emailSettings',
                path: '/settings/email-templates',
                component: require('@/modules/settings/views/emailTemplates.vue').default,
                meta: { menu: new Menu({ showInMenu: true, icon: 'mail', label: 'Email settings', roles: ['SuperAdmin'] }) },
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'settings',
                label: 'Settings',
                roles: ['SuperAdmin'],
                variant: 'primary-clean',
            }),
        },
    },
    {
        name: 'profile',
        path: '/my-profile',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'my-profile' },
        children: [
            {
                name: 'my-profile',
                path: '/',
                component: require('@/modules/profile/views/profile.vue').default,
                meta: {
                    menu: new Menu({ showInMenu: false, icon: 'user', label: 'My profile' }),
                },
            },
        ],
        meta: {
            menu: new Menu({ showInMenu: false, icon: 'user', label: 'My profile' }),
        },
    },
    {
        name: 'breach-of-confidence',
        path: '/confirm-access/:entity?',
        component: require('@/modules/confidence-breach/views/access-check.vue').default,
        meta: {
            menu: new Menu({ showInMenu: false }),
        },
    },
    { name: 'home', path: '/', redirect: { name: 'project-overview' } },
    { name: 'not-found', path: '/', redirect: { name: 'project-overview' } },
];

const router = new VueRouter({ mode: 'history', routes: routeData });

const ifAuthenticated = (to: Route, _: Route, next: any) => {
    const anonymousPaths = ['/login', '/error'];

    const loggedIn = loginHelper.isLoggedIn();
    const matchedRoute = to.matched.length > 0 ? to.matched[0].path : to.path;

    if ((!loggedIn && anonymousPaths.indexOf(matchedRoute) > -1) || (loggedIn && anonymousPaths.indexOf(matchedRoute) < 0)) {
        next();
        return;
    } else if (loggedIn && anonymousPaths.indexOf(matchedRoute) > -1) {
        next('/');
    }

    next(`/login?redirectUrl=${to.path}`);
};

router.beforeEach(ifAuthenticated);

export default router;
