import { SurveyDetails } from '@/models/Survey';
import Participant from '@/modules/participants/models/Participant';

export enum EntityCreateState {
    Undefined = 'undefined',
    Processing = 'processing',
    Created = 'created',
    Failed = 'failed',
    Duplicate = 'duplicate',
}

export enum ParticipantState {
    Undefined = 'undefined',
    Created = 'created',
    Added = 'added',
    Reused = 'reused',
}

export enum RejectResponses {
    Unknown,
    Sent,
    Processing,
    Hardbounce,
    Softbounce,
}

export enum SurveyType {
    Unknown = 0,
    Individual = 'individual',
    Team = 'team',
    IndividualAndTeam = 'individualAndTeam',
    Skipped = 4,
}

// tslint:disable: max-classes-per-file
export class SurveyCreate {
    public id?: number;
    public participant: Participant = new Participant();
    public participantId: number = 0;

    public state: ParticipantState = ParticipantState.Undefined;
    public surveyType: SurveyType;

    public sendEmail: boolean = false;

    public basedOn: SurveyDetails = null; // Parent survey
    public completionDate: Date = null;

    public creationStatus: EntityCreateState = EntityCreateState.Undefined;
    public errorReason: string;

    public surveyLink: string;
    public reportLink: string;

    public organizationId: number;

    constructor(surveyData?: Partial<SurveyCreate>) {
        if (surveyData) {
            Object.assign(this, surveyData);

            if (surveyData.participant) {
                this.participant = new Participant(surveyData.participant);
            }
        }
    }
}
