import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SequenceManagementBase } from '../side-actions/sequence-actions/sequenceAction';

@Component
export default class GridIntervalDisplayComponent extends Vue {
    @Prop() public field: string;
    @Prop() public dataItem: object;

    public interval: number = 0;
    public timeUnit: any = { text: '', value: 0 };

    public mounted() {
        if (this.dataItem[this.field]) {
            this.interval = this.dataItem[this.field];
            this.timeUnit = SequenceManagementBase.getTimeOption(this.interval);

            this.interval = this.interval / this.timeUnit.value;
        } else {
            this.interval = null;
        }
    }
}
