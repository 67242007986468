import SideActionBase from "@/plugins/sideActions/sideActionBase";
import PillarThresholdUpdateActionParameters from "./pillarThresholdUpdateActionParameters";
import { Component } from 'vue-property-decorator';
import PillarThreshold, { PillarThresholdType } from "@/models/PillarThreshold";
import { instrumentsService } from "@/main";

@Component
export default class PillarThresholdUpdateActionComponent extends SideActionBase<PillarThresholdUpdateActionParameters> {
    public async updatePillarThreshold(ev: any) {
        ev.preventDefault();
        switch (this.options.type) {
            case PillarThresholdType.quartile:
                this.updateQuartilePillarThreshold();
                break;
            case PillarThresholdType.benchmark:
                this.updateBenchmarkPillarThreshold();
                break;
            case PillarThresholdType.barChartRatio:
                this.createBarChartRatioPillarThreshold();
                break;
            default:
                break;
        }
        
    }

    private async updateQuartilePillarThreshold() {
        const totalValue = parseFloat(this.options.thresholds.reduce((x, y) => x += y.value, 0).toPrecision(3));
        if (totalValue === 100.00) {
            await this.updateInstrumentPillarThresholds(this.options.instrumentId, this.options.thresholds);
        } else {
            this.showError("Total value of all quartiles must be 100!", 2500)
        }
    }

    private async updateBenchmarkPillarThreshold() {
        const negative = this.options.thresholds.slice(0, 1).reduce((x, y) => x -= +y.value, this.options.thresholds.at(1).value)
        const positive = this.options.thresholds.slice(1, 3).reduce((x, y) => x += +y.value, 0)
        if (negative < 0 || positive > 100) {
            this.showError("The positive or negative deviation(s) are too high!", 2500)
        } else {
            await this.updateInstrumentPillarThresholds(this.options.instrumentId, this.options.thresholds);
        }
    }

    private async createBarChartRatioPillarThreshold() {
        if (this.options.thresholds.at(0).value < this.options.thresholds.at(1).value) {
            await this.updateInstrumentPillarThresholds(this.options.instrumentId, this.options.thresholds);
        } else {
            this.showError("The maximal value must be higher than the minimal value!", 2500)
        }
    }

    private async updateInstrumentPillarThresholds(instrumentId: number, pillarThresholds: PillarThreshold[]) {
        this.showPending(`Updating Pillar Thresholds... 0/${pillarThresholds.length}`);
        let thresholdsAdded: number = 0;

        pillarThresholds.forEach(async (threshold: PillarThreshold) => {
            await instrumentsService.updateInstrumentPillarThreshold(instrumentId, threshold)
                .then((threshold) => {
                    thresholdsAdded ++;
                    if (thresholdsAdded == pillarThresholds.length) {
                        this.clearAndShowSuccess(`All ${thresholdsAdded} Pillar Thresholds have been updated!`);
                        this.finished();
                    }
                    else if(threshold) {
                        this.clearNotifications();
                        this.showPending(`Updating Pillar Thresholds... ${thresholdsAdded}/${pillarThresholds.length}`)
                    }
                })
                .catch(() => {
                    this.clearAndShowError(
                        `An error occurred updating the thresholds, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`
                    );
                });
        })
    }
}