// tslint:disable-next-line: max-classes-per-file
export class InstrumentUpdate {
    public name?: string;
    public active?: boolean;
    public knowledgeModelId?: number;
    public connectedInstruments?: ConnectedInstrument[] = [];
    public isAnonymous: boolean;
    public demographicQuestionsAllowed: boolean;
    public groupQuestionsBySection: boolean;
    public dataValidTtl?: number;
    public instrumentType: string;
    public contextOption: string;
    public contextLocation: string;
    public surveyDuration?: number;
    public surveyPriority?: number;
}

// tslint:disable-next-line: max-classes-per-file
export class ConnectedInstrument {
    public name: string;
    public instrumentId: number;
    public mandatory: boolean = false;

    constructor(obj?: Partial<ConnectedInstrument>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export enum InstrumentType {
    Personal = 'personal',
    Group = 'group',
}

export class InstrumentReport {
    public name: string;
    public alias: string;
    public reportType: InstrumentType;
    public fileTypes: string;
    public fileTypeValue: number;
    public reportUrls: ReportUrlInfo[] = [];
    public languages?: string[] = [];
}

export class ReportUrlInfo {
    public fileType: string;
    public url: string;
}
