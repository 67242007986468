import SideActionBase from '@/plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Translation } from '@/models/Translations';
import { languageService } from '@/main';
import { Language } from '@/models/Language';
import KnowledgeModel from '../models/KnowledgeModel';
import Instrument from '@/modules/instruments/Models/Instrument';

@Component({
    validations: {
        translation: {
            key: { required },
            value: { required },
            language: { required },
        },
    },
} as any)
export default class AddKnowledgeModelTranslationAction extends SideActionBase<CreateKnowledgeModelTranslationSideActionOptions> {
    public translation: Translation = new Translation({ selectedSection: 0, selectedSubSection: 0 });
    public languages: Language[] = [];
    public selectedInstrument: Instrument = null;

    public submitted = false;
    public loading = false;

    public get subSections() {
        if (typeof this.translation.selectedSection === 'undefined') {
            return [];
        }

        return this.sections.find((x) => x.id === this.translation.selectedSection).subSections;
    }

    public get instruments() {
        const instruments = this.$store.getters['instrumentsStore/instruments'];

        return instruments.filter((x) => x.knowledgeModelId === this.options.knowledgeModel.knowledgeModelId);
    }

    public sections: any[] = [
        { id: 0, name: 'Generic', alias: 'generic', subSections: [{ name: 'Generic', alias: 'generic', id: 0 }] },
        {
            id: 1,
            name: 'Survey',
            alias: 'survey',
            subSections: [
                { name: 'Questions', alias: 'surveyQuestions', id: 1 },
                { name: 'Answers', alias: 'surveyAnswers', id: 2 },
            ],
        },
        {
            id: 2,
            name: 'Reports',
            alias: 'reports',
            subSections: [
                { name: 'Reports (web)', alias: 'reportsWeb', id: 3 },
                { name: 'Reports (other)', alias: 'reportsPowerpoint', id: 4 },
            ],
        },
    ];

    public async mounted() {
        this.languages = await languageService.getLanguages();
        this.translation.language = this.options.languageCode
            ? this.languages.find((x) => x.languageCode === this.options.languageCode)
            : this.languages.find((x) => x.languageCode.toLowerCase() === 'en');

        this.translation.selectedSection = this.options.section || 0;
        this.translation.selectedSubSection = this.options.subSection || 0;

        this.translation.reportAlias = this.options.reportAlias || null;
        this.translation.instrumentId = this.options.instrument ? this.options.instrument.instrumentId : this.options.instrumentId || null;
        this.selectedInstrument = this.instruments.find((x) => x.instrumentId === this.translation.instrumentId);

        this.translation.key = this.options.key;

        if (this.isPractitioner) {
            this.cancel();
        }

        this.isLoaded = true;
    }

    public async createTranslation(): Promise<any> {
        this.submitted = true;

        if ((this as any).$v.$invalid) {
            this.showError(`The translation didn't pass the validation.`);
            return;
        }

        if (this.selectedInstrument) {
            this.translation.instrumentId = this.selectedInstrument.instrumentId;
        }

        this.loading = true;
        const [err] = await to(languageService.addKnowledgeModelTranslation(this.translation, this.options.knowledgeModel));
        if (err) {
            this.loading = false;
            return this.clearAndShowError(`The translation couldn't be created due to an error, please try again or contact an admin.`, err);
        }

        this.clearAndShowSuccess('Translation created successfully');
        this.finished(this.translation);
    }

    public close(evt: any): void {
        evt.preventDefault();
        this.clearNotifications();
        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
export class CreateKnowledgeModelTranslationSideActionOptions {
    public knowledgeModel: KnowledgeModel;
    public instrument?: Instrument;
    public instrumentId?: number;
    public languageCode?: string;

    public section?: number;
    public subSection?: number;

    public reportAlias?: string;

    public key?: string;

    constructor(data: CreateKnowledgeModelTranslationSideActionOptions) {
        Object.assign(this, data);
    }
}
