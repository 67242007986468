import { InvoiceStatus } from '@/modules/invoices/models/enums/InvoiceStatus';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridInvoiceStatusComponent extends Vue {
    @Prop() public status: InvoiceStatus;

    public getTextColor(): string {
        if (this.status === InvoiceStatus.Unknown) {
            return 'text-gray-500';
        } else if (this.status === InvoiceStatus.Open) {
            return 'text-success';
        } else if (this.status === InvoiceStatus.ToVerify) {
            return 'text-warning';
        } else if (this.status === InvoiceStatus.Sent) {
            return 'text-info';
        } else if (this.status === InvoiceStatus.Discarded) {
            return 'text-danger';
        }

        return 'text-gray-500';
    }
}
