import { Component } from 'vue-property-decorator';
import { $router, contextOptionService, languageService } from '../../../main';
import BasePage from '../../../models/BasePage';
import { instrumentsService } from '@/main';
import { ContextOptions } from '../../../models/ContextOptions';
import { Language } from '@/models/Language';
import { to } from 'await-to-js';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import { InstrumentReport } from '@/models/Instrument';
import Instrument from '../Models/Instrument';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';

@Component({
    components: {
        'pricing-overview': require('./pricingOverview.vue').default,
        'question-model-overview': require('../components/question-model.vue').default,
        'pillar-thresholds-overview': require('../components/pillar-thresholds.vue').default,
    },
})
export default class InstrumentDetailsComponent extends BasePage {
    get tabIndex(): number {
        return this.$store.getters['instrumentDetailsStore/currentTab'];
    }

    set tabIndex(newTabIndex: number) {
        this.$store.commit('instrumentDetailsStore/TAB_CHANGED', newTabIndex);
    }

    get instruments() {
        return this.$store.getters['instrumentsStore/instruments'];
    }

    get currentKnowledgeModel() {
        return this.knowledgeModels.find((x) => x.knowledgeModelId === this.instrumentData.knowledgeModelId);
    }

    get currentKnowledgeModelInstruments() {
        return this.instruments
            .filter((x) => x.knowledgeModelId === this.instrumentData.knowledgeModelId)
            .filter((x) => x.instrumentId !== this.instrumentData.instrumentId);
    }

    public connectedInstrumentsOptions: any[] = [];

    public instrumentTypeOptions: any[] = [
        { value: 'personal', text: 'Personal instrument' },
        { value: 'group', text: 'Group instrument' },
    ];

    public activeTTL: boolean = false;
    public isLoaded: boolean = false;

    public instrumentData: Instrument = new Instrument();
    public contextMandatoryOptions: any[] = [
        { value: 'noContext', text: 'No context' },
        { value: 'optional', text: 'Context optional' },
        { value: 'mandatory', text: 'Context mandatory' },
        { value: 'surveyBound', text: 'Context self defined' },

    ];
    public selectedMandatoryContext: string = 'noContext';
    public contextOptions: ContextOptions[] = [];
    public selectedOptions: string[] = ['none'];
    public knowledgeModels: KnowledgeModel[] = [];

    public editMode: boolean = false;
    public languageEditMode: boolean = false;
    public settingsEditMode: boolean = false;
    public connectedInstrumentsEditMode: boolean = false;

    public selectedLanguages: any[] = [];
    public languages: Language[] = [];
    public allLanguages: Language[] = [];
    public dirtyLanguages: boolean = false;
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    private instrumentId: number = 0;

    public $refs!: {
        areYouSureModal: areYouSureModalVue;
    };

    public async created() {
        if (!this.isSuperAdmin) {
            return this.$router.push({ name: 'project-overview' });
        }

        this.instrumentId = parseInt($router.currentRoute.params.instrumentId);

        this.instrumentData = await instrumentsService.getInstrumentDetails(this.instrumentId);

        if (!this.instrumentData) {
            return this.showError(
                `Instrument data couldn't be retrieved. Please try again or, when the problem exists, contact us via the support button.`,
            );
        }

        await contextOptionService.getContextOptions().then((options) => {
            this.contextOptions = options;
        });

        await this.$store.dispatch('instrumentsStore/fetch');
        await this.loadLanguages();
        await this.loadKnowledgeModels();

        if (this.instrumentData.knowledgeModelId) {
            this.instrumentData.knowledgeModel = this.knowledgeModels.find((x) => x.knowledgeModelId === this.instrumentData.knowledgeModelId);
        }

        this.connectedInstrumentsOptions = this.currentKnowledgeModelInstruments.map((x) => {
            const relatedInstrument = this.instrumentData.connectedInstruments.find((y) => y.instrumentId === x.id);

            return {
                instrumentId: x.instrumentId,
                text: x.name,
                selected: relatedInstrument != null,
                mandatory: relatedInstrument ? relatedInstrument.mandatory : false,
                id: x.id,
            };
        });

        this.activeTTL = this.instrumentData.dataValidTtl != null;
        this.selectedMandatoryContext = this.instrumentData.contextOption;
        this.selectedOptions = this.instrumentData.contextLocation.split(',').map((x) => x.trim());

        this.clearNotifications();
        this.isLoaded = true;
    }

    public async optionChanged(evt: any) {
        if (evt.length > 1) {
            evt = evt.filter((x) => x !== 'none');
        }

        this.selectedOptions = evt;
    }

    public editReport(report: InstrumentReport) {
        this.$sideActions.push('instrument-report-side-action', { instrumentId: this.instrumentId, report }, async () => {
            this.instrumentData = await instrumentsService.getInstrumentDetails(this.instrumentId);
        });
    }

    public addNewReport() {
        this.$sideActions.push('instrument-report-side-action', { instrumentId: this.instrumentId }, async () => {
            this.instrumentData = await instrumentsService.getInstrumentDetails(this.instrumentId);
        });
    }

    public async loadLanguages() {
        this.selectedLanguages = [];
        this.languages = await languageService.getLanguagesForInstrument(this.instrumentId);
        this.allLanguages = await languageService.getLanguages();

        this.instrumentLanguages.forEach((x) => {
            const language = this.languages.find((y) => y.languageCode === x.value);
            if (language) {
                this.selectedLanguages.push(x.value);
            }
        });
    }

    public startEdit(): void {
        this.editMode = true;
    }

    public startEditConnectedInstruments() {
        this.connectedInstrumentsEditMode = true;
    }

    public cancelEditConnectedInstruments() {
        this.connectedInstrumentsEditMode = false;
    }

    public startLanguageEdit(): void {
        this.languageEditMode = true;
    }

    public startEditSettings() {
        this.settingsEditMode = true;
    }
    public cancelEditSettings() {
        this.settingsEditMode = false;
    }

    public cancel(): void {
        this.editMode = false;
    }

    public cancelLanguageEdit() {
        this.languageEditMode = false;
    }

    public changeLanguage() {
        this.dirtyLanguages = true;
    }

    public openAddLanguageAction(): any {
        this.$sideActions.push(
            'add-language-instrument-action',
            { instrumentId: this.instrumentId, knowledgeModelId: this.instrumentData.knowledgeModelId },
            async (newLang) => {
                const newLangObj = this.allLanguages.find((x) => x.languageCode === newLang);
                this.languages.push(newLangObj);
            },
        );
    }

    public async submit() {
        const self = this;
        this.showPending('instrument details are being saved');

        if (this.selectedMandatoryContext === 'noContext') {
            this.selectedOptions = ['none'];
        }

        const [err, instrument] = await to(
            instrumentsService.updateInstrument(self.instrumentData.instrumentId, {
                name: this.instrumentData.name,
                active: this.instrumentData.active,
                knowledgeModelId: this.instrumentData.knowledgeModel.knowledgeModelId,
                connectedInstruments: this.connectedInstrumentsOptions.filter((x) => x.selected),
                isAnonymous: this.instrumentData.isAnonymous,
                demographicQuestionsAllowed: this.instrumentData.demographicQuestionsAllowed,
                dataValidTtl: this.instrumentData.dataValidTtl,
                instrumentType: this.instrumentData.instrumentType.toString(),
                contextOption: this.selectedMandatoryContext,
                contextLocation: this.selectedOptions.join(','),
                groupQuestionsBySection: this.instrumentData.groupQuestionsBySection,
                surveyDuration: this.instrumentData.surveyDuration,
                surveyPriority: this.instrumentData.surveyPriority,
            }),
        );
        if (err) {
            this.showError('The details could not be updated due to an error');
        }

        self.instrumentData.knowledgeModelId = this.instrumentData.knowledgeModel.knowledgeModelId;
        self.instrumentData.connectedInstruments = instrument.connectedInstruments;

        this.clearAndShowSuccess('The details of this instrument has been successfully updated');
        this.connectedInstrumentsEditMode = this.editMode = this.settingsEditMode = false;
    }

    public get instrumentLanguages() {
        return this.allLanguages.map((x) => {
            return { value: x.languageCode, text: `${x.languageName} (${x.languageCode})` };
        });
    }

    public async deleteLanguage(languageCode: string) {
        const [err] = await to(languageService.removeLanguageFromInstrument(languageCode, this.instrumentId));
        if (err) {
            return this.showError('Failed to remove language');
        }

        await this.loadLanguages();
        return this.showSuccess('Successfully removed language');
    }

    public showAreYouSureModal() {
        this.$refs.areYouSureModal.setScope(
            [this.instrumentData],
            'name',
            'instrument',
            false,
            'Are you sure you want to (soft) delete this instrument? This action cannot be reverted. Practitioners will no longer be able to use this instrument.',
        );
        this.$refs.areYouSureModal.show();
    }

    public deleteInstrument() {
        this.showPending('Deleting instrument..');
        this.$refs.areYouSureModal.hide();

        instrumentsService
            .deleteInstrument(this.instrumentData.instrumentId)
            .then(async () => {
                this.showSuccess('The instrument is successfully deleted');
                await this.$store.dispatch('instrumentsStore/cleanup');
                await this.$store.dispatch('instrumentsStore/fetch');

                this.instrumentData.deleted = true;
            })
            .catch((e) => {
                this.showError(`Instrument couldn't be deleted`, e);
            });
    }

    public openInstrumentExportSideAction(): any {
        this.$sideActions.push('instrument-export-side-action', {
            instrumentId: this.instrumentId
        });
    }

    private async loadKnowledgeModels() {
        const response = await this.knowledgeModelService.getKnowledgeModels({
            skip: 0,
            take: 99,
            $count: false,
        });

        this.knowledgeModels = response.items;
    }
}
