export class Member {
    public id: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public insertion: string = '';
    public email: string = '';
    public twoFactorAuthEnabled: boolean = false;

    public practitionerId: number = 0;
    public partnerId: number = 0;
    public permissions: any = { Translator: false, DirectInvoicing: false };

    constructor(member?: any) {
        if (member) {
            Object.assign(this, member);
        }
    }

    public getFullName(): string {
        let parts = [this.firstName, this.insertion, this.lastName];
        parts = parts.filter((value) => {
            return !!value;
        });

        return parts.join(' ');
    }
}
