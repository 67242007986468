import { DropDownList } from '@progress/kendo-vue-dropdowns';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component({
    components: {
        'kendo-dropdownlist': DropDownList,
    },
})
export default class GridActiosComponent extends Vue {
    public text: string = '';
    public url: string = '';

    @Prop() public item: any;
    @Prop() public actions: any[];
    @Prop() public orientation: any;
    @Prop() public showSingleButton: boolean;

    public propItem: any = {};
    public propActions = [];
    public propOrientation = 'horizontal';
    public valueRender = 'valueTemplate';
    public itemRender = 'itemTemplate';
    private router: VueRouter = {} as VueRouter;

    public mounted() {
        if (this.orientation) {
            this.propOrientation = this.orientation;
        }
    }

    public async goToUrl() {
        await this.router.push(this.url);
    }

    public onSelect(e) {
        if (e && e.event) {
            e.event.preventDefault();
            e.event.stopPropagation();
        }

        if (e && e.dataItem && !e.dataItem.disabled) {
            e.dataItem.function(this.item);
        }
    }

    public onClose(e) {
        e.event.preventDefault();
        e.event.stopPropagation();
    }
}
