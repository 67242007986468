import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Project } from '../../../models/Project';

@Component
export default class GridStatusDisplayComponent extends Vue {
    @Prop() public dataItem: Project;

    public startDate: Date = null;
    public closeDate: Date = null;

    public async created() {
        this.startDate = this.dataItem.startDate;
        this.closeDate = this.dataItem.closeDate;
    }
}
