import QuestionAnswer from "./QuestionAnswer";

export default class Question {
    public questionId: number;
    public instrumentId: number;
    public section: number = 1;
    public answers: QuestionAnswer[];
    public position: number;
    public questionType: string;
    public reversed: boolean;
    public optionCount: number;
    public view?: string;

    constructor(question?: Partial<Question>) {
        if (question) {
            Object.assign(this, question);
        }
    }
}
