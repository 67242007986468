import { OrganizationsService } from '@/services/organizationsService';
import { Component, Prop } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import PageRender from '../../models/PageRender';

@Component({
    validations: {
        email: { required, email },
    },
} as any)
export default class ShareOrganizationComponent extends PageRender {
    @Prop() public organizationId: number;
    public submitted: boolean = false;
    public email: string = '';

    private organizationsService: OrganizationsService = new OrganizationsService();

    public cancel(): void {
        this.email = '';
        this.$emit('cancel');
    }

    public async submit(): Promise<void> {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showError(`The share didn't pass the validation.`);
            return;
        }

        this.showPending('Creating organization share...');

        await this.organizationsService
            .createShare(this.organizationId, this.email)
            .then(() => {
                this.clearAndShowSuccess('The Organization share has successfully been created');
                this.$emit('finished');
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    this.clearAndShowError(`The e-mail address ${this.email} is unknown to us. Try a different e-mail address!`, err);
                } else {
                    this.clearAndShowError('The Organization share could not be created', err);
                }
            });
    }
}
