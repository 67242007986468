import OrderBy, { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import { OrderByField } from '@/models/OrderAndFilter/OrderByField';

export default class ParticipantOrderBy extends OrderBy {
    public fields = [
        new OrderByField({
            key: 'dataItem.firstName',
            field: 'dataItem.FirstName',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'dataItem.lastName',
            field: 'dataItem.LastName',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'dataItem.emailAddress',
            field: 'dataItem.EmailAddress',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'dataItem.completionDate',
            field: 'dataItem.CompletionDate',
            direction: OrderByDirection.None,
        }),
    ];

    public getQueryString(): string {
        const orderFields = this.fields.filter((f: OrderByField) => f.direction !== OrderByDirection.None);
        if (orderFields.length === 0) {
            return '';
        }

        const orders = [];
        orderFields.sort((a, b) => a.order - b.order);
        orderFields.forEach((f: OrderByField) => {
            const fields = f.field.split('.');
            fields.shift();
            const field = fields.join('.');
            orders.push(f.direction === OrderByDirection.Asc ? field : `-${field}`);
        });
        return `OrderBy=${orders.join(',')}`;
    }
}
