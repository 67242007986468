import { SurveyDetails } from '@/models/Survey';

export enum EmailType {
    Unknown = 'Unknown',
    SurveyInvite = 'SurveyInvite',
    SurveyReminder = 'SurveyReminder',
    SurveyReport = 'SurveyReport',
}
export default class EmailActionParameters<T> {
    public title: string = '';
    public template: string = '';
    public placeholderObject: T;

    public projectId: number;
    public knowledgeModelAlias: string = '';
    public recipients: Array<EmailRecipient<T>> = [];
    public emailType: EmailType = EmailType.Unknown;
    public timeZone: string = '';
    public manualSelected: boolean = false;

    constructor(title: string, data: Partial<EmailActionParameters<T>>) {
        Object.assign(this, data);

        this.title = title;

        if (!this.template) {
            throw Error('Email template cannot be empty.');
        }
    }
}

// tslint:disable: max-classes-per-file
export class Recipient<T> {
    public data: T;
    public firstName: string = '';
    public fullName: string = '';
    public preferredLanguage: string = null;
}

export class EmailRecipient<T> extends Recipient<T> {
    public emailAddress: string = '';
    public lastName: string = '';

    public survey: SurveyDetails;
    public participantId: number;

    constructor(recipient: Partial<EmailRecipient<T>>) {
        super();
        Object.assign(this, recipient);
    }
}
