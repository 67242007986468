import { Component } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { loginHelper } from '@/main';
import { CreationStatus } from '../../../models/CreateResult';
import { OrganizationCreate } from '../../../models/Organization';
import { Country } from '@/models/Country';
import Practitioner from '@/modules/practitioners/models/Practitioner';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import SideActionBase from '@/plugins/sideActions/sideActionBase';
import { OrganizationsService } from '@/services/organizationsService';

// tslint:disable-next-line: max-classes-per-file
@Component({
    validations: {
        createOrganization: {
            name: { required },
            organizationType: { required },
            practitioner: {
                requiredIf: requiredIf(() => {
                    return !loginHelper.isInRole('Practitioner');
                }),
            },
        },
    },
} as any)
export default class CreateOrganizationComponent extends SideActionBase<any> {
    public submitted: boolean = false;
    public createOrganization: OrganizationCreate = new OrganizationCreate();

    public countryList: Country[] = [];
    public practitioners: Practitioner[] = [];

    public countryOptions: any[] = [];
    public stateOptions: any[] = [];
    public practitionerOptions: any[] = [];
    private practitionerService: PractitionerService = new PractitionerService();
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        await this.getPractitioners();
    }

    public cancelCreate(): void {
        this.createOrganization = new OrganizationCreate();
        this.cancel();
    }

    public async submit(): Promise<void> {
        this.submitted = true;
        if (!(this as any).$v.$invalid) {
            let practitionerId = loginHelper.getUser().practitionerId;
            if (
                (this.isPartner || this.isSuperAdmin) &&
                this.createOrganization.practitioner &&
                this.createOrganization.practitioner.practitionerId > 0
            ) {
                practitionerId = this.createOrganization.practitioner.practitionerId;
            }

            const createdOrganization = await this.organizationsService.createOrganization({
                name: this.createOrganization.name,
                vat: this.createOrganization.vat,
                countryId: this.createOrganization.country ? this.createOrganization.country.id : null,
                stateId: this.createOrganization.state ? this.createOrganization.state.id : null,
                city: this.createOrganization.city,
                zipCode: this.createOrganization.zipCode,
                address: this.createOrganization.address,
                organizationType: this.createOrganization.organizationType.value,
                contactName: this.createOrganization.contactName,
                practitionerId,
                retentionTime: this.createOrganization.retentionTime,
            });

            if (createdOrganization.creationStatus === CreationStatus.created) {
                this.$emit('created', createdOrganization.entity);
                this.clearAndShowSuccess('The Organization has successfully been created');
            } else {
                this.clearAndShowError('The Organization could not be created', createdOrganization);
            }

            this.finished(createdOrganization ? createdOrganization.entity : null);
        }
    }

    public onCountryChange(ev: any) {
        this.stateOptions = this.countryList.find((x) => {
            return x.id === ev.id;
        }).states;

        if (this.createOrganization.state) {
            this.createOrganization.state = new OrganizationCreate().state;
        }
    }

    private async getPractitioners() {
        await this.practitionerService
            .getPractitioners({
                skip: 0,
                take: 99999,
            })
            .then((practitioners: any) => {
                practitioners.items.forEach((practitioner: Practitioner) => {
                    this.practitionerOptions.push({
                        practitionerId: practitioner.practitionerId,
                        name: practitioner.firstName + ' ' + practitioner.lastName,
                    });
                });
            })
            .catch(() => {
                this.showError(
                    `The practitioners couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem exists, contact us with the support button.`,
                );
            });
    }
}
