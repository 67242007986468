import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import Instrument from '../Instrument';
import AccessScope from './AccessScope';

export default class ApiKey {
    public apiKeyId: string = '';
    public knowledgeModelId: number = null;
    public knowledgeModel: KnowledgeModel = null;
    public name: string = '';
    public description: string = '';
    public accessScope: AccessScope[] = [];
    public instruments: Instrument[] = [];
    public apiKeyHash: string = '';
    public practitionerId: number = 0;
    public disabled: boolean = false;
    public createDate: Date = null;
}
