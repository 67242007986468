import Component from 'vue-class-component';
import Vue from 'vue';
import { routeData } from '@/router';

@Component
export default class TopBarBreadcrumb extends Vue {
    public get breadcrumb() {
        const route = routeData.find((x) => {
            return x.children && (x.children as any[]).filter((y) => y.name === this.$route.name).length > 0;
        });
        return route;
    }

    public get breadcrumbItems() {
        const items = [];

        if (this.breadcrumb && this.breadcrumb.children) {
            this.breadcrumb.children.forEach((routeName) => {
                const resolved = this.$router.resolve({ name: routeName.name, params: this.$route.params });
                if (resolved) {
                    items.push({
                        text: routeName.meta && routeName.meta.menu ? routeName.meta.menu.label : routeName.name,
                        route: resolved.route,
                        path: resolved.href,
                    });
                }
            });
        }

        return items;
    }

    public get splitHref() {
        const route = this.$router.resolve({
            name: this.breadcrumb.name,
            params: {
                ecosystemId: this.$route.params.ecosystemId,
                ecosystemKey: this.$route.params.ecosystemKey,
                teamId: this.$route.params.teamId,
                teamKey: this.$route.params.teamKey,
            },
        });
        return route.href;
    }

    public goTo(resolvedRoute) {
        return this.$router.push(resolvedRoute);
    }
}
