import { AxiosResponse } from 'axios';
import { BaseService } from './baseService';
import Vue from 'vue';
import { ContextOptions } from '@/models/ContextOptions';
import { ContextLocationEnum } from '@/models/enums/ContextLocationEnum';

export class ContextOptionService extends BaseService {
    private endpoint = `${Vue.$env().ManagementServiceEndpoint}`;

    constructor() {
        super();
    }

    public async getContextOptions(): Promise<ContextOptions[]> {
        return [
            {
                id: 0,
                value: ContextLocationEnum.None,
                name: 'None',
            },
            {
                id: 1,
                value: ContextLocationEnum.BetweenQuestions,
                name: 'Between Questions',
            },
            {
                id: 2,
                value: ContextLocationEnum.AtQuestion,
                name: 'At Question',
            },
            {
                id: 4,
                value: ContextLocationEnum.InQuestion,
                name: 'In Question',
            },
        ];
    }

    public getContextOptionsForProject(projectId: number): Promise<ContextOptions[]> {
        return new Promise<ContextOptions[]>(async (resolve, reject) => {
            this.get<ContextOptions[]>(`${this.endpoint}/context-options/${projectId}`)
                .then((response: AxiosResponse<ContextOptions[]>) => {
                    resolve(response.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
