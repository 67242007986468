import { Project } from '@/models/Project';
import { OrganizationGroupService } from '@/services/organizationGroupService';
import { Component } from 'vue-property-decorator';
import { $router, eventBus, projectsService } from '../../main';
import BasePage from '@/models/BasePage';

@Component
export default class AddSurveyComponent extends BasePage {
    public projectId: number;
    public project: Project;
    public organizationGroups: any[] = [];

    private organizationGroupService: OrganizationGroupService = new OrganizationGroupService();

    public get organizationHasGroups() {
        return this.organizationGroups && this.organizationGroups.length > 0;
    }

    public async created() {
        this.projectId = parseInt($router.currentRoute.params.projectId);
        if (this.projectId > 0) {
            this.project = await projectsService.getProject(this.projectId);
            if (this.project.organizationId) {
                this.organizationGroups = await this.organizationGroupService.getOrganizationGroups(this.project.organizationId);
            } else {
                console.error('Project has no organizationId');
            }
        } else {
            console.error('ProjectId is not valid');
        }

        eventBus.$emit('add-wizard-bound');
    }
}
