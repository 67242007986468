import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { loginHelper } from '../../../main';

@Component({
    validations: {
        surveyExportForm: {
            emailAddress: { required },
            password: { required },
            periodStart: { required },
            periodEnd: { required },
        },
    },
} as any)
export default class SurveyOverviewExportActionComponent extends SideActionBase<any> {
    public submitted: boolean = false;
    public surveyExportForm: any = {
        emailAddress: '',
        password: '',
        periodStart: null,
        periodEnd: new Date(),
    };

    public async created() {
        this.surveyExportForm.emailAddress = loginHelper.getGivenName();
    }
}
