import Axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import AuthData from '@prd/shared-core/src/services/Login/AuthData';
import LoginServiceConfig from '@prd/shared-core/src/services/Login/LoginServiceConfig';
import jwt_decode from 'jwt-decode';
import { Mutex } from 'async-mutex';

export default class LoginService {
    public authAxiosInstance: AxiosInstance = Axios.create();
    private unAuthorizedHandler?: CallableFunction;

    private endpoint: string = '';
    private config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        },
    };
    private useRefreshTokens: boolean = true;
    private localStorageKey: string = 'authorizationData';
    private refreshMutex = new Mutex();
    private _passwordPostedData: string = '';

    constructor(config: LoginServiceConfig) {
        this.endpoint = config.endpoint;
        this.localStorageKey = config.localStorageKey;
        if (config.unAuthorizedHandler) {
            this.unAuthorizedHandler = config.unAuthorizedHandler;
        }

        this.authAxiosInstance = this.createAuthAxiosInstance();
        const authData = this.getAuthData();
        this.storeToken(authData.token, authData.refreshToken);
    }

    public async login(userName: string, password: string): Promise<LoginStatusResponse> {
        const secretData = `&username=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}`;
        const data = `grant_type=password${secretData}`;
        this._passwordPostedData = secretData;

        try {
            const response = await Axios.post(`${this.endpoint}token`, data, this.config);
            this._passwordPostedData = secretData;

            if (response.data.TwoFactorRequested) {
                return LoginStatusResponse.TwoFactorRequested;
            }

            this.storeToken(response.data.access_token, response.data.refresh_token);

            return LoginStatusResponse.Success;
        } catch (e) {
            this.clearToken();
            this._passwordPostedData = secretData;
            return LoginStatusResponse.InvalidCredentials;
        }
    }

    public async sendTwoFactorCode(code: string): Promise<LoginStatusResponse> {
        const data = `grant_type=2fa${this._passwordPostedData}&otp=${encodeURIComponent(code)}`;
        try {
            const response = await Axios.post(`${this.endpoint}token`, data, this.config);
            this.storeToken(response.data.access_token, response.data.refresh_token);
            return LoginStatusResponse.Success;
        } catch (e) {
            this.clearToken();
            return LoginStatusResponse.TwoFactorInvalid;
        }
    }

    public async setupTwoFactorAuth(): Promise<any> {
        const token = this.getAccessToken();
        this.config.headers['Authorization'] = `Bearer ${token}`;

        const response = await Axios.post(`${this.endpoint}api/tokens/setup-2fa`, null, this.config);
        return response;
    }

    public async enableTwoFactorAuth(code: string): Promise<any> {
        const token = this.getAccessToken();
        this.config.headers['Authorization'] = `Bearer ${token}`;

        const response = await Axios.post(
            `${this.endpoint}api/tokens/enable-2fa`,
            {
                otp: code,
            },
            this.config,
        );
        return response.data;
    }

    public async refreshToken(): Promise<boolean> {
        return await this.refreshMutex.runExclusive(async () => {
            const authData = this.getAuthData();
            if (!authData || !authData.useRefreshTokens) {
                alert('Not able to refresh your token, because no token was found');
                return false;
            }
            const data = `grant_type=refresh_token&refresh_token=${authData.refreshToken}`;
            try {
                const response = await Axios.post(`${this.endpoint}token`, data, this.config);
                this.useRefreshTokens = true;
                this.storeToken(response.data.access_token, response.data.refresh_token);
                return true;
            } catch (e) {
                this.clearToken();
                return false;
            }
        });
    }

    public storeToken(token: string, refreshToken: string) {
        this.authAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
        localStorage.setItem(
            this.localStorageKey,
            JSON.stringify({
                token,
                refreshToken,
                useRefreshTokens: this.useRefreshTokens,
            }),
        );
    }

    public clearToken(): void {
        localStorage.removeItem(this.localStorageKey);
    }

    public getAuthData(): AuthData {
        const jsonString = localStorage.getItem(this.localStorageKey) || null;
        if (jsonString === null) {
            return new AuthData();
        }

        return JSON.parse(jsonString) as AuthData;
    }

    public getAccessToken(): string {
        const data = this.getAuthData();
        return data.token;
    }

    public getJwtData(): any {
        return jwt_decode(this.getAccessToken());
    }

    public isLoggedIn(): boolean {
        return !!this.getAccessToken();
    }

    public logout() {
        this.clearToken();
    }

    public async forgotPassword(username: string): Promise<any> {
        const data = 'EmailAddress=' + encodeURIComponent(username);
        return Axios.post(`${this.endpoint}` + 'api/tokens/resetpassword', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }

    public resetPassword(code: string, password: string, passwordCheck: string): Promise<any> {
        code = encodeURIComponent(code);
        password = encodeURIComponent(password);
        passwordCheck = encodeURIComponent(passwordCheck);
        const data = `code=${code}&password=${password}&passwordRepeat=${passwordCheck}`;

        return Axios.post(`${this.endpoint}api/tokens/confirmresetpassword`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }

    private createAuthAxiosInstance(): AxiosInstance {
        const axiosInstance = Axios.create();

        axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
            if (!navigator.onLine) {
                return Promise.reject('Offline');
            }

            if (this.isLoggedIn() && this.isTokenExpired()) {
                const success = await this.refreshToken();
                if (!success) {
                    this.unAuthorized();
                    return Promise.reject('Token request failed');
                }

                const jwt = this.getAccessToken();
                config.headers.Authorization = `Bearer ${jwt}`;
            }
            return config;
        });

        axiosInstance.interceptors.response.use(
            (response) => response,
            async (err) => {
                if (err && err.response && err.response.status === 401 && this.isTokenExpired()) {
                    this.unAuthorized();
                }
                return Promise.reject(err);
            },
        );
        return axiosInstance;
    }

    private isTokenExpired(): boolean {
        const expirationDate = this.getExpirationDate().getTime();
        const dateNow = new Date().getTime() + 10 * 1000;
        return expirationDate < dateNow;
    }

    private getExpirationDate(): Date {
        if (!this.isLoggedIn()) {
            return new Date();
        }

        const ac = this.getAccessToken();
        const exp = (jwt_decode(ac) as any).exp;
        return exp ? new Date(exp * 1000) : new Date();
    }

    private unAuthorized() {
        this.logout();
        if (this.unAuthorizedHandler) {
            this.unAuthorizedHandler();
        } else {
            window.location.reload();
        }
    }
}

export enum LoginStatusResponse {
    Success = 0,
    InvalidCredentials = 1,
    TwoFactorRequested = 2,
    TwoFactorInvalid = 3,
}
